import { NotificationName } from "../components/Notification/constants";

export type ClosedAlert = {
  organisationId: string;
  name: NotificationName;
};

export type Redirect = {
  organisationId: string;
  route: string;
};

export interface LibeoLocalStorage {
  email: string;
  token: string;
  userWantDemoDisplayed: boolean;
  closedAlert: Array<ClosedAlert>;
  payLaterThirdParty: boolean;
  payPartiallyThirdParty: boolean;
  payExternallyThirdParty: boolean;
  requestBusinessApprovalThirdParty: boolean;
  putOnHoldThirdParty: boolean;
  redirectThirdParty: string | null;
  closeWindowThirdParty: boolean;
  redirect: Array<Redirect>;
  thirdPartyCustomisedLogo: string;
}

type localStorageProperty = keyof LibeoLocalStorage;

export const getLocalStorage = <T extends localStorageProperty>(key: localStorageProperty): LibeoLocalStorage[T] | undefined => {
  try {
    const localStorage = window.localStorage.getItem("libeo");

    if (localStorage) {
      return JSON.parse(localStorage)[key];
    }
  } catch (e) {
    return undefined;
  }

  return undefined;
};

export const setLocalStorage = <T extends localStorageProperty>(key: localStorageProperty, value: LibeoLocalStorage[T]): void => {
  try {
    const localStorage = window.localStorage.getItem("libeo");

    if (localStorage) {
      const currentLocalStorage: LibeoLocalStorage = JSON.parse(localStorage);
      const newLocalStorage: LibeoLocalStorage = { ...currentLocalStorage, [key]: value };
      window.localStorage.setItem("libeo", JSON.stringify(newLocalStorage));
    } else {
      window.localStorage.setItem("libeo", JSON.stringify({ [key]: value }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const removeFromLocalStorage = (key: localStorageProperty): void => {
  try {
    const localStorage = window.localStorage.getItem("libeo");

    if (localStorage) {
      const currentLocalStorage: LibeoLocalStorage = JSON.parse(localStorage);
      delete currentLocalStorage[key];
      if (Object.keys(currentLocalStorage).length === 0) {
        window.localStorage.removeItem("libeo");
      } else {
        window.localStorage.setItem("libeo", JSON.stringify(currentLocalStorage));
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
