import * as React from "react";
import { Box } from "../Layout";
import { ProgressBarProps } from "./types";

/**
 * `Progressbar` is a display component used to display progress.
 */
export const ProgressBar = ({ max = 100, value, color = "blue-palatinate" }: ProgressBarProps): React.ReactElement => {
  const completion = (value / max) * 100;
  return (
    <Box position="relative">
      <Box height={4} borderRadius="small" backgroundColor={color} opacity={0.2} />
      <Box position="absolute" height={4} width={`${completion}%`} borderRadius="small" backgroundColor={color} />
    </Box>
  );
};

export type { ProgressBarProps };
