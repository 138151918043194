import * as React from "react";
import { AlignCenter, AlignLeft, AlignRight } from "../Layout";
import { MarginSmall } from "../Margin";
import { DialogButtonDisplay, DialogButtonProps } from "./types";

const singleButtonDisplayMap = (button: React.ReactNode, display: DialogButtonDisplay): JSX.Element => {
  if (display === DialogButtonDisplay.RIGHT) {
    return <AlignRight>{button}</AlignRight>;
  }
  return <AlignCenter>{button}</AlignCenter>;
};

const twoButtonsDisplayMap = (primaryButton: React.ReactNode, secondaryButton: React.ReactNode, display: DialogButtonDisplay): JSX.Element => {
  switch (display) {
    case DialogButtonDisplay.VERTICAL_CENTER:
      return (
        <AlignCenter>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {React.cloneElement(primaryButton as React.ReactElement, { width: "100%" })}
            <MarginSmall top>{React.cloneElement(secondaryButton as React.ReactElement, { width: "100%" })}</MarginSmall>
          </div>
        </AlignCenter>
      );
    case DialogButtonDisplay.RIGHT:
      return (
        <AlignRight>
          {secondaryButton}
          {primaryButton}
        </AlignRight>
      );
    case DialogButtonDisplay.SEPARATE:
      return (
        <div style={{ display: "flex" }}>
          <AlignLeft>{secondaryButton}</AlignLeft>
          <AlignRight>{primaryButton}</AlignRight>
        </div>
      );
    default:
      return (
        <AlignCenter>
          {secondaryButton}
          <MarginSmall left>{primaryButton}</MarginSmall>
        </AlignCenter>
      );
  }
};

export const DialogButton: React.FunctionComponent<DialogButtonProps> = ({
  display = DialogButtonDisplay.VERTICAL_CENTER,
  primaryButton,
  secondaryButton,
}) => {
  if (secondaryButton) {
    return twoButtonsDisplayMap(primaryButton, secondaryButton, display);
  }
  return singleButtonDisplayMap(primaryButton, display);
};
