/* eslint-disable max-lines */

import * as React from "react";
import { spacing } from "../theme/spacing";
import { BLUE_PALATINATE } from "../theme/ui-constants";
import { lazyCatchError } from "../utils/lazy-catch-error";
import { DefaultPropsType, IconValue as IconValueEnum } from "./icon.type";

export declare type SVGComponent = React.FunctionComponent<DefaultPropsType>;
export declare type SVGImport = Promise<{ default: React.FunctionComponent<DefaultPropsType> }>;

const NotAllowed = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "NotAllowed" */ "./assets/NotAllowed") as unknown) as SVGImport),
);
const FileViewerPlaceholder = React.lazy(() =>
  lazyCatchError<SVGComponent>(
    () => (import(/* webpackChunkName: "FileViewerPlaceholder" */ "./assets/FileViewerPlaceholder") as unknown) as SVGImport,
  ),
);

const Bookmark = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Bookmark" */ "./assets/Bookmark") as unknown) as SVGImport),
);
const Bluetooth = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Bluetooth" */ "./assets/Bluetooth") as unknown) as SVGImport),
);
const Bookmarks = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Bookmarks" */ "./assets/Bookmarks") as unknown) as SVGImport),
);
const Box = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Box" */ "./assets/Box") as unknown) as SVGImport));
const Broadcast = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Broadcast" */ "./assets/Broadcast") as unknown) as SVGImport),
);
const Browser = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Browser" */ "./assets/Browser") as unknown) as SVGImport),
);
const BrowserUpload = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "BrowserUpload" */ "./assets/BrowserUpload") as unknown) as SVGImport),
);
const Direction = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Direction" */ "./assets/Direction") as unknown) as SVGImport),
);
const BrokenLink = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "BrokenLink" */ "./assets/BrokenLink") as unknown) as SVGImport),
);
const Message = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Message" */ "./assets/Message") as unknown) as SVGImport),
);
const Suitcase = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Suitcase" */ "./assets/Suitcase") as unknown) as SVGImport),
);

const Ticket = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Ticket" */ "./assets/Ticket") as unknown) as SVGImport),
);
const Cloud = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Cloud" */ "./assets/Cloud") as unknown) as SVGImport),
);
const CustomerInvite = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CustomerInvite" */ "./assets/CustomerInvite") as unknown) as SVGImport),
);
const Archive = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Archive" */ "./assets/Archive") as unknown) as SVGImport),
);
const Article = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Article" */ "./assets/Article") as unknown) as SVGImport),
);
const BackSpace = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "BackSpace" */ "./assets/BackSpace") as unknown) as SVGImport),
);
const Basket = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Basket" */ "./assets/Basket") as unknown) as SVGImport),
);
const Crosshair = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Crosshair" */ "./assets/Crosshair") as unknown) as SVGImport),
);
const Dashboard = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Dashboard" */ "./assets/Dashboard") as unknown) as SVGImport),
);
const CircleDashed = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CircleDashed" */ "./assets/CircleDashed") as unknown) as SVGImport),
);
const LightBulb = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "LightBulb" */ "./assets/LightBulb") as unknown) as SVGImport),
);
const ThumbsDown = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ThumbsDown" */ "./assets/ThumbsDown") as unknown) as SVGImport),
);
const ZoomIn = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ZoomIn" */ "./assets/ZoomIn") as unknown) as SVGImport),
);
const ZoomOut = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ZoomOut" */ "./assets/ZoomOut") as unknown) as SVGImport),
);
const Cart = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Cart" */ "./assets/Cart") as unknown) as SVGImport));

const CegidIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CegidIcon" */ "./assets/CegidIcon") as unknown) as SVGImport),
);

const ChainLink = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChainLink" */ "./assets/ChainLink") as unknown) as SVGImport),
);

const ChaintrustIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChaintrustIcon" */ "./assets/ChaintrustIcon") as unknown) as SVGImport),
);

const MyUnisoftIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "MyUnisoftIcon" */ "./assets/MyUnisoftIcon") as unknown) as SVGImport),
);

const Checklist = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Checklist" */ "./assets/Checklist") as unknown) as SVGImport),
);
const StarIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "StarIcon" */ "./assets/StarIcon") as unknown) as SVGImport),
);
const Scissors = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Scissors" */ "./assets/Scissors") as unknown) as SVGImport),
);
const Tags = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Tags" */ "./assets/Tags") as unknown) as SVGImport));
const Wrong = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Wrong" */ "./assets/Wrong") as unknown) as SVGImport),
);

const Mail = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Mail" */ "./assets/Mail") as unknown) as SVGImport));
const tagDelete = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "tagDelete" */ "./assets/tagDelete") as unknown) as SVGImport),
);
const upload = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "upload" */ "./assets/upload") as unknown) as SVGImport),
);
const cloudDownload = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "cloudDownload" */ "./assets/cloudDownload") as unknown) as SVGImport),
);
const Hourglass = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Hourglass" */ "./assets/Hourglass") as unknown) as SVGImport),
);
const Swap = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Swap" */ "./assets/Swap") as unknown) as SVGImport));
const Toggle = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Toggle" */ "./assets/Toggle") as unknown) as SVGImport),
);
const Trophy = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Trophy" */ "./assets/Trophy") as unknown) as SVGImport),
);
const Minus = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Minus" */ "./assets/Minus") as unknown) as SVGImport),
);
const MoneyPot = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "MoneyPot" */ "./assets/MoneyPot") as unknown) as SVGImport),
);
const Reply = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Reply" */ "./assets/Reply") as unknown) as SVGImport),
);
const ReplyAll = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ReplyAll" */ "./assets/ReplyAll") as unknown) as SVGImport),
);
const Return = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Return" */ "./assets/Return") as unknown) as SVGImport),
);
const Ping = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Ping" */ "./assets/Ping") as unknown) as SVGImport));
const QuestionIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "QuestionIcon" */ "./assets/QuestionIcon") as unknown) as SVGImport),
);
const Copy = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Copy" */ "./assets/Copy") as unknown) as SVGImport));
const Phone = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Phone" */ "./assets/Phone") as unknown) as SVGImport),
);
const PhotoGroup = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PhotoGroup" */ "./assets/PhotoGroup") as unknown) as SVGImport),
);
const Card = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Card" */ "./assets/Card") as unknown) as SVGImport));
const Flag = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Flag" */ "./assets/Flag") as unknown) as SVGImport));
const graphBar = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "graphBar" */ "./assets/graphBar") as unknown) as SVGImport),
);
const graphPie = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "graphPie" */ "./assets/graphPie") as unknown) as SVGImport),
);
const Gift = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Gift" */ "./assets/Gift") as unknown) as SVGImport));
const Google = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Google" */ "./assets/Google") as unknown) as SVGImport),
);
const Apple = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Apple" */ "./assets/Apple") as unknown) as SVGImport),
);
const Alarm = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Alarm" */ "./assets/Alarm") as unknown) as SVGImport),
);
const ArrowDown = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowDown" */ "./assets/ArrowDown") as unknown) as SVGImport),
);
const ArrowReturn = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowReturn" */ "./assets/ArrowReturn") as unknown) as SVGImport),
);
const ArrowThinLeft = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowThinLeft" */ "./assets/ArrowThinLeft") as unknown) as SVGImport),
);
const ArrowThinRight = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowThinRight" */ "./assets/ArrowThinRight") as unknown) as SVGImport),
);
const ArrowUp = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowUp" */ "./assets/ArrowUp") as unknown) as SVGImport),
);

const ArrowTopRight = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowTopRight" */ "./assets/ArrowTopRight") as unknown) as SVGImport),
);

const ArrowsTowardsCenter = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ArrowsTowardsCenter" */ "./assets/ArrowsTowardsCenter") as unknown) as SVGImport),
);

const Bell = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Bell" */ "./assets/Bell") as unknown) as SVGImport));
const Blog = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Blog" */ "./assets/Blog") as unknown) as SVGImport));
const Briefcase = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Briefcase" */ "./assets/Briefcase") as unknown) as SVGImport),
);
const BudgetInsight = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "BudgetInsight" */ "./assets/BudgetInsight") as unknown) as SVGImport),
);
const Buy = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Buy" */ "./assets/Buy") as unknown) as SVGImport));
const Calendar = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Calendar" */ "./assets/Calendar") as unknown) as SVGImport),
);
const Change = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Change" */ "./assets/Change") as unknown) as SVGImport),
);
const Checkmark = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Checkmark" */ "./assets/Checkmark") as unknown) as SVGImport),
);
const CheckmarkInCircle = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CheckmarkInCircle" */ "./assets/CheckmarkInCircle") as unknown) as SVGImport),
);
const ChevronDown = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChevronDown" */ "./assets/ChevronDown") as unknown) as SVGImport),
);
const ChevronLeft = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChevronLeft" */ "./assets/ChevronLeft") as unknown) as SVGImport),
);
const ChevronRight = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChevronRight" */ "./assets/ChevronRight") as unknown) as SVGImport),
);
const ChevronUp = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChevronUp" */ "./assets/ChevronUp") as unknown) as SVGImport),
);
const Clock = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Clock" */ "./assets/Clock") as unknown) as SVGImport),
);
const Clockwise = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Clockwise" */ "./assets/Clockwise") as unknown) as SVGImport),
);
const CloudUpload = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CloudUpload" */ "./assets/CloudUpload") as unknown) as SVGImport),
);
const ConfetiMain = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ConfetiMain" */ "./assets/ConfetiMain") as unknown) as SVGImport),
);
const Conversation = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Conversation" */ "./assets/Conversation") as unknown) as SVGImport),
);
const Cross = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Cross" */ "./assets/Cross") as unknown) as SVGImport),
);
const CrossCircled = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "CrossCircled" */ "./assets/CrossCircled") as unknown) as SVGImport),
);
const Dot = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Dot" */ "./assets/Dot") as unknown) as SVGImport));
const Dots3 = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Dots3" */ "./assets/Dots3") as unknown) as SVGImport),
);
const Download = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Download" */ "./assets/Download") as unknown) as SVGImport),
);
const DownloadInTray = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "DownloadInTray" */ "./assets/DownloadInTray") as unknown) as SVGImport),
);
const DragAndDrop = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "DragAndDrop" */ "./assets/DragAndDrop") as unknown) as SVGImport),
);
const Edit = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Edit" */ "./assets/Edit") as unknown) as SVGImport));

const Euro = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Euro" */ "./assets/Euro") as unknown) as SVGImport));

const Ellipse = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Ellipse" */ "./assets/Ellipse") as unknown) as SVGImport),
);
const EmptySearch = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "EmptySearch" */ "./assets/EmptySearch") as unknown) as SVGImport),
);
const Experiment = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Experiment" */ "./assets/Experiment") as unknown) as SVGImport),
);

const Export = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Export" */ "./assets/Export") as unknown) as SVGImport),
);
const EyeOpen = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "EyeOpen" */ "./assets/EyeOpen") as unknown) as SVGImport),
);
const Facture = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Facture" */ "./assets/Facture") as unknown) as SVGImport),
);
const FollowingTheIdea = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "FollowingTheIdea" */ "./assets/FollowingTheIdea") as unknown) as SVGImport),
);
const Folder = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Folder" */ "./assets/Folder") as unknown) as SVGImport),
);
const FundsInsufficient = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "FundsInsufficient" */ "./assets/FundsInsufficient") as unknown) as SVGImport),
);
const Gear = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Gear" */ "./assets/Gear") as unknown) as SVGImport));
const GetPaid = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "GetPaid" */ "./assets/GetPaid") as unknown) as SVGImport),
);
const Graduation = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Graduation" */ "./assets/Graduation") as unknown) as SVGImport),
);
const Heart = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Heart" */ "./assets/Heart") as unknown) as SVGImport),
);
const Hello = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Hello" */ "./assets/Hello") as unknown) as SVGImport),
);
const HelloHand = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "HelloHand" */ "./assets/HelloHand") as unknown) as SVGImport),
);
const Help = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Help" */ "./assets/Help") as unknown) as SVGImport));
const Home = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Home" */ "./assets/Home") as unknown) as SVGImport));
const Information = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Information" */ "./assets/Information") as unknown) as SVGImport),
);
const Inbox = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Inbox" */ "./assets/Inbox") as unknown) as SVGImport),
);
const Intercom = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Intercom" */ "./assets/Intercom") as unknown) as SVGImport),
);
const Interrogation = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Interrogation" */ "./assets/Interrogation") as unknown) as SVGImport),
);
const Invoice = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Invoice" */ "./assets/Invoice") as unknown) as SVGImport),
);
const InvoiceScan = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "InvoiceScan" */ "./assets/InvoiceScan") as unknown) as SVGImport),
);
const InvoiceUpload = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "InvoiceUpload" */ "./assets/InvoiceUpload") as unknown) as SVGImport),
);
const KycIban = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "KycIban" */ "./assets/KycIban") as unknown) as SVGImport),
);
const KycIntro = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "KycIntro" */ "./assets/KycIntro") as unknown) as SVGImport),
);
const LibeoCheckMark = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "LibeoCheckMark" */ "./assets/LibeoCheckMark") as unknown) as SVGImport),
);
const Link = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Link" */ "./assets/Link") as unknown) as SVGImport));
const Lock = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Lock" */ "./assets/Lock") as unknown) as SVGImport));
const Logo = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Logo" */ "./assets/Logo") as unknown) as SVGImport));
const LogoWithoutText = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "LogoWithoutText" */ "./assets/LogoWithoutText") as unknown) as SVGImport),
);
const LibeoPremium = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "LibeoPremium" */ "./assets/LibeoPremium") as unknown) as SVGImport),
);
const LibeoCertified = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "LibeoCertified" */ "./assets/LibeoCertified") as unknown) as SVGImport),
);
const Map = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Map" */ "./assets/Map") as unknown) as SVGImport));
const Menu = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Menu" */ "./assets/Menu") as unknown) as SVGImport));
const Network = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Network" */ "./assets/Network") as unknown) as SVGImport),
);
const NoData0 = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "NoData0" */ "./assets/NoData0") as unknown) as SVGImport),
);
const NoData1 = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "NoData1" */ "./assets/NoData1") as unknown) as SVGImport),
);
const NoData2 = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "NoData2" */ "./assets/NoData2") as unknown) as SVGImport),
);
const Paid = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Paid" */ "./assets/Paid") as unknown) as SVGImport));
const Pay = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Pay" */ "./assets/Pay") as unknown) as SVGImport));
const Paper = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Paper" */ "./assets/Paper") as unknown) as SVGImport),
);
const Paperclip = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Paperclip" */ "./assets/Paperclip") as unknown) as SVGImport),
);
const ParteoSaas = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ParteoSaas" */ "./assets/ParteoSaas") as unknown) as SVGImport),
);
const Pencil = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Pencil" */ "./assets/Pencil") as unknown) as SVGImport),
);
const Pin = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Pin" */ "./assets/Pin") as unknown) as SVGImport));
const Plus = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Plus" */ "./assets/Plus") as unknown) as SVGImport));
const Profile = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Profile" */ "./assets/Profile") as unknown) as SVGImport),
);
const Pulse = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Pulse" */ "./assets/Pulse") as unknown) as SVGImport),
);
const Question = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Question" */ "./assets/Question") as unknown) as SVGImport),
);
const Quickbooks = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Quickbooks" */ "./assets/Quickbooks") as unknown) as SVGImport),
);
const Evoliz = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Evoliz" */ "./assets/Evoliz") as unknown) as SVGImport),
);
const Dext = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Dext" */ "./assets/Dext") as unknown) as SVGImport));
const Retweet = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Retweet" */ "./assets/Retweet") as unknown) as SVGImport),
);
const Rocket = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Rocket" */ "./assets/Rocket") as unknown) as SVGImport),
);
const Rocketman = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Rocketman" */ "./assets/Rocketman") as unknown) as SVGImport),
);
const Search = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Search" */ "./assets/Search") as unknown) as SVGImport),
);
const Sell = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Sell" */ "./assets/Sell") as unknown) as SVGImport));
const Settings = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Settings" */ "./assets/Settings") as unknown) as SVGImport),
);
const Share = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Share" */ "./assets/Share") as unknown) as SVGImport),
);
const Star = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Star" */ "./assets/Star") as unknown) as SVGImport));
const Stat = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Stat" */ "./assets/Stat") as unknown) as SVGImport));
const Store = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Store" */ "./assets/Store") as unknown) as SVGImport),
);
const Tag = React.lazy(() => lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Tag" */ "./assets/Tag") as unknown) as SVGImport));
const ThumbsUp = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ThumbsUp" */ "./assets/ThumbsUp") as unknown) as SVGImport),
);
const Thumbnails = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Thumbnails" */ "./assets/Thumbnails") as unknown) as SVGImport),
);
const TimeReverse = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "TimeReverse" */ "./assets/TimeReverse") as unknown) as SVGImport),
);
const Trash = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Trash" */ "./assets/Trash") as unknown) as SVGImport),
);
const TwoPersons = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "TwoPersons" */ "./assets/TwoPersons") as unknown) as SVGImport),
);
const UnLock = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "UnLock" */ "./assets/UnLock") as unknown) as SVGImport),
);
const UserGroup = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "UserGroup" */ "./assets/UserGroup") as unknown) as SVGImport),
);
const Wallet = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Wallet" */ "./assets/Wallet") as unknown) as SVGImport),
);
const WalletOut = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "WalletOut" */ "./assets/WalletOut") as unknown) as SVGImport),
);
const Warning = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Warning" */ "./assets/Warning") as unknown) as SVGImport),
);
const ZapierIcon = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ZapierIcon" */ "./assets/ZapierIcon") as unknown) as SVGImport),
);
const ErrorModal = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ErrorModal" */ "./assets/ErrorModal") as unknown) as SVGImport),
);
const Welyb = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Welyb" */ "./assets/Welyb") as unknown) as SVGImport),
);
const Sponsorship = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Sponsorship" */ "./assets/Sponsorship") as unknown) as SVGImport),
);
const DocumentFile = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "DocumentFile" */ "./assets/DocumentFile") as unknown) as SVGImport),
);
const UserId = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "UserId" */ "./assets/UserId") as unknown) as SVGImport),
);

const InvoiceImport = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "InvoiceImport" */ "./assets/InvoiceImport") as unknown) as SVGImport),
);

const PayCurrency = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PayCurrency" */ "./assets/PayCurrency") as unknown) as SVGImport),
);

const PuzzleBuild = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PuzzleBuild" */ "./assets/PuzzleBuild") as unknown) as SVGImport),
);

const PuzzleIdea = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PuzzleIdea" */ "./assets/PuzzleIdea") as unknown) as SVGImport),
);

const PhoneTransmission = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PhoneTransmission" */ "./assets/PhoneTransmission") as unknown) as SVGImport),
);

const PhoneMoney = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "PhoneMoney" */ "./assets/PhoneMoney") as unknown) as SVGImport),
);

const ComputerMoney = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ComputerMoney" */ "./assets/ComputerMoney") as unknown) as SVGImport),
);

const ChartsStep = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ChartsStep" */ "./assets/ChartsStep") as unknown) as SVGImport),
);

const Fingerprint = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "Fingerprint" */ "./assets/Fingerprint") as unknown) as SVGImport),
);

const ZenHandy = React.lazy(() =>
  lazyCatchError<SVGComponent>(() => (import(/* webpackChunkName: "ZenHandy" */ "./assets/ZenHandy") as unknown) as SVGImport),
);

const Svgs: { [key in IconValueEnum]: React.LazyExoticComponent<SVGComponent> } = {
  Alarm,
  Apple,
  Archive,
  ArrowDown,
  ArrowReturn,
  ArrowThinLeft,
  ArrowThinRight,
  ArrowUp,
  ArrowTopRight,
  ArrowsTowardsCenter,
  Article,
  BackSpace,
  Basket,
  Bell,
  Blog,
  Bluetooth,
  Bookmark,
  Bookmarks,
  Box,
  Briefcase,
  Broadcast,
  BrokenLink,
  Browser,
  BrowserUpload,
  BudgetInsight,
  Buy,
  Calendar,
  Card,
  Cart,
  CegidIcon,
  ChainLink,
  ChaintrustIcon,
  MyUnisoftIcon,
  Change,
  Checklist,
  Checkmark,
  CheckmarkInCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleDashed,
  Clock,
  Clockwise,
  CustomerInvite,
  Cloud,
  cloudDownload,
  CloudUpload,
  ConfetiMain,
  Conversation,
  Copy,
  Cross,
  CrossCircled,
  Crosshair,
  Dashboard,
  Direction,
  Dot,
  Dots3,
  Download,
  DownloadInTray,
  DragAndDrop,
  Edit,
  Euro,
  Ellipse,
  EmptySearch,
  ErrorModal,
  Experiment,
  Export,
  EyeOpen,
  Facture,
  Flag,
  FollowingTheIdea,
  Folder,
  FundsInsufficient,
  Gear,
  GetPaid,
  Graduation,
  graphBar,
  graphPie,
  Gift,
  Google,
  Heart,
  Hello,
  HelloHand,
  Help,
  Home,
  Hourglass,
  Inbox,
  Information,
  Intercom,
  Interrogation,
  Invoice,
  InvoiceScan,
  InvoiceUpload,
  KycIban,
  KycIntro,
  LibeoCheckMark,
  LightBulb,
  Link,
  Lock,
  Logo,
  LogoWithoutText,
  LibeoPremium,
  LibeoCertified,
  Map,
  Mail,
  Menu,
  Message,
  Minus,
  MoneyPot,
  Network,
  NoData0,
  NoData1,
  NoData2,
  Paid,
  Pay,
  Paper,
  Paperclip,
  ParteoSaas,
  Pencil,
  Phone,
  PhotoGroup,
  Pin,
  Ping,
  Plus,
  Profile,
  Pulse,
  Question,
  QuestionIcon,
  Quickbooks,
  Evoliz,
  Dext,
  Reply,
  ReplyAll,
  Return,
  Retweet,
  Rocket,
  Rocketman,
  Search,
  Sell,
  Settings,
  Share,
  Star,
  StarIcon,
  Scissors,
  Stat,
  Store,
  Suitcase,
  Swap,
  Tag,
  tagDelete,
  Tags,
  ThumbsDown,
  ThumbsUp,
  Thumbnails,
  Ticket,
  TimeReverse,
  Toggle,
  Trash,
  Trophy,
  TwoPersons,
  UnLock,
  upload,
  UserGroup,
  Wallet,
  WalletOut,
  Warning,
  Wrong,
  ZapierIcon,
  ZoomIn,
  ZoomOut,
  Welyb,
  FileViewerPlaceholder,
  NotAllowed,
  Sponsorship,
  DocumentFile,
  UserId,
  InvoiceImport,
  PayCurrency,
  PuzzleBuild,
  PuzzleIdea,
  PhoneTransmission,
  PhoneMoney,
  ComputerMoney,
  ChartsStep,
  Fingerprint,
  ZenHandy,
};

export const Icon = React.forwardRef<HTMLDivElement, DefaultPropsType>(
  (
    {
      value,
      rotate = 0,
      width = "20px",
      height = "20px",
      color = BLUE_PALATINATE,
      style,
      onClick,
      circleBorder,
      backgroundColor,
      borderColor,
      noMargin,
      ...props
    },
    ref,
  ) => {
    const Svg = Svgs[value];
    const iconSizeToCercleSizeRatio = 0.42;
    const svgWidth = circleBorder ? `calc(${width} * ${iconSizeToCercleSizeRatio})` : width;
    const svgHeight = circleBorder ? `calc(${height} * ${iconSizeToCercleSizeRatio})` : height;

    return (
      <div
        // @ts-ignore
        ref={ref}
        style={{
          border: circleBorder ? `1px solid ${borderColor || color}` : undefined,
          borderRadius: "50%",
          backgroundColor: backgroundColor || "transparent",
          width,
          height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: noMargin ? undefined : spacing(0, 1),
          ...style,
          ...(rotate && { transform: `rotate(${rotate}deg)` }),
        }}
        {...props}
      >
        <React.Suspense
          fallback={
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height}>
              <rect x="0" y="0" width="0" height="0" />
            </svg>
          }
        >
          <Svg value={value} data-title={value} width={svgWidth} height={svgHeight} color={color} onClick={onClick} />
        </React.Suspense>
      </div>
    );
  },
);

export { IconValueEnum as IconValue };
