import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { BoxPrimitive, BoxPrimitiveProps, GridContainerProps, ShadowProps } from "../../../primitives";

const DEFAULT_TAG = "div";

export type BoxProps = Polymorphic.Merge<
  Omit<Polymorphic.OwnProps<typeof BoxPrimitive>, keyof GridContainerProps<"noprefix"> | keyof ShadowProps<"noprefix">>,
  {
    shadow?: BoxPrimitiveProps["boxShadow"];
  }
>;
type BoxPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, BoxProps>;

/**
 * `Box` is a general purpose container with no specific semantics that can be used for custom styling purposes.
 */
export const Box = React.forwardRef(({ as = DEFAULT_TAG, shadow, ...props }, ref) => (
  <BoxPrimitive ref={ref} as={as} boxShadow={shadow} {...props} />
)) as BoxPrimitive;
