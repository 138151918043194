/* eslint-disable sonarjs/no-duplicate-string */
import * as Polymorphic from "@radix-ui/react-polymorphic";
import { useButton } from "@react-aria/button";
import * as React from "react";
import { BoxPrimitive, BoxPrimitiveProps, TextPrimitive } from "../../../primitives";
import { REBRANDING_INTERFACE_UI_RED_RED } from "../../../utils/constants";
import { getCursorState } from "../../../utils/cursor";
import { Box } from "../../Layout";
import { Loader } from "../../Loader";
import { renderLegacyIcon } from "../renderLegacyIcon";

const mapVariationToStyle: Record<"default" | "rebranding", Record<ButtonVariation, BoxPrimitiveProps>> = {
  rebranding: {
    primary: {
      borderRadius: "large",
      color: "white",
      backgroundColor: "blue-palatinate",
      border: 0,
      _hover: {
        color: "white",
        backgroundColor: "blue-hover",
      },
      _active: {
        color: "white",
        backgroundColor: "blue-hover",
      },
      _disabled: {
        color: "gray-shuttle",
        backgroundColor: "black-5",
        border: 0,
      },
    },
    secondary: {
      borderRadius: "large",
      color: "blue-palatinate",
      backgroundColor: "transparent",
      borderColor: "blue-palatinate",
      border: 1,
      _hover: {
        color: "blue-hover",
        borderColor: "blue-hover",
      },
      _active: {
        color: "blue-hover",
        borderColor: "blue-hover",
      },
      _disabled: {
        color: "gray-shuttle",
        backgroundColor: "black-5",
        border: 0,
      },
    },
    tertiary: {
      borderRadius: "large",
      color: "blue-palatinate",
      backgroundColor: "transparent",
      border: 0,
      _hover: {
        color: "blue-hover",
        backgroundColor: "transparent",
      },
      _active: {
        color: "blue-hover",
        backgroundColor: "transparent",
      },
      _disabled: {
        color: "gray-shuttle",
        backgroundColor: "transparent",
        border: 0,
      },
    },
    cancel: {
      borderRadius: "large",
      color: REBRANDING_INTERFACE_UI_RED_RED,
      backgroundColor: "white",
      borderColor: REBRANDING_INTERFACE_UI_RED_RED,
      border: 1,
    },
  },
  default: {
    primary: {
      color: "white",
      backgroundColor: "blue-palatinate",
      border: 0,
      _hover: {
        color: "white",
        backgroundColor: "blue-hover",
      },
      _active: {
        color: "white",
        backgroundColor: "blue-hover",
      },
      _disabled: {
        color: "white",
        backgroundColor: "gray-bombay",
        border: 0,
      },
    },
    secondary: {
      color: "blue-palatinate",
      backgroundColor: "transparent",
      borderColor: "blue-palatinate",
      border: 1,
      _hover: {
        color: "blue-hover",
        borderColor: "blue-hover",
      },
      _active: {
        color: "blue-hover",
        borderColor: "blue-hover",
      },
      _disabled: {
        color: "white",
        backgroundColor: "gray-bombay",
        border: 0,
      },
    },
    tertiary: {
      color: "blue-palatinate",
      backgroundColor: "transparent",
      border: 0,
      _hover: {
        color: "blue-hover",
        backgroundColor: "transparent",
      },
      _active: {
        color: "blue-hover",
        backgroundColor: "transparent",
      },
      _disabled: {
        color: "gray-bombay",
        backgroundColor: "transparent",
        border: 0,
      },
    },
    cancel: {
      color: REBRANDING_INTERFACE_UI_RED_RED,
      backgroundColor: "transparent",
      borderColor: REBRANDING_INTERFACE_UI_RED_RED,
      border: 1,
    },
  },
};

type ButtonVariation = "primary" | "secondary" | "tertiary" | "cancel";

export interface ButtonProps extends Pick<BoxPrimitiveProps, "id"> {
  children?: React.ReactNode;
  variation?: ButtonVariation;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  onPress?: () => void;
  type?: "button" | "submit" | "reset";
  // https://github.com/adobe/react-spectrum/issues/1456
  form?: string;
  // https://github.com/adobe/react-spectrum/issues/1801
  to?: string;
  // use `onPress` instead
  onClick?: never;
  // use `isDisabled` instead
  disabled?: never;
}

const DEFAULT_TAG = "button";

type ButtonOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof BoxPrimitive>, ButtonProps>;
type ButtonPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, ButtonOwnProps>;

/**
 * `Button` are used primarily for actions, such as “Add”, “Close”, “Cancel”, or “Save”.
 */
export const Button = React.forwardRef(
  (
    {
      children,
      as = DEFAULT_TAG,
      variation = "primary",
      isDisabled = false,
      isLoading = false,
      isFullWidth = false,
      onPress,
      iconBefore,
      iconAfter,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore https://github.com/adobe/react-spectrum/issues/1526
    const { buttonProps } = useButton({ elementType: as, isDisabled, onPress, ...props }, ref);

    return (
      <BoxPrimitive
        ref={ref}
        as={as}
        aria-busy={isLoading}
        display="inline-flex"
        flexDirection="row"
        flexShrink={0}
        minHeight="40px"
        width={isFullWidth ? "100%" : undefined}
        paddingX={3}
        paddingY={0}
        borderRadius="small"
        alignItems="center"
        justifyContent="center"
        verticalAlign="middle"
        outline="none"
        cursor={getCursorState({ isDisabled, isLoading })}
        pointerEvents={isDisabled ? "none" : undefined}
        {...mapVariationToStyle["rebranding"][variation]}
        {...buttonProps}
        {...props}
      >
        {isLoading && (
          <Box display="inline-flex" marginRight={2}>
            <Loader variation={variation === "secondary" ? "primary" : "secondary"} size="small" />
          </Box>
        )}
        {iconBefore && <Box marginRight={2}>{renderLegacyIcon(iconBefore)}</Box>}
        <TextPrimitive color="inherit" fontSize="14px" fontWeight="600" lineHeight={1.2} textTransform="none" whiteSpace="pre-wrap">
          {children}
        </TextPrimitive>
        {iconAfter && <Box marginLeft={2}>{renderLegacyIcon(iconAfter)}</Box>}
      </BoxPrimitive>
    );
  },
) as ButtonPrimitive;
