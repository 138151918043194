import * as React from "react";
import { inputLabelStyle } from "../styles";

export const InputLabel: React.FunctionComponent<{
  hasFocus?: boolean;
  hasValue?: boolean;
  required?: boolean;
}> = ({ children, hasFocus, hasValue, required }) => (
  <div style={inputLabelStyle(hasFocus, hasValue)}>
    {children}
    {required && " *"}
  </div>
);
