import { useState } from "react";

export const useCopy = (id: string): { copy: () => void; isCopied: boolean } => {
  const [isCopied, setCopied] = useState(false);
  const copy = (): void => {
    const input: HTMLInputElement | null = document.querySelector(id);
    const browserWindow = (window as unknown) as Window;
    if (input && window) {
      input.removeAttribute("disabled");
      input.select();
      document.execCommand("copy");
      setCopied(true);
      const selection = browserWindow.getSelection();
      selection?.removeAllRanges();
      input.blur();
      input.setAttribute("disabled", "true");
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return {
    isCopied,
    copy,
  };
};
