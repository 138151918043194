import { Theme } from "@emotion/react";

const isQuickbooksEmbeded = window.location.href.includes("embed/quickbooksLibeoPay");

export const theme: Theme = {
  fonts: {
    headings: "'Red Hat Display', sans-serif",
    texts: "'Red Hat Display', sans-serif",
  },
  colors: {
    black: "#000000",
    "black-5": "#A3A4A8",
    "black-10": "#E0DCD3",
    "black-60": "#E0DCD3",
    "black-40": "#E0DCD3",
    white: "#FFFFFF",
    "white-sand": "#F7F3EA",

    "gray-bright": "#2D2F39",
    "gray-shuttle": "#666769",
    "gray-bombay": "#A3A4A8",
    "gray-athens-1": "#DFE1E6",
    "gray-athens-2": "#F4F4F4",

    "blue-maastricht": "#000033",
    "blue-midnight": "#00005F",
    "blue-hover": isQuickbooksEmbeded ? "#108000" : "#2626BF",
    "blue-palatinate": isQuickbooksEmbeded ? "#29A01C" : "#3333FF",
    "blue-vivid-sky": "#33CCFF",
    "blue-titan-white": "#F5F5FF",

    "green-british-racing": "#023B2B",
    "green-generic-viridian": "#058964",
    "green-carabbean": "#07D79C",
    "green-medium-aquamarine": "#61E5C0",
    "green-aqua-squeeze": "#F5FAF9",

    "pink-dark-scarlet": "#5D0026",
    "pink-pictorial-carmine": "#BA004B",
    "pink-vivid-raspberry": "#FF0066",
    "pink-hot": "#FF73AB",

    "orange-coquelicot": "#E63900",
    "orange-orioles": "#E84B17",
    "orange-smashed-pumpkin": "#FF6633",
    "orange-coral": "#FF8158",
    "orange-provincial-pink": "#FEF5F2",

    "yellow-bright": "#FFB021",
    "yellow-orange": "#FFC400",
    "yellow-usc-gold": "#FFCC00",
    "yellow-gargoyle-gas": "#FFD945",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: {
    small: "2px",
    medium: "4px",
    large: "8px",
    pill: "50vw",
    round: "100%",
  },
  shadows: {
    panel: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
    overlay: "0px 4px 16px 0px rgba(0, 0, 0, 0.1)",
    popout: "0px 8px 24px 0px rgba(0, 0, 0, 0.1)",
  },
  breakpoints: ["768px", "1280px", "1536px"],
};
