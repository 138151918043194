import * as React from "react";

export const Grid: React.FunctionComponent<{ columnGap?: React.CSSProperties["columnGap"]; rowGap?: React.CSSProperties["rowGap"] }> = ({
  columnGap,
  rowGap,
  children,
}) => {
  return (
    <div
      style={{
        display: "grid",
        columnGap,
        rowGap,
      }}
    >
      {children}
    </div>
  );
};
