import * as React from "react";
import { AlignCenter } from "../Layout";
import { MarginBig, MarginBigger, MarginMedium } from "../Margin";
import { Small } from "../Text";
import { TextGrayShuttle } from "../TextColors";
import { DialogButton } from "./DialogButton";
import { DialogPropsType } from "./types";

export const DialogFooter: React.FunctionComponent<{
  button: DialogPropsType["button"];
  extraInfo: DialogPropsType["extraInfo"];
}> = ({ button, extraInfo }) => {
  return (
    <MarginBig top>
      <MarginBigger left right>
        {button && (
          <div style={{ gridArea: "dialog-button" }} id="dialog-button">
            <DialogButton {...button} />
          </div>
        )}
        {extraInfo && (
          <div style={{ gridArea: "dialog-extra-info" }} id="dialog-extra-info">
            <MarginMedium top>
              <AlignCenter>
                <TextGrayShuttle>
                  <Small>{extraInfo}</Small>
                </TextGrayShuttle>
              </AlignCenter>
            </MarginMedium>
          </div>
        )}
      </MarginBigger>
    </MarginBig>
  );
};
