import * as React from "react";
import { Box } from "../Layout";
import { ToggleButtonItem } from "./ToggleButtonItem";
import { ToggleButtonProps } from "./types";

/**
 * `ToggleButton` is a display component used to display tab options.
 */
export const ToggleButton = <T extends string>(props: ToggleButtonProps<T>): React.ReactElement => {
  const { data, value, onChange } = props;

  return (
    <Box
      paddingY={1}
      paddingLeft={1}
      borderRadius="medium"
      border={1}
      flexDirection="row"
      display="inline-flex"
      backgroundColor="white"
      width="fit-content"
    >
      {data.map((dataItem) => {
        return (
          <ToggleButtonItem
            key={dataItem.value}
            isActive={value === dataItem.value}
            label={dataItem.label}
            onClick={(): void => onChange(dataItem.value)}
          />
        );
      })}
    </Box>
  );
};

export type { ToggleButtonProps };
