import { Text } from "@libeo/design-system";
import * as React from "react";
import { Icon } from "../Icon/Icon";
import { AlignCenter, AlignLeft, VerticalCenter } from "../Layout";
import { MarginSmall } from "../Margin/MarginSmall";
import { GRAY_ATHENS_1, WHITE } from "../theme/ui-constants";
import { Feature, LayoutDirection } from "./types";

export const EmptyStateFeature: React.FunctionComponent<{
  layoutDirection: LayoutDirection;
  feature: Feature;
}> = ({ layoutDirection, feature }) => {
  const featureTitle = (feature: Feature): React.ReactNode => <Text variation="medium">{feature.title}</Text>;

  const featureText = (feature: Feature): React.ReactNode => (
    <Text size="small" color="gray-shuttle">
      {feature.text}
    </Text>
  );

  return (
    <MarginSmall left right key={feature.title}>
      {layoutDirection === "row" ? (
        <>
          <AlignCenter>{featureTitle(feature)}</AlignCenter>
          <AlignCenter>{featureText(feature)}</AlignCenter>
        </>
      ) : (
        <VerticalCenter>
          <div style={{ display: "flex" }}>
            <div>
              <Icon
                value={feature.icon}
                circleBorder
                key={feature.icon}
                backgroundColor={WHITE}
                borderColor={GRAY_ATHENS_1}
                width="42px"
                height="42px"
              />
            </div>
            <div>
              <AlignLeft>{featureTitle(feature)}</AlignLeft>
              <AlignLeft>{featureText(feature)}</AlignLeft>
            </div>
          </div>
        </VerticalCenter>
      )}
    </MarginSmall>
  );
};
