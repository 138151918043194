import { CSSProperties } from "react";
import { spacing } from "../../theme/spacing";
import { FONT_REGULAR, BLUE_MIDNIGHT, WHITE, FONT_SIZE_DEFAULT, SHADOW_PANEL } from "../../theme/ui-constants";
import { defaultButtonStyles } from "../styles";

const getBackgroundColor = (isHovered: boolean, isClicked: boolean): CSSProperties["backgroundColor"] => {
  if (isHovered || isClicked) {
    return BLUE_MIDNIGHT;
  }
  return WHITE;
};

export const choiceButtonStyles = (isHovered: boolean, isClicked: boolean): CSSProperties => ({
  ...defaultButtonStyles({
    backgroundColor: getBackgroundColor(isHovered, isClicked),
    color: isHovered || isClicked ? WHITE : BLUE_MIDNIGHT,
    width: "100%",
    height: "46px",
  }),
  textTransform: "none",
  fontSize: FONT_SIZE_DEFAULT,
  boxShadow: SHADOW_PANEL,
  fontWeight: FONT_REGULAR,
});
export const choiceLabelStyles: CSSProperties = {
  display: "flex",
  flex: 1,
  justifyContent: "flex-start",
};

export const MultipleChoiceButtonSpacinStyles: CSSProperties = {
  margin: spacing(2, 0, 0),
};
export const MultipleChoiceButtonLaterStyles: CSSProperties = {
  margin: spacing(2, 0, 0),
  display: "flex",
  justifyContent: "flex-end",
};

export const optionsContainer = (isMobile: boolean): CSSProperties => ({
  display: isMobile ? "flex" : "grid",
  flexDirection: isMobile ? "column" : "initial",
  gridAutoFlow: "column",
  gridTemplateRows: "1fr 1fr 1fr",
  columnGap: spacing(2),
  width: isMobile ? "initial" : spacing(7.5),
});
