import { REBRANDING_BLACK_5, REBRANDING_GREEN_INK_GREEN_WHITE_85, REBRANDING_GREEN_INK_GREEN_WHITE_95 } from "@libeo/design-system";
import { CSSProperties } from "react";
import { StylesConfig } from "react-select";
import { spacing } from "../theme/spacing";
import {
  BLACK,
  BLUE_MAASTRICHT,
  BLUE_PALATINATE,
  BLUE_VIVID_SKY,
  BORDER_RADIUS_INPUT,
  FONT_FAMILY_DEFAULT,
  FONT_MEDIUM,
  FONT_REGULAR,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_FOR_TEXT_AREA,
  GRAY_ATHENS_2,
  GRAY_BOMBAY,
  GRAY_SHUTTLE,
  PINK_PICTORIAL_CARMINE,
  WHITE,
} from "../theme/ui-constants";

const UPLOAD_TRANSITION_TIME = "all 0.3s ease";

const TextInputStyle = (disabled?: boolean): CSSProperties => ({
  color: disabled ? GRAY_SHUTTLE : BLUE_MAASTRICHT,
  fontSize: FONT_SIZE_FOR_TEXT_AREA,
  outline: "none",
});

export const inputStyle = (hasSuffix?: boolean, disabled?: boolean, label?: boolean): CSSProperties =>
  ({
    ...TextInputStyle(disabled),
    fontFamily: FONT_FAMILY_DEFAULT,
    flex: 1,
    border: "none",
    background: "transparent",
    width: "100%",
    padding: label ? `22px ${hasSuffix ? 0 : "18px"} 14px 18px` : `18px ${hasSuffix ? 0 : "18px"} 18px 20px`,
    height: "100%",
    cursor: disabled ? "not-allowed" : "auto",
  } as CSSProperties);

export const textareaStyle = (disabled?: boolean, height?: CSSProperties["height"]): CSSProperties => ({
  ...inputStyle(false, disabled),
  height: height || "100px",
});

export const InputSpacerStyle: CSSProperties = {
  display: "flex",
  textAlign: "left",
  position: "relative",
  boxSizing: "border-box",
  margin: 0,
  width: "100%",
  marginBottom: "32px",
};

const BOX_SHADOW_INACTIVE = "0px 2px 5px rgba(0, 0, 95, 0.1)";

export const inputBorderStyle = (disabled?: boolean, borderActive?: boolean, borderColor?: string): CSSProperties => ({
  borderRadius: "8px",
  display: "flex",
  position: "relative",
  alignItems: "center",
  boxSizing: "border-box",
  margin: 0,
  width: "100%",
  minHeight: "55px",
  fontFamily: FONT_FAMILY_DEFAULT,
  caretColor: BLUE_PALATINATE,
  boxShadow: "none",
  background: disabled ? GRAY_ATHENS_2 : WHITE,
  transition: "all 0.3s",
  border: `1px solid ${borderColor || (borderActive ? BLUE_PALATINATE : REBRANDING_GREEN_INK_GREEN_WHITE_85)}`,
});

export const inputLabelStyle = (hasFocus?: boolean, hasValue?: boolean): CSSProperties => ({
  pointerEvents: "none",
  position: "absolute",
  fontFamily: FONT_FAMILY_DEFAULT,
  fontWeight: 600,
  fontSize: hasFocus || hasValue ? "12px" : "14px",
  left: 0,
  display: "inline",
  width: "100%",
  maxHeight: "100%",
  lineHeight: hasFocus || hasValue ? "16px" : "24px",
  cursor: "text",
  transition: "color 0.2s, font-size 0.2s, transform 0.2s",
  transform: hasFocus || hasValue ? "translate3d(0px, 5px, 0px)" : "translate3d(0, 15px, 0px)",
  top: 0,
  color: hasFocus ? BLUE_PALATINATE : GRAY_SHUTTLE,
  paddingLeft: "17px",
  paddingRight: "2px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textTransform: "none",
});

export const inputDescriptionStyle = {
  fontFamily: FONT_FAMILY_DEFAULT,
  fontSize: 16,
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
};

export const inputMessageStyle = (color?: string): CSSProperties => ({
  fontFamily: FONT_FAMILY_DEFAULT,
  marginTop: spacing(0.5),
  fontSize: "10px",
  letterSpacing: "0.5px",
  alignItems: "center",
  display: "flex",
  top: "calc(100% + 5px)",
  left: "0",
  color: color,
  lineHeight: "9px",
});

export const inputSuffixStyle: CSSProperties = {
  display: "flex",
  padding: spacing(0, 1),
  justifyContent: "center",
  alignItems: "center",
  minWidth: "40px",
  minHeight: "40px",
  height: "100%",
};

export const checkboxLabelWrapperStyle = (disabled: boolean): CSSProperties => ({
  cursor: disabled ? "default" : "pointer",
});

export const radioButtonLabelWrapperStyle: CSSProperties = {
  cursor: "pointer",
  alignItems: "center",
  display: "flex",
};

export const checkboxInputStyle: CSSProperties = {
  position: "absolute",
  width: 1,
  height: 1,
  border: 0,
  opacity: 0,
  padding: 0,
  clip: "rect(1px, 1px, 1px, 1px)",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export const checkboxCheckedStyle = (checked: boolean, disabled: boolean): CSSProperties => {
  const disabledBackgroundColor = REBRANDING_BLACK_5;
  const disabledBorderColor = GRAY_BOMBAY;
  const backgroundColor = checked ? BLUE_PALATINATE : WHITE;
  const borderColor = checked ? BLUE_PALATINATE : GRAY_BOMBAY;

  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 16,
    width: 16,
    height: 16,
    border: `1px solid ${disabled ? disabledBorderColor : borderColor}`,
    background: disabled ? disabledBackgroundColor : backgroundColor,
    borderRadius: 2,
  };
};

export const radioButtonCheckedStyle = (checked: boolean): CSSProperties => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 16,
  minHeight: 16,
  border: `1px solid ${checked ? BLUE_PALATINATE : GRAY_BOMBAY}`,
  background: checked ? BLUE_PALATINATE : "transparent",
  borderRadius: "50%",
  marginRight: 8,
});

export const radioButtonDot: CSSProperties = {
  height: 6,
  width: 6,
  borderRadius: "50%",
  backgroundColor: WHITE,
};

export const checkboxLabelStyle = (disabled: boolean): CSSProperties => ({
  fontFamily: FONT_FAMILY_DEFAULT,
  fontSize: FONT_SIZE_DEFAULT,
  color: disabled ? GRAY_BOMBAY : BLUE_MAASTRICHT,
  display: "flex",
  alignItems: "center",
});

export const radioButtonLabelStyle = (disabled: boolean): CSSProperties => ({
  fontFamily: FONT_FAMILY_DEFAULT,
  fontSize: FONT_SIZE_DEFAULT,
  color: disabled ? GRAY_BOMBAY : BLUE_MAASTRICHT,
  minWidth: "16px",
  minHeight: "16px",
  display: "grid",
  alignItems: "center",
});

// custom style for react-select https://react-select.com/styles
// other properties group, groupHeading, loadingIndicator, loadingMessage, menuPortal, multiValue, multiValueLabel, multiValueRemove, noOptionsMessage, option
const HoverBackground = REBRANDING_GREEN_INK_GREEN_WHITE_95;
const color = BLACK;
export const selectStyles = (optionsInColumnsView?: boolean): StylesConfig => ({
  container: (base) => ({
    ...base,
    width: "100%",
    minHeight: "23px",
  }),
  option: (base, state) => {
    return {
      ...base,
      width: optionsInColumnsView ? "100%" : "inherit",
      outline: optionsInColumnsView ? `1px solid ${GRAY_ATHENS_2}` : "inherit",
      background: state.isSelected || state.isFocused ? HoverBackground : "transparent",
      color,
      lineHeight: "14px",
      ":active": {
        background: state.isFocused || state.isSelected ? HoverBackground : "transparent",
        color,
      },
      "&:hover": {
        background: state.isFocused || state.isSelected ? HoverBackground : "transparent",
        color,
      },
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    margin: 0,
    padding: 0,
    height: "100%",
    minHeight: "53px",
    background: "transparent",
  }),
  dropdownIndicator: (base) => ({ ...base, boxSizing: "content-box", padding: spacing(2, 1, 1, 0), height: "24px" }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  clearIndicator: (base) => ({
    ...base,
    padding: spacing(2, 1, 2, 0),
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15px",
  }),
  indicatorsContainer: (base) => ({ ...base, padding: 0 }),
  input: (base) => ({ ...base, height: "100%", padding: "2px 0", margin: "0 O 5px 0", lineHeight: "14px" }),
  menu: (base) => ({
    ...base,
    margin: 0,
    borderRadius: "0 0 3px 3px",
    boxShadow: BOX_SHADOW_INACTIVE,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    textTransform: "none",
    fontWeight: FONT_REGULAR,
    fontSize: FONT_SIZE_DEFAULT,
    display: optionsInColumnsView ? "grid" : "inherit",
    gridTemplateColumns: optionsInColumnsView ? "50% 50%" : "inherit",
  }),
  placeholder: (base) => ({ ...base, display: "none" }),
  singleValue: (base) => ({
    ...base,
    overflow: "visible",
    position: "relative",
    transform: "none",
    top: 0,
    lineHeight: "14px",
  }),
  multiValue: (base) => ({
    ...base,
    position: "relative",
    transform: "none",
    top: 0,
    lineHeight: "14px",
    background: `${BLUE_VIVID_SKY} !important`,
    borderRadius: BORDER_RADIUS_INPUT,
  }),
  multiValueRemove: (base) => ({
    ...base,
    background: `transparent !important`,
  }),
  multiValueLabel: (base) => ({ ...base, color: BLUE_MAASTRICHT, fontSize: FONT_SIZE_FOR_TEXT_AREA, outline: "none" }),
  valueContainer: (base) => ({
    ...base,
    padding: "22px 0 9px 18px",
    textTransform: "none",
    fontWeight: FONT_REGULAR,
    fontSize: FONT_SIZE_DEFAULT,
  }),
});

// custom style for react-select https://react-select.com/styles
// other properties group, groupHeading, loadingIndicator, loadingMessage, menuPortal, multiValue, multiValueLabel, multiValueRemove, noOptionsMessage, option
export const multiTagSelectStyles: StylesConfig = Object.assign({}, selectStyles(), {
  multiValue: (base: CSSProperties): CSSProperties => {
    return {
      ...base,
      position: "relative",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      width: "fit-content",
      transform: "none",
      background: `transparent !important`,
      margin: spacing(0),
      padding: spacing(0),
    };
  },
  multiValueLabel: (base: CSSProperties): CSSProperties => {
    return {
      ...base,
      margin: spacing(0),
      padding: spacing(0),
      paddingLeft: spacing(0),
    };
  },
  multiValueRemove: (): CSSProperties => ({
    background: `transparent !important`,
    margin: 0,
    padding: 0,
    height: "fit-content",
    display: "flex",
    width: "fit-content",
  }),
  input: (base: CSSProperties) => ({ ...base, height: "100%", padding: "2px 0", margin: "0 O 5px 0", lineHeight: "16px", display: "inline" }),
});

export const multiSelectStyles: StylesConfig = Object.assign({}, selectStyles(), {
  option: (base: CSSProperties, props: { isSelected: boolean; isFocused: boolean }) => {
    return {
      ...base,
      background: props.isSelected ? GRAY_ATHENS_2 : "transparent",
      "&:active": {
        background: props.isFocused || props.isSelected ? HoverBackground : "transparent",
        color,
      },
      "&:hover": {
        background: props.isFocused || props.isSelected ? HoverBackground : "transparent",
        color,
      },
    };
  },
  valueContainer: (base: CSSProperties): CSSProperties => {
    return {
      ...base,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
    };
  },
  multiValue: (base: CSSProperties): CSSProperties => {
    return {
      ...base,
      position: "relative",
      width: "fit-content",
      transform: "none",
      background: `transparent !important`,
      margin: spacing(0),
      padding: spacing(0),
    };
  },
  multiValueLabel: (base: CSSProperties): CSSProperties => {
    return {
      ...base,
      margin: spacing(0),
      padding: spacing(0),
      paddingLeft: spacing(0),
    };
  },
  multiValueRemove: (): CSSProperties => ({
    background: `transparent !important`,
    margin: 0,
    padding: 0,
    height: "fit-content",
    display: "flex",
    width: "fit-content",
  }),
  input: (base: CSSProperties) => ({ ...base, height: "100%", padding: "2px 0", margin: "0 O 5px 0", lineHeight: "16px", display: "inline" }),
});

const getUploadBorderStyle = (highlight: boolean, error?: boolean, disabled?: boolean) => {
  const borderDefault = `1.6px dashed ${GRAY_BOMBAY}`;
  const borderHighlight = `1.6px dashed ${WHITE}`;
  const borderError = `1.6px dashed ${PINK_PICTORIAL_CARMINE}`;
  const borderDisabled = `1.6px dashed ${REBRANDING_BLACK_5}`;

  if (disabled) {
    return borderDisabled;
  }
  if (highlight) {
    return borderHighlight;
  }
  if (error) {
    return borderError;
  }

  return borderDefault;
};

const getUploadBackgroundStyle = (highlight: boolean, disabled?: boolean) => {
  if (disabled) {
    return REBRANDING_BLACK_5;
  }
  if (highlight) {
    return BLUE_PALATINATE;
  }

  return WHITE;
};

export const uploadBorderStyle = (preview: boolean, highlight: boolean, error?: boolean, disabled?: boolean): CSSProperties => {
  return {
    width: "100%",
    height: "100%",
    padding: preview ? 0 : "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: UPLOAD_TRANSITION_TIME,
    background: getUploadBackgroundStyle(highlight, disabled),
    border: getUploadBorderStyle(highlight, error, disabled),
    borderRadius: "10px",
    pointerEvents: "none",
    overflow: "hidden",
    color: BLUE_PALATINATE,
  };
};

const getUploadTextColor = (highlight: boolean, error?: boolean, disabled?: boolean) => {
  if (disabled) {
    return GRAY_BOMBAY;
  }
  if (highlight) {
    return BLUE_MAASTRICHT;
  }
  if (error) {
    return PINK_PICTORIAL_CARMINE;
  }

  return BLUE_MAASTRICHT;
};

export const uploadIconCloudStyle = (highlight: boolean, error?: boolean, disabled?: boolean): CSSProperties => ({
  color: getUploadTextColor(highlight, error, disabled),
  transition: "transform 1s ease",
  transform: highlight ? "scale(1.2, 1.2)" : "scale(1, 1)",
});

export const uploadTitleStyle = (highlight: boolean, error?: boolean, disabled?: boolean): CSSProperties => ({
  fontWeight: FONT_MEDIUM,
  color: getUploadTextColor(highlight, error, disabled),
  fontSize: "17px",
  lineHeight: "17px",
  textAlign: "center",
  marginBottom: 10,
  position: "relative",
  transition: UPLOAD_TRANSITION_TIME,
});

export const uploadIconRemoveStyle: CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};

export const selectInputCompanyFooterHint: CSSProperties = {
  fontWeight: FONT_MEDIUM,
  fontSize: "14px",
  color: GRAY_BOMBAY,
};

export const selectInputCompanyFooterLabel: CSSProperties = {
  cursor: "pointer",
  fontWeight: FONT_MEDIUM,
  fontSize: "16px",
  color: BLUE_MAASTRICHT,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "5px 0 0",
};
