import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import {
  BLUE_HOVER,
  BLUE_MAASTRICHT,
  BLUE_PALATINATE,
  BLUE_TITAN_WHITE,
  BORDER_MIN_HEIGHT,
  BORDER_RADIUS_BUTTON,
  FONT_BOLD,
  FONT_EXTRA_BOLD,
  FONT_FAMILY_DEFAULT,
  FONT_REGULAR,
  FONT_SIZE_BUTTON,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_SMALL,
  FONT_SIZE_SMALLER,
  FONT_WEIGHT_NORMAL,
  GRAY_ATHENS_2,
  GRAY_BOMBAY,
  PINK_PICTORIAL_CARMINE,
  PINK_VIVID_RASPBERRY,
  SHADOW_PANEL,
  WHITE,
} from "../theme/ui-constants";
import type { LinkButtonStatus } from "./types";

interface ButtonProps {
  disabled?: boolean;
  isVisible?: boolean;
  color?: string;
  backgroundColor?: string;
  width?: CSSProperties["width"];
  height?: React.CSSProperties["height"];
  padding?: React.CSSProperties["padding"];
  isDisplayInline?: boolean;
}

export const defaultButtonStyles = (props: ButtonProps): CSSProperties => {
  const color = props.color || WHITE;
  const backgroundColor = props.backgroundColor || BLUE_PALATINATE;
  return {
    cursor: props.disabled ? "not-allowed" : "pointer",
    display: props.isDisplayInline ? "inline" : "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: FONT_FAMILY_DEFAULT,
    fontSize: FONT_SIZE_BUTTON,
    lineHeight: "17px",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: FONT_BOLD,
    border: "none",
    borderRadius: BORDER_RADIUS_BUTTON,
    backgroundColor: props.disabled ? GRAY_BOMBAY : backgroundColor,
    color: color,
    outline: 0,
    height: props.height || BORDER_MIN_HEIGHT,
    padding: props.padding ?? spacing(0, 2),
    whiteSpace: "nowrap",
    width: props.width || "fit-content",
    ...(props.isVisible === false && { visibility: "hidden" }),
  };
};

export const primaryButtonStyles = (isHovered: boolean, props: ButtonProps): CSSProperties => ({
  ...defaultButtonStyles(props),
  backgroundColor: props.disabled ? GRAY_BOMBAY : isHovered ? BLUE_HOVER : BLUE_PALATINATE,
  color: WHITE,
});

export const secondaryButtonStyles = (isHovered: boolean, props: ButtonProps): CSSProperties => ({
  ...defaultButtonStyles(props),
  border: `1px solid ${BLUE_PALATINATE}`,
  backgroundColor: props.disabled ? GRAY_BOMBAY : isHovered ? GRAY_ATHENS_2 : WHITE,
  color: BLUE_PALATINATE,
  boxShadow: "none",
});

interface LinkButtonProps extends ButtonProps {
  underline?: boolean;
  status?: LinkButtonStatus;
}

const linkButtonColorStyles = (isHovered: boolean, props: LinkButtonProps): CSSProperties => {
  let defaultColor = BLUE_PALATINATE;
  let hoverColor = BLUE_HOVER;

  if (props.status === "warning") {
    defaultColor = PINK_VIVID_RASPBERRY;
    hoverColor = PINK_PICTORIAL_CARMINE;
  }

  return {
    color: props.disabled ? GRAY_BOMBAY : isHovered ? hoverColor : defaultColor,
  };
};

export const linkButtonStyles = (isHovered: boolean, props: LinkButtonProps): CSSProperties => ({
  ...defaultButtonStyles(props),
  backgroundColor: "transparent",
  boxShadow: "none",
  ...(props.underline && { textDecoration: "underline" }),
  ...linkButtonColorStyles(isHovered, props),
});

export const textButtonStyles = (isHovered: boolean, props: ButtonProps): CSSProperties => {
  return {
    ...defaultButtonStyles(props),
    backgroundColor: "transparent",
    color: props.disabled ? GRAY_BOMBAY : isHovered ? BLUE_HOVER : BLUE_PALATINATE,
    textTransform: "none",
    fontWeight: FONT_WEIGHT_NORMAL,
    fontSize: FONT_SIZE_SMALL,
    boxShadow: "none",
    height: "auto",
  };
};

export const buttonIconStyleLeft: CSSProperties = {
  marginRight: spacing(1),
  width: "12px",
  height: "12px",
  color: "inherit",
};

export const buttonIconStyleRight: CSSProperties = {
  marginLeft: spacing(1),
  width: "12px",
  height: "12px",
  color: "inherit",
};

const clipboardBackgroundColor = WHITE;

export const copyToClipBoardButtonStyles = (width?: CSSProperties["width"]): CSSProperties => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  fontSize: FONT_SIZE_BUTTON,
  borderRadius: BORDER_RADIUS_BUTTON,
  border: `1px solid ${BLUE_PALATINATE}`,
  backgroundColor: clipboardBackgroundColor,
  height: BORDER_MIN_HEIGHT,
  padding: spacing(0, 2),
  width: width || "fit-content",
});

export const copyToClipBoardInputStyles: CSSProperties = {
  cursor: "pointer",
  backgroundColor: clipboardBackgroundColor,
  boxShadow: "none",
  border: "none",
  marginRight: spacing(1),
};

export const dropdown = (open: boolean, width?: CSSProperties["width"]): CSSProperties => ({
  fontFamily: FONT_FAMILY_DEFAULT,
  display: "inline-block",
  position: open ? "relative" : "static",
  userSelect: "none",
  width: width ?? "inherit",
});

export const dropdownButton: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  height: BORDER_MIN_HEIGHT,
  borderRadius: BORDER_RADIUS_BUTTON,
  color: WHITE,
  border: "none",
  fontWeight: FONT_EXTRA_BOLD,
  fontSize: FONT_SIZE_BUTTON,
  whiteSpace: "nowrap",
  textAlign: "center",
  cursor: "pointer",
  position: "relative",
  padding: 0,
  backgroundColor: BLUE_PALATINATE,
};

export const dropdownMenu = (isMenuOpen: boolean, menuPosition: "left" | "right", top?: boolean): CSSProperties => {
  return {
    position: "absolute",
    minWidth: "200px",
    zIndex: 10,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: WHITE,
    backgroundClip: "padding-box",
    borderRadius: "2px",
    outline: "none",
    boxSizing: "border-box",
    boxShadow: SHADOW_PANEL,
    color: BLUE_MAASTRICHT,
    fontSize: "16px",
    fontVariant: "tabular-nums",
    lineHeight: "1.5",
    listStyle: "none",
    margin: "4px 0px",
    display: isMenuOpen ? "block" : "none",
    bottom: top ? "100%" : "",
    top: top ? "" : "100%",
    left: menuPosition === "left" ? 0 : "auto",
    right: menuPosition === "right" ? 0 : "auto",
  };
};

export const menuItem = (isHovered: boolean, isDisabled: boolean): CSSProperties => ({
  padding: spacing(2),
  display: "flex",
  alignItems: "center",
  clear: "both",
  margin: "0px",
  backgroundColor: isHovered ? GRAY_ATHENS_2 : WHITE,
  fontWeight: "normal",
  fontSize: FONT_SIZE_DEFAULT,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  transition: "all 0.3s",
  color: isDisabled ? GRAY_BOMBAY : BLUE_MAASTRICHT,
  cursor: isDisabled ? "not-allowed" : "pointer",
});

export const primaryAction = (isHovered: boolean): CSSProperties => ({
  flex: 1,
  textTransform: "uppercase",
  padding: spacing(0, 2),
  minWidth: "100px",
  whiteSpace: "pre",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderTopLeftRadius: BORDER_RADIUS_BUTTON,
  borderBottomLeftRadius: BORDER_RADIUS_BUTTON,
  backgroundColor: isHovered ? BLUE_HOVER : "transparent",
});

export const dropdownArrowIcon = (isHovered: boolean): CSSProperties => ({
  display: "flex",
  alignItems: "center",
  padding: spacing(0, 2),
  borderLeft: `1px solid ${BLUE_TITAN_WHITE}`,
  borderTopRightRadius: BORDER_RADIUS_BUTTON,
  borderBottomRightRadius: BORDER_RADIUS_BUTTON,
  backgroundColor: isHovered ? BLUE_HOVER : "transparent",
});

export const arrowIcon = (open: boolean): CSSProperties => ({
  color: WHITE,
  transform: open ? "rotate(180deg)" : "rotate(0deg)",
  transition: "transform 0.3s ease",
});

export const hoverTextStyles = (isHovered: boolean): CSSProperties => ({
  fontWeight: FONT_REGULAR,
  whiteSpace: "pre",
  fontSize: FONT_SIZE_SMALLER,
  pointerEvents: "none",
  padding: spacing(1),
  opacity: isHovered ? 0.9 : 0,
  position: "absolute",
  color: BLUE_MAASTRICHT,
  background: WHITE,
  boxShadow: SHADOW_PANEL,
  textAlign: "center",
  zIndex: 1,
  transform: "translate(-35%, -110%)",
});

export const iconButtonStyle = (isDisabled: boolean, isHovered: boolean): CSSProperties => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: isDisabled ? "not-allowed" : "pointer",
  width: 38,
  height: 36,
  borderRadius: 6,
  background: isHovered ? GRAY_ATHENS_2 : WHITE,
  flexShrink: 0,
});
