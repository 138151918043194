export enum USStateCode {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  DC = "DC",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export const US_STATES = [
  {
    name: "Alabama",
    code: USStateCode.AL,
  },
  {
    name: "Alaska",
    code: USStateCode.AK,
  },
  {
    name: "Arizona",
    code: USStateCode.AZ,
  },
  {
    name: "Arkansas",
    code: USStateCode.AR,
  },
  {
    name: "California",
    code: USStateCode.CA,
  },
  {
    name: "Colorado",
    code: USStateCode.CO,
  },
  {
    name: "Connecticut",
    code: USStateCode.CT,
  },
  {
    name: "Delaware",
    code: USStateCode.DE,
  },
  {
    name: "District of Columbia",
    code: USStateCode.DC,
  },
  {
    name: "Florida",
    code: USStateCode.FL,
  },
  {
    name: "Georgia",
    code: USStateCode.GA,
  },
  {
    name: "Hawaii",
    code: USStateCode.HI,
  },
  {
    name: "Idaho",
    code: USStateCode.ID,
  },
  {
    name: "Illinois",
    code: USStateCode.IL,
  },
  {
    name: "Indiana",
    code: USStateCode.IN,
  },
  {
    name: "Iowa",
    code: USStateCode.IA,
  },
  {
    name: "Kansas",
    code: USStateCode.KS,
  },
  {
    name: "Kentucky",
    code: USStateCode.KY,
  },
  {
    name: "Louisiana",
    code: USStateCode.LA,
  },
  {
    name: "Maine",
    code: USStateCode.ME,
  },
  {
    name: "Maryland",
    code: USStateCode.MD,
  },
  {
    name: "Massachusetts",
    code: USStateCode.MA,
  },
  {
    name: "Michigan",
    code: USStateCode.MI,
  },
  {
    name: "Minnesota",
    code: USStateCode.MN,
  },
  {
    name: "Mississippi",
    code: USStateCode.MS,
  },
  {
    name: "Missouri",
    code: USStateCode.MO,
  },
  {
    name: "Montana",
    code: USStateCode.MT,
  },
  {
    name: "Nebraska",
    code: USStateCode.NE,
  },
  {
    name: "Nevada",
    code: USStateCode.NV,
  },
  {
    name: "New Hampshire",
    code: USStateCode.NH,
  },
  {
    name: "New Jersey",
    code: USStateCode.NJ,
  },
  {
    name: "New Mexico",
    code: USStateCode.NM,
  },
  {
    name: "New York",
    code: USStateCode.NY,
  },
  {
    name: "North Carolina",
    code: USStateCode.NC,
  },
  {
    name: "North Dakota",
    code: USStateCode.ND,
  },
  {
    name: "Ohio",
    code: USStateCode.OH,
  },
  {
    name: "Oklahoma",
    code: USStateCode.OK,
  },
  {
    name: "Oregon",
    code: USStateCode.OR,
  },
  {
    name: "Pennsylvania",
    code: USStateCode.PA,
  },
  {
    name: "Rhode Island",
    code: USStateCode.RI,
  },
  {
    name: "South Carolina",
    code: USStateCode.SC,
  },
  {
    name: "South Dakota",
    code: USStateCode.SD,
  },
  {
    name: "Tennessee",
    code: USStateCode.TN,
  },
  {
    name: "Texas",
    code: USStateCode.TX,
  },
  {
    name: "Utah",
    code: USStateCode.UT,
  },
  {
    name: "Vermont",
    code: USStateCode.VT,
  },
  {
    name: "Virginia",
    code: USStateCode.VA,
  },
  {
    name: "Washington",
    code: USStateCode.WA,
  },
  {
    name: "West Virginia",
    code: USStateCode.WV,
  },
  {
    name: "Wisconsin",
    code: USStateCode.WI,
  },
  {
    name: "Wyoming",
    code: USStateCode.WY,
  },
];
