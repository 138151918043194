import { Box, Text } from "@libeo/design-system";
import * as React from "react";

export const CardText: React.FunctionComponent<{}> = ({ children }) => (
  <Box flexDirection="row" justifyContent="center" marginBottom={2}>
    <Text size="small" variation="regular" textAlign="center">
      {children}
    </Text>
  </Box>
);
