import { Brand } from "./types/brand";

const ORGANISATION_ID_PARAM = ":organisationId([0-9a-fA-f]{8}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{12})";
const INVOICE_ID_PARAM = ":invoiceId([0-9a-fA-f]{8}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{12})";

const REDIRECT_URL_PARAMS = ":redirectUrl";
export const BASE_URL_EMBED = "/embed";

export type RoutePathType = Brand<string, "RoutePathType">;

const PRIVATE_ROUTE: Record<string, RoutePathType> = {
  EMPTY_ROUTE: `/` as RoutePathType,
  HOME: `/${ORGANISATION_ID_PARAM}` as RoutePathType,
  CLAIM: `/claim` as RoutePathType,
  CLAIM_ONBOARDING_MOBILE: `/claim/onboarding-mobile` as RoutePathType,
  CLAIM_GB_COMPANY: `/claim/gb` as RoutePathType,
  CLAIM_COMPANY: `/claim/company/:siren?` as RoutePathType,
  COMPANY_NOT_FOUND: `/claim/company/:siren?/:userId/not-found` as RoutePathType,
  CLAIM_COMPANY_EMPLOYEE_COUNT: `/${ORGANISATION_ID_PARAM}/claim/qualifying-employee-count` as RoutePathType,
  CLAIM_USER_QUALIFYING_QUESTION_POSITION: `/claim/qualifying-user-position` as RoutePathType,
  CLAIM_COMPANY_QUALIFYING_QUESTION_INVOICE_COUNT: `/${ORGANISATION_ID_PARAM}/claim/qualifying-invoice-count` as RoutePathType,
  CLAIM_COMPANY_QUALIFYING_QUESTION_ENTITY_COUNT: `/${ORGANISATION_ID_PARAM}/claim/qualifying-entity-count` as RoutePathType,
  CLAIM_USER_QUALIFYING_QUESTION_PLATFORM_USAGE: `/claim/qualifying-platform-usage` as RoutePathType,
  CLAIM_COMPANY_WELCOME: `/${ORGANISATION_ID_PARAM}/claim/welcome` as RoutePathType,
  ONBOARDING_VIDEO: `/${ORGANISATION_ID_PARAM}/onboarding-video` as RoutePathType,
  SWITCH_ORGANISATION: `/${ORGANISATION_ID_PARAM}/switch-organisation` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_PURCHASE_ORDER: `/${ORGANISATION_ID_PARAM}/purchase/draft/purchase-order/:draftPurchaseOrderId` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_CREDIT_NOTE_DOCUMENT: `/${ORGANISATION_ID_PARAM}/purchase/draft/credit-note-document/:creditNoteDocumentId` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_INVOICE: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_INVOICE_SIMPLIFIED: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/simplified/:id` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_INVOICE_SPLIT: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/split/:pageIndexes` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_AUTOMATION_RULES: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/edit-automation-rules/:automationRuleId` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_PURCHASE_ACCOUNT_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/purchase-account/:supplierId/edit` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_TAGS_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/tags/:supplierId/edit` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_PAYMENT_METHOD_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/payment-method/:supplierId/edit` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_DUE_DATE_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/due-date/:supplierId/edit` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_BUSINESS_APPROVAL_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/business-approval/:ruleId/edit` as RoutePathType,
  PURCHASE_DRAFT_CONTROL_EDIT_CATEGORY_RULE_ON_BUSINESS_APPROVAL_SIDEBAR: `/${ORGANISATION_ID_PARAM}/purchase/draft/invoice/:id/automations/category/business-approval/:ruleId/edit` as RoutePathType,
  PURCHASE: `/${ORGANISATION_ID_PARAM}/purchase` as RoutePathType,
  PURCHASE_DRAFT: `/${ORGANISATION_ID_PARAM}/purchase/draft` as RoutePathType,
  PURCHASE_BILLS: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/:actionUrl?/:paymentAmount?/:payActionType?/:code?/:bankAccountIdUsedForPayment?` as RoutePathType,
  PURCHASE_BILLS_FILTERS: `/${ORGANISATION_ID_PARAM}/purchase/bills/filters` as RoutePathType,
  PURCHASE_BILLS_PAY: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay/:paymentAmount/:payActionType?/:code?` as RoutePathType,
  PURCHASE_BILLS_PAY_USD: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-usd/:paymentAmount/:payActionType?/:code?` as RoutePathType, // In the future, a global pay-foreign may be more appropriate
  PURCHASE_BILLS_PAY_NOW_FINTECTURE_SUPPLIER_ADDRESS: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-now-fintecture-supplier-address/:paymentAmount/:payActionType?` as RoutePathType,
  PURCHASE_BILLS_PAY_NOW_FINTECTURE: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-now-fintecture/:paymentAmount/:payActionType?` as RoutePathType,
  PURCHASE_BILLS_PAY_PARTIALLY: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-partially` as RoutePathType,
  PURCHASE_BILLS_PAY_LATER: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-later` as RoutePathType,
  PURCHASE_BILLS_PAY_EXTERNALLY: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/pay-externally` as RoutePathType,
  PURCHASE_BILLS_HOLD_PAYMENT: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/hold` as RoutePathType,
  PURCHASE_BILLS_REQUEST_BUSINESS_APPROVAL: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/request-business-approval-of-invoice` as RoutePathType,
  PURCHASE_BILLS_DETAIL: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/display` as RoutePathType,
  PURCHASE_BILLS_DETAIL_TEST_INVOICE: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/display/test-invoice` as RoutePathType,
  PURCHASE_BILLS_IBAN_TEST_INVOICE: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/iban/test-invoice` as RoutePathType,
  PURCHASE_BILLS_UPDATE_INVOICE: `/${ORGANISATION_ID_PARAM}/purchase/bills/:id?/update` as RoutePathType,
  EMBED: `${BASE_URL_EMBED}/:thirdPartyConsumer` as RoutePathType,
  GET_STARTED: `/${ORGANISATION_ID_PARAM}/get-started/` as RoutePathType,
  PURCHASE_CREDIT_NOTES: `/${ORGANISATION_ID_PARAM}/purchase/credit-notes/` as RoutePathType,
  PURCHASE_CREDIT_NOTES_DETAILS: `/${ORGANISATION_ID_PARAM}/purchase/credit-notes/details/:creditNoteId` as RoutePathType,
  PURCHASE_ORDERS: `/${ORGANISATION_ID_PARAM}/purchase/purchase-orders` as RoutePathType,
  PURCHASE_ORDER_DETAIL: `/${ORGANISATION_ID_PARAM}/purchase/purchase-orders/detail/:purchaseOrderId` as RoutePathType,
  PURCHASE_ORDER_EDIT: `/${ORGANISATION_ID_PARAM}/purchase/purchase-order/edit/:purchaseOrderId` as RoutePathType,
  PURCHASE_ORDER_PAY: `/${ORGANISATION_ID_PARAM}/purchase/purchase-orders/pay/:purchaseOrderId` as RoutePathType,
  PURCHASE_REQUESTS: `/${ORGANISATION_ID_PARAM}/purchase/requests/:id?/:actionUrl?/:code?/:payActionType?` as RoutePathType,
  PURCHASE_REQUESTS_DETAIL: `/${ORGANISATION_ID_PARAM}/purchase/requests/:id?/detail` as RoutePathType,
  PURCHASE_REQUESTS_HOLD: `/${ORGANISATION_ID_PARAM}/purchase/requests/:id?/hold` as RoutePathType,
  NETWORK: `/${ORGANISATION_ID_PARAM}/network` as RoutePathType,
  NETWORK_SUPPLIER: `/${ORGANISATION_ID_PARAM}/network/supplier/:action?` as RoutePathType,
  NETWORK_SUPPLIER_IMPORT: `/${ORGANISATION_ID_PARAM}/network/supplier/import` as RoutePathType,
  NETWORK_SUPPLIER_ADD_SUPPLIER: `/${ORGANISATION_ID_PARAM}/network/supplier/add-supplier` as RoutePathType,
  NETWORK_CUSTOMER_ADD_CUSTOMER: `/${ORGANISATION_ID_PARAM}/network/customer/add-customer` as RoutePathType,
  NETWORK_CUSTOMER: `/${ORGANISATION_ID_PARAM}/network/customer/:action?` as RoutePathType,
  SUPPLIER: `/${ORGANISATION_ID_PARAM}/partner/:id/` as RoutePathType,
  SUPPLIER_EDIT_IBAN: `/${ORGANISATION_ID_PARAM}/partner/:id/edit-iban/:actionId?` as RoutePathType,
  SUPPLIER_ADD_SIREN: `/${ORGANISATION_ID_PARAM}/partner/:id/add-siren/:actionId?` as RoutePathType,
  SUPPLIER_EDIT_SIREN: `/${ORGANISATION_ID_PARAM}/partner/:id/edit-siren/:actionId?` as RoutePathType,
  SUPPLIER_EDIT_ADDRESS: `/${ORGANISATION_ID_PARAM}/partner/:id/edit-address/:actionId?` as RoutePathType,
  SUPPLIER_EDIT_CONTACT: `/${ORGANISATION_ID_PARAM}/partner/:id/edit-contact/:actionId?` as RoutePathType,
  SUPPLIER_ADD_COMPANY_NUMBER: `/${ORGANISATION_ID_PARAM}/partner/:id/add-company-number/:actionId?` as RoutePathType,
  CUSTOMER: `/${ORGANISATION_ID_PARAM}/customer/:id/` as RoutePathType,
  CUSTOMER_EDIT_CONTACT: `/${ORGANISATION_ID_PARAM}/customer/:id/edit-contact/:actionId?` as RoutePathType,
  QUICKBOOKS_IFRAME: `/api/v1/quickbooks/libeopay/bill-pay-url/:realmId/:billId` as RoutePathType,
  QUICKBOOKS_LOGOUT: `/${ORGANISATION_ID_PARAM}/quickbooks-logout` as RoutePathType,
  QUICKBOOKS_OPTIONS: `/${ORGANISATION_ID_PARAM}/quickbooks/options/:tab?` as RoutePathType,
  PREPREND_ORGANISATION_ID_AND_REDIRECT: `/redirect/${REDIRECT_URL_PARAMS}` as RoutePathType,
  INVOICES_EXPORT: `/${ORGANISATION_ID_PARAM}/invoices/exports` as RoutePathType,
  BALANCE: `/${ORGANISATION_ID_PARAM}/balance` as RoutePathType,
  PROFILE_INFORMATIONS: `/${ORGANISATION_ID_PARAM}/profile/informations` as RoutePathType,
  COMPANY_INFORMATIONS_BUSINESS_APPROVAL: `/${ORGANISATION_ID_PARAM}/company/informations/business-validation` as RoutePathType,
  COMPANY_INFORMATIONS_ACTION: `/${ORGANISATION_ID_PARAM}/company/informations/:action?/:actionId?` as RoutePathType,
  COMPANY_INFORMATIONS_EDIT_CONTACT: `/${ORGANISATION_ID_PARAM}/company/informations/edit-contact/:actionId?` as RoutePathType,
  COMPANY_INFORMATIONS_EDIT_ADDRESS: `/${ORGANISATION_ID_PARAM}/company/informations/edit-address/:actionId?` as RoutePathType,
  COMPANY_ACCOUNTING_OPTIONS: `/${ORGANISATION_ID_PARAM}/company/accounting/options/:tab?/:action?` as RoutePathType,
  COMPANY_ACCOUNTING_OPTIONS_IMPORT: `/${ORGANISATION_ID_PARAM}/company/accounting/options/:tab/import` as RoutePathType,
  COMPANY_INTEGRATIONS: `/${ORGANISATION_ID_PARAM}/company/integrations` as RoutePathType,
  COMPANY_ACCOUNTING_LIST: `/${ORGANISATION_ID_PARAM}/company/accounting/list` as RoutePathType,
  COMPANY_ACCOUNTING: `/${ORGANISATION_ID_PARAM}/company/accounting` as RoutePathType,
  COMPANY_ACCOUNTING_SETTINGS: `/${ORGANISATION_ID_PARAM}/company/accounting/settings/:id?` as RoutePathType,
  COMPANY_QUICKBOOKS: `/${ORGANISATION_ID_PARAM}/company/quickbooks` as RoutePathType,
  COMPANY_TEAM: `/${ORGANISATION_ID_PARAM}/company/team/:action?` as RoutePathType,
  COMPANY_TEAM_INVITE: `/${ORGANISATION_ID_PARAM}/company/team/invite` as RoutePathType,
  COMPANY_PRICING: `/${ORGANISATION_ID_PARAM}/pricing` as RoutePathType,
  COMPANY_BANK: `/${ORGANISATION_ID_PARAM}/company/bank/:view?/:id?` as RoutePathType,
  COMPANY_BANK_DETAIL: `/${ORGANISATION_ID_PARAM}/company/bank/detail/:id` as RoutePathType,
  COMPANY_BANK_ADD: `/${ORGANISATION_ID_PARAM}/company/bank/add/` as RoutePathType,
  COMPANY_BANK_PAYMENT: `/${ORGANISATION_ID_PARAM}/company/bank/payment/` as RoutePathType,
  COMPANY_BANK_SYNC: `/${ORGANISATION_ID_PARAM}/company/bank-sync` as RoutePathType,
  COMPANY_BANK_TRANSACTION_DETAIL: `/${ORGANISATION_ID_PARAM}/company/bank-sync/transaction/:id` as RoutePathType,
  COMPANY_BANK_MANDATE: `/${ORGANISATION_ID_PARAM}/company/mandate/:bankId?` as RoutePathType,
  COMPANY_BANK_MANDATE_CODE: `/${ORGANISATION_ID_PARAM}/company/mandate/:bankId?/code` as RoutePathType,
  COMPANY_SETTINGS: `/${ORGANISATION_ID_PARAM}/profile/settings` as RoutePathType,
  KYC: `/kyc/${ORGANISATION_ID_PARAM}` as RoutePathType,
  KYC_STEP_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/:step?/:view?/:userId?` as RoutePathType,
  KYC_COMPANY_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/company` as RoutePathType,
  KYC_COMPANY_ADDRESS_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/company/address` as RoutePathType,
  KYC_COMPANY_TURNOVER_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/company/turnover` as RoutePathType,
  KYC_COMPANY_OUTSIDE_EU_ACTIVITY_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/company/outside-eu-activity` as RoutePathType,
  KYC_COMPANY_KBIS_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/company/justification` as RoutePathType,
  KYC_PERSONAL_INFORMATION_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/personal-information` as RoutePathType,
  KYC_PERSONAL_INFORMATION_VIEW_PART_1: `/kyc/${ORGANISATION_ID_PARAM}/personal-information/part-1/:userId?` as RoutePathType,
  KYC_PERSONAL_INFORMATION_VIEW_PART_2: `/kyc/${ORGANISATION_ID_PARAM}/personal-information/part-2/:userId?` as RoutePathType,
  KYC_PERSONAL_INFORMATION_VIEW_PART_3: `/kyc/${ORGANISATION_ID_PARAM}/personal-information/part-3/:userId?` as RoutePathType,
  KYC_PERSONAL_BENEFICIARIES_VIEW: `/kyc/${ORGANISATION_ID_PARAM}/beneficiaries` as RoutePathType,
  KYC_BENEFICIARY_FORM_VIEW_PART_1: `/kyc/${ORGANISATION_ID_PARAM}/beneficiaries/part-1/:userId?` as RoutePathType,
  KYC_BENEFICIARY_FORM_VIEW_PART_2: `/kyc/${ORGANISATION_ID_PARAM}/beneficiaries/part-2/:userId?` as RoutePathType,
  KYC_BENEFICIARY_FORM_VIEW_PART_3: `/kyc/${ORGANISATION_ID_PARAM}/beneficiaries/part-3/:userId?` as RoutePathType,
  KYC_SIGN: `/kyc/${ORGANISATION_ID_PARAM}/sign` as RoutePathType,
  KYC_IBAN: `/kyc/${ORGANISATION_ID_PARAM}/iban` as RoutePathType,
  ONBOARDING: `/${ORGANISATION_ID_PARAM}/welcome-onboarding` as RoutePathType,
  ANALYTICS: `/${ORGANISATION_ID_PARAM}/analytics` as RoutePathType,
  ANALYTICS_TREASURY: `/${ORGANISATION_ID_PARAM}/analytics/treasury` as RoutePathType,
  ANALYTICS_INVOICE_TAGS: `/${ORGANISATION_ID_PARAM}/analytics/invoice-tags` as RoutePathType,
  ANALYTICS_PAYMENT_SCHEDULE: `/${ORGANISATION_ID_PARAM}/analytics/payment-schedule` as RoutePathType,
  ANALYTICS_AMOUNT_PER_ACCOUNTING_ACCOUNT: `/${ORGANISATION_ID_PARAM}/analytics/amount-per-accounting-account` as RoutePathType,
  SALES: `/${ORGANISATION_ID_PARAM}/sales` as RoutePathType,
  RECEIVABLES: `/${ORGANISATION_ID_PARAM}/receivables` as RoutePathType,
  RECEIVABLES_DETAIL: `/${ORGANISATION_ID_PARAM}/receivables/:receivableId/detail` as RoutePathType,
  NETWORK_IBAN: `/${ORGANISATION_ID_PARAM}/network/iban` as RoutePathType,
  NETWORK_IBAN_SCREEN_UNVALIDATED_IBAN_DETAIL: `/${ORGANISATION_ID_PARAM}/network/iban/unvalidated-iban/detail/:unvalidatedIbanId` as RoutePathType,
  SPONSORSHIP_INVITE: `/${ORGANISATION_ID_PARAM}/sponsorship/invite` as RoutePathType,
  AUTOMATIONS: `/${ORGANISATION_ID_PARAM}/automations` as RoutePathType,
  ADD_NEW_AUTOMATION_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/create` as RoutePathType,
  EDIT_AUTOMATION_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/:automationRuleId/edit` as RoutePathType,
  EDIT_DUE_DATE_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/due-date/:supplierId/edit` as RoutePathType,
  EDIT_PURCHASE_ACCOUNT_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/purchase-account/:supplierId/edit` as RoutePathType,
  EDIT_TAGS_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/tags/:supplierId/edit` as RoutePathType,
  EDIT_PAYMENT_METHOD_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/payment-method/:supplierId/edit` as RoutePathType,
  EDIT_BUSINESS_APPROVAL_RULE_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/business-approval/:ruleId/edit` as RoutePathType,
  EDIT_CATEGORY_RULE_ON_BUSINESS_APPROVAL_SIDEBAR: `/${ORGANISATION_ID_PARAM}/automations/category/business-approval/:ruleId/edit` as RoutePathType,
  SALES_DRAFT_CONTROL_RECEIVABLE_INVOICE: `/${ORGANISATION_ID_PARAM}/sales/draft/receivable-invoice/:invoiceReceivableId` as RoutePathType,
  SALES_DRAFT: `/${ORGANISATION_ID_PARAM}/sales/draft` as RoutePathType,
  ORGANISATION_PROMOTIONAL_CODE: `/${ORGANISATION_ID_PARAM}/promotional-code` as RoutePathType,
  ASSISTED_ONBOARDING_CLAIM: `/assisted-onboarding/claim` as RoutePathType,
  ASSISTED_ONBOARDING_SIGNUP: `/assisted-onboarding/signup/:id?/:email?` as RoutePathType,
  ASSISTED_ONBOARDING_PRICING: `/assisted-onboarding/pricing` as RoutePathType,
  WISE_AUTH_CALLBACK: `/auth/wise/callback` as RoutePathType,
};

const PUBLIC_ROUTE: Record<string, RoutePathType> = {
  EXTERNAL_ROUTE_RECEIVE_PAYMENTS_SUCCESS: "/external/receive-ibanless-payments/success/:ibanRequestId/:publicToken" as RoutePathType,
  EXTERNAL_ROUTE_RECEIVE_PAYMENTS_UPLOAD_IBAN: "/external/receive-ibanless-payments/upload-payment-information/:ibanRequestId/:publicToken" as RoutePathType,
  EXTERNAL_ROUTE_RECEIVE_PAYMENTS_STATUS_TRACKER: "/external/receive-ibanless-payments/status-tracker/:ibanRequestId/:publicToken" as RoutePathType,
  EXTERNAL_ROUTE_RECEIVE_PAYMENTS: "/external/receive-ibanless-payments/:view?/:ibanRequestId/:publicToken" as RoutePathType,
  EXTERNAL_ROUTE_SUPPLIER_PAYMENT_PROOF: "/external/supplier-payment-proof/:paymentId" as RoutePathType,
  EXTERNAL_ROUTE_PAYMENT_STATUS_TRACKER: "/external/payment-status-tracker/:paymentRequestId" as RoutePathType,
  EXTERNAL_ROUTE_PAY_RECEIVABLE: "/external/pay/receivable/:invoiceReceivableId" as RoutePathType,
  EXTERNAL_ROUTE_PAY_RECEIVABLE_FINTECTURE_SUCCESS: "/external/pay/receivable/:invoiceReceivableId/fintecture/success" as RoutePathType,
  EXTERNAL_ROUTE_BUSINESS_APPROVED: `/external/business-validation/approved/${INVOICE_ID_PARAM}/:approverToken?` as RoutePathType,
  EXTERNAL_ROUTE_BUSINESS_REFUSED: `/external/business-validation/refused/${INVOICE_ID_PARAM}/:approverToken?` as RoutePathType,
  EXTERNAL_ROUTE_BUSINESS_APPROVAL: `/external/business-validation/${INVOICE_ID_PARAM}/:approverToken` as RoutePathType,
  EXTERNAL_ROUTE_LIBEO_PAY: "/external/libeo/pay/:realmId?/:billId?" as RoutePathType,
  EXTERNAL_ROUTE_EVOLIZ: "/external/evoliz/:siret" as RoutePathType,
  EXTERNAL_ROUTE: "/external/:view/:invoiceId?/:publicToken?" as RoutePathType,
  LOGIN: "/login/:hash?" as RoutePathType,
  SIGNUP: "/signup" as RoutePathType,
  LOGOUT: "/logout" as RoutePathType,
  SIGNUP_SUCCESS: "/signup-success" as RoutePathType,
  SIGNUP_CONFIRM_EMAIL: "/signup-confirm-email" as RoutePathType,
  RESET_PASSWORD_REQUEST: "/reset-password-request" as RoutePathType,
  RESET_PASSWORD_EMAIL_SENT: "/reset-password-email-sent" as RoutePathType,
  RESET_PASSWORD: "/reset-password/:hash" as RoutePathType,
  POC_WISE: "/poc-wise" as RoutePathType,
  MOBILE_LANDING: "/welcome-mobile-app" as RoutePathType,
};

export const EXTERNAL_ROUTE: Record<string, RoutePathType> = {
  DEMO_LANDING_PAGE: "https://libeo.io/lp-ap-payez-vos-fournisseurs-en-un-clic-v1" as RoutePathType,
  DEMO_LANDING_BOOK: "https://libeo.io/demo-personnalisee" as RoutePathType,
  MANUAL_SIGN_UP: "https://libeo.io/inscription-manuelle" as RoutePathType,
  KYC_FAQ: "https://support.libeo.io/hc/fr-fr/sections/360004289299-V%C3%A9rifications-r%C3%A9glementaires" as RoutePathType,
  LIBEO_BALANCE: "https://support.libeo.io/hc/fr-fr/articles/4402152122770-Mon-solde-Libeo-comment-%C3%A7a-marche-" as RoutePathType,
};

export const ROUTE_PATH: Record<string, RoutePathType> = {
  ...PUBLIC_ROUTE,
  ...PRIVATE_ROUTE,
  ...EXTERNAL_ROUTE,
};

export const WELYB_URL = "https://app.welyb.fr/";
export const LIBEO_WALLET_SUPPORT_URL = "https://support.libeo.io/hc/fr-fr/articles/4402152122770-Mon-solde-Libeo-comment-%C3%A7a-marche";
