/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');    // -> 'Javascript'
 */
export const capitalize = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.toLocaleLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toLocaleUpperCase());
};

export const uppercaseFirstLetter = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const removeWhiteSpaces = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.replace(/ /g, "");
};

export const replaceLastOccurence = (str: string, needle: string, replaceBy: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  const index = str.lastIndexOf(needle);
  if (index === -1) {
    return str;
  }
  return `${str.substring(0, index)}${replaceBy}${str.substring(index + 1)}`;
};

export const limitStringLength = (str: string, needle: number): string => (str.length > 10 ? `${str.slice(0, needle)}...` : str);

export const removeDotsFromString = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.replace(/\.+/g, "");
};

export const removeDashesFromString = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.replace(/-+/g, "");
};

export const replaceUnderscoresWithWhiteSpaces = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  return str.replace(/_/g, " ");
};
