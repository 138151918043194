import { useState } from "react";

export function useCopyText(): { copyText: (text: string) => void; isCopied: boolean } {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyText = async (text: string): Promise<void> => {
    const input: HTMLInputElement = document.createElement("input");
    input.style.opacity = "0";
    document.body.appendChild(input);
    input.value = text;
    const browserWindow = (window as unknown) as Window;
    if (input && window) {
      input.removeAttribute("disabled");
      input.select();
      document.execCommand("copy");
      const selection = browserWindow.getSelection();
      selection?.removeAllRanges();
      input.blur();
      input.setAttribute("disabled", "true");
      input.remove();
      setIsCopied(true);
    }
  };

  return {
    isCopied,
    copyText,
  };
}
