import * as React from "react";
import { spacing } from "../theme/spacing";
import { GREEN_CARABBEAN } from "../theme/ui-constants";

export const BackgroundGreen: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: GREEN_CARABBEAN,
        margin: spacing(0),
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  );
};
