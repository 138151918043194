import * as React from "react";
import { tdStyle } from "./styles";

export const TableTh: React.FunctionComponent<{
  styles?: React.CSSProperties;
  onClick?: () => void;
}> = ({ children, styles, onClick }) => {
  return (
    <th style={tdStyle(styles)} onClick={onClick}>
      {children}
    </th>
  );
};
