import * as React from "react";
export const AlignRight: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        whiteSpace: "break-spaces",
      }}
    >
      {children}
    </div>
  );
};
