import { nowTimestampInSeconds } from "@libeo/utils";
import * as axios from "axios";
import { get as getCookie } from "es-cookie";
import { getToken, setRefreshToken, setToken } from "../../hooks/useToken.hook";
import { JWTDecode } from "../../utils/JWTDecode.helper";
import config from "../../utils/config";
import { safelyMultiplyNumbers } from "../../utils/money-calculus/money-calculus.helper";

const REFRESH_TOKEN_LIMIT_IN_SECONDS = safelyMultiplyNumbers(5, 60);

type JWTPayload = {
  email: string; // deprecated don't use, waiting for multi-organisation
  roleId: string; // deprecated don't use, waiting for multi-organisation
  currentOrganisationId: string | undefined; // deprecated don't use, waiting for multi-organisation
  roles: { roleId: string; organisationId: string }[];
  iat: number;
  exp: number;
  sub: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnsafeJWTPayload = Record<string, any>;

const JWTPayloadIsUpToDate = (jwt: UnsafeJWTPayload): jwt is JWTPayload => {
  // eslint-disable-next-line no-prototype-builtins
  if (!jwt.hasOwnProperty("sub")) {
    return false;
  }
  return true;
};

export const refreshJWT = async (force?: boolean): Promise<void> => {
  const refreshToken = getCookie("refreshToken");
  const JWT = getToken();

  if (JWT && refreshToken) {
    const decodedToken = JWTDecode<UnsafeJWTPayload>(JWT);
    const payload: { exp: number } = decodedToken.payload as { exp: number };
    const tokenExpiry = payload.exp;
    const nowInSeconds = nowTimestampInSeconds();
    // eslint-disable-next-line libeo/no-unsafe-currency-calculus
    const willTokenExpireSoon = tokenExpiry - nowInSeconds < REFRESH_TOKEN_LIMIT_IN_SECONDS;
    const shouldRefreshToken = willTokenExpireSoon || !JWTPayloadIsUpToDate(decodedToken.payload);
    if (shouldRefreshToken || force) {
      try {
        const domain = await config.domain();
        const freshToken = await axios.default.post<{ accessToken: string; refreshToken: string }>(`${domain}/auth/refresh-token`, { refreshToken });
        setToken(freshToken.data.accessToken);
        setRefreshToken(freshToken.data.refreshToken);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }
};
