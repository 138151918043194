import * as React from "react";
import { Icon, IconValue } from "../../Icon/Icon";
import { renderNoValueStyle, renderNoValueTextDescriptionStyle, renderNoValueTextStyle } from "./styles";
import { SelectCompanyType } from "./types";

export const renderNoValue = (
  loading: boolean,
  inputValue: string,
  notFoundText: SelectCompanyType["notFoundText"],
  noDataText: SelectCompanyType["noDataText"],
) => {
  return function render() {
    return (
      !loading &&
      inputValue &&
      inputValue !== "" &&
      inputValue.length >= 3 && (
        <div style={renderNoValueStyle}>
          <Icon width="100px" height="100px" value={IconValue.EmptySearch} />
          <p style={renderNoValueTextStyle}>{notFoundText}</p>
          <p style={renderNoValueTextDescriptionStyle}>{noDataText}</p>
        </div>
      )
    );
  };
};
