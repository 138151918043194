import { DateWithoutTime, getMaxOrOneDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getConditionalDateAutoload = (
  payload: Pick<PaymentDatesPayload, "paymentStrategy" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate">,
): DateWithoutTime | undefined => {
  const { ibanValidationDate, validationDate } = payload;
  const conditionalDate = getMaxOrOneDateWithoutTime(ibanValidationDate, validationDate);
  return conditionalDate ? getCloserBusinessDate(conditionalDate) : undefined;
};
