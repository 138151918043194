import { useDebounceOnClick } from "../hooks/useDebounceOnClick.hook";
import { useHover } from "../hooks/useHover.hook";

export const useButton = ({
  onClick,
}: {
  onClick?: () => void;
}): {
  isHovered: boolean;
  stopAndCallOnClick: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
} => {
  const { isHovered, ...hoverProps } = useHover();

  const stopAndCallOnClick = async (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): Promise<void> => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      await onClick();
    }
  };
  const debounceClick = useDebounceOnClick<React.MouseEvent<HTMLButtonElement | HTMLDivElement>>(stopAndCallOnClick);

  return {
    stopAndCallOnClick: debounceClick,
    isHovered,
    ...hoverProps,
  };
};
