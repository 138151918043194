import * as React from "react";
import { spacing } from "../theme/spacing";

export const MarginMedium: React.FunctionComponent<{ top?: boolean; right?: boolean; bottom?: boolean; left?: boolean }> = ({
  children,
  top,
  right,
  bottom,
  left,
}) => {
  const hasNoDirection = !top && !right && !bottom && !left;
  if (hasNoDirection) {
    return (
      <div
        style={{
          margin: spacing(2),
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      style={{
        marginTop: top ? spacing(2) : spacing(0),
        marginRight: right ? spacing(2) : spacing(0),
        marginBottom: bottom ? spacing(2) : spacing(0),
        marginLeft: left ? spacing(2) : spacing(0),
      }}
    >
      {children}
    </div>
  );
};
