import { addNBusinessDaysToDate } from "../../date-compute-business-days/add-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";
import { getTriggeringDateTopup } from "../triggering-date/get-triggering-date-topup";

export const getReceptionOfFundsDateTopup = (payload: PaymentDatesPayload): DateWithoutTime => {
  const { payoutValidationDate, payoutCreationDate } = payload;

  if (payoutValidationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payoutValidationDate), 2));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  if (payoutCreationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payoutCreationDate), 2));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  const triggeringDate = getTriggeringDateTopup(payload);

  const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(triggeringDate), 2));
  return getCloserBusinessDate(receptionOfFundsDate);
};
