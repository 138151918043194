import * as React from "react";
import { MeContext } from "./me.types";

const Context = React.createContext<MeContext>({
  currentUser: undefined,
  currentCompany: undefined,
  organisation: undefined,
  organisationSettings: undefined,
  preferencies: undefined,
  refetch: () => undefined,
  loading: false,
});

const { Provider, Consumer } = Context;
//ts-prune-ignore-next
export { Context, Provider, Consumer };
