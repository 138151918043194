import { Box, Heading, Button } from "@libeo/design-system";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { handleNewVersion } from "../../store/helpers/applicationVersion";
import { logDisplayNewReleaseModal } from "../../store/helpers/httpError";

// WARNING ! Import less possible dependencies because boundary error can be called
// on error and crash loop error as the dependency doesn't exist

interface PromiseRejectionEventWithReason extends Omit<PromiseRejectionEvent, "reason"> {
  reason: {
    message?: string;
  };
}

class ErrorBoundary extends React.PureComponent<{ children: React.ReactNode }, { hasError: boolean; showModal: boolean }> {
  constructor(props: Readonly<{ children: React.ReactNode }>) {
    super(props);
    window.onunhandledrejection = (event: PromiseRejectionEventWithReason): void => {
      if (event.reason.message === "New version") {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.sendVersionMismatchError(new Error(event.reason.message));
        this.setState({ hasError: true, showModal: true });
      }
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  readonly state = {
    hasError: false,
    showModal: false,
  };

  async sendVersionMismatchError(error: Error): Promise<boolean> {
    const version = await handleNewVersion();
    if (version.versionMismatch) {
      logDisplayNewReleaseModal(error, version);
    }
    return version.versionMismatch;
  }

  async componentDidCatch(error: Error): Promise<void> {
    const versionMisMatch = await this.sendVersionMismatchError(error);
    this.setState({ hasError: true, showModal: versionMisMatch });
  }

  reloadPageWithoutCache = (): void => {
    window.location.href = window.location.origin;
  };

  render(): React.ReactNode {
    const { hasError, showModal } = this.state;
    return !hasError ? (
      this.props.children
    ) : showModal ? (
      <Box
        left="0px"
        top="0px"
        backgroundColor="rgba(255, 255, 255, 0.5)"
        position="fixed"
        width="100%"
        height="100%"
        overflow="hidden"
        zIndex={1000000}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          position="absolute"
          minWidth="250px"
          maxWidth="700px"
          width="50%"
          minHeight="150px"
          margin="0 auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          shadow="panel"
          padding="20px"
        >
          <Box paddingBottom={3} width="100px" as="img" src="/images/HelpingHands/handy-working.svg" alt="" />
          <Heading level="3">
            <FormattedMessage id="error.app.crash" />
          </Heading>
          <Button onPress={this.reloadPageWithoutCache}>
            <FormattedMessage id="error.app.crash_button" />
          </Button>
        </Box>
      </Box>
    ) : (
      <Box>
        <FormattedMessage id="error.module.crash" />
      </Box>
    );
  }
}

export default ErrorBoundary;
