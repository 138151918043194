import { CSSProperties } from "react";
import { PARAGRAPH_STYLE } from "../../theme/common";
import { spacing } from "../../theme/spacing";
import { BLUE_MAASTRICHT, BLUE_PALATINATE, FONT_FAMILY_DEFAULT, FONT_REGULAR, FONT_SIZE_DEFAULT } from "../../theme/ui-constants";

export const ICON_SIZE = "15px";

export const alertWrapper = (color: CSSProperties["color"] = BLUE_PALATINATE): CSSProperties => ({
  width: "100%",
  border: `1px solid ${color}`,
  borderLeft: `4px solid ${color}`,
  borderRadius: "4px",
  position: "relative",
  padding: spacing(2),
  lineHeight: PARAGRAPH_STYLE.lineHeight,
});

export const alertIconWrapper: CSSProperties = {
  width: PARAGRAPH_STYLE.lineHeight,
  height: PARAGRAPH_STYLE.lineHeight,
  display: "flex",
  alignItems: "center",
};

export const alertBackground = (color: CSSProperties["background"] = BLUE_PALATINATE): CSSProperties => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  background: color,
  borderRadius: "4px",
  opacity: 0.05,
  left: 0,
  top: 0,
  pointerEvents: "none",
});

export const alertContent: CSSProperties = {
  width: "100%",
  height: "100%",
  borderRadius: "4px",
  color: BLUE_MAASTRICHT,
  fontSize: FONT_SIZE_DEFAULT,
  fontFamily: FONT_FAMILY_DEFAULT,
  fontStyle: "normal",
  fontWeight: FONT_REGULAR,
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto",
  columnGap: spacing(1),
};

export const buttonCallToAction = (color: CSSProperties["color"] = BLUE_PALATINATE): CSSProperties => ({
  color: color,
  textDecoration: "underline",
  fontSize: FONT_SIZE_DEFAULT,
  fontFamily: FONT_FAMILY_DEFAULT,
  fontStyle: "normal",
  fontWeight: FONT_REGULAR,
  cursor: "pointer",
  display: "flex",
});

export const buttonClose: CSSProperties = {
  ...alertIconWrapper,
  cursor: "pointer",
};
