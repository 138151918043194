import * as React from "react";
import * as styles from "./styles";

export const SimpleUnorderedList: React.FunctionComponent<{
  style?: React.CSSProperties;
  id?: string;
  bulletIsGrey?: boolean;
  elements: React.ReactNode[];
}> = ({ elements, style, id, bulletIsGrey = false }) => (
  <ul id={id} style={{ ...style }}>
    {elements.map((element, index) => (
      <li style={styles.listElement} key={index}>
        <div style={styles.bullet(bulletIsGrey)} />
        <div>{element}</div>
      </li>
    ))}
  </ul>
);
