import { useEffect } from "react";

export const useDialog = (visible: boolean) => {
  useEffect(() => {
    const root: HTMLDivElement = (document.querySelector("#root") as unknown) as HTMLDivElement;

    if (visible) {
      root.style.filter = "blur(3px)";
    } else {
      root.style.filter = "";
    }

    return () => {
      root.style.filter = "";
    };
  }, [visible]);

  return null;
};
