import React, { useEffect, useState } from "react";
import { Box } from "../Layout/Box";
import { Text } from "../Typography/Text";

interface AvatarProps {
  organisationName: string | null;
  domainName: string | null;
  size?: number;
  onPress?: () => void;
}

const matchUrl = /.+\..{2,3}/;
const logoProvider = "https://logo.clearbit.com/";

export const Avatar = ({ organisationName, domainName, size = 40, onPress }: AvatarProps): React.ReactElement => {
  const [isError, setIsError] = useState<boolean>(false);

  const matchDomain = domainName && matchUrl.test(domainName);
  const imgSrc = domainName ? `${logoProvider}${domainName.replace(/http(.?):\/\//, "").toLowerCase()}?size=${size * 2}` : undefined;
  const shouldRenderLogo = Boolean(domainName && matchDomain && imgSrc !== undefined && !isError);
  const organisationNameShort = organisationName && organisationName.substring(0, 2);

  useEffect(() => {
    setIsError(false);
  }, [domainName]);

  const onError = (): void => {
    setIsError(true);
  };

  const Logo = (): React.ReactElement => <Box as="img" src={imgSrc} onError={onError} height="100%" objectFit="cover" />;

  const FallBack = (): React.ReactElement => (
    <Box alignItems="center" justifyContent="center" backgroundColor="blue-palatinate" height="100%">
      <Text textTransform="uppercase" color="white">
        {organisationNameShort}
      </Text>
    </Box>
  );

  return (
    <Box overflow="hidden" width={size} height={size} borderRadius="100%" onClick={onPress}>
      {shouldRenderLogo ? <Logo /> : <FallBack />}
    </Box>
  );
};
