import * as React from "react";
import { InlineInputProps } from "./types";

export const useInlineInput = ({
  onChangeStringValue,
}: InlineInputProps): {
  isEditing: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  onBlurEdit: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
  onSubmit: () => void;
  onClickActivateEditMode: () => void;
} => {
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onChangeValue = async (value: string | undefined): Promise<void> => {
    setIsEditing(false);
    await onChangeStringValue(value);
  };

  const onClickEdit = (): void => {
    setIsEditing(true);
  };

  const onBlurEdit = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    await onChangeValue(event.currentTarget.value);
  };

  const onSubmit = async (): Promise<void> => {
    await onChangeValue(inputRef.current?.value);
  };

  return {
    isEditing,
    inputRef,
    onClickActivateEditMode: onClickEdit,
    onBlurEdit,
    onSubmit,
  };
};
