import { useState } from "react";

export const useError = (
  defaultError = false,
): {
  hasError: boolean;
  hasAnError: () => void;
  hasNoError: () => void;
} => {
  const [hasError, setHasError] = useState(defaultError);

  const hasAnError = (): void => {
    setHasError(true);
  };

  const hasNoError = (): void => {
    setHasError(false);
  };

  return {
    hasError,
    hasAnError,
    hasNoError,
  };
};
