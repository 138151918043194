import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { FONT_SIZE_DEFAULT, FONT_REGULAR } from "../theme/ui-constants";

export const checklistItemStyle = (disable: boolean): CSSProperties => ({
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "17px 1fr",
  gridGap: spacing(2),
  color: "inherit",
  opacity: disable ? "0.4" : "1",
  fontSize: FONT_SIZE_DEFAULT,
  fontWeight: FONT_REGULAR,
});

export const checklistStyle = (color: CSSProperties["color"], numberOfChildren: number): CSSProperties => ({
  width: "fit-content",
  height: "auto",
  display: "grid",
  gridTemplateRows: `repeat(${numberOfChildren}, auto)`,
  gridGap: spacing(1),
  color: color,
});
