import { Loader } from "@libeo/design-system";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { AlignCenter } from "../Layout";
import { useOffsetTop } from "../hooks/useOffsetTop.hook";
import { MOBILE_MEDIA_QUERY } from "../theme";
import { spacing } from "../theme/spacing";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import { tableStyle } from "./styles";
import { TableColumnsPropsType, TableData } from "./types";
import { useTable } from "./useTable";

export const Table = <T extends TableData>(props: TableColumnsPropsType<T>): React.ReactElement => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MEDIA_QUERY });
  const {
    selectedRowKeys,
    onCheckRow,
    onCheckAllRow,
    shouldDisplayTableHeader,
    dataSource,
    emptyText,
    loading,
    id,
    isSticky,
    background,
    boxShadow,
  } = useTable(props);

  const { refElement: tableRef, offsetTop } = useOffsetTop();

  const loadingComponent = (
    <AlignCenter>
      <Loader />
    </AlignCenter>
  );

  return !loading ? (
    <>
      <table ref={tableRef} id={id} style={tableStyle(isMobile, background, boxShadow)}>
        {shouldDisplayTableHeader && (
          <thead>
            <TableHead {...props} isSticky={isSticky} topSticky={offsetTop} onCheckAllRow={onCheckAllRow} />
          </thead>
        )}
        <tbody>
          <TableBody {...props} dataSource={dataSource} selectedRowKeys={selectedRowKeys} onCheckRow={onCheckRow} />
        </tbody>
      </table>
      {(!dataSource || dataSource?.length === 0) && (
        <div
          style={{
            margin: spacing(2, 0),
            display: "grid",
            justifyContent: "center",
          }}
        >
          {emptyText}
        </div>
      )}
      {props.children}
    </>
  ) : (
    loadingComponent
  );
};
