import * as React from "react";
import { Icon, IconValue } from "../../Icon/Icon";
import { useHover } from "../../hooks/useHover.hook";
import * as styles from "./styles";
import { InlineInputProps } from "./types";
import { useInlineInput } from "./useInlineInput.hook";

export const InlineInput: React.FunctionComponent<InlineInputProps> = (props) => {
  const { id, defaultValue } = props;
  const { inputRef, isEditing, onBlurEdit, onClickActivateEditMode, onSubmit } = useInlineInput(props);
  const { isHovered, ...hoverProps } = useHover();

  return (
    <div id={id} {...hoverProps} style={styles.inputWrapper({ isHovered })}>
      {isEditing ? (
        <form onSubmit={onSubmit}>
          <input
            style={styles.inputStyle}
            autoCorrect="false"
            type="text"
            spellCheck={false}
            autoFocus
            ref={inputRef}
            defaultValue={defaultValue}
            onBlur={onBlurEdit}
          />
        </form>
      ) : (
        <div onClick={onClickActivateEditMode} style={{ display: "flex" }}>
          {defaultValue}
          {isHovered && <Icon value={IconValue.Edit} width={15} height={15} />}
        </div>
      )}
    </div>
  );
};
