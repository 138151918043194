import * as React from "react";
import { Avatar } from "../Avatar/Avatar";
import { AvatarProps, AvatarSizeValues } from "../Avatar/type";

export const CardAvatar: React.FunctionComponent<AvatarProps> = ({ children, icon, domainName, text, backgroundColor, boxShadow }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: AvatarSizeValues.BIG / 2,
          minWidth: AvatarSizeValues.BIG,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        >
          <Avatar
            boxShadow={boxShadow}
            backgroundColor={backgroundColor}
            domainName={domainName}
            text={text}
            icon={icon}
            size={AvatarSizeValues.BIG}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
