import React from "react";
import { TableColumnsPropsType, TableData } from "./types";

export type TableHeadProps<T extends TableData> = {
  onCheckAllRow?: (checked: boolean) => void;
  isSticky: boolean;
  topSticky: React.CSSProperties["top"];
} & TableColumnsPropsType<T>;

export const useTableHead = <T extends TableData>(props: TableHeadProps<T>) => {
  const { columns, rowSelection, onCheckAllRow, dataSource, isSticky, topSticky, rowHeaderHeight } = props;
  const [defaultCheckboxChecked, setDefaultCheckboxChecked] = React.useState(false);

  function onBeforeCheckAllRow(checked: boolean): void {
    if (onCheckAllRow) {
      setDefaultCheckboxChecked(checked);
      onCheckAllRow(checked);
    }
  }

  return {
    columns,
    rowSelection,
    onCheckAllRow,
    dataSource,
    defaultCheckboxChecked,
    isSticky,
    topSticky,
    rowHeaderHeight,
    onBeforeCheckAllRow,
  };
};
