import * as React from "react";

export const TextWrapperWithIcon: React.FunctionComponent<{
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}> = ({ children, style, iconRight, iconLeft }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      margin: 0,
      padding: 0,
      width: "fit-content",
      ...style,
    }}
  >
    {iconLeft && <div>{iconLeft}</div>}
    {children}
    {iconRight && <div>{iconRight}</div>}
  </div>
);
