import * as React from "react";
import { FONT_FAMILY_DEFAULT, FONT_REGULAR, FONT_SIZE_SMALL } from "../theme/ui-constants";

export const Small: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <small
    style={{
      fontFamily: FONT_FAMILY_DEFAULT,
      fontWeight: FONT_REGULAR,
      fontSize: FONT_SIZE_SMALL,
      ...style,
    }}
  >
    {children}
  </small>
);
