import * as React from "react";
import { MarginSmall } from "../Margin";
import { CheckboxIcon } from "./Icon/icons";
import { FormItemWrapper } from "./Wrapper";
import { useCheckbox } from "./hooks/useCheckbox.hook";
import { useInput } from "./hooks/useInput.hook";
import { checkboxCheckedStyle, checkboxInputStyle, checkboxLabelStyle, checkboxLabelWrapperStyle } from "./styles";
import { CheckboxProps } from "./types";

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
  const { tabIndex, id, required, isDisabled: disabled, label, defaultChecked, message, description, style, checked } = props;
  const { color, error } = useInput(props);

  const { isChecked, onCheck, onClick, checkboxRef } = useCheckbox(props);

  return (
    <FormItemWrapper description={description} color={color} required={required} style={style} message={message} noBorder>
      <label style={checkboxLabelWrapperStyle(Boolean(disabled))} onClick={onClick}>
        <input
          tabIndex={tabIndex}
          ref={checkboxRef}
          disabled={disabled}
          data-error={error}
          type="checkbox"
          id={id}
          defaultChecked={defaultChecked}
          style={checkboxInputStyle}
          checked={checked}
          onChange={onCheck}
        />
        <div style={checkboxLabelStyle(Boolean(disabled))}>
          <div style={checkboxCheckedStyle(Boolean(isChecked), Boolean(disabled))}>{isChecked && <CheckboxIcon disabled={disabled} />}</div>
          {label && (
            <MarginSmall left>
              {label}
              {required && " *"}
            </MarginSmall>
          )}
        </div>
      </label>
    </FormItemWrapper>
  );
};
