import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { GRAY_ATHENS_2 } from "../theme/ui-constants";
import { LayoutDirection } from "./types";

export const wrapper = (isMobile: boolean, isWrapperEmptyStateWithVideo?: boolean): CSSProperties => {
  const desktopMaxWidth = spacing(8);
  const mobileMaxWidth = "80vw";

  return {
    backgroundColor: GRAY_ATHENS_2,
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: isWrapperEmptyStateWithVideo ? spacing(3) : spacing(4),
    maxWidth: !isMobile ? desktopMaxWidth : mobileMaxWidth,
  };
};

export const benefitsWrapper = (layoutDirection: LayoutDirection): CSSProperties => (layoutDirection === "column" ? { height: "100%" } : {});

export const benefitsGrid = (numberOfFeatures: number, layoutDirection: LayoutDirection): CSSProperties => {
  const gridTemplate =
    layoutDirection === "row"
      ? { gridTemplateColumns: `repeat(${numberOfFeatures}, 1fr)` }
      : {
          gridTemplateRows: `repeat(${numberOfFeatures}, 1fr)`,
          height: "100%",
        };

  return {
    display: "grid",
    ...gridTemplate,
  };
};
