import * as React from "react";
import { AsyncDialogType } from "./types";

interface AsyncDialogProps {
  ask: (options: AsyncDialogType) => Promise<boolean>;
  confirm: () => void;
  cancel: () => void;
  close: () => void;
}

interface AsyncDialogInterface {
  asyncDialog?: AsyncDialogProps;
}

const Context = React.createContext<AsyncDialogInterface>({
  asyncDialog: {
    ask: async () => false,
    confirm: () => undefined,
    cancel: () => undefined,
    close: () => undefined,
  },
});

const { Provider, Consumer } = Context;
export { Context, Provider, Consumer };
