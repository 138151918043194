import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { AlignCenter } from "../Layout";
import { MarginSmall } from "../Margin";
import { Small } from "../Text";
import { TextGrayShuttle } from "../TextColors/TextGrayShuttle";
import { GREEN_CARABBEAN, LINE_HEIGHT_FOR_TEXT_AREA, WHITE } from "../theme/ui-constants";

export const EmptyStateBlock: React.FunctionComponent<{
  text: string;
  icon: IconValue;
  mainCta?: React.ReactNode;
}> = ({ text, icon, mainCta }) => {
  return (
    <div>
      <AlignCenter>
        <Icon value={icon} circleBorder key={icon} backgroundColor={GREEN_CARABBEAN} color={WHITE} borderColor={WHITE} width="42px" height="42px" />
      </AlignCenter>
      <MarginSmall left right key={text}>
        <div>
          <AlignCenter>
            <TextGrayShuttle>
              <Small style={{ lineHeight: LINE_HEIGHT_FOR_TEXT_AREA }}>{text}</Small>
            </TextGrayShuttle>
          </AlignCenter>
          <AlignCenter>{mainCta}</AlignCenter>
        </div>
      </MarginSmall>
    </div>
  );
};
