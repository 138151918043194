import { CannotPayInvoiceWithFintectureReason, Invoice, Organisation, SignUpInput, User } from "@libeo/graphql";
import { prefixWithFrenchCodeNumber, replaceUnderscoresWithWhiteSpaces, uppercaseFirstLetter } from "@libeo/utils";
import { SignUpFormCredentialsType } from "../../components/Auth/SignUp/type";
import { PredictionNames } from "../../components/FileViewer";
import { NotificationName } from "../../components/Notification/constants";
import { PayAction } from "../../components/Payments/types";
import { TrackingUserTraitsContextType } from "../../context/Tracking/TrackingUserTraits.types";
import { AppliedAutomationRulesOnInvoiceControl } from "../../screens/Control/ControlInvoice/type";
import { DEFAULT_APPLICATION_DOMAIN } from "../../utils/constants";
import { getThirdPartyConsumerFromUrl } from "../../utils/getThirdPartyConsumerFromUrl";

export enum BankReconciliationDocumentType {
  INVOICE = "INVOICE",
  CREDIT_NOTE = "CREDIT_NOTE",
  PAYMENT = "PAYMENT",
  EXTERNAL_PAYMENT = "EXTERNAL_PAYMENT",
}
export enum SignUpProvider {
  LIBEO = "LIBEO",
  APPLE_CONNECT = "APPLE_CONNECT",
  GOOGLE_CONNECT = "GOOGLE_CONNECT",
  QUICKBOOKS_CONNECT = "QUICKBOOKS_CONNECT",
}

export enum ClickOnOnboardingCtaLocation {
  ONBOARDING_PAGE = "ONBOARDING_PAGE",
  CLAIM_PAGE = "CLAIM_PAGE",
  EMPTY_STATE = "EMPTY_STATE",
  ALERT = "ALERT",
  RECEIVABLES_PAGE = "RECEIVABLES_PAGE",
}

export enum PayableDocumentType {
  PURCHASE_ORDER = "PURCHASE_ORDER",
  INVOICE = "INVOICE",
}

export enum VideoDescription {
  INVOICE_EMPTY_STATE = "INVOICE_EMPTY_STATE",
  DRAFT_EMPTY_STATE = "DRAFT_EMPTY_STATE",
  QUICKBOOKS_LIBEO_PAY_PRESENTATION = "QUICKBOOKS_LIBEO_PAY_PRESENTATION",
}
export const evolizApplicationDomain = "evoliz";
export const quickbooksApplicationDomain = "quickbooksLibeoPay";

export enum LandingPageButton {
  QUICKBOOKS_CTA_TOP_CLICKED = "QUICKBOOKS_CTA_TOP_CLICKED",
  QUICKBOOKS_CTA_MIDDLE_CLICKED = "QUICKBOOKS_CTA_MIDDLE_CLICKED",
  QUICKBOOKS_CTA_BOTTOM_CLICKED = "QUICKBOOKS_CTA_BOTTOM_CLICKED",
}

export enum ClickOnOnboardingCtaEventName {
  CONTROL_INVOICE_CTA_CLICKED = "Control invoice cta clicked",
  UPLOAD_INVOICE_CTA_CLICKED = "Upload invoice cta clicked",
  UPLOAD_INVOICE_RECEIVABLE_CTA_CLICKED = "Upload invoice receivable cta clicked",
  UPLOAD_PURCHASE_ORDER_CTA_CLICKED = "Upload purchase order cta clicked",
  UPLOAD_CREDIT_NOTE_CTA_CLICKED = "Upload credit note cta clicked",
  INITIATE_KYC_CTA_CLICKED = "Initiate KYC cta clicked",
  INVITE_TEAM_MEMBER_CTA_CLICKED = "Invite team member cta clicked",
  SYNC_BANK_ACCOUNT_CTA_CLICKED = "Sync bank account cta clicked",
  EXPORT_ACCOUNTING_ENTRIES_CTA_CLICKED = "Export accounting entries cta clicked",
  EXPORT_INVOICES_CTA_CLICKED = "Export invoices cta clicked",
  IMPORT_SUPPLIERS_CTA_CLICKED = "Import suppliers cta clicked",
  COMPANY_NOT_FOUND_CTA_CLICKED = "Company not found cta clicked",
  INITIATE_FIRST_PAIEMENT_WITH_LIBEO_CTA_CLICKED = "Initiate first paiement with libeo cta clicked",
  AR_BETA_TESTER_CTA_CLICKED = "Participate in beta test cta clicked",
}

enum ViewAlertLocation {
  ALERT = "ALERT",
}

export enum WidgetName {
  INVOICE_TO_CONTROL_COUNT = "INVOICE_TO_CONTROL_COUNT",
  INVOICE_TO_PAY_COUNT = "INVOICE_TO_PAY_COUNT",
  TOTAL_TO_PAY = "TOTAL_TO_PAY",
  MONTHLY_GRAPH = "MONTHLY_GRAPH",
  SUPPLIER_GRAPH = "SUPPLIER_GRAPH",
  INVOICE_TO_CONTROL = "INVOICE_TO_CONTROL",
  INVOICE_TO_PAY = "INVOICE_TO_PAY",
}

export enum OnboardingVideoButton {
  SKIP = "SKIP",
  START = "START",
}

enum SegmentEventName {
  BANK_RECONCILIATION = "bank reconciliation button clicked",
  BTN_WARNING_FORCE_IBAN_CANCEL = "User has cancelled force iban after duplicate warning",
  BTN_WARNING_FORCE_IBAN_CONTINUE = "User has accept force iban duplicate warning",
  BULK_PAID = "Paid by bulk",
  BULK_REFUSED = "Refused by bulk",
  BULK_VALIDATED = "Validated by bulk",
  CLICK_ON_LEARN_MORE_ABOUT_FAST_PAYMENT = "Click on learn more about fast payment",
  COMPANY_NOT_FOUND_POPIN_SHOWED = "Company not found Popin Showed", // -> popin
  CONTROL_FORM_FOOTER_SAVE_LATER_CLICKED = "Control form footer save later clicked",
  CUSTOMER_ADDED = "Customer added",
  DASHBOARD_WIDGET_CLICKED = "Dashboard widget clicked",
  DEXT_CTA_COPIED = "User copied on Dext sync cta",
  DUPLICATE_DOCUMENT_BUTTON_DELETE_DOCUMENT_CLICKED = "Duplicate Document Button Delete Document Clicked",
  DUPLICATE_DOCUMENT_MODAL_VIEWED = "Duplicate Document Viewed",
  EVOLIZ_CTA_COPIED = "User copied on Evoliz sync cta",
  EXCO_CTA_COPY = "User copied on Exco sync cta",
  EXTERNAL_CTA_DOWNLOAD_INVOICE_CLICKED = "External - cta download invoice clicked",
  EXTERNAL_CTA_DOWNLOAD_PROOF_OF_PAYMENT_CLICKED = "External - cta download proof of payment clicked",
  EXTERNAL_IBANLESS_PAGE_VIEWED = "External Ibanless Page Viewed",
  EXTERNAL_IBANLESS_RECEIVE_PAYMENTS_CTA_GET_PAYMENT_CLICKED = "External Ibanless - Receive Payments - cta get payment clicked",
  EXTERNAL_IBANLESS_SUCCESS_CTA_TRACK_PAYMENT_STATUS_CLICKED = "External Ibanless - Information upload success - cta track payment status clicked",
  EXTERNAL_IBANLESS_SUCCESS_CTA_USE_LIBEO_FOR_ACCOUNT_PAYABLES_CLICKED = "External Ibanless - Information upload success - cta use libeo for account payables clicked",
  EXTERNAL_IBANLESS_SUCCESS_CTA_USE_LIBEO_FOR_ACCOUNT_RECEIVABLES_CLICKED = "External Ibanless - Information upload success - cta use libeo for account receivables clicked",
  EXTERNAL_IBANLESS_UPLOAD_PAYMENT_INFO_CTA_VALIDATE_RIB_UPLOAD_CLICKED = "External Ibanless - Upload payment information - cta validate RIB upload clicked",
  EXTERNAL_PROOF_OF_PAYMENT_CTA_USE_LIBEO_FOR_ACCOUNT_RECEIVABLES_CLICKED = "External Proof of payment - cta use libeo for account receivables clicked",
  INVOICE_CONTROL_CLICK_DOWNLOAD = "Invoice Control Click Download",
  INVOICE_CONTROL_CLICK_PREDICTION = "Invoice Control Click Prediction",
  INVOICE_CONTROLLED_AND_CONTINUED_TO_NEXT = "Invoice controlled and continued to the next",
  INVOICE_CONTROLLED_AND_DETAIL_DISPLAYED = "Invoice controlled and detail displayed",
  KYC_PAYMENT_IS_DONE_SELECTED = "KYC payment is done selected",
  KYC_PAYMENT_IS_NOT_DONE_YET_SELECTED = "KYC payment is not done yet selected",
  NAVIGATION_LINK_CLICKED = "Navigation link clicked",
  ONBOARDING_ALERT_CLICKED = "Onboarding Alert Clicked",
  ONBOARDING_ALERT_VIEWED = "Onboarding Alert Viewed",
  ONBOARDING_VIDEO_NEXT_PAGE = "Onboarding Video Next Page",
  PAY_WARNING_DUPLICATE_CANCEL = "User has cancelled payment after duplicate warning",
  PAY_WARNING_DUPLICATE_CONTINUE = "User has paid despite duplicate warning",
  QUALIFYING_QUESTION_ANSWERED = "qualifying question answered",
  QUICKBOOKS_LANDING_VIEWED = "Quickbooks Landing Viewed",
  EVOLIZ_LANDING_VIEWED = "Evoliz Landing Viewed",
  SELECT_MY_BANK_DIALOG_USER_BANK_NOT_LISTED = "Select My Bank Dialog - User banl not listed",
  SELECT_MY_BANK_DIALOG_USER_SELECTED_A_BANK = "Select My Bank Dialog - User selected a bank",
  SIGN_UP_PERSONA = "Signup Page Viewed",
  SIGN_UP_SUCCESS = "User Signed Up Successfully",
  SPLIT_INVOICE = "Invoice document splitted",
  SUPPLIER_ADDED = "Supplier added",
  TEST_INVOICE_PAYMENT_SUCCESS_POPIN_SHOWED = "Test Invoice Payment Success Popin Showed",
  UPGRADE_POPIN_SHOWED = "Upgrade Popin Showed",
  USER_CLICKED_ON_BANK_RECONCILIATION = "User clicked on bank reconciliation CTA",
  USER_CLICKED_ON_FINANCE_YOUR_INVOICE_BUTTON = "User clicked on finance your invoice button",
  USER_CLICKED_ON_KYC_IN_TEST_PAYMENT_POPIN = "User Clicked On KYC In Test Payment Popin",
  USER_CLICKED_ON_PAY_BUTTON = "User clicked on pay button",
  USER_CLICKED_ON_TEST_INVOICE_BUTTON = "User Clicked On Test Invoice Button",
  USER_DISPLAY_MODAL_NEW_RELEASE = "User must reload to new app release",
  USER_INITIATED_PAY_FLOW = "User initiated pay flow",
  USER_LEFT_KYC = "KYC - User Dropped",
  USER_PUT_KYC_ON_SUSPEND = "KYC - User Finished Later",
  USER_RECREATED_IBANLESS_PAYMENTS_WARNING_POPIN = "User recreate ibanless payments warning popin",
  USER_REDIRECTED_TO_APP_UNAVAILABLE_PAGE = "User redirected to app unavailable page",
  USER_STARTED_SIGNUP_FLOW = "User Started Signup Flow",
  USER_WITH_NO_KYC_COULD_NOT_PAY_FINTECTURE = "User with no kyc could not pay with fintecture",
  VIDEO_CONTENT_STARTED = "Video Content Started",
  VIDEO_PLAYBACK_PAUSED = "Video Playback Paused",
  WALLET_DEPOSIT_POPIN_SHOWED = "Libeo Wallet deposit popin showed",
  WELYB_CTA_CLICK = "User clicked on Welyb sync cta",
  ZAPIER_CTA_COPIED = "User copied on Zapier sync cta",
}

const removePasswordFromSignUpInput = <T extends SignUpFormCredentialsType>(user: T): Omit<T, "password"> => {
  const { password: _password, ...userWithoutPassword } = user;
  return userWithoutPassword;
};

interface WindowWithSegment extends Window {
  analytics: SegmentAnalytics.AnalyticsJS;
}

const isWindowWithSegment = (window: Window): window is WindowWithSegment => {
  return Boolean(((window as unknown) as WindowWithSegment).analytics);
};

const formatDatesInUserTraitsObject = (userTraits: TrackingUserTraitsContextType): TrackingUserTraitsContextType => {
  const signupDate = userTraits.signupDate;

  if (signupDate) {
    userTraits.signupDate = new Date(signupDate);
  }

  return userTraits;
};

const track = (
  userId: string,
  event: SegmentEventName | ClickOnOnboardingCtaEventName,
  properties: Record<string, string | boolean | number | string[] | Object> = {},
): void => {
  const applicationDomain = getThirdPartyConsumerFromUrl() ?? DEFAULT_APPLICATION_DOMAIN;
  const isFirstSession = sessionStorage.getItem("isFirstSession") === "True";

  if (isFirstSession) {
    properties.isFirstSession = true;
  }

  if (isWindowWithSegment(window)) {
    window.analytics.track && window.analytics.track(event, { userId, applicationDomain, ...properties });
  }
};

export const signUpLandingPersona = (signupPayload: { email?: string; source?: string; invoiceId?: string }): void => {
  const segmentData: { email?: string; source?: string; invoiceId?: string } = Object.assign({}, signupPayload);

  if (isWindowWithSegment(window)) {
    window.analytics.track && window.analytics.track(SegmentEventName.SIGN_UP_PERSONA, segmentData);
  }
};

export const signUpSubmitFormCredentials = (signUpInput: SignUpFormCredentialsType): void => {
  const user = removePasswordFromSignUpInput(signUpInput);
  if (isWindowWithSegment(window)) {
    window.analytics.identify && window.analytics.identify(user);
  }
};

export const signUpSubmit = (signUpInput: SignUpInput): void => {
  const user = removePasswordFromSignUpInput(signUpInput);
  user.phone = user.phone ? prefixWithFrenchCodeNumber(user.phone) : undefined;

  if (isWindowWithSegment(window)) {
    window.analytics.identify && window.analytics.identify(user);
  }
};

export const signUpSuccess = (userId: string, signUpInput: SignUpInput): void => {
  const user = removePasswordFromSignUpInput(signUpInput);
  user.phone = user.phone ? prefixWithFrenchCodeNumber(user.phone) : undefined;

  if (isWindowWithSegment(window)) {
    window.analytics.identify && window.analytics.identify(userId, user);
    window.analytics.track && window.analytics.track(SegmentEventName.SIGN_UP_SUCCESS, { userId, user });
  }
};

export const updateUserTrait = (userId: string, userTraits: TrackingUserTraitsContextType): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.identify && window.analytics.identify(userId, formatDatesInUserTraitsObject(userTraits));
  }
};

export const trackCompanyNotFoundPopinShowed = (userId: string): void => {
  track(userId, SegmentEventName.COMPANY_NOT_FOUND_POPIN_SHOWED, { userId });
};

export const trackSelectMyBankUserSelectedABank = (userId: string, bankProvider: string): void => {
  track(userId, SegmentEventName.SELECT_MY_BANK_DIALOG_USER_SELECTED_A_BANK, { bankProvider });
};

export const trackSelectMyBankDialogBankNotListed = (userId: string): void => {
  track(userId, SegmentEventName.SELECT_MY_BANK_DIALOG_USER_BANK_NOT_LISTED);
};

export const trackWalletDepositPopinShowed = (userId: string): void => {
  track(userId, SegmentEventName.WALLET_DEPOSIT_POPIN_SHOWED, { userId });
};

export const trackClickOnLearnMoreAboutFastPayment = (
  organisationId: Organisation["id"],
  userId: User["id"],
  invoiceId: Invoice["id"],
  amount: Invoice["total"],
): void => {
  if (!amount) {
    throw new Error("Segment > trackClickOnLearnMoreAboutFastPayment > Invoice has no total");
  }
  track(userId, SegmentEventName.CLICK_ON_LEARN_MORE_ABOUT_FAST_PAYMENT, { organisationId, invoiceId, amount });
};

export const CompanyQualifyingQuestionAnswered = (userId: string, question: string, answer: string, answerOrder: string[]): void => {
  track(userId, SegmentEventName.QUALIFYING_QUESTION_ANSWERED, { userId, answer, question, answerOrder });
};

export const SplitInvoice = (userId: string, count: number): void => {
  track(userId, SegmentEventName.SPLIT_INVOICE, { userId, count });
};

export const InvoiceAndControllerAndContinuedToNext = (userId: string, appliedAutomationRules: AppliedAutomationRulesOnInvoiceControl): void => {
  track(userId, SegmentEventName.INVOICE_CONTROLLED_AND_CONTINUED_TO_NEXT, {
    userId,
    appliedAutomationRules: JSON.parse(JSON.stringify(appliedAutomationRules)),
  });
};

export const InvoiceAndControllerAndDetailDisplayed = (userId: string, appliedAutomationRules: AppliedAutomationRulesOnInvoiceControl): void => {
  track(userId, SegmentEventName.INVOICE_CONTROLLED_AND_DETAIL_DISPLAYED, {
    userId,
    appliedAutomationRules: JSON.parse(JSON.stringify(appliedAutomationRules)),
  });
};

export const InvoiceControlClickPrediction = (userId: string, predictionName: PredictionNames): void => {
  track(userId, SegmentEventName.INVOICE_CONTROL_CLICK_PREDICTION, { userId, predictionName });
};

export const InvoiceControlClickDownload = (userId: string, invoiceId: string): void => {
  track(userId, SegmentEventName.INVOICE_CONTROL_CLICK_DOWNLOAD, { userId, invoiceId });
};
export const UserWithNoKycCouldNotPayWithFintecture = (
  userId: string,
  organisationId: string,
  invoiceId: string,
  fintectureNotAllowedReasons: CannotPayInvoiceWithFintectureReason[],
): void => {
  track(userId, SegmentEventName.USER_WITH_NO_KYC_COULD_NOT_PAY_FINTECTURE, { userId, organisationId, invoiceId, fintectureNotAllowedReasons });
};

export const BulkPaid = (userId: string, organisationId: string, numberOfInvoices: number): void => {
  track(userId, SegmentEventName.BULK_PAID, { userId, organisationId, numberOfInvoices });
};

export const BulkValidated = (userId: string, organisationId: string, numberOfInvoices: number): void => {
  track(userId, SegmentEventName.BULK_VALIDATED, { userId, organisationId, numberOfInvoices });
};

export const BulkRefused = (userId: string, organisationId: string, numberOfInvoices: number): void => {
  track(userId, SegmentEventName.BULK_REFUSED, { userId, organisationId, numberOfInvoices });
};

export const UserInitiatedPayFlow = (
  userId: string,
  organisationId: string,
  payAction: PayAction,
  paymentFromDocument: PayableDocumentType,
  paymentFromDocumentId: string,
): void => {
  track(userId, SegmentEventName.USER_INITIATED_PAY_FLOW, { userId, organisationId, payAction, paymentFromDocument, paymentFromDocumentId });
};

export const UserWarningCancelPayFlowDuplicateInvoice = (userId: string, organisationId: string, invoiceId: string): void => {
  track(userId, SegmentEventName.PAY_WARNING_DUPLICATE_CANCEL, { userId, organisationId, invoiceId });
};

export const UserWarningContinuePayFlowDuplicateInvoice = (userId: string, organisationId: string, invoiceId: string): void => {
  track(userId, SegmentEventName.PAY_WARNING_DUPLICATE_CONTINUE, { userId, organisationId, invoiceId });
};

export const UserWarningCancelForceDuplicateIban = (userId: string, organisationId: string, iban: string): void => {
  track(userId, SegmentEventName.BTN_WARNING_FORCE_IBAN_CANCEL, { userId, organisationId, iban });
};

export const UserWarningContinueForceDuplicateIban = (userId: string, organisationId: string, iban: string): void => {
  track(userId, SegmentEventName.BTN_WARNING_FORCE_IBAN_CONTINUE, { userId, organisationId, iban });
};

export const UserWarningRecreateIbanlessPayments = (userId: string, organisationId: string, shouldRecreate: boolean): void => {
  track(userId, SegmentEventName.USER_RECREATED_IBANLESS_PAYMENTS_WARNING_POPIN, { userId, organisationId, shouldRecreate });
};

export const userStartedSignup = ({
  provider = SignUpProvider.LIBEO,
  inputEmail = "",
  realmId = "",
  billId = "",
  applicationDomain = "",
  landingPageButton,
}: {
  provider?: SignUpProvider;
  inputEmail?: string | null;
  realmId?: string;
  billId?: string;
  applicationDomain?: string;
  landingPageButton?: LandingPageButton;
}): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.track?.(SegmentEventName.USER_STARTED_SIGNUP_FLOW, {
      realmId,
      billId,
      provider,
      inputEmail,
      applicationDomain,
      landingPageButton,
    });
  }
};

export const UserClickedOnPayButton = (
  userId: string,
  organisationId: string,
  invoiceId: string,
  payAction: PayAction,
  payWithFintecture: boolean,
): void => {
  track(userId, SegmentEventName.USER_CLICKED_ON_PAY_BUTTON, { userId, organisationId, invoiceId, payAction, payWithFintecture });
};

export const UserRedirectedToAppUnavailablePage = (locationHref: string, operationName: string, organisationId: string | undefined): void => {
  window.analytics?.track?.(SegmentEventName.USER_REDIRECTED_TO_APP_UNAVAILABLE_PAGE, { locationHref, operationName, organisationId });
};

export const SupplierWasAdded = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.SUPPLIER_ADDED, { userId, organisationId });
};

export const CustomerWasAdded = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.CUSTOMER_ADDED, { userId, organisationId });
};

export const UserClickOnZapierSyncCta = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.ZAPIER_CTA_COPIED, { userId, organisationId });
};

export const UserClickOnEvolizSyncCta = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.EVOLIZ_CTA_COPIED, { userId, organisationId });
};

export const UserClickOnDextSyncCta = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.DEXT_CTA_COPIED, { userId, organisationId });
};

export const UserClickOnExcoSyncCta = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.EXCO_CTA_COPY, { userId, organisationId });
};

export const UserClickOnWelybSyncCta = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.WELYB_CTA_CLICK, { userId, organisationId });
};

export const UserClickOnBankReconciliationCta = (payload: {
  userId: string;
  organisationId: string;
  documentId: string;
  documentType: BankReconciliationDocumentType;
  transactionId: string;
}): void => {
  const { userId } = payload;
  track(userId, SegmentEventName.USER_CLICKED_ON_BANK_RECONCILIATION, payload);
};

export const userSelectedKycPaymentIsDone = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.KYC_PAYMENT_IS_DONE_SELECTED, { userId, organisationId });
};

export const userSelectedKycPaymentIsNotDoneYet = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.KYC_PAYMENT_IS_NOT_DONE_YET_SELECTED, { userId, organisationId });
};

export const dispatchClickOnOnboardingCta = (
  userId: string,
  organisationId: string | undefined,
  event: ClickOnOnboardingCtaEventName,
  location: ClickOnOnboardingCtaLocation | ViewAlertLocation,
  options?: Record<string, string | boolean | number | string[]>,
): void => {
  const properties = organisationId ? { userId, organisationId, location, ...options } : { userId, location, ...options };
  track(userId, event, properties);
};

export const userClickedOnMenuItem = (
  userId: string,
  linkLabel: string,
  navigationLocation: "lateral navigation" | "header navigation" | "togglebutton navigation",
): void => {
  track(userId, SegmentEventName.NAVIGATION_LINK_CLICKED, { userId, linkLabel, navigationLocation });
};

export const userClickedOnControlFormSaveLaterItem = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.CONTROL_FORM_FOOTER_SAVE_LATER_CLICKED, { organisationId, userId });
};

export const trackPageView = (pageName: string, url: string): void => {
  window.analytics?.page?.(pageName, {
    type: "page",
    name: pageName,
    properties: {
      title: pageName,
      url: url,
    },
  });
};

export const userClickedOnWidget = (userId: string, organisationId: string, widgetName: WidgetName): void => {
  track(userId, SegmentEventName.DASHBOARD_WIDGET_CLICKED, { userId, organisationId, widgetName });
};

export const userSkippedVideo = (userId: string, organisationId: string, buttonClicked: OnboardingVideoButton): void => {
  track(userId, SegmentEventName.ONBOARDING_VIDEO_NEXT_PAGE, { userId, organisationId, buttonClicked });
};

export const userPauseOnVideo = (
  userId: string,
  organisationId: string,
  location: ClickOnOnboardingCtaLocation,
  videoTimeStamp: number,
  videoDescription: VideoDescription,
): void => {
  track(userId, SegmentEventName.VIDEO_PLAYBACK_PAUSED, { userId, organisationId, videoTimeStamp, location, videoDescription });
};

export const userStartOnVideo = (
  userId: string,
  organisationId: string,
  location: ClickOnOnboardingCtaLocation,
  videoTimeStamp: number,
  videoDescription: VideoDescription,
): void => {
  track(userId, SegmentEventName.VIDEO_CONTENT_STARTED, { userId, organisationId, videoTimeStamp, location, videoDescription });
};

export const quickbooksUserPauseOnVideo = (
  applicationDomain?: string,
  realmId?: string,
  billId?: string,
  videoDescription?: VideoDescription,
  videoTimeStamp?: number,
): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.track?.(SegmentEventName.VIDEO_PLAYBACK_PAUSED, {
      applicationDomain,
      realmId,
      billId,
      videoDescription,
      videoTimeStamp,
    });
  }
};

export const quickbooksUserStartOnVideo = (
  applicationDomain?: string,
  realmId?: string,
  billId?: string,
  videoDescription?: VideoDescription,
  videoTimeStamp?: number,
): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.track?.(SegmentEventName.VIDEO_CONTENT_STARTED, {
      applicationDomain,
      realmId,
      billId,
      videoDescription,
      videoTimeStamp,
    });
  }
};

export const quickbooksLandingViewed = (applicationDomain?: string, realmId?: string, billId?: string): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.track?.(SegmentEventName.QUICKBOOKS_LANDING_VIEWED, {
      applicationDomain,
      realmId,
      billId,
    });
  }
};

export const evolizLandingViewed = (applicationDomain?: string, siret?: string, email?: string): void => {
  if (isWindowWithSegment(window)) {
    window.analytics.track?.(SegmentEventName.EVOLIZ_LANDING_VIEWED, {
      applicationDomain,
      siret,
      email,
    });
  }
};

export const identifyExternalIbanlessSupplier = ({ supplierEmail }: { supplierEmail: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.identify) {
    window.analytics.identify({ email: supplierEmail });
  }
};

export const trackExternalIbanlessPageViewed = (properties: {
  ibanRequestId: string;
  organisationId: string;
  supplierInformation: { id: string; email: string };
  totalAmount: number;
  currency: string;
  route: "receive-payments" | "upload-iban" | "upload-iban-success";
}): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_PAGE_VIEWED, properties);
  }
};

export const trackExternalIbanlessCTAGetPaymentClicked = (properties: {
  ibanRequestId: string;
  organisationId: string;
  supplierInformation: { id: string; email: string };
  totalAmount: number;
  currency: string;
  doesCustomMessageForSupplierExists: boolean;
}): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_RECEIVE_PAYMENTS_CTA_GET_PAYMENT_CLICKED, properties);
  }
};

export const trackExternalIbanlessCTAValidateRIBUploadClicked = (properties: { ibanRequestId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_UPLOAD_PAYMENT_INFO_CTA_VALIDATE_RIB_UPLOAD_CLICKED, properties);
  }
};

export const trackExternalIbanlessCTAUseLibeoForAccountReceivablesClicked = (properties: { ibanRequestId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_SUCCESS_CTA_USE_LIBEO_FOR_ACCOUNT_RECEIVABLES_CLICKED, properties);
  }
};

export const trackExternalIbanlessCTAUseLibeoForAccountPayablesClicked = (properties: { ibanRequestId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_SUCCESS_CTA_USE_LIBEO_FOR_ACCOUNT_PAYABLES_CLICKED, properties);
  }
};

export const trackExternalIbanlessCTATrackPaymentStatusClicked = (properties: { ibanRequestId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_IBANLESS_SUCCESS_CTA_TRACK_PAYMENT_STATUS_CLICKED, properties);
  }
};

export const trackExternalCTADownloadInvoiceClicked = (properties: { ibanRequestId?: string; invoiceId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_CTA_DOWNLOAD_INVOICE_CLICKED, properties);
  }
};

export const trackExternalCTADownloadProofOfPaymentClicked = (properties: { invoiceId: string }): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_CTA_DOWNLOAD_PROOF_OF_PAYMENT_CLICKED, properties);
  }
};

export const trackExternalProofOfPaymentCTAUseLibeoForAccountReceivablesClicked = (properties: {
  paymentId?: string;
  paymentRequestId?: string;
  invoiceId: string;
}): void => {
  if (isWindowWithSegment(window) && window.analytics.track) {
    window.analytics.track(SegmentEventName.EXTERNAL_PROOF_OF_PAYMENT_CTA_USE_LIBEO_FOR_ACCOUNT_RECEIVABLES_CLICKED, properties);
  }
};

export const alertNotificationViewed = (userId: string, organisationId: string, notificationName: NotificationName): void => {
  const formattedNotificationName = uppercaseFirstLetter(replaceUnderscoresWithWhiteSpaces(`${notificationName}`));
  track(userId, SegmentEventName.ONBOARDING_ALERT_VIEWED, {
    userId,
    organisationId,
    formattedNotificationName,
  });
};

export const alertNotificationClicked = (userId: string, organisationId: string, notificationName: NotificationName): void => {
  const formattedNotificationName = uppercaseFirstLetter(replaceUnderscoresWithWhiteSpaces(`${notificationName}`));
  track(userId, SegmentEventName.ONBOARDING_ALERT_CLICKED, { userId, organisationId, formattedNotificationName });
};

export const userClickedOnKycOnTestInvoicePopin = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.USER_CLICKED_ON_KYC_IN_TEST_PAYMENT_POPIN, { userId, organisationId });
};

export const duplicateDocumentModalViewed = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.DUPLICATE_DOCUMENT_MODAL_VIEWED, { userId, organisationId });
};

export const duplicateDocumentButtonDeleteDocumentClicked = (userId: string, organisationId: string): void => {
  track(userId, SegmentEventName.DUPLICATE_DOCUMENT_BUTTON_DELETE_DOCUMENT_CLICKED, { userId, organisationId });
};
