import * as React from "react";
import { default as ReactSelect, FocusEventHandler } from "react-select";
import {
  LoadingMessageComponent,
  MenuListComponent,
  NoOptionsMessageComponent,
  OptionComponent,
  SelectClearIndicator,
  SelectDropDownIndicator,
  SingleValueComponent,
} from "../Select/SelectComponents";
import { FormItemWrapper } from "../Wrapper/FormItemWrapper";
import { useInput } from "../hooks/useInput.hook";
import { inputStyle, selectStyles } from "../styles";
import { InputProps } from "../types";
import { SelectCompanyFooter } from "./SelectCompanyFooter";
import { renderNoValue } from "./SelectCompanyNoValue";
import { SelectCompanyOptionsType, SelectCompanyType } from "./types";
import { useSelectCompany } from "./useSelectCompany";

export const KEYBOARD_ENTER_KEY = 13;

export const SelectCompany = (props: SelectCompanyType): JSX.Element => {
  const {
    filterOption,
    hasError,
    isRequired,
    description,
    message,
    options,
    label,
    defaultValue,
    value,
    notFoundText,
    noDataText,
    onChangeOption,
    isReady = true,
    footerOptionClick,
    style,
  } = props;
  const inputProps = useInput(({ error: hasError, ...props } as unknown) as InputProps);
  const {
    inputValue,
    isLoading,
    isInputFocused,
    hasValue,
    onFocus,
    onBlur,
    onBeforeInputValueChange,
    selectCreateRef,
    renderOption,
  } = useSelectCompany(props);

  function onKeyDown(event: React.KeyboardEvent<HTMLElement>): void {
    if (event.keyCode === KEYBOARD_ENTER_KEY) {
      event.preventDefault();
      // event.stopPropagation();
      const select = selectCreateRef?.current?.select;
      if (!select?.state?.focusedOption) {
        footerOptionClick && footerOptionClick();
      }
    }
  }

  return (
    <FormItemWrapper
      color={inputProps.color}
      label={label}
      required={isRequired}
      description={description}
      inputFocus={isInputFocused}
      inputHasValue={hasValue}
      message={message}
      style={style}
    >
      {isReady && (
        <ReactSelect<SelectCompanyOptionsType>
          onKeyDown={onKeyDown}
          ref={selectCreateRef}
          defaultValue={defaultValue}
          value={value}
          styles={selectStyles()}
          isClearable={true}
          escapeClearsValue={true}
          placeholder=""
          onChange={onChangeOption}
          blurInputOnSelect={false}
          isLoading={isLoading}
          components={{
            DropdownIndicator: SelectDropDownIndicator(),
            ClearIndicator: SelectClearIndicator,
            Option: OptionComponent(renderOption(false)),
            SingleValue: SingleValueComponent(renderOption(true)),
            LoadingMessage: LoadingMessageComponent(),
            NoOptionsMessage: NoOptionsMessageComponent(renderNoValue(Boolean(isLoading), inputValue || "", notFoundText, noDataText)),
            MenuList: MenuListComponent(undefined, undefined, SelectCompanyFooter(props)),
          }}
          style={inputStyle(undefined, undefined, Boolean(label))}
          onFocus={onFocus as FocusEventHandler}
          error={hasError}
          onBlur={onBlur as FocusEventHandler}
          allowCreateWhileLoading={true}
          id={props.id}
          filterOption={filterOption}
          onInputChange={onBeforeInputValueChange}
          options={!isLoading ? options : undefined}
        />
      )}
    </FormItemWrapper>
  );
};
