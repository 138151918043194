import * as React from "react";
import { WHITE } from "../theme/ui-constants";

export const TextWhite: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <span
      style={{
        color: WHITE,
      }}
    >
      {children}
    </span>
  );
};
