import * as React from "react";
import { useFullPageCarousel } from "./FullPageCarousel.hook";
import { FullPageCarouselButtons } from "./FullPageCarouselButtons";
import { FullPageCarouselFooter } from "./FullPageCarouselFooter";
import { fullPageCarouselInnerStyles, fullPageCarouselWrapperStyles } from "./styles";
import { FullPageCarouselProps } from "./types";

export const FullPageCarousel: React.FunctionComponent<FullPageCarouselProps> = ({
  items,
  nextButtonNode,
  prevButtonNode,
  finishButtonNode,
  onFinish,
}) => {
  const numberOfStep = items.length;
  const { step, nextStep, goToStep, previousStep, finishStep } = useFullPageCarousel({ numberOfItems: numberOfStep, onFinish });

  return (
    <div style={fullPageCarouselWrapperStyles} onClick={nextStep}>
      <div style={fullPageCarouselInnerStyles}>
        {items?.[step]}
        <FullPageCarouselFooter
          nextButtonNode={nextButtonNode}
          prevButtonNode={prevButtonNode}
          finishButtonNode={finishButtonNode}
          onClickNextStep={nextStep}
          onClickPrevStep={previousStep}
          onClickFinishStep={finishStep}
          currentStep={step}
          numberOfStep={numberOfStep}
        >
          <FullPageCarouselButtons onClickStep={goToStep} currentStep={step} numberOfItems={numberOfStep} />
        </FullPageCarouselFooter>
      </div>
    </div>
  );
};
