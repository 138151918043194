import { Property } from "csstype";

export const getCursorState = ({ isDisabled, isLoading }: { isDisabled?: boolean; isLoading?: boolean }): Property.Cursor => {
  if (isLoading) {
    return "wait";
  }

  if (isDisabled) {
    return "not-allowed";
  }

  return "pointer";
};
