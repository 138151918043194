import * as React from "react";
import * as ReactDOM from "react-dom";
import Dropzone, { DropzoneRef } from "react-dropzone";

const uploadHiddenRoot = document.querySelector("body");
let resolve: (confirm: File | undefined) => void;
const onCancel = () => {
  resolve?.(undefined);
};

const onConfirm = (file: File) => {
  resolve?.(file);
};

const setResolvePromise = () => {
  return new Promise((res: (file: File | undefined) => void) => {
    resolve = res;
  });
};

const onDrop = (acceptedFiles?: File[]) => {
  if (!acceptedFiles || !acceptedFiles[0]) {
    return onCancel();
  }
  onConfirm(acceptedFiles[0]);
};

export const useAsyncSelectFile = () => {
  const dropzoneRef = React.useRef<DropzoneRef | null>(null);

  React.useEffect(() => {
    const node = document.createElement("div");
    if (uploadHiddenRoot) {
      uploadHiddenRoot.appendChild(node);
      ReactDOM.render(
        <Dropzone ref={dropzoneRef} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            );
          }}
        </Dropzone>,
        node,
      );
    }
    return () => {
      ReactDOM.unmountComponentAtNode(node);
      uploadHiddenRoot?.removeChild(node);
    };
  }, []);

  const select = async (): Promise<File | undefined> => {
    dropzoneRef.current?.open();
    return await setResolvePromise();
  };

  return {
    select,
  };
};
