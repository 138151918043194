import * as Polymorphic from "@radix-ui/react-polymorphic";
import { useButton } from "@react-aria/button";
import * as React from "react";
import { BoxPrimitive, BoxPrimitiveProps } from "../../../primitives";
import { getCursorState } from "../../../utils/cursor";
import { renderLegacyIcon } from "../renderLegacyIcon";

export interface IconButtonProps extends Pick<BoxPrimitiveProps, "id"> {
  children: React.ReactNode;
  isDisabled?: boolean;
  onPress?: () => void;
}

const DEFAULT_TAG = "button";

type IconButtonOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof BoxPrimitive>, IconButtonProps>;
type IconButtonPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, IconButtonOwnProps>;

/**
 * `IconButton` are used primarily for toolbar actions.
 */
export const IconButton = React.forwardRef(({ children, isDisabled = false, onPress, ...props }, ref) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore https://github.com/adobe/react-spectrum/issues/1526
  const { buttonProps } = useButton({ isDisabled, onPress, ...props }, ref);

  return (
    <BoxPrimitive
      ref={ref}
      as="button"
      display="inline-flex"
      width="34px"
      height="34px"
      borderRadius="medium"
      alignItems="center"
      justifyContent="center"
      verticalAlign="middle"
      outline="none"
      border={0}
      color="gray-bright"
      backgroundColor="transparent"
      cursor={getCursorState({ isDisabled })}
      pointerEvents={isDisabled ? "none" : undefined}
      _hover={{ backgroundColor: "black-5" }}
      _disabled={{ color: "gray-bombay", backgroundColor: "transparent" }}
      {...buttonProps}
      {...props}
    >
      {renderLegacyIcon(children, 16)}
    </BoxPrimitive>
  );
}) as IconButtonPrimitive;
