interface LibeoSessionStorage {
  thirdPartyConsumer: string;
}

type sessionStorageProperty = keyof LibeoSessionStorage;

export const getSessionStorage = <T extends sessionStorageProperty>(key: sessionStorageProperty): LibeoSessionStorage[T] | undefined => {
  try {
    const sessionStorage = window.sessionStorage.getItem("libeo");

    if (sessionStorage) {
      return JSON.parse(sessionStorage)[key];
    }
  } catch (e) {
    return undefined;
  }

  return undefined;
};

export const setSessionStorage = <T extends sessionStorageProperty>(key: sessionStorageProperty, value: LibeoSessionStorage[T]): void => {
  try {
    const sessionStorage = window.sessionStorage.getItem("libeo");

    if (sessionStorage) {
      const currentSessionStorage: LibeoSessionStorage = JSON.parse(sessionStorage);
      const newSessionStorage: LibeoSessionStorage = { ...currentSessionStorage, [key]: value };
      window.sessionStorage.setItem("libeo", JSON.stringify(newSessionStorage));
    } else {
      window.sessionStorage.setItem("libeo", JSON.stringify({ [key]: value }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
