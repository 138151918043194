import { useEffect, useState } from "react";

export function useWindowSize(): { width: number | undefined; height: number | undefined } {
  const isClient = typeof window === "object";

  function getSize(): { width: number | undefined; height: number | undefined } {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>(getSize);

  useEffect((): (() => void) => {
    function handleResize(): void {
      setWindowSize(getSize());
    }
    if (isClient) {
      window.addEventListener("resize", handleResize);
    }

    return (): void => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}
