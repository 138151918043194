import { Heading } from "@libeo/design-system";
import * as React from "react";
import { AlignRight } from "../../Layout";

export const FormSection: React.FunctionComponent<{
  id?: HTMLElement["id"];
  area: React.CSSProperties["gridArea"];
  title: string;
  rightContent?: React.ReactNode;
}> = ({ id, title, area, rightContent, children }) => {
  return (
    <div
      id={id}
      style={{
        gridArea: area,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Heading size="l" marginBottom={0}>
        {title}
      </Heading>
      {rightContent && <AlignRight>{rightContent}</AlignRight>}
      {children}
    </div>
  );
};
