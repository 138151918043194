import { useState, useEffect, useRef, useCallback } from "react";
import StateManager from "react-select";
import AsyncCreatable from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import Select from "react-select/src/Select";
import { DefaultOptionsType } from "../types";

export const useDefaultInputValue = (
  defaultInputStringValue?: string,
  forwardRef?: (ref: React.MutableRefObject<HTMLInputElement | null> | null) => void,
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputStringValue, setInputStringValue] = useState<string>(defaultInputStringValue || "");

  const changeStringValue = (stringValue: string) => {
    if (inputRef.current) {
      inputRef.current.value = stringValue;
      setInputStringValue(stringValue);
    }
  };

  useEffect(() => {
    if (forwardRef) {
      forwardRef(inputRef);
    }
  }, [forwardRef, inputRef]);

  useEffect(() => {
    if (inputStringValue !== defaultInputStringValue) {
      changeStringValue(defaultInputStringValue || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, defaultInputStringValue]);

  return {
    setInputStringValue,
    inputRef,
    defaultInputStringValue,
    inputStringValue,
  };
};

export const useSelectDefaultValue = <OptionType extends DefaultOptionsType>(defaultInputOption?: OptionType) => {
  const selectRef = useRef<StateManager<OptionType>>(null);
  const selectCreateRef = useRef<Creatable<OptionType>>(null);
  const selectAsyncCreatableRef = useRef<AsyncCreatable<OptionType>>(null);
  const [optionValue, setOptionValue] = useState<OptionType | undefined>(defaultInputOption);

  const updateOptionValue = (option?: OptionType | undefined, select?: Select<OptionType>): void => {
    if (option) {
      select?.setValue(option, "select-option");
      setOptionValue(option);
    } else {
      select?.clearValue();
      setOptionValue(undefined);
    }
  };

  useEffect(() => {
    const shoudlUpdateDefaultValue = defaultInputOption?.value !== optionValue?.value || defaultInputOption?.label !== optionValue?.label;
    if (shoudlUpdateDefaultValue) {
      if (selectRef && selectRef.current) {
        updateOptionValue(defaultInputOption, selectRef.current.select);
      }

      if (selectCreateRef && selectCreateRef.current && selectCreateRef.current.select) {
        // creatable select has a ref bug
        // https://github.com/JedWatson/react-select/issues/2181#issuecomment-502894404
        const selectCreateRefSelectRef = (selectCreateRef.current.select as unknown) as StateManager<OptionType>;
        updateOptionValue(defaultInputOption, selectCreateRefSelectRef.select);
      }

      if (selectAsyncCreatableRef && selectAsyncCreatableRef.current && selectAsyncCreatableRef.current.select) {
        // creatable select has a ref bug
        // https://github.com/JedWatson/react-select/issues/2181#issuecomment-502894404
        const selectAsyncCreatableRefSelectRef = (((selectAsyncCreatableRef.current.select as unknown) as AsyncCreatable<OptionType>)
          .select as unknown) as StateManager<OptionType>;
        updateOptionValue(defaultInputOption, selectAsyncCreatableRefSelectRef.select);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef, defaultInputOption, setOptionValue]);

  return {
    updateOptionValue,
    selectRef,
    selectCreateRef,
    selectAsyncCreatableRef,
    defaultInputOption,
    optionValue,
  };
};

// to avoid re-renders
const emptyOption: [] = [];

export const useMultiSelectDefaultValue = <OptionType extends DefaultOptionsType>(defaultInputOption?: OptionType[]) => {
  const selectRef = useRef<StateManager<OptionType[]>>(null);
  const [optionValue, setOptionValue] = useState<OptionType[] | undefined>(defaultInputOption);

  const updateOptionValue = useCallback(
    (option?: OptionType[] | undefined, select?: Select<OptionType[]>): void => {
      if (option) {
        select?.setValue(option, "select-option");
        setOptionValue(option);
      } else {
        select?.clearValue();
        setOptionValue(emptyOption);
      }
    },
    [setOptionValue],
  );

  useEffect(() => {
    const shoudlNotUpdateDefaultValue = defaultInputOption?.every((option, index) => {
      if (!optionValue || !optionValue[index]) {
        return false;
      }
      const newOption = optionValue[index];

      return option.value === newOption.value && option.label === newOption.label;
    });

    if (!shoudlNotUpdateDefaultValue) {
      updateOptionValue(defaultInputOption, selectRef?.current?.select);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef, defaultInputOption, setOptionValue]);

  return {
    updateOptionValue,
    selectRef,
    defaultInputOption,
    optionValue,
  };
};
