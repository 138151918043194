import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { useInput } from "../../hooks/useInput.hook";
import { BoxPrimitive, InputPrimitive, InputPrimitiveProps } from "../../primitives";
import { Text } from "./../Typography/Text/";
import { InputLabel } from "./InputLabel";
import { ResetButton } from "./InputResetButton";
import { InputProps, InputVariation } from "./types";

const smallBorderWidth = 1;

const mapVariationToStyle: Record<InputVariation, InputPrimitiveProps> = {
  primary: {
    borderWidth: smallBorderWidth,
    borderStyle: "solid",
    backgroundColor: "white",
    color: "black",
    _focus: {
      color: "black",
      borderColor: "blue-palatinate",
    },
  },
};

const DEFAULT_TAG = "input";

type InputOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof InputPrimitive>, InputProps>;
type InputPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, InputOwnProps>;

/**
 * `Input` is used primarily to accept data from the user.
 */

export const Input = React.forwardRef(
  (
    {
      as = DEFAULT_TAG,
      suffix,
      errorMessage,
      variation = "primary",
      label = "",
      value,
      hasAutoFocus,
      isRequired,
      hasReset = false,
      hasError,
      ...props
    },
    ref,
  ) => {
    const { onFocus, onBlur, isFocus, onChange, hasValue } = useInput(props);

    return (
      <>
        <BoxPrimitive display="flex" position="relative" flexDirection="row" width="100%">
          <InputLabel label={label} inputHasFocus={isFocus} inputHasValue={hasValue} isRequired={isRequired} />
          <InputPrimitive
            ref={ref}
            as={as}
            display="flex"
            minHeight="56px"
            width="100%"
            paddingLeft={3}
            paddingRight={5}
            paddingTop={3}
            data-error={hasError}
            paddingBottom={label ? 1 : 3}
            borderColor={hasError ? "pink-pictorial-carmine" : "gray-bombay"}
            borderRadius="large"
            alignItems="flex-start"
            justifyContent="center"
            verticalAlign="bottom"
            autoFocus={hasAutoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            defaultValue={props.defaultValue}
            {...mapVariationToStyle[variation]}
            {...props}
          />
          {hasValue && hasReset && <ResetButton />}
          {suffix}
        </BoxPrimitive>
        {errorMessage && (
          <Text marginY={2} color="pink-pictorial-carmine" size="small">
            {errorMessage}
          </Text>
        )}
      </>
    );
  },
) as InputPrimitive;
