import * as React from "react";
import ReactSelect, { ActionMeta, OptionsType, ValueType } from "react-select";
import Creatable from "react-select/creatable";
import {
  LoadingMessageComponent,
  MenuListComponent,
  MultipleValueComponent,
  MultiValueRemoveComponent,
  NoOptionsMessageComponent,
  OptionComponent,
  SelectClearIndicator,
  SelectDropDownIndicator,
  SingleValueComponent,
} from "./Select/SelectComponents";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { useSelect } from "./hooks/useSelect.hook";
import { inputStyle, selectStyles } from "./styles";
import { DefaultOptionsType, InputProps, SelectProps } from "./types";
export const Select = <OptionType extends DefaultOptionsType>(props: SelectProps<OptionType>) => {
  const {
    menuPlacement,
    style,
    required,
    isDisabled,
    creatable,
    isLoading,
    label,
    message,
    suffix,
    options,
    renderValue,
    renderMultipleValue,
    renderOption,
    description,
    renderNoValue,
    renderMenuList,
    renderMenuListFooter,
    renderMenuListHeader,
    isValidNewOption,
    isClearable,
    id,
    onKeyDown,
    noBorder,
    optionsInColumnsView,
  } = props;
  const { color, onFocus, onBlur, inputFocus } = useInput((props as unknown) as InputProps);
  const { selectRef, selectCreateRef, defaultInputOption, value, optionValue, onChange, onScrollToBottom, onCreatableSelectBlur } = useSelect(
    props,
    onBlur,
  );

  function beforeOnChange(value: ValueType<OptionType>, action: ActionMeta<OptionType>): void {
    const selectedOption = (value as unknown) as OptionType;
    onChange(selectedOption, action);
  }

  function beforeIsValidNewOption(inputValue: string, value: ValueType<OptionType>, options: OptionsType<OptionType>): boolean {
    const selectedOption = (value as unknown) as OptionType;
    const allOptions = (options as unknown) as OptionType[];
    if (isValidNewOption) {
      return Boolean(isValidNewOption(inputValue, selectedOption, allOptions));
    }

    return true;
  }

  return (
    <FormItemWrapper
      noBorder={noBorder}
      style={style}
      color={color}
      label={label}
      required={required}
      description={description}
      inputFocus={inputFocus}
      inputHasValue={Boolean(optionValue) || Boolean(value)}
      suffix={suffix}
      message={message}
    >
      {creatable ? (
        <Creatable<OptionType>
          onKeyDown={onKeyDown}
          menuPlacement={menuPlacement}
          id={id}
          ref={selectCreateRef}
          onMenuScrollToBottom={onScrollToBottom}
          defaultValue={defaultInputOption}
          value={value}
          styles={selectStyles(optionsInColumnsView)}
          isClearable={isClearable === undefined ? true : isClearable}
          escapeClearsValue={true}
          placeholder=""
          onChange={beforeOnChange}
          isLoading={isLoading}
          isValidNewOption={beforeIsValidNewOption}
          components={{
            DropdownIndicator: suffix ? undefined : SelectDropDownIndicator(props.dropdownProps),
            ClearIndicator: SelectClearIndicator,
            Option: OptionComponent(renderOption),
            SingleValue: SingleValueComponent(renderValue),
            MultiValue: MultipleValueComponent(renderMultipleValue),
            MultiValueRemove: MultiValueRemoveComponent,
            LoadingMessage: LoadingMessageComponent(),
            NoOptionsMessage: NoOptionsMessageComponent(renderNoValue),
            MenuList: MenuListComponent(renderMenuListHeader, renderMenuList, renderMenuListFooter),
          }}
          isDisabled={isDisabled}
          style={inputStyle(true, isDisabled, Boolean(label))}
          onFocus={onFocus as React.FocusEventHandler}
          onBlur={onCreatableSelectBlur as React.FocusEventHandler}
          options={options}
        />
      ) : (
        <ReactSelect<OptionType>
          onKeyDown={onKeyDown}
          menuPlacement={menuPlacement}
          id={id}
          ref={selectRef}
          onMenuScrollToBottom={onScrollToBottom}
          defaultValue={defaultInputOption}
          value={value}
          styles={selectStyles(optionsInColumnsView)}
          isClearable={isClearable === undefined ? true : isClearable}
          escapeClearsValue={true}
          placeholder=""
          onChange={beforeOnChange}
          isLoading={isLoading}
          components={{
            DropdownIndicator: suffix ? undefined : SelectDropDownIndicator(props.dropdownProps),
            ClearIndicator: SelectClearIndicator,
            Option: OptionComponent(renderOption),
            SingleValue: SingleValueComponent(renderValue),
            MultiValue: MultipleValueComponent(renderMultipleValue),
            MultiValueRemove: MultiValueRemoveComponent,
            LoadingMessage: LoadingMessageComponent(),
            NoOptionsMessage: NoOptionsMessageComponent(renderNoValue),
          }}
          isDisabled={isDisabled}
          style={inputStyle(true, isDisabled, Boolean(label))}
          onFocus={onFocus as React.FocusEventHandler}
          onBlur={onBlur as React.FocusEventHandler}
          options={options}
        />
      )}
    </FormItemWrapper>
  );
};
