// type-coverage:ignore-next-line
import { ApolloProvider } from "@apollo/client";
import { NotificationProvider } from "@libs/ui";
import * as React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import App from "./App";
import { I18NConsumer, I18NProvider } from "./context/I18NContext";
import { AppLocaleType } from "./context/types";
import * as serviceWorker from "./serviceWorker";
import { initErrorTracker } from "./services/error";
import * as Mixpanel from "./services/mixpanel";
import configureClient from "./store/apollo";
import "./wdyr";

async function render(Component: React.FunctionComponent): Promise<void> {
  const client = await configureClient();
  ReactDOM.render(
    <I18NProvider>
      <I18NConsumer>
        {({ appLocale }: { appLocale: AppLocaleType }): JSX.Element => {
          return (
            <IntlProvider locale={appLocale.locale} messages={appLocale.messages} defaultLocale={appLocale.locale}>
              {/* type-coverage:ignore-next-line */}
              <ApolloProvider client={client}>
                <NotificationProvider>
                  <Component />
                </NotificationProvider>
                {/* type-coverage:ignore-next-line */}
              </ApolloProvider>
            </IntlProvider>
          );
        }}
      </I18NConsumer>
    </I18NProvider>,
    document.getElementById("root"),
  );
}

Mixpanel.init();
initErrorTracker();

// eslint-disable-next-line @typescript-eslint/no-floating-promises
render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp: React.FunctionComponent<{}> = (require("./App") as { default: React.FunctionComponent<{}> }).default;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
