import * as React from "react";
import { Icon } from "../../Icon/Icon";
import { alertIconWrapper, ICON_SIZE } from "../styles/Banner.styles";
import { BannerIconProps } from "../types/Banner.types";

export const BannerIcon: React.FunctionComponent<BannerIconProps> = ({ color, iconValue }) => {
  return (
    <div style={alertIconWrapper}>
      <Icon noMargin color={color} width={ICON_SIZE} height={ICON_SIZE} value={iconValue} />
    </div>
  );
};
