import * as React from "react";
import { EmptyStateFeature } from "./EmptyStateFeature";
import { EmptyStateIconFrieze } from "./EmptyStateIconFrieze";
import { benefitsGrid, benefitsWrapper } from "./styles";
import { Feature, LayoutDirection } from "./types";

export const EmptyStateBenefitsBlock: React.FunctionComponent<{
  layoutDirection?: LayoutDirection;
  pageFeatures: Feature[];
  isPageEmbed?: boolean;
}> = ({ layoutDirection = "row", pageFeatures, isPageEmbed }) => {
  return (
    <div style={benefitsWrapper(layoutDirection)}>
      {layoutDirection === "row" && <EmptyStateIconFrieze pageFeatures={pageFeatures} isPageEmbed={isPageEmbed} />}

      <div style={benefitsGrid(pageFeatures.length, layoutDirection)}>
        {pageFeatures.map((feature) => (
          <EmptyStateFeature key={feature.title} layoutDirection={layoutDirection} feature={feature} />
        ))}
      </div>
    </div>
  );
};
