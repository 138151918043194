import * as React from "react";
export const Tile: React.FunctionComponent<{
  minWidth: React.CSSProperties["minWidth"];
  minHeight: React.CSSProperties["minHeight"];
  gutter: React.CSSProperties["gap"];
}> = ({ children, minWidth, minHeight, gutter }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth},1fr))`,
        gridTemplateRows: `repeat(auto-fill, minmax(${minHeight}, 1fr))`,
        gridGap: gutter,
      }}
    >
      {children}
    </div>
  );
};
