import * as React from "react";
import { FullPageCarouselButton } from "./FullPageCarouselButton";
import { fullPageCarouselButtonsStyles } from "./styles";
import { FullPageCarouselButtonsProps } from "./types";

export const FullPageCarouselButtons: React.FunctionComponent<FullPageCarouselButtonsProps> = ({ numberOfItems, currentStep, onClickStep }) => {
  const buttons: React.ReactNode[] = [];

  for (let buttonIndex = 0; buttonIndex < numberOfItems; buttonIndex++) {
    buttons.push(<FullPageCarouselButton key={buttonIndex} onClick={onClickStep} currentIndex={currentStep} buttonIndex={buttonIndex} />);
  }

  return <div style={fullPageCarouselButtonsStyles(numberOfItems)}>{buttons}</div>;
};
