import { useState } from "react";
import { useToast } from "../Toast/toast.hook";
import { NotificationContext, NotificationProps } from "./notification.types";

export const useNotificationProvider = (): NotificationContext => {
  const toast = useToast();
  const [notification, setNotification] = useState<NotificationProps | null>(null);

  const add = (option: NotificationProps): void => {
    const isBanner = Boolean(option.cta);
    if (isBanner) {
      setNotification(option);
    } else {
      toast(option);
    }
  };

  const reset = (): void => {
    setNotification(null);
  };

  return {
    notification,
    add,
    reset,
  };
};
