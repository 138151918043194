import { set as setCookie, get as getCookie, remove as removeCookie } from "es-cookie";

const TOKEN_NAME = "token";
const REFRESH_TOKEN_NAME = "refreshToken";

export const setRefreshToken = (refreshToken: string): void => {
  setCookie(REFRESH_TOKEN_NAME, refreshToken);
};

const getRefreshToken = (): string | undefined => {
  return getCookie(REFRESH_TOKEN_NAME);
};

export const removeRefreshToken = (): void => {
  removeCookie(REFRESH_TOKEN_NAME);
};

export const setToken = (token: string): void => {
  if (__LIBEO__.ENV === "test") {
    setCookie(TOKEN_NAME, token);
  } else {
    setCookie(TOKEN_NAME, token, { sameSite: "none", secure: true });
  }
};

export const getToken = (): string | undefined => {
  return getCookie(TOKEN_NAME);
};

export const removeToken = (): void => {
  removeCookie(TOKEN_NAME);
};

export const useToken = (): {
  token: string | undefined;
  refreshToken: string | undefined;
} => {
  const token = getToken();
  const refreshToken = getRefreshToken();

  return {
    token,
    refreshToken,
  };
};
