import { Button } from "@libeo/design-system";
import * as React from "react";
import { Dialog } from "../Dialog/Dialog";
import { useLoading } from "../hooks/useLoading.hook";
import { Provider } from "./context";
import { AsyncDialogType } from "./types";

const asyncDialogRoot = document.querySelector("body");
let resolve: (confirm: boolean) => void;

const setResolvePromise = (): Promise<boolean> => {
  return new Promise((res: (confirm: boolean) => void) => {
    resolve = res;
  });
};

const onClose = (callback?: () => Promise<boolean> | undefined | void) => {
  return async (): Promise<void> => {
    if (callback) {
      await callback();
    }
    resolve?.(false);
  };
};

const AsyncDialogProvider: React.FunctionComponent = ({ children }) => {
  const { isLoading, loadBegin, loadEnd } = useLoading();
  const { isLoading: isCancelActionLoading, loadBegin: loadBeginCancel, loadEnd: loadEndCancel } = useLoading();

  const onCancel = (callback?: () => boolean | Promise<boolean> | undefined) => {
    return async (): Promise<void> => {
      loadBeginCancel();
      if (callback) {
        const asyncResult = await callback();
        loadEndCancel();
        return resolve?.(asyncResult ?? false);
      }
      loadEndCancel();
      resolve?.(false);
    };
  };

  const onConfirm = (callback?: () => boolean | Promise<boolean> | undefined) => {
    return async (): Promise<void> => {
      loadBegin();
      if (callback) {
        const asyncResult = await callback();
        loadEnd();
        return resolve?.(asyncResult ?? false);
      }
      loadEnd();
      resolve?.(true);
    };
  };

  const [options, setOptions] = React.useState<AsyncDialogType | null>(null);
  const ask = async (options: AsyncDialogType): Promise<boolean> => {
    setOptions(options);
    if (asyncDialogRoot) {
      const hasConfirmed = await setResolvePromise();

      setOptions(null);

      return hasConfirmed;
    }
    return false;
  };

  const asyncDialog = React.useMemo(
    () => ({
      ask,
      confirm: (): void => {
        void onConfirm(options?.validateActionCallback)();
      },
      cancel: (): void => {
        void onCancel(options?.cancelActionCallback)();
      },
      close: (): void => {
        void onClose(options?.onClose ?? options?.cancelActionCallback)();
      },
    }),
    [options],
  );

  return (
    <Provider value={{ asyncDialog }}>
      {Boolean(options) && (
        <Dialog
          fullPage={Boolean(options?.fullPage)}
          isEmbed={options?.isEmbed}
          visible
          illustration={options?.illustration}
          title={options?.title}
          description={options?.description}
          onClose={onClose(options?.onClose ?? options?.cancelActionCallback)}
          button={
            options?.validateAction
              ? {
                  primaryButton: (
                    <Button isLoading={isLoading} onPress={onConfirm(options?.validateActionCallback)}>
                      {options?.validateAction}
                    </Button>
                  ),
                  secondaryButton: options?.cancelAction ? (
                    <Button variation="tertiary" isLoading={isCancelActionLoading} onPress={onCancel(options?.cancelActionCallback)}>
                      {options?.cancelAction}
                    </Button>
                  ) : undefined,
                }
              : undefined
          }
          extraInfo={options?.extraInfo}
        >
          {options?.content}
        </Dialog>
      )}
      {children}
    </Provider>
  );
};

export default AsyncDialogProvider;
