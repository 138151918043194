import { CheckedState } from "@radix-ui/react-checkbox";
import React from "react";
import { TableProps, TableItemKey } from "./types";

export type TableBodyRowProps<TableItem extends object> = Pick<
  TableProps<TableItem>,
  | "columns"
  | "isInline"
  | "onClickRow"
  | "hasRowSelection"
  | "onUpdateRowSelection"
  | "selectedRowKeys"
  | "rowCanBeSelected"
  | "rowDisabledReason"
  | "renderPanel"
  | "isActiveRow"
> & {
  itemKey: TableItemKey;
  row: TableItem;
  isLastRow: boolean;
};

export const useTableBodyRow = <TableItem extends object>(
  props: TableBodyRowProps<TableItem>,
): {
  isOpened: boolean;
  isRowActive: boolean;
  isRowSelected: boolean;
  canRowBeSelected: boolean;
  checkboxDisabledReason: string | undefined;
  onClickTableRow: () => void;
  onClickCheckbox: (event: React.MouseEvent) => void;
} => {
  const {
    itemKey,
    row,
    onClickRow,
    onUpdateRowSelection,
    isActiveRow,
    selectedRowKeys = [],
    rowCanBeSelected,
    rowDisabledReason,
    renderPanel,
  } = props;

  const isRowSelected = selectedRowKeys.includes(itemKey) || false;
  const canRowBeSelected = rowCanBeSelected?.(row) || false;
  const checkboxDisabledReason = rowDisabledReason?.(row);
  const isRowActive = isActiveRow?.(row) || false;
  const [isOpened, setIsOpened] = React.useState(false);

  const onClickTableRow = (): void => {
    if (renderPanel) {
      setIsOpened((isOpened) => !isOpened);
    }

    onClickRow?.(row);
  };

  const toggleCheckbox = (isSelected: CheckedState): void => {
    if (onUpdateRowSelection) {
      if (isSelected) {
        onUpdateRowSelection(selectedRowKeys.concat(itemKey));
      } else {
        onUpdateRowSelection(selectedRowKeys.filter((key) => key !== itemKey));
      }
    }
  };

  const onClickCheckbox = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (canRowBeSelected) {
      toggleCheckbox(!isRowSelected);
    }
  };

  return {
    isOpened,
    isRowActive,
    isRowSelected,
    canRowBeSelected,
    checkboxDisabledReason,
    onClickCheckbox,
    onClickTableRow,
  };
};
