import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";
import { Button } from "../Button";
import { Box } from "../Layout";

export type PopoverProps = PopoverPrimitive.PopoverOwnProps & {
  trigger?: React.ReactNode;
  content?: React.ReactNode;
  contentAlign?: "start" | "center" | "end";
};

const DEFAULT_TAG = Button;

type PopoverPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, PopoverProps>;

export const Popover = React.forwardRef(({ as = DEFAULT_TAG, content, trigger, contentAlign = "center", open, onOpenChange, defaultOpen }, _) => {
  return (
    <PopoverPrimitive.Root open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
      {trigger && <PopoverPrimitive.Trigger as={as}>{trigger}</PopoverPrimitive.Trigger>}
      {content && (
        <PopoverPrimitive.Content align={contentAlign}>
          <Box shadow="overlay" backgroundColor="white">
            {content}
          </Box>
        </PopoverPrimitive.Content>
      )}
    </PopoverPrimitive.Root>
  );
}) as PopoverPrimitive;

export const PopoverClose = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return <PopoverPrimitive.Close as="div">{children}</PopoverPrimitive.Close>;
};
