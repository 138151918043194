/** @jsxImportSource @emotion/react */

import { Interpolation, Theme, useTheme } from "@emotion/react";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { color, createSystem, space, typography } from "system-props";
import { filterDOMProps } from "../../utils/dom";
import { IconPrimitiveProps } from "../types";

const system = createSystem({
  strict: false,
  tokenPrefix: "noprefix",
  pseudoSelectors: {
    _hover: "&:hover",
  },
});

const DEFAULT_TAG = "i";

const extraProps = {
  cursor: true,
  textDecoration: true,
  textTransform: true,
  whiteSpace: true,
} as const;

const cssSystem = system({
  ...color,
  ...space,
  ...typography,
  ...extraProps,
});

export const IconPrimitive = React.forwardRef(
  ({ children, as: Component = DEFAULT_TAG, className, ...props }, ref): React.ReactElement => {
    const theme = useTheme();

    const style: Interpolation<Theme> = {
      ...cssSystem({ theme, ...props }),
    };

    const componentProps = {
      ref,
      children,
      className,
      css: style,
      ...filterDOMProps({ ...props }, extraProps),
    };

    return <Component {...componentProps} />;
  },
) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, IconPrimitiveProps>;

IconPrimitive.defaultProps = {
  color: "blue-palatinate",
};

export type { IconPrimitiveProps };
