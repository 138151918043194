import { max, parseZone, utc } from "moment";

export const STANDARD_DATE_FORMAT = "YYYY-MM-DD";
export type DateWithoutTime = string;
export const DATE_PICKER_DATE_FORMAT = "DD/MM/YYYY";
const WORLD_STANDARD_DATE_FORMAT = "YYYY-MM-D";
export const ENGLISH_DATE_FORMAT_WITHOUT_SEPARATOR = "YYYYMMDD";

export const formatEnglishDateWithoutSeparatorToDateWithoutTime = (englishDateWithoutSeparator: string): DateWithoutTime => {
  return parseZone(englishDateWithoutSeparator, ENGLISH_DATE_FORMAT_WITHOUT_SEPARATOR).utc().format(STANDARD_DATE_FORMAT);
};

export const formatDateToDateWithoutTime = (dateToFormat: Date): DateWithoutTime => {
  return parseZone(dateToFormat).utc().format(STANDARD_DATE_FORMAT);
};

export const formatDateToLocalDateWithoutTime = (dateToFormat: Date): DateWithoutTime => {
  return parseZone(dateToFormat).format(STANDARD_DATE_FORMAT);
};

export const formatDateToLocalDatePicker = (dateToFormat: Date): DateWithoutTime => {
  return parseZone(dateToFormat).format(DATE_PICKER_DATE_FORMAT);
};

export const todayUtcInDateWithoutTime = (): DateWithoutTime => {
  return utc().format(STANDARD_DATE_FORMAT);
};

export const todayInDateWithoutTime = (): DateWithoutTime => {
  return parseZone().format(STANDARD_DATE_FORMAT);
};

export const isDateWithoutTime = (maybeADate: string | undefined | null): maybeADate is DateWithoutTime => {
  if (!maybeADate) {
    return false;
  }

  return parseZone(maybeADate, STANDARD_DATE_FORMAT, true).isValid();
};

export const getMaxDateWithoutTime = (date1: DateWithoutTime, date2: DateWithoutTime): DateWithoutTime => {
  return max(parseZone(date1), parseZone(date2)).format(STANDARD_DATE_FORMAT);
};

export const getMaxOrOneDateWithoutTime = (date1?: DateWithoutTime, date2?: DateWithoutTime): DateWithoutTime | undefined => {
  if (date1 && date2) {
    return max(parseZone(date1), parseZone(date2)).format(STANDARD_DATE_FORMAT);
  }
  return date1 || date2;
};

export const isDateWithoutTimeBeforeToday = (date: DateWithoutTime): boolean => {
  return parseZone(date).utc().isBefore(todayUtcInDateWithoutTime());
};

export const isDateWithoutTimeSameOrBeforeToday = (date: DateWithoutTime): boolean => {
  return parseZone(date).utc().isSameOrBefore(parseZone(todayUtcInDateWithoutTime()));
};

export const addXDaysToDate = (date: string, numberOfDaysToAdd: number): DateWithoutTime => {
  return parseZone(date, STANDARD_DATE_FORMAT).add(numberOfDaysToAdd, "days").format(STANDARD_DATE_FORMAT);
};

export const addXMonthsToDate = (date: string, numberOfMonthsToAdd: number): DateWithoutTime => {
  return parseZone(date, STANDARD_DATE_FORMAT).add(numberOfMonthsToAdd, "month").format(STANDARD_DATE_FORMAT);
};

export const extractDateWithoutTime = (date: string, validDateFormats: string[] = [WORLD_STANDARD_DATE_FORMAT]): DateWithoutTime | undefined => {
  const invoiceDateMoment = parseZone(date, validDateFormats, true);
  if (!invoiceDateMoment || !invoiceDateMoment.isValid()) {
    return undefined;
  }
  return invoiceDateMoment.local().format(STANDARD_DATE_FORMAT);
};

export const dateWithoutTimeToDate = (dateWithoutTime: DateWithoutTime | undefined | null): Date | undefined => {
  if (!isDateWithoutTime(dateWithoutTime)) {
    return undefined;
  }
  const dateArray = dateWithoutTime.split("-");
  const year = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1], 10) - 1;
  const day = parseInt(dateArray[2]);
  return new Date(year, month, day);
};

export const dateToLocaleFormat = (dateWithoutTime: DateWithoutTime): string => {
  return parseZone(dateWithoutTime).format("ll");
};

export const getSameMonthAtDayFromDate = (dateWithoutTime: DateWithoutTime, day: number): DateWithoutTime => {
  const dateArray = dateWithoutTime.split("-");
  const year = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1], 10) - 1;
  return formatDateToLocalDateWithoutTime(new Date(year, month, day));
};

export const getNextMonthAtDayFromDate = (dateWithoutTime: DateWithoutTime, day: number): DateWithoutTime => {
  const dateArray = dateWithoutTime.split("-");
  const year = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1], 10);
  return formatDateToLocalDateWithoutTime(new Date(year, month, day));
};

export const getEndOfMonthFromDate = (dateWithoutTime: DateWithoutTime, day: number): DateWithoutTime => {
  const date = parseZone(dateWithoutTime, STANDARD_DATE_FORMAT).add(day, "days");
  const endOfMonth = date.endOf("month").toDate();
  return formatDateToDateWithoutTime(endOfMonth);
};

export const addXDaysToEndOfTenDaysPeriod = (dateWithoutTime: DateWithoutTime, days: number): DateWithoutTime => {
  const [year, month, day] = dateWithoutTime.split("-");
  const tenDaysPeriod = Math.ceil(Number(day) / 10) * 10;
  const dayOfEndOfTenDaysPeriod = Math.min(tenDaysPeriod, 30);
  return addXDaysToDate([year, month, dayOfEndOfTenDaysPeriod].join("-"), days);
};
