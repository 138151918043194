import { REBRANDING_INTERFACE_UI_RED_RED, REBRANDING_INTERFACE_UI_YELLOW_YELLOW } from "@libeo/design-system";
import { CSSProperties, useState } from "react";
import { InputProps } from "../types";

export const useInput = (props: InputProps) => {
  const { error, warning, defaultValue, onChangeStringValue, onKeyPressEnter, suffix, value, isDisabled: disabled } = props;
  const [inputHasValue, setInputHasValue] = useState(Boolean(value) || Boolean(defaultValue));
  const [inputFocus, setInputFocus] = useState(false);

  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setInputFocus(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };
  const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setInputFocus(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const newValue = event.currentTarget.value;
    setInputHasValue(newValue === "" ? false : true);
    if (onChangeStringValue) {
      onChangeStringValue(newValue);
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter" && onKeyPressEnter) {
      onKeyPressEnter(event);
    }
  };

  let color: CSSProperties["color"] = undefined;
  if (error) {
    color = REBRANDING_INTERFACE_UI_RED_RED;
  } else if (warning) {
    color = REBRANDING_INTERFACE_UI_YELLOW_YELLOW;
  }

  return {
    inputHasValue: inputHasValue || Boolean(value),
    error,
    defaultValue,
    inputFocus,
    disabled,
    onFocus,
    onBlur,
    onChange,
    onKeyPress,
    color,
    hasSuffix: Boolean(suffix),
  };
};
