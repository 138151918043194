import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { MarginSmall } from "../Margin";

export const ButtonIcon: React.FunctionComponent<{
  loading?: boolean;
  disabled?: boolean;
  iconValue?: IconValue;
  position?: "before" | "after";
  color?: React.CSSProperties["color"];
}> = ({ iconValue, loading, color, position }) => {
  return !loading && iconValue ? (
    <MarginSmall left={position === "after"} right={position === "before"}>
      <Icon value={iconValue} color={color} noMargin />
    </MarginSmall>
  ) : null;
};
