import { CSSProperties } from "react";
import { IconValue } from "../Icon/Icon";
import { NotificationLevel } from "../Notification/notification.enum";
import { INFO, SUCCESS, WARNING, ERROR } from "../theme/ui-constants";

export const NotificationLevelToColorMap: Record<NotificationLevel, CSSProperties["color"]> = {
  INFO: INFO,
  SUCCESS: SUCCESS,
  WARNING: WARNING,
  ERROR: ERROR,
  EMAIL: INFO,
};

export const NotificationLevelToIconMap: Record<NotificationLevel, IconValue> = {
  INFO: IconValue.Information,
  SUCCESS: IconValue.CheckmarkInCircle,
  WARNING: IconValue.Warning,
  ERROR: IconValue.NotAllowed,
  EMAIL: IconValue.Mail,
};
