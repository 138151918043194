const getQueryStringParams = <T extends Record<string, string>>(query: string): T => {
  if (/[?#]/.test(query)) {
    return getQueryStringParams(query.split("?")[1]);
  }
  return (query.split("&").reduce((params: Record<string, string>, param) => {
    const [key, value] = param.split("=");
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
    return params;
  }, {}) as unknown) as T;
};

export const extractParamFromQueryString = <T extends Record<string, string>>(location: string): T => {
  if (!location) {
    return ({} as unknown) as T;
  }

  return getQueryStringParams(location);
};
