import { addNBusinessDaysToDate } from "../../date-compute-business-days/add-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";
import { getTriggeringDateFastpay } from "../triggering-date/get-triggering-date-fastpay";

export const getReceptionOfFundsDateFastpay = (payload: PaymentDatesPayload): DateWithoutTime => {
  const { payinCreationDate, payoutCreationDate } = payload;

  if (payinCreationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payinCreationDate), 2));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  if (payoutCreationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payoutCreationDate), 2));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  const triggeringDate = getTriggeringDateFastpay(payload);

  const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(triggeringDate), 2));
  return getCloserBusinessDate(receptionOfFundsDate);
};
