//Type with "any" because we keep getting TS error "Index signature is missing in type xxx"
// https://github.com/microsoft/TypeScript/issues/15300#

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T extends { [key in string]: any }, K extends keyof T>(
  objectToGroupBy: Array<T>,
  groupByKey: K,
): Map<T[K], typeof objectToGroupBy> => {
  const map = new Map<T[K], typeof objectToGroupBy>();

  objectToGroupBy.forEach((element) => {
    const key = <T[K]>element[groupByKey];
    const collection = map.get(key);
    map.set(key, collection ? [...collection, element] : [element]);
  });
  return map;
};
