import { parseZone } from "moment";
import { DateWithoutTime, dateWithoutTimeToDate } from "../date-without-time/date-without-time";

export const generateDueDate = (date?: Date): Date | undefined => {
  if (!date) {
    return date;
  }

  return parseZone(date).add(30, "day").toDate();
};

export const getDateOrCalculatedDueDate = (documentDate?: DateWithoutTime | null, dueDate?: DateWithoutTime | null): Date => {
  if (dueDate) {
    return dateWithoutTimeToDate(dueDate) as Date;
  }

  if (documentDate) {
    return generateDueDate(dateWithoutTimeToDate(documentDate)) as Date;
  }

  return generateDueDate(new Date()) as Date;
};

export const determinateIfDateIsCalculated = (documentDate?: DateWithoutTime | null, dueDate?: DateWithoutTime | null): boolean => {
  if (!documentDate) {
    return false;
  }
  if (dueDate) {
    return false;
  }

  return true;
};
