import * as React from "react";
import { GRAY_ATHENS_1 } from "../theme/ui-constants";

export const BorderGrey: React.FunctionComponent<{ radius?: boolean }> = ({ radius = true, children }) => {
  return (
    <div
      style={{
        border: `1px solid ${GRAY_ATHENS_1}`,
        boxSizing: "border-box",
        borderRadius: radius ? "10px" : undefined,
        width: "fit-content",
      }}
    >
      {children}
    </div>
  );
};
