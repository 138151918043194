import { updateLocale, parseZone } from "moment-business-days";
import { frenchHolidays } from "../french-holidays/french-holidays";

export const subtractNBusinessDaysToDate = (date: Date, nBusinessDays: number): Date => {
  updateLocale("fr", {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidayFormat: "YYYY-MM-DD",
    holidays: frenchHolidays(date.getFullYear()),
  });
  return parseZone(date).utc().startOf("day").businessSubtract(nBusinessDays, "days").toDate();
};
