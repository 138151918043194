import { addNBusinessDaysToDate } from "../../date-compute-business-days/add-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getReceptionOfFundsDateFintecture = (payload: PaymentDatesPayload): DateWithoutTime => {
  const { paymentAtDate } = payload;

  const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(paymentAtDate), 1));
  return getCloserBusinessDate(receptionOfFundsDate);
};
