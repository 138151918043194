import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { AlignCenter } from "../Layout/AlignCenter";
import { HorizontalLinesBetweenNodes } from "../Line";
import { MarginBig } from "../Margin/MarginBig";
import { GRAY_ATHENS_1, WHITE } from "../theme/ui-constants";

export const EmptyStateIconFrieze: React.FunctionComponent<{
  pageFeatures: { title: string; text: string; icon: IconValue }[];
  isPageEmbed?: boolean;
}> = ({ pageFeatures, isPageEmbed }) => {
  return (
    <MarginBig bottom>
      <AlignCenter>
        <div style={{ width: isPageEmbed ? "85%" : "75%" }}>
          <HorizontalLinesBetweenNodes
            nodes={pageFeatures.map((feature) => (
              <Icon
                value={feature.icon}
                circleBorder
                key={feature.icon}
                backgroundColor={WHITE}
                borderColor={GRAY_ATHENS_1}
                width="42px"
                height="42px"
              />
            ))}
          />
        </div>
      </AlignCenter>
    </MarginBig>
  );
};
