import * as React from "react";
import { Icon, IconValue } from "../../Icon/Icon";
import { MarginSmall } from "../../Margin";
import { spacing } from "../../theme/spacing";
import { selectInputCompanyFooterHint, selectInputCompanyFooterLabel } from "../styles";
import { SelectCompanyType } from "./types";

export const SelectCompanyFooter = ({ footerOptionHint, footerOptionLabel, footerOptionClick }: SelectCompanyType) => {
  return function renderFooter() {
    return (
      (footerOptionHint || footerOptionLabel) && (
        <div
          id="input-select-company-footer"
          style={{
            padding: spacing(1),
          }}
          onClick={footerOptionClick}
        >
          <div style={selectInputCompanyFooterHint}>{footerOptionHint && footerOptionHint()}</div>
          <div style={selectInputCompanyFooterLabel}>
            <MarginSmall right>
              <Icon value={IconValue.ArrowReturn} />
            </MarginSmall>
            {footerOptionLabel && footerOptionLabel()}
          </div>
        </div>
      )
    );
  };
};
