import { AddressType } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotEmptyString = (maybeAnEmptyString?: string | null | undefined): maybeAnEmptyString is string => {
  if (maybeAnEmptyString === undefined) {
    return false;
  }

  if (maybeAnEmptyString === null) {
    return false;
  }

  if (typeof maybeAnEmptyString !== "string") {
    return false;
  }

  if (maybeAnEmptyString.length === 0) {
    return false;
  }

  return true;
};

const capitalizeString = (stringToFormat: string): string => {
  if (typeof stringToFormat !== "string") {
    return "";
  }
  const trimmedString = stringToFormat.trim().toLocaleLowerCase();
  const allWords = trimmedString.split(" ");
  if (allWords.length === 0) {
    return "";
  }

  for (let i = 0, x = allWords.length; i < x; i++) {
    allWords[i] = allWords[i] && allWords[i][0].toUpperCase() + allWords[i].substr(1);
  }

  return allWords.join(" ");
};
export const getFormattedAddress = (address: AddressType | null | undefined): string => {
  if (!address) {
    return "";
  }
  const formattedAddressLine1 = isNotEmptyString(address.address1) ? `${capitalizeString(address.address1 as string)} - ` : "";
  const formattedZipCode = isNotEmptyString(address.zipcode) ? `${capitalizeString(address.zipcode)} - ` : "";
  const formattedCity = isNotEmptyString(address.city) ? `${capitalizeString(address.city)} ` : "";
  const formattedCountry = isNotEmptyString(address.country) ? `${address.country.toLocaleUpperCase()} ` : "";
  const formattedAddress = `${formattedAddressLine1}${formattedZipCode}${formattedCity}${formattedCountry}`;

  return formattedAddress;
};
