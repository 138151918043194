import * as React from "react";
import { BLUE_MAASTRICHT } from "../theme/ui-constants";

export const TextBlueMaastricht: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <span
      style={{
        color: BLUE_MAASTRICHT,
      }}
    >
      {children}
    </span>
  );
};
