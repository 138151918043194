import { ThemeProvider } from "@libeo/design-system";
import { ConfirmProvider } from "@libs/ui";
import * as React from "react";
import Konami from "react-konami-code";
import { Router } from "react-router";
import { AppRouter } from "./AppRouter";
import { ErrorBoundary } from "./components/Error";
import { RedirectThirdPartyProvider } from "./hooks/ThirdParty/RedirectThirdParty.provider";
import history from "./store/history";

function activateBetaMode(): void {
  __LIBEO__.ENV = "staging";
  alert("Vous avez activé le mode ßeta ! 🌈🚀 Attention certaine features sont expérimentales 🧨");
}

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <ConfirmProvider>
          <RedirectThirdPartyProvider>
            <Konami action={activateBetaMode} />
            <Router history={history}>
              <AppRouter />
            </Router>
          </RedirectThirdPartyProvider>
        </ConfirmProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
