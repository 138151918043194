import * as React from "react";
import { GRAY_BRIGHT, FONT_EXTRA_BOLD, FONT_FAMILY_DEFAULT, WHITE, FONT_SIZE_SMALLER } from "../theme/ui-constants";

export type JustifyContentAvailableOnAllBrowsers = "flex-start" | "center" | "flex-end";

export const TableHeadText: React.FunctionComponent<{ titleAlign?: React.CSSProperties["justifyContent"] }> = ({ titleAlign, children }) => {
  return (
    <div
      style={{
        textTransform: "uppercase",
        fontFamily: FONT_FAMILY_DEFAULT,
        fontWeight: FONT_EXTRA_BOLD,
        fontSize: FONT_SIZE_SMALLER,
        color: GRAY_BRIGHT,
        backgroundColor: WHITE,
        display: "flex",
        justifyContent: titleAlign ?? "flex-start",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
