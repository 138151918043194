import dateFnsLocal from "date-fns/locale/fr";
import moment from "moment";
import * as React from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { getLocale } from "../services/I18N";
import { AppLocaleType } from "./types";

interface InjectedI18NContextProps {
  appLocale: AppLocaleType;
}

interface State extends InjectedI18NContextProps {}

const defaultAppLocale: AppLocaleType = {
  messages: {},
  locale: "fr-FR",
};

const I18NContext = React.createContext<State>({
  appLocale: defaultAppLocale,
});

class I18NProvider extends React.PureComponent<{}, State> {
  state = {
    appLocale: defaultAppLocale,
  };

  async componentDidMount(): Promise<void> {
    const locale = getLocale().value;
    const module: { default: AppLocaleType } = await import(/* webpackChunkName: "locales" */ `../services/I18N/locales/${locale}`);
    try {
      await import(/* webpackChunkName: "momentLocale" */ `moment/locale/${locale.replace(/_.*/, "")}`);
    } catch (_e) {
      moment.locale(locale);
    }

    try {
      if (dateFnsLocal) {
        registerLocale(locale, dateFnsLocal);
        setDefaultLocale(locale);
      }
    } catch (_e) {
      moment.locale(locale);
    }

    const appLocale: AppLocaleType = {
      ...module.default,
      messages: {
        // ...defaultModule.messages,
        ...module.default.messages,
      } as AppLocaleType["messages"],
    };

    this.setState({ appLocale });
  }

  render(): JSX.Element {
    return <I18NContext.Provider value={this.state}>{this.props.children as React.ReactNode}</I18NContext.Provider>;
  }
}

const I18NConsumer = I18NContext.Consumer;

export { I18NProvider, I18NConsumer };
