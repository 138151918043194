import { useCallback, useState } from "react";

export const useOffsetTop = <T extends HTMLElement>(): {
  tableRef: (node: T) => void;
  offsetTop: number;
} => {
  const [offsetTop, setOffsetTop] = useState(0);
  const tableRef = useCallback<(node: T) => void>((node) => {
    if (node !== null) {
      setOffsetTop(node.parentElement?.offsetTop || 0);
    }
  }, []);

  return { tableRef, offsetTop };
};
