import { Box, Grid, Heading, Text } from "@libeo/design-system";
import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { GREEN_CARABBEAN, WHITE } from "../theme";
import { StatusTrackerProps } from "./types";

const CircleCheckmark = ({ isCompleted }: { isCompleted: boolean }): JSX.Element => (
  <Box
    backgroundColor={isCompleted ? "green-carabbean" : "white"}
    borderColor="green-carabbean"
    borderWidth="1px"
    borderRadius="50%"
    width="48px"
    height="48px"
    justifyContent="center"
    alignItems="center"
    margin="auto"
  >
    <Icon value={IconValue.Checkmark} color={isCompleted ? WHITE : GREEN_CARABBEAN} width={12} height={12} noMargin />
  </Box>
);

/**
 * `StatusTracker` is a display component used for tracking the progress of a product or service. Does not motivate user to take actions.
 */
export const StatusTracker = ({ id, steps }: StatusTrackerProps): React.ReactElement => {
  return (
    <Box
      width="fit-content"
      backgroundColor="white"
      borderRadius="large"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray-athens-2"
      padding={4}
    >
      <Grid id={id} justifyContent="center" alignItems="center" autoFlow="column" rowGap={3} rows={["1fr", "1fr"]}>
        {steps.map(({ title, info, isCompleted }, index) => (
          <>
            <CircleCheckmark isCompleted={isCompleted} />
            <Box margin="auto">
              <Heading size="s" level="4" textAlign="center">
                {title}
              </Heading>
              <Text variation="medium" size="standard" color="gray-shuttle" textAlign="center">
                {info}
              </Text>
            </Box>
            {index + 1 !== steps.length && (
              <Box margin="auto" width={["40px", "121px", "121px"]} height={1} backgroundColor="green-carabbean" marginX={4} />
            )}
            <Box />
          </>
        ))}
      </Grid>
    </Box>
  );
};

export type { StatusTrackerProps };
