import * as React from "react";
import { WHITE, BLUE_PALATINATE, FONT_SIZE_H2, FONT_FAMILY_DEFAULT, FONT_SIZE_H1, FONT_SIZE_H3, FONT_SIZE_H4 } from "../theme/ui-constants";
import { AvatarProps, AvatarSizeValues } from "./type";

const getFontSize = (size: AvatarSizeValues) => {
  switch (size) {
    case AvatarSizeValues.DEFAULT:
      return FONT_SIZE_H4;
    case AvatarSizeValues.SMALL:
      return FONT_SIZE_H3;
    case AvatarSizeValues.BIG:
      return FONT_SIZE_H2;
    case AvatarSizeValues.HUGE:
      return FONT_SIZE_H1;
    default: {
      const never: never = size;
      return never;
    }
  }
};
export const Avatar: React.FunctionComponent<AvatarProps> = ({
  text,
  icon,
  domainName,
  size = AvatarSizeValues.DEFAULT,
  children,
  backgroundColor = BLUE_PALATINATE,
  boxShadow,
}) => {
  const matchUrl = /.+\..{2,3}/;
  const matchDomain = domainName && matchUrl.test(domainName);
  const [noImage, setNoImage] = React.useState(false);
  React.useEffect(() => {
    setNoImage(false);
  }, [domainName]);
  function onError() {
    setNoImage(true);

    return false;
  }

  const logo = domainName && matchDomain && (
    <img
      style={{
        width: size,
        height: size,
      }}
      alt={domainName}
      src={`https://logo.clearbit.com/${domainName.replace(/http(.?):\/\//, "").toLowerCase()}?size=${size * 2}`}
      onError={onError}
    />
  );
  const isValidLogo = logo && !noImage;

  return (
    <div
      style={{
        fontFamily: FONT_FAMILY_DEFAULT,
        textTransform: "uppercase",
        width: size,
        height: size,
        borderRadius: isValidLogo ? "100%" : "50%",
        backgroundColor: isValidLogo ? "transparent" : backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: WHITE,
        overflow: "hidden",
        fontSize: getFontSize(size),
        boxShadow: boxShadow,
        flexShrink: 0,
      }}
    >
      {children}
      {icon}
      {isValidLogo ? logo : text && text.substring(0, 2)}
    </div>
  );
};
