import { useEffect, useState } from "react";

export function useDebounceOnClick<T extends React.MouseEvent<Element, MouseEvent>>(onClickCallback: (event: T) => void): (event: T) => void {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setIsWaiting(false);
    }, 1000);

    return (): void => {
      clearTimeout(handler);
    };
  }, [isWaiting, onClickCallback]);

  const onClick = (event: T): void => {
    if (!isWaiting) {
      onClickCallback(event);
      setIsWaiting(true);
    }
  };
  return onClick;
}
