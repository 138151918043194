import * as React from "react";
import { Box } from "../Layout";
import { Text } from "../Typography/Text";
import { TableSortDirection, ColumnProps } from "./types";
import { mapAlignToAlignItems } from "./utils";

const getArrowColor = (sortDirection: TableSortDirection, currentSortDirection?: TableSortDirection): string => {
  return sortDirection === currentSortDirection ? "blue-palatinate" : "gray-shuttle";
};

interface TableCellHeaderProps<TableItem extends object> extends Pick<ColumnProps<TableItem>, "isSortable" | "align" | "width"> {
  onClick?: () => void;
  sortDirection?: TableSortDirection;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  children?: React.ReactNode;
}

export const TableCellHeader = <TableItem extends object>(props: TableCellHeaderProps<TableItem>): React.ReactElement => {
  const { children, onClick, isSortable, sortDirection: currentSortDirection, align, width, isFirstColumn, isLastColumn } = props;

  return (
    <Box
      as="th"
      display="table-cell"
      height={0}
      verticalAlign="middle"
      borderBottomWidth={1}
      cursor={isSortable ? "pointer" : undefined}
      onClick={onClick}
      width={width}
    >
      <Box height="100%" paddingLeft={isFirstColumn ? 3 : 2} paddingRight={isLastColumn ? 3 : 2} justifyContent="center">
        <Box flexDirection="row" alignItems="center" justifyContent={mapAlignToAlignItems(align)}>
          <Text textAlign="left" size="small" variation="uppercase" color="gray-shuttle" hasEllipsis>
            {children}
          </Text>
          {isSortable && (
            <Box justifyContent="center" height="16px" marginLeft={2}>
              <Box
                height="0"
                width="0"
                marginY="1px"
                borderLeft="4px solid transparent"
                borderRight="4px solid transparent"
                borderColor="transparent"
                borderBottomWidth="4px"
                borderBottomColor={getArrowColor("ASC", currentSortDirection)}
              />
              <Box
                height="0"
                width="0"
                marginY="1px"
                borderLeft="4px solid transparent"
                borderRight="4px solid transparent"
                borderColor="transparent"
                borderTopWidth="4px"
                borderTopColor={getArrowColor("DESC", currentSortDirection)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
