import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import { Slot } from "@radix-ui/react-slot";
import { useButton } from "@react-aria/button";
import * as React from "react";
import { getCursorState } from "../../../utils/cursor";
import { Box } from "../../Layout";
import { Text } from "../../Typography";
import { Button } from "../Button";
import { renderLegacyIcon } from "../renderLegacyIcon";

const DEFAULT_COLOR = "blue-palatinate";
const SECONDARY_COLOR = "blue-hover";
const TERTIARY_COLOR = "gray-athens-2";

export interface DropdownButtonItemOption {
  id: string;
  render: React.ReactNode;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export type DropdownButtonProps = DropdownMenu.DropdownMenuOwnProps &
  Pick<DropdownMenu.DropdownMenuContentOwnProps, "side" | "align"> & {
    mainCallToAction: DropdownButtonItemOption;
    dropdownItems: DropdownButtonItemOption[];
    isLoading?: boolean;
  };

type DropdownSecondaryButtonProps = {
  mainCallToAction: DropdownButtonItemOption;
  dropdownItem: DropdownButtonItemOption;
  isLoading: boolean;
};

const DEFAULT_TAG = "div";

type DropdownSecondaryButtonPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, DropdownSecondaryButtonProps>;

export const DropdownSecondaryButton = React.forwardRef(({ mainCallToAction, dropdownItem, isLoading }, ref) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore https://github.com/adobe/react-spectrum/issues/1526
  const { buttonProps } = useButton({ isDisabled: dropdownItem.isDisabled, onPress: dropdownItem.onClick }, ref);

  return (
    <DropdownMenu.Label as={Box}>
      <Box
        as={DEFAULT_TAG}
        ref={ref}
        data-dropdown-secondary-button
        id={`${mainCallToAction.id}-${dropdownItem.id}`}
        cursor={getCursorState({ isDisabled: dropdownItem.isDisabled, isLoading })}
        pointerEvents={dropdownItem.isDisabled ? "none" : undefined}
        padding="3"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        color="black"
        _hover={{
          color: SECONDARY_COLOR,
          backgroundColor: TERTIARY_COLOR,
        }}
        _disabled={{
          color: TERTIARY_COLOR,
          backgroundColor: "white",
        }}
        {...buttonProps}
      >
        {dropdownItem.icon && (
          <Box marginRight={2} color={dropdownItem.isDisabled ? TERTIARY_COLOR : DEFAULT_COLOR}>
            {renderLegacyIcon(dropdownItem.icon, 15)}
          </Box>
        )}
        <Text>{dropdownItem.render}</Text>
      </Box>
    </DropdownMenu.Label>
  );
}) as DropdownSecondaryButtonPrimitive;

/**
 * `Dropdown` is a button with menu that displays menu when arrow is clicked.
 */
export const DropdownButton = ({
  mainCallToAction,
  dropdownItems,
  side = "bottom",
  align = "end",
  isLoading,
  ...props
}: DropdownButtonProps): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onOpenChange = (open: boolean): void => {
    setIsOpen(open);
  };
  const toggleOpen = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <DropdownMenu.Root onOpenChange={onOpenChange} open={isOpen}>
      <Box flexDirection="row" alignItems="stretch" cursor="pointer" id={mainCallToAction.id}>
        <Button
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          onPress={mainCallToAction.onClick}
          isDisabled={mainCallToAction.isDisabled || isLoading}
          isLoading={isLoading}
          data-dropdown-primary-button
          flex="1"
          {...props}
        >
          {mainCallToAction.render}
        </Button>
        <DropdownMenu.Trigger as={Slot}>
          <Box
            onClick={toggleOpen}
            data-dropdown-button
            backgroundColor={isOpen ? SECONDARY_COLOR : DEFAULT_COLOR}
            width="40px"
            color="white"
            borderLeftWidth="1px"
            borderRightStyle="solid"
            justifyContent="center"
            alignItems="center"
            borderTopRightRadius="large"
            borderBottomRightRadius="large"
            _hover={{
              backgroundColor: isOpen ? DEFAULT_COLOR : SECONDARY_COLOR,
            }}
          >
            <svg width="12px" height="12px" viewBox="0 0 448 512" style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}>
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              />
            </svg>
          </Box>
        </DropdownMenu.Trigger>
      </Box>
      {dropdownItems && (
        <DropdownMenu.Content as={Box} {...props} side={side} align={align} sideOffset={0} backgroundColor="white" width="100%" shadow="panel">
          {dropdownItems.map((dropdownItem) => (
            <DropdownSecondaryButton dropdownItem={dropdownItem} mainCallToAction={mainCallToAction} isLoading={Boolean(isLoading)} />
          ))}
        </DropdownMenu.Content>
      )}
    </DropdownMenu.Root>
  );
};
