import * as React from "react";
import { spacing } from "../theme/spacing";
import { BLUE_MAASTRICHT, FONT_FAMILY_DEFAULT, FONT_REGULAR, FONT_SIZE_DEFAULT } from "../theme/ui-constants";

export const Paragraph: React.FunctionComponent<{
  style?: React.CSSProperties;
  id?: string;
}> = ({ children, style, id }) => (
  <p
    id={id}
    style={{
      fontFamily: FONT_FAMILY_DEFAULT,
      fontStyle: "normal",
      fontWeight: FONT_REGULAR,
      fontSize: FONT_SIZE_DEFAULT,
      color: BLUE_MAASTRICHT,
      margin: 0,
      padding: spacing(0, 0, 1, 0),
      lineHeight: "initial",
      ...style,
    }}
  >
    {children}
  </p>
);
