import * as dateFns from "date-fns";
import * as React from "react";
import { Icon } from "../../Icon/Icon";
import { IconValue } from "../../Icon/icon.type";
import { useDatePickerCustomHeader } from "../hooks/useDatePickerCustomHeader.hook";

interface CustomHeaderProps {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export const useCustomHeader = () => {
  const { onChangeYear, months, years } = useDatePickerCustomHeader();

  return function customHeaderInner({
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: CustomHeaderProps) {
    return (
      <div
        style={{
          display: "flex",
          height: 30,
          justifyContent: "center",
          margin: 10,
          position: "relative",
        }}
      >
        {!prevMonthButtonDisabled && (
          <div className="form-date-prev-month" onClick={decreaseMonth}>
            <Icon value={IconValue.ChevronDown} />
          </div>
        )}
        <span>{months[dateFns.getMonth(date)]}</span>
        <div className="form-select form-date-month">
          <select value={dateFns.getYear(date)} onChange={onChangeYear(changeYear)}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {!nextMonthButtonDisabled && (
          <div className="form-date-next-month" onClick={increaseMonth}>
            <Icon value={IconValue.ChevronDown} />
          </div>
        )}
      </div>
    );
  };
};
