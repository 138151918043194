import { LIBEO_VERSION } from "../../version";
import { versionErrorManifestNotFound } from "./httpError";
import { NewApplicationType } from "./types";

export const handleNewVersion = async (): Promise<NewApplicationType> => {
  const cacheBustingTime = new Date().getTime();
  try {
    const response = await fetch(`${document.location.origin}/manifest.json?cachebust=${cacheBustingTime}`);
    const manifest: { version: string } = await response.json();
    return {
      cacheBustingTime: cacheBustingTime.toString(),
      manifestVersion: manifest.version,
      libeoVersion: LIBEO_VERSION,
      versionMismatch: manifest.version !== LIBEO_VERSION,
    };
  } catch (error) {
    const errorVersion = {
      cacheBustingTime: cacheBustingTime.toString(),
      manifestVersion: "unknown",
      libeoVersion: LIBEO_VERSION,
      versionMismatch: true,
    };
    versionErrorManifestNotFound(error, errorVersion);
    return errorVersion;
  }
};
