import * as React from "react";
import * as styles from "./styles";

export const Fade: React.FunctionComponent<{ toggle: boolean; time: number }> = ({ toggle, children, time }) => {
  const [delayedToggle, setDelayedToggle] = React.useState<boolean>(false);

  React.useEffect(() => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const delayed = (): void => {
      setTimeout(() => {
        setDelayedToggle(toggle);
      }, 0);
    };
    delayed();
  }, [toggle]);

  return <div style={delayedToggle ? styles.fadeIn(time) : styles.fadeOut(time)}>{children}</div>;
};
