import * as React from "react";
import { MarginMedium, MarginSmall } from "../../Margin";
import { RadioButton } from "./RadioButton";
import { radioButtonsStyle } from "./styles";
import { RadioButtonProps, RadioButtonsProps } from "./types";

export const RadioButtons: React.FunctionComponent<RadioButtonsProps> = ({ name, question, options, onChangeBooleanValue }) => {
  return (
    <div style={radioButtonsStyle}>
      <MarginMedium bottom>{question}</MarginMedium>
      {options.map((option: RadioButtonProps) => (
        <MarginSmall key={option.key} bottom>
          <RadioButton name={name} onChangeBooleanValue={onChangeBooleanValue} {...option} />
        </MarginSmall>
      ))}
    </div>
  );
};
