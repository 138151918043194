import { Loader, Tag, Text } from "@libeo/design-system";
import { Box } from "@libeo/design-system";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  components,
  IndicatorProps,
  OptionProps,
  SingleValueProps,
  MenuListComponentProps,
  MultiValueProps,
  ValueContainerProps,
} from "react-select";
import { Icon } from "../../Icon/Icon";
import { IconValue } from "../../Icon/icon.type";
import { CrossIcon } from "../Icon/icons";
import { DefaultOptionsType, SelectProps } from "../types";

export const SelectDropDownIndicator = <OptionType extends DefaultOptionsType>(dropdownProps?: SelectProps<OptionType>["dropdownProps"]) => {
  const { dropdownRotation = true, dropdownIconValue, dropdownColor } = dropdownProps || {};
  return function SelectDropDownIndicatorComponent<OptionType extends DefaultOptionsType>(props: IndicatorProps<OptionType>) {
    if (!components.DropdownIndicator) {
      return null;
    }
    return (
      <components.DropdownIndicator {...props}>
        <Icon
          width="12px"
          height="12px"
          style={{
            cursor: "pointer",
            transition: "rotate 0.3s ease",
            transform: dropdownRotation ? `rotate(${props.isFocused ? -180 : 0}deg)` : undefined,
          }}
          value={dropdownIconValue ? dropdownIconValue : IconValue.ChevronDown}
          color={dropdownColor}
        />
      </components.DropdownIndicator>
    );
  };
};

export const SelectClearIndicator = <OptionType extends DefaultOptionsType>(props: IndicatorProps<OptionType>) => {
  if (!components.ClearIndicator) {
    return null;
  }
  return (
    <components.ClearIndicator {...props}>
      <CrossIcon />
    </components.ClearIndicator>
  );
};

export const MultiValueRemoveComponent = (
  hasOverflow: boolean,
  ...props: React.ComponentProps<typeof components.MultiValueRemove>
): JSX.Element | null => {
  if (!components.MultiValueRemove || hasOverflow) {
    return null;
  }
  return (
    <components.MultiValueRemove {...props} style={{ margin: 0, padding: 0 }}>
      <CrossIcon />
    </components.MultiValueRemove>
  );
};

export const MultiValueContainerComponent = <OptionType extends DefaultOptionsType>(
  renderMultiValueContainer: SelectProps<OptionType>["renderMultiValueContainer"],
) => {
  return function MultiValueContainer(props: { data: OptionType } & Omit<OptionProps<OptionType>, "data">) {
    return renderMultiValueContainer ? (
      renderMultiValueContainer(props.data, <div {...props} style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "center" }} />)
    ) : (
      <components.MultiValueContainer {...props} />
    );
  };
};

export const ValueContainerComponent = <OptionType extends DefaultOptionsType>(hasOverflow: boolean) => {
  return function ValueContainer(props: ValueContainerProps<OptionType>) {
    const { hasValue, children } = props;
    if (!hasOverflow || !hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    const CHIPS_LIMIT = 1;
    const [chips, otherChildren] = children as [React.ReactNode[], React.ReactNode];
    const overflowCounter = chips?.length;
    const displayChips = chips?.slice(0, CHIPS_LIMIT) || [];

    return (
      <components.ValueContainer {...props}>
        <Box width="100%" overflow="hidden" flexDirection="row" justifyContent="flex-start" alignItems="center" padding="20px 0 9px 10px">
          <Box flex="1">
            <Text size="standard" variation="regular" hasEllipsis>
              {displayChips}
            </Text>
          </Box>

          {overflowCounter - CHIPS_LIMIT > 0 && <Tag display="flex" label={`+ ${overflowCounter - CHIPS_LIMIT}`} />}

          <Box marginLeft={2}>{otherChildren}</Box>
        </Box>
      </components.ValueContainer>
    );
  };
};

export const OptionComponent = <OptionType extends DefaultOptionsType>(renderOption: SelectProps<OptionType>["renderOption"]) => {
  return function Option(props: { data: OptionType } & Omit<OptionProps<OptionType>, "data">) {
    return <components.Option {...props}>{renderOption ? renderOption(props.data) : props.children}</components.Option>;
  };
};

export const SingleValueComponent = <OptionType extends DefaultOptionsType>(renderValue: SelectProps<OptionType>["renderValue"]) => {
  return function SingleValue(props: SingleValueProps<OptionType>) {
    return <components.SingleValue {...props}>{renderValue ? renderValue(props.data) : props.children}</components.SingleValue>;
  };
};

export const MultipleValueComponent = <OptionType extends DefaultOptionsType>(
  renderMultipleValue: SelectProps<OptionType>["renderMultipleValue"],
) => {
  return function MultipleValue(props: MultiValueProps<OptionType>) {
    return <components.MultiValue {...props}>{renderMultipleValue ? renderMultipleValue(props.data) : props.children}</components.MultiValue>;
  };
};

export const LoadingMessageComponent = () => {
  return function LoadingMessage(props: React.ComponentProps<typeof components.LoadingMessage>) {
    return (
      <components.LoadingMessage {...props}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      </components.LoadingMessage>
    );
  };
};

export const NoOptionsMessageComponent = <OptionType extends DefaultOptionsType>(renderNoValue?: SelectProps<OptionType>["renderNoValue"]) => {
  return function NoOptionsMessage(props: React.ComponentProps<typeof components.NoOptionsMessage>) {
    return (
      <components.NoOptionsMessage {...props}>
        {renderNoValue ? renderNoValue() : <FormattedMessage id="common.form.no_value" />}
      </components.NoOptionsMessage>
    );
  };
};

export const MenuListComponent = <OptionType extends DefaultOptionsType>(
  renderMenuListHeader?: () => React.ReactNode,
  renderMenuList?: SelectProps<OptionType>["renderMenuList"],
  renderMenuListFooter?: () => React.ReactNode,
) => {
  return function MenuList(props: MenuListComponentProps<OptionType>) {
    return (
      <>
        {renderMenuListHeader ? renderMenuListHeader() : null}
        <components.MenuList {...props}>
          {renderMenuList ? renderMenuList() : null}
          {props.children}
        </components.MenuList>
        {renderMenuListFooter ? renderMenuListFooter() : null}
      </>
    );
  };
};
