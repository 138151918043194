/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import * as React from "react";
import { CSSAnimation, CSSAnimationPrimitiveProps } from "../../primitives/CSSAnimation";

const smallSize = "10px";
const defaultSize = "16px";
const primaryBorderColor = "blue-palatinate";
const secondaryBorderColor = "white";
const tertiaryBorderColor = "blue-vivid-sky";
const standardBorderWidth = 2;
const smallBorderWidth = 1;

const mapVariationToStyle: Record<LoaderSize, Record<LoaderVariation, CSSAnimationPrimitiveProps>> = {
  standard: {
    primary: {
      width: defaultSize,
      height: defaultSize,
      borderWidth: standardBorderWidth,
      borderColor: primaryBorderColor,
      borderBottomColor: "transparent",
    },
    secondary: {
      width: defaultSize,
      height: defaultSize,
      borderWidth: standardBorderWidth,
      borderColor: secondaryBorderColor,
      borderBottomColor: "transparent",
    },
    tertiary: {
      width: defaultSize,
      height: defaultSize,
      borderColor: tertiaryBorderColor,
      borderBottomColor: "transparent",
      borderWidth: standardBorderWidth,
    },
  },
  small: {
    primary: {
      width: smallSize,
      height: smallSize,
      borderWidth: smallBorderWidth,
      borderColor: primaryBorderColor,
      borderBottomColor: "transparent",
    },
    secondary: {
      width: smallSize,
      height: smallSize,
      borderWidth: smallBorderWidth,
      borderColor: secondaryBorderColor,
      borderBottomColor: "transparent",
    },
    tertiary: {
      width: smallSize,
      height: smallSize,
      borderWidth: smallBorderWidth,
      borderColor: tertiaryBorderColor,
      borderBottomColor: "transparent",
    },
  },
};

type LoaderVariation = "primary" | "secondary" | "tertiary";
type LoaderSize = "standard" | "small";

interface LoaderProps extends Omit<CSSAnimationPrimitiveProps, "animation"> {
  variation?: LoaderVariation;
  size?: LoaderSize;
}

const rotate = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  100% {transform: rotate(360deg) scale(1)}
`;

export const Loader = ({ variation = "primary", size = "standard", ...props }: LoaderProps): React.ReactElement => {
  const style = css`
    animation: ${rotate} 1s 0s infinite linear;
  `;
  return (
    <CSSAnimation
      animation={style}
      backgroundColor="transparent"
      width="16px"
      height="16px"
      borderRadius="100%"
      border="2px solid"
      display="inline-block"
      justifyContent="center"
      alignItems="center"
      {...props}
      {...mapVariationToStyle[size][variation]}
    />
  );
};
