import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "../Layout";
import { Text } from "../Typography";

interface InputLabelProps {
  label: string;
  inputHasFocus: boolean;
  inputHasValue: boolean;
  isRequired?: boolean;
}

export const InputLabel = (props: InputLabelProps): React.ReactElement => (
  <Box
    position="absolute"
    width="100%"
    cursor="text"
    overflow="hidden"
    pointerEvents="none"
    transform={props.inputHasFocus || props.inputHasValue ? "translate(16px, 8px)" : "translate(16px, 16px)"}
    transition="color 0.2s, font-size 0.2s, transform 0.2s"
  >
    <Text
      textTransform="none"
      whiteSpace="nowrap"
      size={props.inputHasFocus || props.inputHasValue ? "small" : "standard"}
      variation="bold"
      color={props.inputHasFocus ? "blue-palatinate" : "gray-bombay"}
    >
      <FormattedMessage id={props.label} />
      {props.isRequired && " *"}
    </Text>
  </Box>
);
