import * as React from "react";
import { useHover } from "../hooks/useHover.hook";
import { BLUE_PALATINATE, FONT_FAMILY_DEFAULT } from "../theme/ui-constants";

export const Underline: React.FunctionComponent<{
  style?: React.CSSProperties;
  color?: React.CSSProperties["color"];
  underlineOnHoverOnly?: boolean;
}> = ({ children, style, underlineOnHoverOnly, color }) => {
  const { isHovered, ...hoverProps } = useHover();
  const isUnderlined = !underlineOnHoverOnly || (underlineOnHoverOnly && isHovered) ? true : false;

  return (
    <span
      style={{
        fontFamily: FONT_FAMILY_DEFAULT,
        color: color ? color : BLUE_PALATINATE,
        textDecoration: isUnderlined ? "underline" : "none",
        margin: 0,
        padding: 0,
        ...style,
      }}
      {...hoverProps}
    >
      {children}
    </span>
  );
};
