import * as Tabs from "@radix-ui/react-tabs";
import * as React from "react";
import { MarginProps } from "../../primitives";
import { Box } from "../Layout";

export interface TabsRootProps<T extends string> extends MarginProps, Omit<Tabs.TabsOwnProps, "value" | "onValueChange"> {
  children: React.ReactNode;
  value?: T;
  onValueChange?: (value: T) => void;
}

export function TabsRoot<T extends string>({ value, onValueChange, ...props }: TabsRootProps<T>): React.ReactElement {
  return (
    <Tabs.Root
      as={Box}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onValueChange={onValueChange}
      alignItems="flex-start"
      {...props}
    />
  );
}
