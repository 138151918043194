import { REBRANDING_INTERFACE_UI_BLUE_BLUE_BLACK_25, REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25 } from "@libeo/design-system";
import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_TITAN_WHITE, FONT_SIZE_SMALL, GREEN_BRITISH_RACING, GREEN_AQUA_SQUEEZE, ORANGE_PROVINCIAL_PINK } from "../theme/ui-constants";
import { AlertVariation } from "./types";

const textColors: Record<AlertVariation, string> = {
  info: REBRANDING_INTERFACE_UI_BLUE_BLUE_BLACK_25,
  success: GREEN_BRITISH_RACING,
  warning: REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
};

const backgroundColors: Record<AlertVariation, string> = {
  info: BLUE_TITAN_WHITE,
  success: GREEN_AQUA_SQUEEZE,
  warning: ORANGE_PROVINCIAL_PINK,
};

export const alertContainer = (variation: AlertVariation): CSSProperties => ({
  display: "flex",
  padding: spacing(1, 2),
  fontSize: FONT_SIZE_SMALL,
  lineHeight: "18px",
  borderRadius: 10,
  color: textColors[variation],
  backgroundColor: backgroundColors[variation],
});

export const iconContainer = {
  display: "flex",
  height: 18,
  alignItems: "center",
  marginRight: spacing(1),
};
