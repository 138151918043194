/** @jsxImportSource @emotion/react */

import { Interpolation, Theme, useTheme } from "@emotion/react";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { border, color, createSystem, flexbox, grid, layout, position, shadow, transition, space } from "system-props";
import { filterDOMProps } from "../../utils/dom";
import { BoxPrimitiveProps } from "../types";

const notBusyOrActive = ':not([aria-busy="true"]):not([data-state="active"])';

const system = createSystem({
  strict: false,
  tokenPrefix: "noprefix",
  pseudoSelectors: {
    _hover: `&:hover${notBusyOrActive}`,
    _focus: "&:focus",
    _hoverAndFocus: "&:hover, &:focus",
    _disabled: `&[disabled], &[disabled]:hover${notBusyOrActive}, &[disabled]:focus, &[aria-disabled], &[aria-disabled]:hover${notBusyOrActive}, &[aria-disabled]:focus`,
    _active: '&[data-state="active"]',
  },
});

const extraProps = {
  cursor: true,
  outline: true,
  pointerEvents: true,
  transform: true,
  translate: true,
} as const;

const cssSystem = system({
  ...border,
  ...color,
  ...flexbox,
  ...grid,
  ...layout,
  ...position,
  ...shadow,
  ...space,
  ...transition,
  ...extraProps,
});

const DEFAULT_TAG = "div";

export const BoxPrimitive = React.forwardRef(
  ({ children, as: Component = DEFAULT_TAG, ...props }, ref): React.ReactElement => {
    const theme = useTheme();

    const style: Interpolation<Theme> = {
      boxSizing: "border-box",
      minWidth: 0,
      overflowAnchor: props.overflowAnchor,
      transform: props.transform,
      objectFit: props.objectFit,
      ...cssSystem({ theme, ...props }),
    };

    const componentProps = {
      ref,
      children,
      css: style,
      ...filterDOMProps({ ...props }, extraProps),
    };

    return <Component {...componentProps} />;
  },
) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, BoxPrimitiveProps>;

BoxPrimitive.defaultProps = {
  display: "flex",
  flexDirection: "column",
  borderColor: "gray-athens-1",
  borderStyle: "solid",
};

export type { BoxPrimitiveProps };
