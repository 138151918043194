import isPropValid from "@emotion/is-prop-valid";
import { shouldForwardProp } from "system-props";

export const filterDOMProps = (props: Record<string, unknown>, extraProps = {}): Record<string, unknown> => {
  return Object.keys(props).reduce((acc, prop) => {
    if (typeof prop === "string" && shouldForwardProp(prop) && isPropValid(prop) && !Object.keys(extraProps).includes(prop)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[prop] = props[prop];
    }
    return acc;
  }, {} as Record<string, unknown>);
};
