import * as React from "react";
import * as ReactDOM from "react-dom";
import { spacing } from "../theme/spacing";
import { BLACK, BLUE_MAASTRICHT, FONT_REGULAR, FONT_SIZE_SMALLER, SHADOW_PANEL, WHITE } from "../theme/ui-constants";

export type TooltipPlacement = "top" | "left" | "right" | "bottom" | "immediate-right";

function getTranslationFromPlacement(
  placement: TooltipPlacement = "top",
): { left?: React.CSSProperties["left"]; top?: React.CSSProperties["top"]; transform: React.CSSProperties["transform"] } {
  const caretSize = 5;

  switch (placement) {
    case "top":
      return {
        top: `-${caretSize}px`,
        transform: "translate(-50%, -100%)",
      };
    case "left":
      return {
        left: `-${caretSize}px`,
        transform: "translate(-100%, -50%)",
      };
    case "right":
      return {
        left: `calc(100% + ${caretSize * 2}px)`,
        transform: "translate(0, -50%)",
      };
    case "immediate-right":
      return {
        left: `calc(100% + ${caretSize}px)`,
        transform: "translate(0%, -50%)",
      };
    case "bottom":
      return {
        top: `calc(100% + ${caretSize}px)`,
        transform: "translate(-50%, 0%)",
      };
    default: {
      const never: never = placement;
      return never;
    }
  }
}

const tooltipPortalRoot = document.querySelector("body");
export const ToolTip: React.FunctionComponent<{
  placement?: TooltipPlacement;
  title: React.ReactNode;
  visible?: boolean;
  mode?: "light" | "dark";
  width?: React.CSSProperties["width"];
  isFullWidth?: boolean;
  displayStyle?: string;
}> = ({ placement = "top", mode = "dark", visible = true, title, children, width = "fit-content", isFullWidth, displayStyle }) => {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [display, setDisplay] = React.useState(false);
  const [position, setPosition] = React.useState({ top: 0, left: 0, width: 0, height: 0 });
  const isDark = mode === "dark";

  function onMouseOver(): void {
    if (!title || !visible) {
      return;
    }
    const limit = tooltipRef.current?.getBoundingClientRect();
    if (limit) {
      const offsetLeft = limit.left;
      const offsetTop = limit.top;
      const offsetWidth = limit.width;
      const offsetHeight = limit.height;
      setPosition({
        top: offsetTop,
        left: offsetLeft,
        width: offsetWidth,
        height: offsetHeight,
      });
    }
    setDisplay(true);
  }

  function onMouseOut(): void {
    setDisplay(false);
  }

  const tooltip = tooltipPortalRoot
    ? ReactDOM.createPortal(
        <div
          style={{
            position: "fixed",
            zIndex: 99,
            pointerEvents: "none",
            ...position,
          }}
        >
          <div
            style={{
              fontWeight: FONT_REGULAR,
              width,
              whiteSpace: "pre",
              fontSize: FONT_SIZE_SMALLER,
              pointerEvents: "none",
              padding: spacing(1),
              opacity: display ? 1 : 0,
              position: "absolute",
              color: isDark ? WHITE : BLACK,
              background: isDark ? BLUE_MAASTRICHT : WHITE,
              boxShadow: SHADOW_PANEL,
              top: "50%",
              left: "50%",
              textAlign: "center",
              ...getTranslationFromPlacement(placement),
            }}
          >
            {title}
          </div>
        </div>,
        tooltipPortalRoot,
      )
    : null;

  const fullWidthStyle = {
    width: isFullWidth ? "100%" : "auto",
  };

  return (
    <div
      ref={tooltipRef}
      style={{ position: "relative", display: displayStyle ?? "inline-block", ...fullWidthStyle }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {tooltip}
      {children}
    </div>
  );
};
