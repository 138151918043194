import * as React from "react";
import { ActionMeta, OptionsType, ValueType } from "react-select";
import Creatable from "react-select/creatable";
import {
  LoadingMessageComponent,
  MenuListComponent,
  MultipleValueComponent,
  MultiValueContainerComponent,
  MultiValueRemoveComponent,
  OptionComponent,
  SelectClearIndicator,
  SingleValueComponent,
} from "./Select/SelectComponents";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { useMultiSelect } from "./hooks/useMultiSelect.hook";
import { inputStyle, multiTagSelectStyles } from "./styles";
import { DefaultOptionsType, InputProps, MultiSelectProps } from "./types";
const REGEX_EMAIL_VALIDATOR = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const InputMultipleEmails = <OptionType extends DefaultOptionsType>(props: MultiSelectProps<OptionType>): JSX.Element => {
  const {
    label,
    style,
    required,
    isLoading,
    message,
    suffix,
    renderValue,
    renderMultiValueContainer,
    renderMultipleValue,
    renderOption,
    description,
    renderMenuList,
    renderMenuListFooter,
    renderMenuListHeader,
    isClearable,
    id,
    onKeyDown,
  } = props;
  const { color, onFocus, onBlur, inputFocus } = useInput((props as unknown) as InputProps);
  const { defaultInputOption, onChange, onScrollToBottom, inputHasValue } = useMultiSelect(props);

  function beforeOnChange(value: ValueType<OptionType>, action: ActionMeta<OptionType>): void {
    const selectedOption = (value as unknown) as OptionType[];
    onChange(selectedOption, action);
  }

  function isValidNewOption(inputValue: string, _value: ValueType<OptionType>, options: OptionsType<OptionType>): boolean {
    const isOptionMatchingRegex = Boolean(inputValue.match(REGEX_EMAIL_VALIDATOR));
    const isOptionAlreadyExists = options.findIndex((option) => option.value === inputValue.toLowerCase()) > -1;

    return isOptionMatchingRegex && !isOptionAlreadyExists;
  }

  return (
    <FormItemWrapper
      style={style}
      color={color}
      label={label}
      required={required}
      description={description}
      inputFocus={inputFocus}
      inputHasValue={inputHasValue}
      suffix={suffix}
      message={message}
    >
      <Creatable<OptionType>
        isMulti
        key={defaultInputOption?.map((v) => v.value).join() || "defaultValues"}
        onKeyDown={onKeyDown}
        id={id}
        onMenuScrollToBottom={onScrollToBottom}
        defaultValue={defaultInputOption}
        styles={multiTagSelectStyles}
        isClearable={isClearable}
        escapeClearsValue={true}
        onChange={beforeOnChange}
        isLoading={isLoading}
        isValidNewOption={isValidNewOption}
        components={{
          DropdownIndicator: undefined,
          ClearIndicator: SelectClearIndicator,
          Option: OptionComponent(renderOption),
          SingleValue: SingleValueComponent(renderValue),
          MultiValue: MultipleValueComponent(renderMultipleValue),
          MultiValueRemove: MultiValueRemoveComponent,
          MultiValueContainer: MultiValueContainerComponent(renderMultiValueContainer),
          LoadingMessage: LoadingMessageComponent(),
          NoOptionsMessage: (): null => null,
          MenuList: MenuListComponent(renderMenuListHeader, renderMenuList, renderMenuListFooter),
        }}
        style={inputStyle(true, false, true)}
        onFocus={onFocus as React.FocusEventHandler}
        onBlur={onBlur as React.FocusEventHandler}
      />
    </FormItemWrapper>
  );
};
