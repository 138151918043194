import * as React from "react";
import { Checkbox } from "../Form/Checkbox";
import { ToolTip } from "../Tooltip/Tooltip";
import { spacing } from "../theme/spacing";
import { TableTd } from "./TableTd";
import { TableTr } from "./TableTr";
import { TableColumnsPropsType, TableData } from "./types";

export const TableBody = <T extends TableData>(
  props: { onCheckRow?: (index: number, checked: boolean) => void } & TableColumnsPropsType<T>,
): React.ReactElement => {
  const {
    dataSource,
    columns,
    selectedRowKeys,
    rowSelection,
    rowHeight,
    onClickRow,
    isSelected,
    onCheckRow,
    rowCanBeSelected,
    rowDisabledReason,
  } = props;

  const onBeforeClickRow = (row: T) => {
    return (): void => {
      if (onClickRow) {
        onClickRow(row);
      }
    };
  };

  const onChangeBooleanValue = (rowIndex: number) => {
    return (checked: boolean): void => {
      if (onCheckRow) {
        onCheckRow(rowIndex, checked);
      }
    };
  };

  const checkboxComponent = (rowIndex: number, data: T): JSX.Element | null => {
    if (!rowSelection) {
      return null;
    }
    const canCheckRow = rowCanBeSelected && !rowCanBeSelected(data) ? false : true;
    const disableReason = rowDisabledReason && rowDisabledReason(data);

    const isChecked = canCheckRow && Boolean(selectedRowKeys?.includes(rowIndex));

    const checkbox = (
      <Checkbox
        key={`table-body-checkbox-${data.id}`}
        isDisabled={!canCheckRow}
        style={{ marginBottom: 0 }}
        defaultChecked={isChecked}
        onChangeBooleanValue={onChangeBooleanValue(rowIndex)}
      />
    );

    return (
      <TableTd
        styles={{
          width: "40px",
          textAlign: "center",
          paddingLeft: spacing(1),
        }}
        key={`${rowIndex}-checkbox`}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {disableReason ? <ToolTip title={disableReason}>{checkbox}</ToolTip> : <div style={{ display: "inline-block" }}>{checkbox}</div>}
        </div>
      </TableTd>
    );
  };

  return (
    <>
      {dataSource?.map((data, rowIndex) => {
        return (
          <TableTr
            key={rowIndex}
            onClick={onBeforeClickRow(data)}
            isSelected={isSelected ? isSelected(data) : false}
            rowHeight={rowHeight}
            hasOnClickRow={Boolean(onClickRow)}
          >
            {checkboxComponent(rowIndex, data)}
            {columns?.map((column, columnIndex) => {
              const isLast = columnIndex === columns.length - 1;
              const isFirst = columnIndex === 0 && !rowSelection;

              return (
                <TableTd
                  styles={{
                    width: column.width,
                    textAlign: column.align,
                    paddingLeft: isFirst ? spacing(2) : spacing(1),
                    paddingRight: isLast ? spacing(2) : spacing(1),
                  }}
                  key={`${rowIndex}-${columnIndex}`}
                >
                  {column.render ? column.render(data) : undefined}
                </TableTd>
              );
            })}
          </TableTr>
        );
      })}
    </>
  );
};
