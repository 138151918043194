import * as React from "react";
import { spacing } from "../theme/spacing";
export const Gutter: React.FunctionComponent<{ width?: React.CSSProperties["width"] }> = ({ width, children }) => {
  return (
    <div
      style={{
        display: "grid",
        gap: spacing(1),
        gridTemplateColumns: "auto auto",
        width: width || "fit-content",
      }}
    >
      {children}
    </div>
  );
};
