import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { Integration, LogLevel } from "@sentry/types";
import { version } from "../../../../package.json";

export function initErrorTracker(): void {
  const tracingIntegration = new TracingIntegrations.BrowserTracing();

  if (__LIBEO__.REACT_APP_SENTRY_DSN && __LIBEO__.ENV === "production") {
    Sentry.init({
      dsn: __LIBEO__.REACT_APP_SENTRY_DSN,
      release: version,
      environment: __LIBEO__.ENV,
      logLevel: LogLevel.Error,
      integrations: [(tracingIntegration as unknown) as Integration],
      tracesSampleRate: 0.2,
    });
  }
}
