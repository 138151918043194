import { CurrencyInformation, CURRENCIES } from "./currencies";
import { CurrencyCode, CurrenciesCodes } from "./currency.enum";

export const getCurrencySymbolFromCurrencyCode = (currencyCode: CurrencyCode): string => {
  const targetCurrency: CurrencyInformation = CURRENCIES[currencyCode];
  return targetCurrency.symbol;
};

export const isCurrencyCode = (currencyInString: string): boolean => {
  return !!CurrenciesCodes.find((currencyCode) => currencyCode === currencyInString);
};
