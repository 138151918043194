import { ActionMeta } from "react-select";
import { DefaultOptionsType, MultiSelectProps } from "../types";
import { useMultiSelectDefaultValue } from "./useDefaultFormValue.hook";

export const useMultiSelect = <OptionType extends DefaultOptionsType>(props: MultiSelectProps<OptionType>) => {
  const { onChangeOptions, onScrollEnd, defaultValue, value } = props;
  const { selectRef, defaultInputOption, optionValue, updateOptionValue } = useMultiSelectDefaultValue(defaultValue);

  const onChange = (option: OptionType[], actionMeta: ActionMeta<OptionType>): void => {
    updateOptionValue(option);
    if (onChangeOptions) {
      onChangeOptions(option, actionMeta);
    }
  };

  const onScrollToBottom = (): void => {
    if (onScrollEnd) {
      onScrollEnd();
    }
  };

  return {
    value,
    inputHasValue: Boolean(optionValue && Boolean(optionValue.length > 0)) || Boolean(value && Boolean(value.length > 0)),
    selectRef,
    defaultInputOption,
    onChange,
    onScrollToBottom,
  };
};
