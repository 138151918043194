import { useMeContext } from "../contexts/Me/me.hook";
import { areLegalInformationBritish } from "../utils/legal-information/are-legal-information-british.utils";
import { areLegalInformationFrench } from "../utils/legal-information/are-legal-information-french.utils";

export const useCurrentUser = (): ReturnType<typeof useMeContext>["currentUser"] => {
  const result = useMeContext();

  return result.currentUser;
};

export const useCurrentOrganisation = (): ReturnType<typeof useMeContext>["organisation"] => {
  const result = useMeContext();

  return result.organisation;
};

export const useCurrentCompany = (): ReturnType<typeof useMeContext>["currentCompany"] => {
  const result = useMeContext();

  return result.currentCompany;
};

export const useCurrentOrganisationSettings = (): ReturnType<typeof useMeContext>["organisationSettings"] => {
  const result = useMeContext();

  return result.organisationSettings;
};

export const useCurrentOrganisationPreferencies = (): ReturnType<typeof useMeContext>["preferencies"] => {
  const result = useMeContext();

  return result.preferencies;
};

export const useRefetchMe = (): ReturnType<typeof useMeContext>["refetch"] => {
  const result = useMeContext();

  return result.refetch;
};

export const useMeLoading = (): ReturnType<typeof useMeContext>["loading"] => {
  const result = useMeContext();

  return result.loading;
};

export const useCurrentCompanyNumber = (): string | null => {
  const result = useMeContext();
  const currentCompany = result.currentCompany;

  if (areLegalInformationBritish(currentCompany?.legalInformation) && currentCompany?.legalInformation.companyNumber) {
    return currentCompany?.legalInformation.companyNumber;
  }

  return null;
};

export const useCurrentCompanySiret = (): string | null => {
  const meContext = useMeContext();
  const currentCompany = meContext.currentCompany;

  if (areLegalInformationFrench(currentCompany?.legalInformation) && currentCompany?.legalInformation.siret) {
    return currentCompany?.legalInformation?.siret;
  }

  return null;
};
