import qs from "query-string";

export const extractParamFromUrl = <T extends object>(location: string): T => {
  if (!location) {
    return ({} as unknown) as T;
  }

  return (qs.parse(location, { parseBooleans: true, arrayFormat: "index" }) as unknown) as T;
};

export const convertParamForUrl = (location: object): string => {
  return qs.stringify(location, { arrayFormat: "index" });
};
