import { Heading, Text } from "@libeo/design-system";
import * as React from "react";
import { AlignCenter } from "../Layout";
import { MarginBigger, MarginMedium } from "../Margin";
import { spacing } from "../theme/spacing";
import { DialogPropsType } from "./types";

export const DialogHead: React.FunctionComponent<{
  illustration: DialogPropsType["illustration"];
  title: DialogPropsType["title"];
  description: DialogPropsType["description"];
}> = ({ illustration, title, description }) => {
  const dialogTitle = (
    <AlignCenter>
      <Heading size="xl">{title}</Heading>
    </AlignCenter>
  );

  return (
    <MarginBigger left right>
      {illustration && (
        <div style={{ gridArea: "dialog-illustration", height: spacing(5) }} id="dialog-illustration">
          {illustration}
        </div>
      )}

      <div style={{ gridArea: "dialog-title" }} id="dialog-title">
        {illustration ? <MarginMedium top>{dialogTitle}</MarginMedium> : dialogTitle}
      </div>

      {description && (
        <div style={{ gridArea: "dialog-description" }} id="dialog-description">
          <MarginMedium top>
            <AlignCenter>
              <Text>{description}</Text>
            </AlignCenter>
          </MarginMedium>
        </div>
      )}
    </MarginBigger>
  );
};
