import { DateWithoutTime } from "../../date-without-time/date-without-time";
import { PaymentDatesPayload, PaymentStrategy } from "../payment-date.types";
import { getReceptionOfFundsDateAutoload } from "./get-reception-of-funds-date-autoload";
import { getReceptionOfFundsDateFastpay } from "./get-reception-of-funds-date-fastpay";
import { getReceptionOfFundsDateFintecture } from "./get-reception-of-funds-date-fintecture";
import { getReceptionOfFundsDateTopup } from "./get-reception-of-funds-date-topup";

export const getReceptionOfFundsDate = (payload: PaymentDatesPayload): DateWithoutTime => {
  const { paymentStrategy } = payload;

  const paymentStrategyMapper: {
    [key in PaymentStrategy]: (payload: PaymentDatesPayload) => ReturnType<typeof getReceptionOfFundsDate>;
  } = {
    [PaymentStrategy.TREEZOR_AUTOLOAD]: getReceptionOfFundsDateAutoload,
    [PaymentStrategy.TREEZOR_TOPUP]: getReceptionOfFundsDateTopup,
    [PaymentStrategy.TREEZOR_FAST_PAYMENT]: getReceptionOfFundsDateFastpay,
    [PaymentStrategy.FINTECTURE]: getReceptionOfFundsDateFintecture,
  };

  return paymentStrategyMapper[paymentStrategy](payload);
};
