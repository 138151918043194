export function lazyCatchError<T>(fn: () => Promise<{ default: T }>): Promise<{ default: T }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(async (error: unknown) => {
        const errorMessage = `lazyCatchError \n${error}`;
        reject(errorMessage);
      });
  });
}
