import { Loader } from "@libeo/design-system";
import * as React from "react";
import { IconValue } from "../Icon/Icon";
import { ButtonIcon } from "./ButtonIcon";
import { textButtonStyles } from "./styles";
import { useButton } from "./useButton.hook";

export const TextButton: React.FunctionComponent<{
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  isVisible?: boolean;
  onClick?: () => void | Promise<void>;
  iconValueBefore?: IconValue;
  iconValueAfter?: IconValue;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  padding?: React.CSSProperties["padding"];
  download?: string;
}> = ({ id, iconValueBefore, iconValueAfter, loading, isVisible, disabled, onClick, children, width, height, padding }) => {
  const { isHovered, stopAndCallOnClick, ...hoverProps } = useButton({ onClick });

  const disabledOnClick = disabled || loading ? undefined : stopAndCallOnClick;

  return (
    <div id={id} style={textButtonStyles(isHovered, { isVisible, disabled, width, padding, height })} onClick={disabledOnClick} {...hoverProps}>
      {loading && <Loader marginRight={1} size="small" />}
      <ButtonIcon position="before" loading={loading} iconValue={iconValueBefore} />
      {children}
      <ButtonIcon position="after" loading={loading} iconValue={iconValueAfter} />
    </div>
  );
};
