import * as React from "react";
import { Box } from "../Layout";

export * from "./types";

export function TableWrapper(props: React.PropsWithChildren<{ id?: string; isInline?: boolean }>): React.ReactElement {
  const { id, isInline, children } = props;

  return (
    <Box
      as={(props: React.HTMLAttributes<HTMLTableElement>): React.ReactElement => (
        <table
          {...props}
          style={{
            boxShadow: !isInline ? "0px 1px 2px 0px rgba(0, 0, 0, 0.15)" : undefined,
            borderSpacing: "0px",
            borderCollapse: "separate",
            tableLayout: "fixed",
          }}
        />
      )}
      id={id}
      display="table"
      width="100%"
      backgroundColor="white"
      borderRadius={isInline ? "large" : "small"}
      overflow={isInline ? "hidden" : undefined}
      borderWidth={isInline ? 1 : 0}
    >
      {children}
    </Box>
  );
}
