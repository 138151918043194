import * as React from "react";
import { spacing } from "../theme/spacing";
import { FONT_EXTRA_BOLD, FONT_FAMILY_DEFAULT, FONT_SIZE_SMALLER } from "../theme/ui-constants";

export const Chip: React.FunctionComponent<{ color: string; text: string }> = ({ color, text }) => {
  return (
    <div
      style={{
        height: spacing(2),
        display: "flex",
        alignItems: "center",
        color: color,
        fontStyle: "normal",
        fontWeight: FONT_EXTRA_BOLD,
        fontSize: FONT_SIZE_SMALLER,
        fontFamily: FONT_FAMILY_DEFAULT,
        textTransform: "uppercase",
      }}
    >
      <div style={{ height: spacing(1), width: spacing(1), marginRight: spacing(1), backgroundColor: color, borderRadius: 15 }} />
      <div>{text}</div>
    </div>
  );
};
