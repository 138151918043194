import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
  IdGetter,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  split,
} from "@apollo/client";
import { KeyFieldsFunction } from "@apollo/client/cache/inmemory/policies";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import * as Sentry from "@sentry/browser";
import { defaultDataIdFromObject } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import { generatePath, matchPath } from "react-router";
import { ConnectionParams } from "subscriptions-transport-ws";
import * as uuid from "uuid";
import { HistoryPrivateLayout } from "../components/Layout/PrivateLayout";
import { GraphQLError } from "../gqlError.type.d";
import { getToken, removeToken } from "../hooks/useToken.hook";
import { ROUTE_PATH } from "../routes-constants";
import { DEFAULT_APPLICATION_DOMAIN } from "../utils/constants";
import { getThirdPartyConsumerFromUrl } from "../utils/getThirdPartyConsumerFromUrl";
import { getOrganisationId } from "../utils/organisation";
import { handleNewVersion } from "./helpers/applicationVersion";
import { getUrls } from "./helpers/getUrls";
import { hasFiles } from "./helpers/hasFile";
import { logDisplayNewReleaseModal, logRedirectionErrorAppUnavailablePage } from "./helpers/httpError";
import { refreshJWT } from "./helpers/refreshJWT";
import history from "./history";
import { IdGetterObjExtended } from "./type";

const dataIdFromObject: KeyFieldsFunction = (object: IdGetterObjExtended): ReturnType<IdGetter> => {
  if (!object || (typeof object.total !== "undefined" && typeof object.rows !== "undefined")) {
    return undefined;
  }

  if (object) {
    switch (object.__typename) {
      case "Company":
        return object.id ? object.id : object.name ? `${object.__typename}:${object.name}` : `${object.__typename}:${object.siret}`;
      case "Beneficiary":
        return `${object.__typename}:${object.userId}`;
      case "QuickbooksState":
        return object.__typename;
    }
  }

  return defaultDataIdFromObject(object) ?? undefined;
};

const createCache = (): InMemoryCache => {
  return new InMemoryCache({
    dataIdFromObject,
  });
};

interface Headers extends Record<string, string | number | undefined> {
  Accept: string;
  Authorization?: string;
  "X-Powered-By": string;
  "X-Organisation-Id"?: string;
  "X-Application-Domain"?: string;
  "x-transaction-id"?: string;
}

const createAuthLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) =>
    fromPromise(refreshJWT()).flatMap(() => {
      const headers = (operation.getContext() as { incomingHeaders: Headers }).incomingHeaders || ({} as Headers);
      headers.Accept = "application/json";
      headers["X-Powered-By"] = "Libeo";
      const JWT = getToken();

      headers.Authorization = `Bearer ${JWT}`;
      headers["X-Organisation-Id"] = getOrganisationId();
      headers["X-Application-Domain"] = getThirdPartyConsumerFromUrl() || DEFAULT_APPLICATION_DOMAIN;
      headers["x-transaction-id"] = uuid.v4();

      operation.setContext({
        headers,
      });

      return forward(operation);
    }),
  );
};

const handleGraphQlErrors = (graphQLErrors: readonly GraphQLError[]): void => {
  graphQLErrors.forEach((error) => {
    const { message, extensions } = error;

    const isGqlUnauthorized = extensions?.exception?.status === 401;
    const jsUnauthorized = message && ((message as unknown) as { statusCode: number }).statusCode === 401;

    if (isGqlUnauthorized || jsUnauthorized) {
      removeToken();
      const pathnameIsLogin = matchPath(history.location.pathname, ROUTE_PATH.LOGIN);
      if (!pathnameIsLogin) {
        const historyPrivateLayout: HistoryPrivateLayout = {
          redirectAfterLogin: `${window.location.pathname}${window.location.search}${window.location.hash}`,
        };
        history.push(generatePath(ROUTE_PATH.LOGIN), historyPrivateLayout);
      }
    } else {
      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        request: {
          url: window.location.href,
        },
        message: `Graphql error: ${JSON.stringify(error.message)}`,
        extra: {
          operation: `${JSON.stringify(error)}`,
        },
      });
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
const handleNetworkError = async (networkError: Error, operation: Operation): Promise<void> => {
  if (networkError.message === "Failed to fetch") {
    const version = await handleNewVersion();
    if (version.versionMismatch) {
      logDisplayNewReleaseModal(networkError, version);
      throw new Error("New version");
    } else {
      logRedirectionErrorAppUnavailablePage(networkError, operation, version);
      throw new Error("App unavailable");
    }
  } else {
    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      ...networkError,
      message: `Error network: ${JSON.stringify(networkError)}`,
      extra: {
        operation: `${JSON.stringify(operation)}`,
      },
    });
  }
};

const createErrorLink = (): ApolloLink =>
  onError((props) => {
    const { graphQLErrors, networkError, operation } = props;
    if (graphQLErrors) {
      handleGraphQlErrors(graphQLErrors as GraphQLError[]);
    }

    if (networkError) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleNetworkError(networkError, operation);
    }
  });

const createNetworkLink = (uploadLink: ApolloLink, httpLink: ApolloLink, wsLink: WebSocketLink): ApolloLink => {
  return split(
    (operation) => {
      if (hasFiles(operation.variables)) {
        return true;
      }
      return false;
    },
    uploadLink, // if file uploadlink else split
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink, // if subscription wsLink else httpLink
      httpLink,
    ),
  );
};

const createWsLink = (socketUrl: string): WebSocketLink => {
  return new WebSocketLink({
    uri: socketUrl,
    options: {
      reconnect: true,
      reconnectionAttempts: 3,
      inactivityTimeout: 11000, // should stop the socket after 11 seconds of inactivity
      connectionParams: (): Function | ConnectionParams | Promise<ConnectionParams> | undefined => ({
        authorization: getToken(),
      }),
    },
  });
};

export const create = async (): Promise<ApolloClient<NormalizedCacheObject>> => {
  const cache = createCache();
  const authLink = createAuthLink();
  const errorLink = createErrorLink();

  const [graphqlUrl, socketUrl] = await getUrls();
  const uploadLink = createUploadLink({ uri: graphqlUrl });
  const httpLink = createHttpLink({ uri: graphqlUrl });
  // const httpLink = new BatchHttpLink({ uri: graphqlUrl, headers: { batch: "true " } });
  const wsLink = createWsLink(socketUrl);

  const networkLinks = createNetworkLink((uploadLink as unknown) as ApolloLink, httpLink, wsLink);

  const link = ApolloLink.from([errorLink, authLink, networkLinks]);
  const client = new ApolloClient({
    link,
    cache: cache,
    defaultOptions: {
      mutate: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
      watchQuery: {
        errorPolicy: "all",
      },
    },
  });

  return client;
};
