import { Box, Heading, Text } from "@libeo/design-system";
import * as React from "react";
import { AlignLeft } from "../Layout";

export const TitleWithSubtitleAbove: React.FunctionComponent<{ id?: string; subtitle: React.ReactNode }> = ({ children, id, subtitle }) => (
  <AlignLeft>
    <Box>
      <Text color="gray-shuttle">{subtitle}</Text>
      <Heading id={id} size="xl">
        {children}
      </Heading>
    </Box>
  </AlignLeft>
);
