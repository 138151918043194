export enum IconValue {
  NotAllowed = "NotAllowed",
  Alarm = "Alarm",
  Apple = "Apple",
  Archive = "Archive",
  ArrowDown = "ArrowDown",
  ArrowReturn = "ArrowReturn",
  ArrowThinLeft = "ArrowThinLeft",
  ArrowThinRight = "ArrowThinRight",
  ArrowTopRight = "ArrowTopRight",
  ArrowsTowardsCenter = "ArrowsTowardsCenter",
  ArrowUp = "ArrowUp",
  Article = "Article",
  BackSpace = "BackSpace",
  Basket = "Basket",
  Bell = "Bell",
  Blog = "Blog",
  Bluetooth = "Bluetooth",
  Bookmark = "Bookmark",
  Bookmarks = "Bookmarks",
  Box = "Box",
  Briefcase = "Briefcase",
  Broadcast = "Broadcast",
  BrokenLink = "BrokenLink",
  Browser = "Browser",
  BrowserUpload = "BrowserUpload",
  BudgetInsight = "BudgetInsight",
  Buy = "Buy",
  Calendar = "Calendar",
  CustomerInvite = "CustomerInvite",
  Card = "Card",
  Cart = "Cart",
  CegidIcon = "CegidIcon",
  ChainLink = "ChainLink",
  ChaintrustIcon = "ChaintrustIcon",
  MyUnisoftIcon = "MyUnisoftIcon",
  Change = "Change",
  Checklist = "Checklist",
  Checkmark = "Checkmark",
  CheckmarkInCircle = "CheckmarkInCircle",
  ChevronDown = "ChevronDown",
  ChevronLeft = "ChevronLeft",
  ChevronRight = "ChevronRight",
  ChevronUp = "ChevronUp",
  CircleDashed = "CircleDashed",
  Clock = "Clock",
  Clockwise = "Clockwise",
  Cloud = "Cloud",
  cloudDownload = "cloudDownload",
  CloudUpload = "CloudUpload",
  ConfetiMain = "ConfetiMain",
  Conversation = "Conversation",
  Copy = "Copy",
  Cross = "Cross",
  CrossCircled = "CrossCircled",
  Crosshair = "Crosshair",
  Dashboard = "Dashboard",
  Direction = "Direction",
  DocumentFile = "DocumentFile",
  Dot = "Dot",
  Dots3 = "Dots3",
  Download = "Download",
  DownloadInTray = "DownloadInTray",
  DragAndDrop = "DragAndDrop",
  Edit = "Edit",
  Euro = "Euro",
  Ellipse = "Ellipse",
  EmptySearch = "EmptySearch",
  ErrorModal = "ErrorModal",
  Experiment = "Experiment",
  Export = "Export",
  EyeOpen = "EyeOpen",
  Facture = "Facture",
  Flag = "Flag",
  FollowingTheIdea = "FollowingTheIdea",
  Folder = "Folder",
  FundsInsufficient = "FundsInsufficient",
  Gear = "Gear",
  GetPaid = "GetPaid",
  Graduation = "Graduation",
  graphBar = "graphBar",
  graphPie = "graphPie",
  Gift = "Gift",
  Google = "Google",
  Heart = "Heart",
  Hello = "Hello",
  HelloHand = "HelloHand",
  Help = "Help",
  Home = "Home",
  Hourglass = "Hourglass",
  Inbox = "Inbox",
  Information = "Information",
  Intercom = "Intercom",
  Interrogation = "Interrogation",
  Invoice = "Invoice",
  InvoiceScan = "InvoiceScan",
  InvoiceUpload = "InvoiceUpload",
  KycIban = "KycIban",
  KycIntro = "KycIntro",
  LibeoCheckMark = "LibeoCheckMark",
  LightBulb = "LightBulb",
  Link = "Link",
  Lock = "Lock",
  Logo = "Logo",
  LogoWithoutText = "LogoWithoutText",
  LibeoPremium = "LibeoPremium",
  LibeoCertified = "LibeoCertified",
  Map = "Map",
  Mail = "Mail",
  Menu = "Menu",
  Message = "Message",
  Minus = "Minus",
  MoneyPot = "MoneyPot",
  Network = "Network",
  NoData0 = "NoData0",
  NoData1 = "NoData1",
  NoData2 = "NoData2",
  Paid = "Paid",
  Paper = "Paper",
  Paperclip = "Paperclip",
  ParteoSaas = "ParteoSaas",
  Pay = "Pay",
  Pencil = "Pencil",
  Phone = "Phone",
  PhotoGroup = "PhotoGroup",
  Pin = "Pin",
  Ping = "Ping",
  Plus = "Plus",
  Profile = "Profile",
  Pulse = "Pulse",
  Question = "Question",
  QuestionIcon = "QuestionIcon",
  Quickbooks = "Quickbooks",
  Evoliz = "Evoliz",
  Dext = "Dext",
  Reply = "Reply",
  ReplyAll = "ReplyAll",
  Return = "Return",
  Retweet = "Retweet",
  Rocket = "Rocket",
  Rocketman = "Rocketman",
  Search = "Search",
  Sell = "Sell",
  Settings = "Settings",
  Share = "Share",
  Star = "Star",
  StarIcon = "StarIcon",
  Scissors = "Scissors",
  Stat = "Stat",
  Store = "Store",
  Suitcase = "Suitcase",
  Swap = "Swap",
  Tag = "Tag",
  tagDelete = "tagDelete",
  Tags = "Tags",
  ThumbsDown = "ThumbsDown",
  ThumbsUp = "ThumbsUp",
  Thumbnails = "Thumbnails",
  Ticket = "Ticket",
  TimeReverse = "TimeReverse",
  Toggle = "Toggle",
  Trash = "Trash",
  Trophy = "Trophy",
  TwoPersons = "TwoPersons",
  UnLock = "UnLock",
  upload = "upload",
  UserGroup = "UserGroup",
  Wallet = "Wallet",
  WalletOut = "WalletOut",
  Warning = "Warning",
  Wrong = "Wrong",
  ZapierIcon = "ZapierIcon",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  Welyb = "Welyb",
  FileViewerPlaceholder = "FileViewerPlaceholder",
  Sponsorship = "Sponsorship",
  UserId = "UserId",
  InvoiceImport = "InvoiceImport",
  PayCurrency = "PayCurrency",
  PuzzleBuild = "PuzzleBuild",
  PuzzleIdea = "PuzzleIdea",
  PhoneTransmission = "PhoneTransmission",
  PhoneMoney = "PhoneMoney",
  ComputerMoney = "ComputerMoney",
  ChartsStep = "ChartsStep",
  Fingerprint = "Fingerprint",
  ZenHandy = "ZenHandy",
}

type CssPropertiesWithoutHeightOrWidth = Omit<
  React.CSSProperties,
  "width" | "height" | "backgroundColor" | "borderColor" | "color" | "rotate" | "margin" | "padding"
>;

export interface DefaultPropsType extends React.SVGProps<SVGSVGElement> {
  value: IconValue;
  circleBorder?: boolean;
  color?: string;
  rotate?: 0 | 45 | 90 | 135 | 180;
  style?: CssPropertiesWithoutHeightOrWidth;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["width"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  borderColor?: React.CSSProperties["backgroundColor"];
  noMargin?: boolean;
}
