import * as React from "react";
import { InputSpacerStyle } from "../styles";

export const InputSpacer: React.FunctionComponent<{ style?: React.CSSProperties }> = ({ children, style }) => (
  <div
    style={{
      ...InputSpacerStyle,
      ...style,
    }}
  >
    {children}
  </div>
);
