import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_MIDNIGHT, BLUE_VIVID_SKY, FONT_EXTRA_BOLD, FONT_SIZE_SMALLER, SILVER, WHITE } from "../theme/ui-constants";

export const fullPageStyles: CSSProperties = {
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateAreas: `"full-page-carousel-logo"
    "full-page-carousel-image"
    "full-page-carousel-title"
    "full-page-carousel-list"`,
  gridTemplateRows: "10% 15% 17% 43%",
  gridGap: "5%",
  overflow: "hidden",
};

export const fullPageCarouselWrapperStyles: CSSProperties = {
  width: "100%",
  height: "100%",
  padding: spacing(3),
  background: BLUE_MIDNIGHT,
};

export const fullPageCarouselInnerStyles: CSSProperties = {
  width: "100%",
  height: "100%",
  position: "relative",
};

export const fullPageCarouselButtonWrapperStyles: CSSProperties = {
  cursor: "pointer",
  padding: spacing(1),
};

export const fullPageCarouselButtonInnerStyles = (buttonIndex: number, currentIndex: number): CSSProperties => ({
  width: "6px",
  height: "6px",
  borderRadius: "6px",
  background: buttonIndex === currentIndex ? BLUE_VIVID_SKY : SILVER,
});

export const fullPageCarouselButtonsStyles = (numberOfItems: number): CSSProperties => ({
  width: "fit-content",
  display: "grid",
  gridTemplateColumns: `repeat(${numberOfItems}, auto)`,
  margin: "0 auto",
  bottom: spacing(2),
});

export const fullPageCarouselFooterStyles: CSSProperties = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: `auto 1fr auto`,
  position: "absolute",
  bottom: 0,
  zIndex: 1,
};

export const fullPageCarouselTextWrapperStyles: CSSProperties = {
  color: WHITE,
  textTransform: "uppercase",
  fontSize: FONT_SIZE_SMALLER,
  fontWeight: FONT_EXTRA_BOLD,
  cursor: "pointer",
};

export const fullPageCarouselTextInnerStyles = (visible: boolean): CSSProperties => ({
  visibility: visible ? "visible" : "hidden",
});

export const fullPageImageStyles: CSSProperties = {
  gridArea: "full-page-carousel-image",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};

export const fullPageListStyles: CSSProperties = {
  gridArea: "full-page-carousel-list",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
};

export const fullPageLogoStyles: CSSProperties = {
  gridArea: "full-page-carousel-logo",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

export const fullPageTitleStyles: CSSProperties = {
  gridArea: "full-page-carousel-title",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};
