import * as React from "react";
import { FullPageCarouselHookProps } from "./types";

export const useFullPageCarousel = ({
  numberOfItems,
  onFinish,
}: FullPageCarouselHookProps): {
  step: number;
  nextStep: () => void;
  previousStep: () => void;
  goToStep: (newStep: number) => void;
  finishStep: () => void;
} => {
  const [step, setStep] = React.useState(0);

  const isBetweenStep = (newStep: number): boolean => {
    if (newStep > numberOfItems - 1) {
      return false;
    }
    if (newStep < 0) {
      return false;
    }

    return true;
  };

  const nextStep = (): void => {
    if (isBetweenStep(step + 1)) {
      setStep((previousStep) => previousStep + 1);
    }
  };

  const previousStep = (): void => {
    if (isBetweenStep(step - 1)) {
      setStep((previousStep) => previousStep - 1);
    }
  };

  const finishStep = (): void => {
    onFinish();
  };

  const goToStep = (newStep: number): void => {
    if (isBetweenStep(step)) {
      setStep(() => newStep);
    }
  };

  return {
    step,
    nextStep,
    previousStep,
    goToStep,
    finishStep,
  };
};
