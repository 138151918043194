import * as React from "react";
import { TableColumnsPropsType, TableData } from "./types";

export const useTable = <T extends TableData>(props: TableColumnsPropsType<T>) => {
  const {
    dataSource,
    emptyText,
    shouldDisplayTableHeader = true,
    loading,
    id,
    isSticky,
    onUpdateRowSelection,
    rowCanBeSelected,
    rowDefaultSelected,
    background,
    boxShadow,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>(rowDefaultSelected || []);

  const onBeforeUpdateSelectedRowKeys = (selectedRows: number[]): void => {
    if (onUpdateRowSelection) {
      onUpdateRowSelection(dataSource?.filter((_row, index) => selectedRows.includes(index)) ?? []);
    }
  };

  function onCheckRow(rowIndex: number, checked: boolean): void {
    setSelectedRowKeys((previousKeys) => {
      if (rowIndex === undefined || rowIndex === null) {
        return previousKeys;
      }
      if (checked) {
        const updatedCheckedSelected = previousKeys.concat(rowIndex);
        onBeforeUpdateSelectedRowKeys(updatedCheckedSelected);
        return updatedCheckedSelected;
      }
      const updatedSelected = previousKeys.filter((index) => index !== rowIndex);
      onBeforeUpdateSelectedRowKeys(updatedSelected);
      return updatedSelected;
    });
  }

  function onCheckAllRow(checked: boolean): void {
    setSelectedRowKeys(() => {
      if (!dataSource || !checked) {
        onBeforeUpdateSelectedRowKeys([]);
        return [];
      }
      const selectedRows: number[] = [];
      for (const row of dataSource) {
        const index = dataSource.indexOf(row);
        if (index !== -1 && rowCanBeSelected) {
          const canCheck = rowCanBeSelected(row);
          if (canCheck) {
            selectedRows.push(index);
          }
        }
      }
      const updatedSelected = selectedRows.filter((positionInDataSource) => {
        return dataSource[positionInDataSource];
      });
      onBeforeUpdateSelectedRowKeys(updatedSelected);
      return selectedRows;
    });
  }
  return {
    selectedRowKeys,
    onCheckRow,
    onCheckAllRow,
    dataSource,
    shouldDisplayTableHeader,
    emptyText,
    loading,
    id,
    isSticky: isSticky ?? true,
    background,
    boxShadow,
  };
};
