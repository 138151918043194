import * as React from "react";
import { useHover } from "../../hooks/useHover.hook";

export const LandingButton: React.FunctionComponent<{
  onClick?: () => void;
  backgroundColor?: string;
  hoverColor?: string;
  borderRadius?: string;
  padding?: string;
  type?: "button" | "submit" | "reset";
}> = ({ children, onClick, backgroundColor, hoverColor, borderRadius, padding, type }) => {
  const { isHovered, onMouseOver, onMouseOut } = useHover();
  const button = isHovered ? (
    <button
      type={type}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{
        cursor: "pointer",
        border: "0px",
        backgroundColor: hoverColor,
        borderRadius,
        padding,
      }}
    >
      {children}
    </button>
  ) : (
    <button
      type={type}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{
        cursor: "pointer",
        border: "0px",
        backgroundColor,
        borderRadius,
        padding,
      }}
    >
      {children}
    </button>
  );

  return button;
};
