import * as React from "react";
import { Box } from "../Layout";
import { Text } from "../Typography";

interface ToggleButtonItemProps {
  isActive: boolean;
  label: string;
  onClick?: () => void;
}

export const ToggleButtonItem = ({ isActive, label, onClick }: ToggleButtonItemProps): React.ReactElement => {
  return (
    <Box
      as="button"
      borderColor="transparent"
      borderRadius="medium"
      height="24px"
      paddingX={2}
      marginRight={1}
      justifyContent="center"
      onClick={onClick}
      backgroundColor={isActive ? "blue-palatinate" : "transparent"}
      cursor="pointer"
      _hover={{ backgroundColor: isActive ? "blue-palatinate" : "gray-athens-1" }}
    >
      <Text color={isActive ? "white" : undefined}>{label}</Text>
    </Box>
  );
};
