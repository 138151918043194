import {
  REBRANDING_GREEN_INK_GREEN_BLACK_20,
  REBRANDING_GREEN_INK_GREEN,
  REBRANDING_FONT,
  REBRANDING_SHADOW_OVERLAY,
  REBRANDING_SHADOW_PANEL,
  REBRANDING_SHADOW_POPOUT,
  REBRANDING_GREEN_INK_GREEN_WHITE_85,
  REBRANDING_GREEN_INK_GREEN_WHITE_95,
  REBRANDING_BLACK_100,
  REBRANDING_WHITE_PAPER,
  REBRANDING_BLACK_20,
  REBRANDING_BLACK_40,
  REBRANDING_BLACK_60,
  REBRANDING_INTERFACE_UI_GREEN_GREEN,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95,
  REBRANDING_INTERFACE_UI_RED_RED,
  REBRANDING_INTERFACE_UI_RED_RED_BLACK_25,
  REBRANDING_INTERFACE_UI_RED_RED_WHITE_95,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95,
  REBRANDING_PEN_ORANGE_PEN_ORANGE,
  REBRANDING_GREEN_INK_GREEN_BLACK_40,
  REBRANDING_BACKGROUND_OVERLAY,
  REBRANDING_INTERFACE_UI_BLUE_BLUE_WHITE_95,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_85,
  REBRANDING_FONT_HEADLINE,
} from "@libeo/design-system";

// Deprecated
export const DEPRECATED_DEFAULT_COLOR = "#0d1b2a";
export const DEPRECATED_LIGHT_COLOR = "#989CA8";
export const DEPRECATED_FONT_FAMILY_SECONDARY = "'Overpass', sans-serif";

const isQuickbooksEmbed = window.location.href.includes("embed/quickbooksLibeoPay");

// COLORS
export const BLUE_MAASTRICHT = REBRANDING_BLACK_100;
export const BLUE_MIDNIGHT = REBRANDING_GREEN_INK_GREEN_BLACK_40;
export const BLUE_PALATINATE = isQuickbooksEmbed ? "#29A01C" : REBRANDING_GREEN_INK_GREEN;
export const BLUE_VIVID_SKY = REBRANDING_PEN_ORANGE_PEN_ORANGE;
export const BLUE_TITAN_WHITE = REBRANDING_INTERFACE_UI_BLUE_BLUE_WHITE_95;
export const BLUE_HOVER = isQuickbooksEmbed ? "#108000" : REBRANDING_GREEN_INK_GREEN_BLACK_20;
export const GREEN_BRITISH_RACING = REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25;
export const GREEN_GENERIC_VIRIDIAN = REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25;
export const GREEN_CARABBEAN = REBRANDING_INTERFACE_UI_GREEN_GREEN;
export const GREEN_MEDIUM_AQUAMARINE = REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_85;
export const GREEN_AQUA_SQUEEZE = REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95;

export const PINK_DARK_SCARLET = REBRANDING_INTERFACE_UI_RED_RED;
export const PINK_PICTORIAL_CARMINE = REBRANDING_INTERFACE_UI_RED_RED;
export const PINK_VIVID_RASPBERRY = REBRANDING_INTERFACE_UI_RED_RED_BLACK_25;
export const PINK_HOT = REBRANDING_INTERFACE_UI_RED_RED_WHITE_95;

export const ORANGE_COQUELICOT = "#E63900";
export const ORANGE_ORIOLES = "#E84B17";
export const ORANGE_SMASHED_PUMPKIN = "#FF6633";
export const ORANGE_CORAL = "#FF8158";
export const ORANGE_PROVINCIAL_PINK = REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95;

export const YELLOW_BRIGHT = REBRANDING_INTERFACE_UI_YELLOW_YELLOW;
export const YELLOW_ORANGE = REBRANDING_INTERFACE_UI_YELLOW_YELLOW;
export const YELLOW_USC_GOLD = REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25;
export const YELLOW_GARGOYLE_GAS = REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95;

export const BLACK = REBRANDING_BLACK_100;
export const SILVER = "#BEBEBE";
export const GRAY_BRIGHT = REBRANDING_BLACK_60;
export const GRAY_SHUTTLE = REBRANDING_BLACK_40;
export const GRAY_BOMBAY = REBRANDING_BLACK_20;
export const GRAY_ATHENS_1 = REBRANDING_GREEN_INK_GREEN_WHITE_85;
export const GRAY_ATHENS_2 = REBRANDING_GREEN_INK_GREEN_WHITE_95;
export const WHITE = REBRANDING_WHITE_PAPER;

export const BACKGROUND_COLOR_APP = REBRANDING_WHITE_PAPER;
export const BACKGROUND_OVERLAY = REBRANDING_BACKGROUND_OVERLAY;

export const NEUTRAL_DARK_GREY = "rgba(0, 0, 0, 0.65)";

// FONT
export const FONT_THIN = 100;
export const FONT_LETTER_SPACING_SMALL = "0.5px";
export const FONT_FAMILY_DEFAULT = REBRANDING_FONT;
export const FONT_FAMILY_HEADLINE = REBRANDING_FONT_HEADLINE;
export const FONT_SIZE_H1 = "32px";
export const FONT_SIZE_H2 = "25px";
export const FONT_SIZE_H3 = "20px";
export const FONT_SIZE_H4 = "16px";
export const FONT_SIZE_BUTTON = "11px";
export const FONT_SIZE_DEFAULT = "14px";
export const FONT_SIZE_BASE = "16px";
export const FONT_SIZE_SMALL = "12px";
export const FONT_SIZE_SMALLER = "10px";
export const FONT_SIZE_FOR_TEXT_AREA = "14px";

export const LINE_HEIGHT_FONT_SIZE_BASE = "24px";
export const LINE_HEIGHT_FOR_TEXT_AREA = "18px";
export const LINE_HEIGHT_FOR_TABLE_CELL = "18px";
export const LINE_HEIGHT_SIZE_SMALL = "14px";

export const FONT_BLACK = 900;
export const FONT_EXTRA_BOLD = 800;
export const FONT_BOLD = 700;
export const FONT_EXTRA_LIGHT = 200;
export const FONT_LIGHT = 300;
export const FONT_MEDIUM = 500;
export const FONT_REGULAR = 400;
export const FONT_SEMI_BOLD = 600;
export const FONT_WEIGHT_NORMAL = "normal";

// COMMON
export const BORDER_RADIUS_COMMON = "2px";
export const BORDER_RADIUS_LINK = "6px";
export const BORDER_RADIUS_ALTERNATIVE = "10px";
export const HEADER_NAV_HEIGHT = "56px";
export const HEADER_MOBILE_HEIGHT = "56px";
export const MAX_CONTENT = "max-content";

// CARD
export const BORDER_RADIUS_CARD = "2px";
export const BORDER_RADIUS_CARD_ICON = "2px";

// BUTTON
export const BORDER_RADIUS_BUTTON = "8px";
export const BORDER_MIN_HEIGHT = "40px";

// INPUT
export const BORDER_RADIUS_INPUT = "2px";
export const INPUT_ICON_SIZE = 15;
export const INPUT_HEIGHT = 35;

// MEDIA QUERY
export const DESKTOP_MEDIA_QUERY = 1920;
export const TABLET_MEDIA_QUERY = 1365;
export const MOBILE_MEDIA_QUERY = 1023;

// SHADOWS
export const SHADOW_PANEL = REBRANDING_SHADOW_PANEL;
export const SHADOW_OVERLAY = REBRANDING_SHADOW_OVERLAY;
export const SHADOW_POPOUT = REBRANDING_SHADOW_POPOUT;

// COLOR DOMAIN
export const INFO = BLUE_PALATINATE;
export const SUCCESS = GREEN_CARABBEAN;
export const ERROR = ORANGE_COQUELICOT;
export const WARNING = ORANGE_CORAL;

export const getRandomLabelColor = (labelName: string): string => {
  const labelColors = [
    BLUE_MAASTRICHT,
    BLUE_MIDNIGHT,
    BLUE_PALATINATE,
    BLUE_VIVID_SKY,
    GREEN_BRITISH_RACING,
    GREEN_GENERIC_VIRIDIAN,
    GREEN_CARABBEAN,
    GREEN_MEDIUM_AQUAMARINE,
    PINK_DARK_SCARLET,
    PINK_PICTORIAL_CARMINE,
    PINK_VIVID_RASPBERRY,
    PINK_HOT,
    ORANGE_COQUELICOT,
    ORANGE_ORIOLES,
    ORANGE_SMASHED_PUMPKIN,
    ORANGE_CORAL,
    YELLOW_BRIGHT,
    YELLOW_ORANGE,
    YELLOW_USC_GOLD,
    YELLOW_GARGOYLE_GAS,
  ];
  const hashLabelNumber = labelName.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  // If you change the length f the labelColors, compute the new binary mask : 0x (labelColors in hexadecimal)
  const binaryLength = 0xf;
  const indexColor = (hashLabelNumber > 0 ? hashLabelNumber : ~hashLabelNumber - 1) & binaryLength;

  return labelColors[indexColor];
};

// you can check is => https://en.wikipedia.org/wiki/Relative_luminance
export const getIsTooBlackColor = (colorHex: string): boolean => {
  if (colorHex.length !== 7) {
    return false;
  }
  const hex: number = parseInt(colorHex.substring(1), 16);
  const r = (hex >> 16) & 0xff;
  const g = (hex >> 8) & 0xff;
  const b = (hex >> 0) & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luma < 80;
};

// PARTNERSHIP

export const ZAPIER_ORANGE_COLOR = "#ff4a00";
export const EVOLIZ_COLOR = "#fff";
