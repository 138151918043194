import { Organisation } from "@libeo/graphql";
import { Notification, NotificationName } from "../../components/Notification/constants";
import { ClosedAlert, getLocalStorage, setLocalStorage } from "../../utils/localStorage";

const findClosedAlert = (organisationId: Organisation["id"], name?: NotificationName): ClosedAlert | undefined => {
  const closedAlerts = getLocalStorage<"closedAlert">("closedAlert");
  return closedAlerts?.find((closedAlert: ClosedAlert) => closedAlert.name === name && closedAlert.organisationId === organisationId);
};

const hasAlertBeenClosed = (organisationId: Organisation["id"], name?: NotificationName): boolean => Boolean(findClosedAlert(organisationId, name));

export const saveAlertClosed = (organisationId: Organisation["id"], name: NotificationName): void => {
  const closedAlerts = getLocalStorage<"closedAlert">("closedAlert");
  if (!closedAlerts) {
    setLocalStorage<"closedAlert">("closedAlert", [{ organisationId, name }]);
    return;
  }

  if (!hasAlertBeenClosed(organisationId, name)) {
    setLocalStorage<"closedAlert">("closedAlert", closedAlerts.concat({ organisationId, name }));
  }
};

export const shouldDisplayNotification = (organisationId: Organisation["id"], option: Notification): boolean => {
  const isAlert = Boolean(option.cta);
  return !isAlert || !hasAlertBeenClosed(organisationId, option.name);
};
