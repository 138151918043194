import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { BoxPrimitive, BoxPrimitiveProps } from "../../primitives";
import { Text } from "../Typography";

export interface TagProps extends Pick<BoxPrimitiveProps, "id"> {
  label: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

const DEFAULT_TAG = "button";

type TagPropsOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof BoxPrimitive>, TagProps>;
type TagPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, TagPropsOwnProps>;

/**
 * `Tag` is used to add details about an element and to filter.
 */
export const Tag = React.forwardRef(({ as = DEFAULT_TAG, label, isActive = false, ...props }, ref) => {
  return (
    <BoxPrimitive
      ref={ref}
      as={as}
      borderColor={isActive ? "gray-athens-1" : "black-10"}
      borderStyle="solid"
      alignItems="center"
      borderRadius="pill"
      borderWidth={1}
      paddingX={2}
      marginRight={1}
      justifyContent="center"
      backgroundColor={isActive ? "gray-athens-2" : "black-5"}
      minWidth="max-content"
      cursor={props.onClick ? "pointer" : undefined}
      _focus={{ outline: 0 }}
      {...props}
    >
      <Text size="small" color={isActive ? "blue-palatinate" : "black"}>
        {label}
      </Text>
    </BoxPrimitive>
  );
}) as TagPrimitive;
