import { CSSProperties } from "react";
import { spacing } from "../../theme/spacing";
import { FONT_SEMI_BOLD, BLUE_MAASTRICHT, FONT_REGULAR, GRAY_BOMBAY } from "../../theme/ui-constants";

export const searchCompanyRowOptionStyle: CSSProperties = {
  flexDirection: "row",
  padding: "5px 0 5px 0",
  flex: 1,
  transition: "opacity 0.3 ease",
  opacity: 1,
  alignItems: "center",
  display: "flex",
};

export const searchCompanyAvatarStyle: CSSProperties = {
  marginRight: "10px",
  alignItems: "center",
  display: "flex",
};

export const searchCompanyColStyle: CSSProperties = {
  flex: 1,
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  wordBreak: "break-all",
  whiteSpace: "normal",
  margin: spacing(0, 1, 0, 0),
};

export const searchCompanyNameStyle: CSSProperties = {
  fontWeight: FONT_SEMI_BOLD,
  fontSize: "16px",
  color: BLUE_MAASTRICHT,
  lineHeight: "19px",
};

export const searchCompanyAddressStyle: CSSProperties = {
  fontWeight: FONT_REGULAR,
  fontSize: "12px",
  color: GRAY_BOMBAY,
  lineHeight: "16px",
};

export const searchCompanySiretStyle: CSSProperties = {
  fontWeight: FONT_REGULAR,
  fontSize: "12px",
  color: GRAY_BOMBAY,
  lineHeight: "16px",
};

export const renderNoValueStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "10px 0",
};

export const renderNoValueTextStyle: CSSProperties = {
  fontWeight: 500,
  fontSize: "20px",
  color: BLUE_MAASTRICHT,
  textAlign: "center",
  lineHeight: "33px",
  marginBottom: 0,
};
export const renderNoValueTextDescriptionStyle: CSSProperties = {
  fontWeight: 500,
  fontSize: "17px",
  color: GRAY_BOMBAY,
  textAlign: "center",
  lineHeight: "28px",
  marginBottom: 0,
};
