/** @jsxImportSource @emotion/react */

import { Interpolation, Theme, useTheme } from "@emotion/react";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { color, createSystem, space, typography } from "system-props";
import { filterDOMProps } from "../../utils/dom";
import { TextPrimitiveProps } from "../types";

const system = createSystem({
  strict: false,
  tokenPrefix: "noprefix",
  pseudoSelectors: {
    _hover: "&:hover",
  },
});

const mapPropsToEllipsis = ({ hasEllipsis }: TextPrimitiveProps): React.CSSProperties => {
  if (hasEllipsis) {
    return {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
    } as const;
  }
  return {};
};

const getDisplay = (
  Component: string | React.ReactElement,
  display?: TextPrimitiveProps["display"],
): TextPrimitiveProps["display"] | string | undefined => {
  if (display) {
    return display;
  }

  const type = typeof Component === "string" ? Component : Component.type;

  if (typeof type === "string") {
    return ["a", "span"].includes(type) ? "inline-block" : "block";
  }

  return undefined;
};

const DEFAULT_TAG = "span";

const extraProps = {
  cursor: true,
  textDecoration: true,
  textTransform: true,
  whiteSpace: true,
} as const;

const cssSystem = system({
  ...color,
  ...space,
  ...typography,
  ...extraProps,
});

export const TextPrimitive = React.forwardRef(
  ({ children, as: Component = DEFAULT_TAG, display, ...props }, ref): React.ReactElement => {
    const theme = useTheme();

    const style: Interpolation<Theme> = {
      display: getDisplay(Component, display),
      ...cssSystem({ theme, ...props }),
      ...mapPropsToEllipsis(props),
    };

    const componentProps = {
      ref,
      children,
      css: style,
      ...filterDOMProps({ ...props }, extraProps),
    };

    return <Component {...componentProps} />;
  },
) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, TextPrimitiveProps>;

TextPrimitive.defaultProps = {
  color: "black",
  fontFamily: "texts",
};

export type { TextPrimitiveProps };
