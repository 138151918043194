export type EventMessageToParentWindow = "close" | "success" | "error";
export type EventActionToParentWindow =
  | "pay"
  | "pay_externally"
  | "request_business_approval"
  | "cancel_business_approval"
  | "hold"
  | "unhold"
  | "cancel_payment_request"
  | "cancel_payment_request";

function triggerMessageToParentWindow(eventName: EventMessageToParentWindow, action?: EventActionToParentWindow, reason?: string): void {
  // @ts-ignore
  window.parent.postMessage(
    {
      event: eventName,
      action: action,
      reason: reason,
    },
    "*",
  );
}

export const useInvoiceEmbedPostMessage = (): {
  triggerMessageToParentWindow: (eventName: EventMessageToParentWindow, action?: EventActionToParentWindow, reason?: string) => void;
} => {
  return {
    triggerMessageToParentWindow,
  };
};
