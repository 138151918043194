import * as React from "react";
import { InputMessage } from "../Wrapper/InputMessage";
import { useInput } from "../hooks/useInput.hook";
import { useRadioButton } from "../hooks/useRadioButton.hook";
import { radioButtonLabelStyle, radioButtonLabelWrapperStyle } from "../styles";
import { RadioButtonProps } from "./types";

export const RadioButton: React.FunctionComponent<RadioButtonProps> = (props) => {
  const { id, name, value, required, isDisabled: disabled, label, defaultChecked, message } = props;
  const { error, color } = useInput(props);

  const { onCheck, radioButtonRef } = useRadioButton(props);

  return (
    <label style={radioButtonLabelWrapperStyle}>
      <input
        ref={radioButtonRef}
        disabled={disabled}
        data-error={error}
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: 16,
          minHeight: 16,
          borderRadius: "50%",
          marginTop: 0,
          marginRight: 8,
        }}
        onChange={onCheck}
      />
      {label && (
        <div style={radioButtonLabelStyle(Boolean(disabled))}>
          {label}
          {required && " *"}
        </div>
      )}
      {message && <InputMessage color={color}>{message}</InputMessage>}
    </label>
  );
};
