import * as React from "react";
import { GRAY_ATHENS_1 } from "../theme/ui-constants";

export const HorizontalLineMedium: React.FunctionComponent<{}> = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "2px",
        backgroundColor: GRAY_ATHENS_1,
        borderRadius: "3px",
      }}
    />
  );
};
