import { CSSProperties } from "react";
import Select, { Props } from "react-select";
import { DefaultOptionsType, SelectProps } from "../types";

enum DueDateMethod {
  THIS_MONTH = "THIS_MONTH",
  NEXT_MONTH = "NEXT_MONTH",
  AFTER_INVOICEDATE = "AFTER_INVOICEDATE",
  AFTER_END_OF_MONTH_OF_INVOICEDATE = "AFTER_END_OF_MONTH_OF_INVOICEDATE",
  END_OF_MONTH = "END_OF_MONTH",
}

interface SearchCompanyAddressesType {
  address1: string | null;
  address2: string | null;
  siret: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
}

export interface SelectGbCompanyOptionsType extends DefaultOptionsType {
  id?: string | null;
  siren?: string | null;
  siret?: string | null;
  companyNumber: string | null;
  name: string | null;
  brandName: string | null;
  domainName: string | null;
  isLibeoMember: boolean | null;
  isLibeoPartner: boolean | null;
  knownAddress?: SearchCompanyAddressesType | null;
  defaultDueDateDay?: number | null;
  defaultDueDateMethod?: DueDateMethod | null;
}

export interface SelectCompanyOptionsType extends DefaultOptionsType {
  id?: string | null;
  siren?: string | null;
  siret?: string | null;
  companyNumber?: string | null;
  name: string | null;
  brandName: string | null;
  domainName: string | null;
  isLibeoMember: boolean | null;
  isLibeoPartner?: boolean | null;
  knownAddress?: SearchCompanyAddressesType | null;
  defaultDueDateDay?: number | null;
  defaultDueDateMethod?: DueDateMethod | null;
}

export interface AddressType {
  id: string | null;
  address1: string | null;
  address2: string | null;
  siret: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  phone: string | null;
  label: string | null;
  isEditable: boolean | null;
}

export interface SelectCompanyType {
  id: SelectProps<SelectCompanyOptionsType>["id"];
  renderButton?: (company: SelectCompanyOptionsType) => React.ReactNode;
  renderTag?: (company: SelectCompanyOptionsType) => React.ReactNode;
  isRequired?: SelectProps<SelectCompanyOptionsType>["required"];
  isReady?: SelectProps<SelectCompanyOptionsType>["ready"];
  hasError?: SelectProps<SelectCompanyOptionsType>["error"];
  description?: SelectProps<SelectCompanyOptionsType>["description"];
  alreadyInLibeoText?: React.ReactNode;
  notFoundText?: React.ReactNode;
  noDataText?: React.ReactNode;
  siretText?: React.ReactNode;
  message?: SelectProps<SelectCompanyOptionsType>["message"];
  options: SelectProps<SelectCompanyOptionsType>["options"];
  label: SelectProps<SelectCompanyOptionsType>["label"];
  defaultValue?: SelectProps<SelectCompanyOptionsType>["defaultValue"];
  value?: SelectProps<SelectCompanyOptionsType>["value"];
  isLoading?: boolean;
  onChangeOption?: SelectProps<SelectCompanyOptionsType>["onChangeOption"];
  onBlur?: SelectProps<SelectCompanyOptionsType>["onBlur"];
  onFocus?: SelectProps<SelectCompanyOptionsType>["onFocus"];
  filterOption?: Props["filterOption"];
  forwardRef?: (ref: React.RefObject<Select<SelectCompanyOptionsType>>) => void;
  onInputValueChange: (value: string) => void | Promise<void>;
  footerOptionHint?: () => React.ReactNode;
  footerOptionLabel?: () => React.ReactNode;
  footerOptionClick?: () => void;
  style?: CSSProperties;
}
