import { DateWithoutTime } from "../date-without-time/date-without-time";

export enum PaymentStrategy {
  TREEZOR_AUTOLOAD = "TREEZOR_AUTOLOAD",
  TREEZOR_TOPUP = "TREEZOR_TOPUP",
  TREEZOR_FAST_PAYMENT = "TREEZOR_FAST_PAYMENT",
  FINTECTURE = "FINTECTURE",
}

export type PaymentDatesPayload = {
  paymentStrategy: PaymentStrategy;
  paymentAtDate: DateWithoutTime;
  ibanValidationDate?: DateWithoutTime;
  sufficientFundsDate?: DateWithoutTime;
  clickToPayDate?: DateWithoutTime;
  validationDate?: DateWithoutTime;
  payinCreationDate?: DateWithoutTime;
  collectionDate?: DateWithoutTime;
  payinTransferDate?: DateWithoutTime;
  payoutCreationDate?: DateWithoutTime;
  payoutValidationDate?: DateWithoutTime;
};
