import * as React from "react";
import { BackgroundGrey } from "../BackgroundColors/BackgroundGrey";
import { BorderGrey } from "../Borders/BorderGrey";
import { AlignLeft } from "../Layout";
import { MarginMedium, MarginSmall } from "../Margin";
import { PaddingBig } from "../Padding";
import { Bold } from "../Text";
import { PaneItem } from "./PaneItem";
import { PaneLabel } from "./PaneLabel";
import { PaneLevel, PaneProps } from "./types";

export const Pane: React.FunctionComponent<PaneProps> = ({ label, level, title, items, button }) => {
  const content: React.ReactNode = (
    <>
      {label && <PaneLabel level={level} label={label} />}
      <AlignLeft>
        <PaddingBig top bottom left right>
          <MarginSmall bottom>
            <Bold>{title}</Bold>
          </MarginSmall>
          <MarginMedium bottom>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <PaneItem level={level} text={item} />
                </li>
              ))}
            </ul>
          </MarginMedium>
          {button}
        </PaddingBig>
      </AlignLeft>
    </>
  );

  return level === PaneLevel.RECOMMENDED ? <BackgroundGrey>{content}</BackgroundGrey> : <BorderGrey>{content}</BorderGrey>;
};
