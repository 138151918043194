import * as React from "react";
import { ActionMeta, FocusEventHandler, OptionsType, ValueType } from "react-select";
import Creatable from "react-select/creatable";
import {
  LoadingMessageComponent,
  MenuListComponent,
  MultipleValueComponent,
  MultiValueContainerComponent,
  MultiValueRemoveComponent,
  NoOptionsMessageComponent,
  OptionComponent,
  SelectClearIndicator,
  SelectDropDownIndicator,
  SingleValueComponent,
} from "./Select/SelectComponents";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { useMultiSelect } from "./hooks/useMultiSelect.hook";
import { inputStyle, multiTagSelectStyles } from "./styles";
import { DefaultOptionsType, InputProps, MultiSelectProps } from "./types";
export const TagMultiSelect = <OptionType extends DefaultOptionsType>(props: MultiSelectProps<OptionType>): React.ReactElement => {
  const {
    style,
    value,
    required,
    isDisabled: disabled,
    isLoading,
    label,
    message,
    suffix,
    options,
    renderValue,
    renderMultiValueContainer,
    renderMultipleValue,
    renderOption,
    description,
    renderNoValue,
    renderMenuList,
    renderMenuListFooter,
    renderMenuListHeader,
    isValidNewOption,
    isClearable,
    id,
    onKeyDown,
    isSearchable,
  } = props;
  const { color, onFocus, onBlur, inputFocus } = useInput((props as unknown) as InputProps);
  const { defaultInputOption, onChange, onScrollToBottom, inputHasValue } = useMultiSelect(props);

  function beforeOnChange(value: ValueType<OptionType>, action: ActionMeta<OptionType>): void {
    const selectedOption = (value as unknown) as OptionType[];
    onChange(selectedOption, action);
  }

  function beforeIsValidNewOption(inputValue: string, value: ValueType<OptionType>, options: OptionsType<OptionType>): boolean {
    const selectedOption = (value as unknown) as OptionType;
    const allOptions = (options as unknown) as OptionType[];
    if (isValidNewOption) {
      return Boolean(isValidNewOption(inputValue, selectedOption, allOptions));
    }
    const optionAlreadyExists = options.findIndex((option) => option.value === inputValue.toLowerCase()) > -1;
    return Boolean(inputValue) && !optionAlreadyExists;
  }

  return (
    <FormItemWrapper
      style={style}
      color={color}
      label={label}
      required={required}
      description={description}
      inputFocus={inputFocus}
      inputHasValue={inputHasValue}
      suffix={suffix}
      message={message}
    >
      <Creatable<OptionType>
        isMulti
        key={defaultInputOption?.map((v) => v.value).join() || "defaultValues"}
        onKeyDown={onKeyDown}
        id={id}
        isSearchable={isSearchable}
        onMenuScrollToBottom={onScrollToBottom}
        defaultValue={defaultInputOption}
        value={value}
        styles={multiTagSelectStyles}
        isClearable={isClearable}
        escapeClearsValue={true}
        placeholder=""
        onChange={beforeOnChange}
        isLoading={isLoading}
        isValidNewOption={beforeIsValidNewOption}
        components={{
          DropdownIndicator: suffix ? undefined : SelectDropDownIndicator(),
          ClearIndicator: SelectClearIndicator,
          Option: OptionComponent(renderOption),
          SingleValue: SingleValueComponent(renderValue),
          MultiValue: MultipleValueComponent(renderMultipleValue),
          MultiValueRemove: MultiValueRemoveComponent,
          MultiValueContainer: MultiValueContainerComponent(renderMultiValueContainer),
          LoadingMessage: LoadingMessageComponent(),
          NoOptionsMessage: NoOptionsMessageComponent(renderNoValue),
          MenuList: MenuListComponent(renderMenuListHeader, renderMenuList, renderMenuListFooter),
        }}
        isDisabled={disabled}
        style={inputStyle(true, disabled, Boolean(label))}
        onFocus={onFocus as FocusEventHandler}
        onBlur={onBlur as FocusEventHandler}
        options={options}
      />
    </FormItemWrapper>
  );
};
