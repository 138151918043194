import { Heading } from "@libeo/design-system";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Icon, IconValue } from "../Icon/Icon";
import { AlignCenter } from "../Layout";
import { MarginBig, MarginMedium, MarginSmall } from "../Margin";
import { GREEN_CARABBEAN, MOBILE_MEDIA_QUERY } from "../theme/ui-constants";
import { wrapper } from "./styles";

export const EmptyState: React.FunctionComponent<{
  withIcon?: boolean;
  pageTitle: string;
  pageBenefits?: React.ReactNode;
  icon?: IconValue;
  cta?: React.ReactNode | Array<React.ReactNode>;
  textToShowOnMobile?: string;
}> = ({ withIcon = true, icon = IconValue.LightBulb, pageTitle, pageBenefits, cta, textToShowOnMobile }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MEDIA_QUERY });

  return (
    <div style={wrapper(isMobile)}>
      {withIcon && (
        <MarginMedium bottom>
          <AlignCenter>
            <Icon
              width="32px"
              height="32px"
              color="white"
              backgroundColor={GREEN_CARABBEAN}
              circleBorder
              borderColor={GREEN_CARABBEAN}
              value={icon}
            />
          </AlignCenter>
        </MarginMedium>
      )}

      <AlignCenter>
        <Heading size="l">{pageTitle}</Heading>
      </AlignCenter>

      {isMobile && <MarginBig top>{textToShowOnMobile}</MarginBig>}

      {!isMobile && (
        <>
          {pageBenefits && <MarginBig top>{pageBenefits}</MarginBig>}
          {cta && (
            <MarginBig top>
              <AlignCenter>
                {Array.isArray(cta)
                  ? cta.map((ctaElement, index) => (
                      <MarginSmall left right key={index}>
                        {ctaElement}
                      </MarginSmall>
                    ))
                  : cta}
              </AlignCenter>
            </MarginBig>
          )}
        </>
      )}
    </div>
  );
};
