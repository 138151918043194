import * as React from "react";

export const useToggleList = (): {
  values: string[];
  onChange: (value: string) => string[];
} => {
  const [values, setValues] = React.useState<Array<string>>([]);
  const onChange = (value: string): string[] => {
    const doesIncludeValues = values.includes(value);
    const newValues = doesIncludeValues ? values.filter((elem) => elem !== value) : [...values, value];
    setValues(newValues);
    return newValues;
  };
  return {
    values,
    onChange,
  };
};
