/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Checkbox } from "../Checkbox";
import { Box } from "../Layout/Box";
import { Tooltip } from "../Tooltip";
import { TableCell } from "./TableCell";
import { TableRow } from "./TableRow";
import { TableProps, TableItem } from "./types";
import { TableBodyRowProps, useTableBodyRow } from "./useTableBodyRow.hook";

type CheckboxCellProps = {
  isRowSelected: boolean;
  canRowBeSelected: boolean;
  disabledReason: string | undefined;
  onClickCheckbox: (event: React.MouseEvent) => void;
};

const CheckboxCell = (props: CheckboxCellProps): React.ReactElement => {
  const { isRowSelected, canRowBeSelected, disabledReason, onClickCheckbox } = props;

  return (
    <TableCell width="32px" isFirstColumn={true} isLastColumn={false} onClick={onClickCheckbox}>
      <Tooltip content={!canRowBeSelected && disabledReason ? disabledReason : undefined}>
        <Checkbox checked={isRowSelected} isDisabled={!canRowBeSelected} />
      </Tooltip>
    </TableCell>
  );
};

const TableBodyRow = <TableItem extends object>(props: TableBodyRowProps<TableItem>): React.ReactElement => {
  const { itemKey, columns, row, isInline, isLastRow, hasRowSelection, renderPanel } = props;
  const { isOpened, isRowActive, isRowSelected, canRowBeSelected, checkboxDisabledReason, onClickCheckbox, onClickTableRow } = useTableBodyRow(props);

  const tableRowKey = `tbody-row-${itemKey}${canRowBeSelected ? "-selectable" : ""}${isRowSelected ? "-checked" : ""}`;

  return (
    <>
      <TableRow key={tableRowKey} height={60} onClick={onClickTableRow} isActive={isRowActive}>
        {hasRowSelection && (
          <CheckboxCell
            isRowSelected={isRowSelected}
            canRowBeSelected={canRowBeSelected}
            disabledReason={checkboxDisabledReason}
            onClickCheckbox={onClickCheckbox}
          />
        )}
        {columns.map((column, columnIndex) => {
          return (
            <TableCell
              key={`tbody-row-table-cell-${itemKey}-${column.fieldName}`}
              hasBorder={isInline && !isLastRow && !isOpened}
              isFirstColumn={!hasRowSelection && columnIndex === 0}
              isLastColumn={columnIndex === columns.length - 1}
              {...column}
            >
              {column.render?.(row)}
            </TableCell>
          );
        })}
      </TableRow>
      {isOpened && renderPanel && (
        <TableRow>
          <TableCell hasBorder={isInline && !isLastRow} colSpan={columns.length} isFirstColumn isLastColumn>
            {renderPanel(row)}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

type TableBodyProps<TableItem extends object> = Pick<
  TableProps<TableItem>,
  | "isInline"
  | "columns"
  | "data"
  | "onClickRow"
  | "hasRowSelection"
  | "onUpdateRowSelection"
  | "selectedRowKeys"
  | "rowCanBeSelected"
  | "rowDisabledReason"
  | "renderPanel"
  | "isActiveRow"
  | "id"
>;

export const TableBody = <T extends TableItem>(props: TableBodyProps<T>): React.ReactElement => {
  const {
    isInline,
    columns,
    data,
    onClickRow,
    hasRowSelection,
    onUpdateRowSelection,
    selectedRowKeys,
    rowCanBeSelected,
    rowDisabledReason,
    renderPanel,
    isActiveRow,
  } = props;

  return (
    <Box as="tbody" display="table-row-group">
      {data.map((row, rowIndex) => {
        const itemKey = row.id || rowIndex;
        return (
          <TableBodyRow
            key={`tbdoy-row-${itemKey}`}
            row={row}
            itemKey={itemKey}
            columns={columns}
            isInline={isInline}
            isLastRow={rowIndex === data.length - 1}
            onClickRow={onClickRow}
            hasRowSelection={hasRowSelection}
            onUpdateRowSelection={onUpdateRowSelection}
            selectedRowKeys={selectedRowKeys}
            rowCanBeSelected={rowCanBeSelected}
            rowDisabledReason={rowDisabledReason}
            renderPanel={renderPanel}
            isActiveRow={isActiveRow}
          />
        );
      })}
    </Box>
  );
};
