/* eslint-disable sonarjs/no-duplicate-string */
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import * as React from "react";
import { Box } from "../Layout";
import { Text, TextSize } from "../Typography";

const DEFAULT_TAG = "button";

type CheckboxRootPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, Polymorphic.OwnProps<typeof Box>>;

const CheckboxRoot = React.forwardRef((props, ref): React.ReactElement => <Box ref={ref} as="button" {...props} />) as CheckboxRootPrimitive;

export interface CheckboxProps {
  label?: React.ReactNode;
  checked?: CheckboxPrimitive.CheckedState;
  defaultChecked?: CheckboxPrimitive.CheckedState;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (checked: CheckboxPrimitive.CheckedState) => void;
  labelSize?: TextSize;
}

export const Checkbox = React.forwardRef<HTMLLabelElement, CheckboxProps>((props, ref) => {
  const { checked: checkedProp, defaultChecked, isDisabled, label, onChange, isRequired, labelSize, ...rest } = props;

  const [checked = false, setChecked] = useControllableState({
    prop: checkedProp,
    defaultProp: defaultChecked,
    onChange,
  });

  return (
    <Box ref={ref} as="label" alignItems="center" flexDirection="row" cursor={isDisabled ? "default" : "pointer"} {...rest}>
      <CheckboxPrimitive.Root
        name="checkbox"
        as={CheckboxRoot}
        disabled={isDisabled}
        required={isRequired}
        defaultChecked={defaultChecked}
        checked={checked}
        onCheckedChange={setChecked}
        flexShrink={0}
        padding={0}
        justifyContent="center"
        alignItems="center"
        width={16}
        height={16}
        borderRadius="small"
        borderWidth={1}
        cursor="inherit"
        pointerEvents={isDisabled ? "none" : undefined}
        backgroundColor={checked ? "blue-palatinate" : "white"}
        borderColor={checked ? "blue-palatinate" : "gray-bombay"}
        _disabled={{ backgroundColor: "black-5", borderColor: "gray-bombay" }}
      >
        <CheckboxPrimitive.Indicator as={CheckIcon} color="white" width={16} height={16} />
      </CheckboxPrimitive.Root>
      {label && (
        <Text size={labelSize ? labelSize : "standard"} marginLeft={2} color={isDisabled ? "gray-bombay" : "black-40"}>
          {label}
          {isRequired && " *"}
        </Text>
      )}
    </Box>
  );
});
