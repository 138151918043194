import { Loader } from "@libeo/design-system";
import * as React from "react";
import { choiceButtonStyles, choiceLabelStyles } from "./styles";
import { ChoiceButtonProp } from "./types";
import { useChoiceButton } from "./useChoiceButton.hook";

export const ChoiceButton: React.FunctionComponent<ChoiceButtonProp> = (props) => {
  const { id, children, label, highlightOnClick } = props;
  const { isLoading, isHovered, isClicked, ...choiceButtonProps } = useChoiceButton(props);

  return (
    <button type="submit" style={choiceButtonStyles(isHovered || isLoading, Boolean(highlightOnClick && isClicked))} id={id} {...choiceButtonProps}>
      <div style={choiceLabelStyles}>
        {children}
        {label}
      </div>
      {isLoading && <Loader marginLeft={1} variation="secondary" size="small" />}
    </button>
  );
};
