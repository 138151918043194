import { DateWithoutTime } from "../date-without-time/date-without-time";
import { getCollectionDate } from "./collection-date/get-collection-date";
import { getCloserBusinessDate } from "./get-closer-business-date";
import { PaymentDatesPayload } from "./payment-date.types";
import { getReceptionOfFundsDate } from "./reception-of-funds/get-reception-of-funds-date";
import { getTriggeringDate } from "./triggering-date/get-triggering-date";

export const getPaymentDates = (
  payload: PaymentDatesPayload,
): { triggeringDate: DateWithoutTime; collectionDate?: DateWithoutTime; receptionOfFundsDate: DateWithoutTime } => {
  const payloadWithRightPaymentAt = {
    ...payload,
    paymentAt: getCloserBusinessDate(payload.paymentAtDate),
  };

  return {
    triggeringDate: getTriggeringDate(payloadWithRightPaymentAt),
    collectionDate: getCollectionDate(payloadWithRightPaymentAt),
    receptionOfFundsDate: getReceptionOfFundsDate(payloadWithRightPaymentAt),
  };
};
