import { Global, css } from "@emotion/react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "../Layout";
import { Tag } from "../Tag";
import { ToggleSelectorProps } from "./types";

/**
 * `ToggleSelector` is a display component used to display a multiple selection.
 */
export const ToggleSelector = <T extends string>(props: ToggleSelectorProps<T>): React.ReactElement => {
  const { data, values, onChange } = props;

  return (
    <>
      <Global
        styles={css`
          #toggleSelector::-webkit-scrollbar {
            display: none;
          }
          #toggleSelector {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}
      />
      <Box flexDirection="row" overflow="scroll" id="toggleSelector">
        {data.map((dataItem) => {
          return (
            <Tag
              key={dataItem.value}
              isActive={values && values.includes(dataItem.value)}
              label={<FormattedMessage id={dataItem.label} />}
              onClick={(): void => onChange(dataItem.value)}
            />
          );
        })}
      </Box>
    </>
  );
};

export type { ToggleSelectorProps };
