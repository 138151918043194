import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { copyToClipBoardButtonStyles, copyToClipBoardInputStyles } from "./styles";
import { useButton } from "./useButton.hook";

interface CopyToClipboardButtonProps {
  id?: string;
  textToCopy: string;
  onCopied?: () => Promise<void> | void;
  width?: React.CSSProperties["width"];
}

export const useCopyToClipboardButton = ({ onCopied }: { onCopied?: () => Promise<void> | void }) => {
  const textRef = React.useRef<HTMLInputElement>(null);

  const onClick = async () => {
    textRef.current?.select();
    document.execCommand("copy");
    if (onCopied) {
      await onCopied();
    }
  };

  return {
    onClick,
    textRef,
  };
};

export const CopyToClipboardButton: React.FunctionComponent<CopyToClipboardButtonProps> = ({ id, textToCopy, onCopied, width }) => {
  const { textRef, onClick } = useCopyToClipboardButton({ onCopied });
  const { stopAndCallOnClick } = useButton({ onClick });

  return (
    <div id={id} style={copyToClipBoardButtonStyles(width)} onClick={stopAndCallOnClick}>
      <input ref={textRef} type="text" readOnly value={textToCopy} style={copyToClipBoardInputStyles} />
      <Icon width="15" height="15" value={IconValue.Copy} />
    </div>
  );
};
