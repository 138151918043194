import * as React from "react";
import { spacing } from "../theme/spacing";
import { GRAY_ATHENS_2 } from "../theme/ui-constants";

export const BackgroundGrey: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: GRAY_ATHENS_2,
        margin: spacing(0),
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  );
};
