import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_MAASTRICHT, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT, LINE_HEIGHT_FONT_SIZE_BASE } from "../theme/ui-constants";

export const toastTextStyle: CSSProperties = {
  color: BLUE_MAASTRICHT,
  margin: 0,
  zIndex: 1,
  flex: 1,
};

export const toastTextDescriptionStyle: CSSProperties = {
  fontFamily: FONT_FAMILY_DEFAULT,
  fontSize: FONT_SIZE_DEFAULT,
  lineHeight: LINE_HEIGHT_FONT_SIZE_BASE,
  padding: spacing(1, 0),
};

export const toastCloseButtonStyle: CSSProperties = {
  width: spacing(3),
  height: spacing(3),
};

export const crossIcon: CSSProperties = {
  width: spacing(3),
  height: spacing(3),
};

export const toastSuccessIconWrapper: CSSProperties = {
  width: "80px",
  height: "100%",
};
