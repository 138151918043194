/** @jsxImportSource @emotion/react */

import { Interpolation, Theme, useTheme } from "@emotion/react";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { border, color, createSystem, flexbox, grid, layout, position, shadow, space, typography } from "system-props";
import { filterDOMProps } from "../../utils/dom";
import { InputPrimitiveProps } from "../types";

const system = createSystem({
  strict: false,
  tokenPrefix: "noprefix",
  pseudoSelectors: {
    _hover: "&:hover",
    _focus: "&:focus",
    _hoverAndFocus: "&:hover, &:focus",
    _disabled: "[disabled], [disabled]:hover, [disabled]:focus, [aria-disabled], [aria-disabled]:hover, [aria-disabled]:focus",
  },
});

const DEFAULT_TAG = "input";

const extraProps = {
  cursor: true,
  outline: true,
  pointerEvents: true,
} as const;

const cssSystem = system({
  ...border,
  ...color,
  ...flexbox,
  ...grid,
  ...layout,
  ...position,
  ...shadow,
  ...space,
  ...typography,
  ...extraProps,
});

export const InputPrimitive = React.forwardRef(
  ({ children, as: Component = DEFAULT_TAG, ...props }, ref): React.ReactElement => {
    const theme = useTheme();

    const style: Interpolation<Theme> = {
      ...cssSystem({ theme, ...props }),
    };

    const componentProps = {
      ref,
      children,
      css: style,
      ...filterDOMProps({ ...props }, extraProps),
    };

    return <Component {...componentProps} />;
  },
) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, InputPrimitiveProps>;

InputPrimitive.defaultProps = {
  color: "gray-shuttle",
  fontFamily: "texts",
};

export type { InputPrimitiveProps };
