import * as React from "react";
import { BORDER_RADIUS_CARD, SHADOW_PANEL, WHITE } from "../theme/ui-constants";

export const CardWrapper: React.FunctionComponent<{
  style?: React.CSSProperties;
  id?: string;
  hover?: boolean;
}> = ({ id, children, style }) => {
  return (
    <div
      id={id}
      style={{
        boxShadow: SHADOW_PANEL,
        transition: "all 0.2s ease-out",
        background: WHITE,
        borderRadius: BORDER_RADIUS_CARD,
        alignItems: "stretch",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
