import * as React from "react";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { inputStyle } from "./styles";
import { InputProps } from "./types";

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function InputWithForwardRef(props, ref) {
  const {
    isDisabled: disabled,
    style,
    id,
    required,
    label,
    message,
    suffix,
    type,
    autocomplete,
    description,
    maxLength,
    min,
    max,
    placeholder,
    value,
  } = props;
  const { color, onFocus, onBlur, inputFocus, onChange, defaultValue, error, hasSuffix, inputHasValue, onKeyPress } = useInput(props);

  return (
    <FormItemWrapper
      color={color}
      label={label}
      description={description}
      required={required}
      inputFocus={inputFocus}
      inputHasValue={inputHasValue}
      suffix={suffix}
      message={message}
      disabled={disabled}
      style={style}
    >
      <input
        ref={ref}
        disabled={disabled}
        data-error={error}
        autoComplete={autocomplete}
        type={type}
        id={id}
        min={min}
        onKeyPress={onKeyPress}
        max={max}
        step="any"
        defaultValue={defaultValue}
        style={inputStyle(hasSuffix, disabled, Boolean(label))}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
      />
    </FormItemWrapper>
  );
});
