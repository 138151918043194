import * as React from "react";
export const HorizontalButtonRight: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {children}
    </div>
  );
};
