import * as React from "react";
import { FullPageCarouselFooterButtonText } from "./FullPageCarouselFooterButtonText";
import { fullPageCarouselFooterStyles } from "./styles";
import { FullPageCarouselFooterProps } from "./types";

export const FullPageCarouselFooter: React.FunctionComponent<FullPageCarouselFooterProps> = ({
  onClickNextStep,
  onClickPrevStep,
  onClickFinishStep,
  currentStep,
  numberOfStep,
  children,
  nextButtonNode,
  prevButtonNode,
  finishButtonNode,
}) => {
  return (
    <div style={fullPageCarouselFooterStyles}>
      <FullPageCarouselFooterButtonText visible={currentStep > 0} onClick={onClickPrevStep}>
        {prevButtonNode}
      </FullPageCarouselFooterButtonText>
      {children}
      {currentStep < numberOfStep - 1 ? (
        <FullPageCarouselFooterButtonText visible onClick={onClickNextStep}>
          {nextButtonNode}
        </FullPageCarouselFooterButtonText>
      ) : (
        <FullPageCarouselFooterButtonText visible onClick={onClickFinishStep}>
          {finishButtonNode}
        </FullPageCarouselFooterButtonText>
      )}
    </div>
  );
};
