import { useState } from "react";

export const useHover = (): {
  isHovered: boolean;
  onMouseOver: () => void;
  onMouseOut: () => void;
} => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver = (): void => {
    setIsHovered(true);
  };

  const onMouseOut = (): void => {
    setIsHovered(false);
  };

  return {
    isHovered,
    onMouseOver,
    onMouseOut,
  };
};
