import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_PALATINATE, GRAY_SHUTTLE } from "../theme/ui-constants";

export const listElement: CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const bullet = (bulletIsGrey: boolean): CSSProperties => ({
  backgroundColor: bulletIsGrey ? GRAY_SHUTTLE : BLUE_PALATINATE,
  width: "5px",
  height: "5px",
  marginRight: spacing(1),
  borderRadius: "50%",
});
