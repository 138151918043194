import { useRef, useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { DateRangePickerProps } from "../types";

export const useDateRangePicker = (props: DateRangePickerProps) => {
  const { defaultValue, onChangeDateValue } = props;
  const calendarRef = useRef<ReactDatePicker>(null);
  const [startDate, setStartDate] = useState<Date | undefined>(props.startDate || defaultValue?.start);
  const [endDate, setEndDate] = useState<Date | undefined>(props.endDate || defaultValue?.end);

  useEffect(() => {
    setStartDate(props.startDate || defaultValue?.start);
  }, [props.startDate, defaultValue?.start]);

  useEffect(() => {
    setEndDate(props.endDate || defaultValue?.end);
  }, [props.endDate, defaultValue?.end]);

  const onChangeDateFromDatePicker = ([newStartDate, newEndDate]: [Date, Date]): void => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    if (newStartDate && newEndDate) {
      onChangeDateValue({ start: newStartDate, end: newEndDate });
      calendarRef.current?.setOpen(false);
    }
  };

  const openDatePicker = (): void => {
    if (!calendarRef.current?.isCalendarOpen()) {
      calendarRef.current?.setOpen(true);
    }
  };

  return {
    startDate,
    endDate,
    calendarRef,
    openDatePicker,
    onChangeDateFromDatePicker,
  };
};
