import * as React from "react";
import { BLUE_MIDNIGHT } from "../theme/ui-constants";

export const TextBlueMidnight: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <span
      style={{
        color: BLUE_MIDNIGHT,
      }}
    >
      {children}
    </span>
  );
};
