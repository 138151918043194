import { Property } from "csstype";
import * as React from "react";

export const LandingText: React.FunctionComponent<{
  fontFamily: string;
  fontSize: string;
  fontWeight?: number;
  color?: string;
  lineHeight?: number;
  textAlign?: Property.TextAlign;
}> = ({ children, fontFamily, fontSize, fontWeight, color, textAlign, lineHeight }) => {
  const defaultLineHeight = lineHeight ?? 1.32;
  const defaultTextAlign = textAlign ?? "left";

  return <span style={{ fontFamily, fontSize, fontWeight, color, lineHeight: defaultLineHeight, textAlign: defaultTextAlign }}>{children}</span>;
};
