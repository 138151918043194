import { get as getCookie, set as setCookie } from "es-cookie";

const APP_DEFAULT_LANGUAGE_NAME = "appDefaultLanguage";
const DEFAULT_LANGUAGE_IF_LOCALE_LANGUAGE_IS_NOT_SUPPORTED: Locale = { label: "app.language.en", value: "en" };

interface Locale {
  label: string;
  value: string;
}

export const listOfAvailableLanguages: Locale[] = [
  { label: "app.language.fr", value: "fr" },
  { label: "app.language.en", value: "en" },
];

export const setAppDefaultLanguage = (language: Locale["value"]): void => {
  setCookie(APP_DEFAULT_LANGUAGE_NAME, language);
};

const getAppDefaultLanguage = (): Locale["value"] | undefined => {
  return getCookie(APP_DEFAULT_LANGUAGE_NAME);
};

const getLocaleValue = (): Locale["value"] => {
  const lang: NavigatorLanguage["language"] = navigator.language;
  const appDefaultLanguage = getAppDefaultLanguage();

  return appDefaultLanguage ?? lang.substr(0, 2);
};

export const getLocale = (): Locale => {
  const localeValue = getLocaleValue();
  const appLanguage = Object.values(listOfAvailableLanguages).find((locale: Locale) => locale.value === localeValue);

  return appLanguage ?? DEFAULT_LANGUAGE_IF_LOCALE_LANGUAGE_IS_NOT_SUPPORTED;
};
