import * as React from "react";
import { FONT_FAMILY_DEFAULT, FONT_REGULAR, FONT_SIZE_DEFAULT } from "../theme/ui-constants";

export const Default: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <span
    style={{
      fontFamily: FONT_FAMILY_DEFAULT,
      fontWeight: FONT_REGULAR,
      fontSize: FONT_SIZE_DEFAULT,
      ...style,
    }}
  >
    {children}
  </span>
);
