import * as React from "react";
import { AlignCenter, VerticalCenter } from "../Layout";
import { MarginSmall } from "../Margin";
import { HorizontalLineMedium } from "./HorizontalLineMedium";

export const HorizontalLinesBetweenNodes: React.FunctionComponent<{
  nodes: React.ReactNode[];
}> = ({ nodes }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `auto repeat(${nodes.length - 1}, 2fr auto)`,
      }}
    >
      {nodes.map((node, index) => (
        <React.Fragment key={index}>
          <AlignCenter>
            <MarginSmall right left>
              {node}
            </MarginSmall>
          </AlignCenter>
          {index < nodes.length - 1 && (
            <VerticalCenter>
              <AlignCenter>
                <HorizontalLineMedium />
              </AlignCenter>
            </VerticalCenter>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
