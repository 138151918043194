import * as React from "react";
import { Avatar } from "../../Avatar/Avatar";
import { Default } from "../../Text/Default";
import { getFormattedAddress } from "./helper";
import {
  searchCompanyAddressStyle,
  searchCompanyAvatarStyle,
  searchCompanyColStyle,
  searchCompanyNameStyle,
  searchCompanyRowOptionStyle,
  searchCompanySiretStyle,
} from "./styles";
import { AddressType, SelectCompanyOptionsType, SelectCompanyType } from "./types";

export const SelectCompanyRow = (
  props: SelectCompanyOptionsType,
  isSelectedValue: boolean,
  renderButton: SelectCompanyType["renderButton"],
  renderTag: SelectCompanyType["renderTag"],
  siretText: SelectCompanyType["siretText"],
  alreadyInLibeoText?: SelectCompanyType["notFoundText"],
): JSX.Element => {
  const formattedAddress = getFormattedAddress((props.knownAddress as unknown) as AddressType);
  const name = props.name ?? "";
  const isLibeoMember = props.isLibeoMember;
  const domainName = props.domainName;
  const siret = props.siret || props.companyNumber;

  return (
    <div id={`select-company-${props.id ? props.id : props.siren}`} style={searchCompanyRowOptionStyle}>
      <div style={searchCompanyAvatarStyle}>
        <Avatar text={name} domainName={domainName} />
      </div>
      <div style={searchCompanyColStyle}>
        {name ? (
          <div id="control-invoice-supplier-name" style={searchCompanyNameStyle}>
            {name}
            {isLibeoMember && <Default>{alreadyInLibeoText}</Default>}
          </div>
        ) : null}
        {formattedAddress && <div style={searchCompanyAddressStyle}>{formattedAddress}</div>}
        {siret && (
          <div style={searchCompanySiretStyle}>
            <div>
              {siretText}
              {siret}
            </div>
          </div>
        )}
        {renderTag && renderTag(props)}
      </div>
      {renderButton && !isSelectedValue && renderButton(props)}
    </div>
  );
};
