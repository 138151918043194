import { Button, Text } from "@libeo/design-system";
import * as React from "react";
import { BackgroundGrey } from "../BackgroundColors";
import { Icon } from "../Icon/Icon";
import { AlignLeft, AlignRight } from "../Layout";
import { MarginMedium } from "../Margin";
import { PaddingBig } from "../Padding";
import { ActionCardProps } from "./types";

export const ActionCard: React.FunctionComponent<ActionCardProps> = ({ title, message, icon, cta, onClick }) => {
  return (
    <BackgroundGrey>
      <PaddingBig>
        <AlignLeft>
          <MarginMedium bottom>
            <Text variation="medium">{title}</Text>
            <Text color="gray-shuttle">{message}</Text>
          </MarginMedium>
        </AlignLeft>
        <AlignRight>
          <Button onPress={onClick} iconBefore={<Icon value={icon} />}>
            {cta}
          </Button>
        </AlignRight>
      </PaddingBig>
    </BackgroundGrey>
  );
};
