export const scalingFactor = 8;
export const createSpacing = (factor?: number): string => `${factor ? Math.floor(scalingFactor * Math.pow(2, factor - 1)) : 0}px`;

/**
 * helper to create consistent spacing between the elements of your UI.
 * accepts up to 4 arguments
 * @example
 * spacing(1); // return 8px
 * @example
 * spacing(1, 2); // return 8px 16px
 * @example
 * spacing(1, 2, 0, 3); // return 8px 16px 0px 32px
 * @example
 * spacing(0.5); // return 4px
 */
export const spacing = (...factors: [number, number?, number?, number?]): string => {
  return factors
    .map((factor) => createSpacing(factor))
    .join(" ")
    .trim();
};
