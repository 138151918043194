import { Loader } from "@libeo/design-system";
import * as React from "react";
import { IconValue } from "../Icon/Icon";
import { ButtonIcon } from "./ButtonIcon";
import { primaryButtonStyles, hoverTextStyles } from "./styles";
import { useButton } from "./useButton.hook";

export const PrimaryButton: React.FunctionComponent<{
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => Promise<void> | void;
  iconValueBefore?: IconValue;
  iconValueAfter?: IconValue;
  width?: React.CSSProperties["width"];
  onHoverText?: string;
  color?: string;
  form?: string;
}> = ({ id, iconValueBefore, iconValueAfter, loading, disabled, onClick, children, width, onHoverText, color, form }) => {
  const { isHovered, stopAndCallOnClick, ...hoverProps } = useButton({ onClick });
  const disabledOnClick = disabled || loading ? undefined : stopAndCallOnClick;

  return (
    <button type="submit" style={primaryButtonStyles(isHovered, { disabled, width })} id={id} onClick={disabledOnClick} form={form} {...hoverProps}>
      {loading && <Loader marginRight={1} variation="secondary" size="small" />}
      <ButtonIcon position="before" loading={loading} iconValue={iconValueBefore} color={color} />
      {onHoverText && <div style={hoverTextStyles(isHovered)}>{onHoverText}</div>}
      {children}
      <ButtonIcon position="after" loading={loading} iconValue={iconValueAfter} color={color} />
    </button>
  );
};
