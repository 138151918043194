import * as React from "react";
import Modal from "react-modal";
import { spacing } from "../theme";
import { DialogClose } from "./DialogClose";
import { DialogFooter } from "./DialogFooter";
import { DialogHead } from "./DialogHead";
import { dialogGrid } from "./styles";
import { DialogPropsType } from "./types";
import { useDialog } from "./useDialog.hook";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const Dialog: React.FunctionComponent<DialogPropsType> = ({
  visible,
  onClose,
  illustration,
  title,
  description,
  children,
  button,
  extraInfo,
  fullPage = false,
  isEmbed = false,
}) => {
  useDialog(Boolean(visible));
  const dialogIncludes = {
    illustration: Boolean(illustration),
    description: Boolean(description),
    content: Boolean(children),
    button: Boolean(button),
    extraInfo: Boolean(extraInfo),
  };

  return (
    <Modal
      isOpen={Boolean(visible)}
      style={{
        content: dialogGrid(dialogIncludes, fullPage, isEmbed),
      }}
    >
      {onClose && <DialogClose onClose={onClose} />}

      <div style={{ gridArea: "dialog-header" }} id="dialog-header">
        <DialogHead illustration={illustration} title={title} description={description} />
      </div>

      {children && (
        <div style={{ gridArea: "dialog-content", height: "100%", overflow: "auto" }} id="dialog-content">
          {fullPage ? (
            <div style={{ paddingTop: spacing(3), height: "100%" }}>{children}</div>
          ) : (
            <div style={{ paddingTop: spacing(3), height: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  whiteSpace: "break-spaces",
                  height: "100%",
                }}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      )}

      {(button || extraInfo) && (
        <div style={{ gridArea: "dialog-footer" }} id="dialog-footer">
          <DialogFooter button={button} extraInfo={extraInfo} />
        </div>
      )}
    </Modal>
  );
};
