import * as React from "react";
import { useFullPageCarouselFooterButtonText } from "./FullPageCarouselFooterButtonText.hook";
import { fullPageCarouselTextInnerStyles, fullPageCarouselTextWrapperStyles } from "./styles";
import { FullPageCarouselFooterButtonProps } from "./types";

export const FullPageCarouselFooterButtonText: React.FunctionComponent<FullPageCarouselFooterButtonProps> = (props) => {
  const { visible, children } = props;
  const { onBeforeClick } = useFullPageCarouselFooterButtonText(props);
  return (
    <div style={fullPageCarouselTextWrapperStyles} onClick={onBeforeClick}>
      <span style={fullPageCarouselTextInnerStyles(visible)}>{children}</span>
    </div>
  );
};
