import { useState, useRef, useEffect, RefObject } from "react";
import { CheckboxProps } from "../types";

export const useCheckbox = (
  props: CheckboxProps,
): {
  isChecked: boolean | undefined;
  checkboxRef: RefObject<HTMLInputElement>;
  onCheck: () => void;
  onClick: (event: React.MouseEvent<HTMLLabelElement>) => void;
} => {
  const { onChangeBooleanValue, defaultChecked, isDisabled: disabled, onClickWhenDisabled, checked } = props;
  const [isChecked, setIsChecked] = useState(props.defaultChecked || checked);
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef && checkboxRef.current) {
      if (typeof defaultChecked === "boolean") {
        // uncontrolled mode
        checkboxRef.current.checked = Boolean(defaultChecked);
        setIsChecked(defaultChecked);
      } else if (typeof checked === "boolean") {
        // controlled mode
        setIsChecked(checked);
      }
    }
  }, [defaultChecked, checkboxRef, checked]);

  function onClick(event: React.MouseEvent<HTMLLabelElement>): void {
    event.stopPropagation();

    if (disabled && onClickWhenDisabled) {
      onClickWhenDisabled();
    }
  }

  function onCheck(): void {
    if (checkboxRef && checkboxRef.current) {
      const toggledIsChecked = Boolean(!isChecked);

      setIsChecked(toggledIsChecked);
      checkboxRef.current.checked = toggledIsChecked;
      if (onChangeBooleanValue) {
        onChangeBooleanValue(toggledIsChecked);
      }
    }
  }

  return {
    isChecked,
    checkboxRef,
    onClick,
    onCheck,
  };
};
