import { Operation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import * as segment from "../../services/segment";
import { getOrganisationId } from "../../utils/organisation";
import { NewApplicationType } from "./types";

export const logDisplayNewReleaseModal = (error: Error, version: NewApplicationType): void => {
  Sentry.captureEvent({
    level: Sentry.Severity.Error,
    message: "Libeo app display new release modal",
    tags: {
      customError: "displayNewReleaseModal",
    },
    extra: {
      version,
      error,
    },
  });
};

export const versionErrorManifestNotFound = logDisplayNewReleaseModal;

export const logRedirectionErrorAppUnavailablePage = (networkError: Error, operation: Operation, version: NewApplicationType): void => {
  const organisationIdFromUrl = getOrganisationId();
  segment.UserRedirectedToAppUnavailablePage(window.location.href, operation.operationName, organisationIdFromUrl);

  Sentry.captureEvent({
    level: Sentry.Severity.Error,
    ...networkError,
    message: "Redirect to Libeo.io website unavailable",
    tags: {
      customError: "redirectAppUnavailable",
    },
    extra: {
      version,
      operation: `${JSON.stringify(operation)}`,
    },
  });
};
