import * as React from "react";
import { Button } from "../Button";
import { Box } from "../Layout";
import { TimelineButtonProps } from "./types";

export const TimelineButton = ({ children, isFolded, ...props }: TimelineButtonProps): React.ReactElement => {
  return (
    <Box position="relative" flexDirection="row" paddingBottom={3}>
      {isFolded ? (
        <Box position="absolute" top="0px" left="3px" height="100%" justifyContent="space-around">
          {Array.from({ length: 6 }, (_, i) => i).map((index) => (
            <Box key={index} width={3} height={3} borderRadius="pill" backgroundColor="gray-athens-1" />
          ))}
        </Box>
      ) : null}
      <Box marginLeft="5px">
        <Button variation="tertiary" {...props}>
          {children}
        </Button>
      </Box>
    </Box>
  );
};
