/* Font */
export const REBRANDING_FONT_HEADLINE = "'GT Pressura', sans-serif";
export const REBRANDING_FONT = "'Inter', sans-serif";
export const REBRANDING_FONT_BOLD = 600;
/* Primary */
export const REBRANDING_GREEN_INK_GREEN = "#005C3D"; /* Primary/Green/Ink Green */
export const REBRANDING_GREEN_INK_GREEN_BLACK_40 = "#0A3522"; /* Primary/Green/Ink Green Black 40% */
export const REBRANDING_GREEN_INK_GREEN_BLACK_20 = "#044C32"; /* Primary/Green/Ink Green Black 20% */
export const REBRANDING_GREEN_INK_GREEN_WHITE_85 = "#D9E6E2"; /* Primary/Green/Ink Green White 85% */
export const REBRANDING_GREEN_INK_GREEN_WHITE_95 = "#F2F7F5"; /* Primary/Green/Ink Green White 95% */
export const REBRANDING_PEN_ORANGE_PEN_ORANGE = "#FF5928"; /* Primary/Pen Orange/Pen Orange */
export const REBRANDING_PEN_ORANGE_PEN_ORANGE_BLACK_40 = "#A13B1B"; /* Primary/Pen Orange/PenOrange Black 40% */
export const REBRANDING_PEN_ORANGE_PEN_ORANGE_BLACK_20 = "#D04A22"; /* Primary/Pen Orange/PenOrange Black 20% */
export const REBRANDING_PEN_ORANGE_PEN_ORANGE_WHITE_85 = "#FFE6DF"; /* Primary/Pen Orange/Pen Orange White 85% */
export const REBRANDING_PEN_ORANGE_PEN_ORANGE_WHITE_95 = "#FFF7F4"; /* Primary/Pen Orange/Pen Orange White 95% */
export const REBRANDING_WHITE_SAND = "#F7F3EA"; /* Primary/White/Sand */
export const REBRANDING_WHITE_PAPER = "#FFFFFF"; /* Primary/White/Paper */
/* Secondary */
export const REBRANDING_SECONDARY_PASTEL_AQUA = "#D1F0EF"; /* Secondary/Pastel Aqua */
export const REBRANDING_SECONDARY_PASTEL_CORAL = "#FFE4CC"; /* Secondary/Pastel Coral */
export const REBRANDING_SECONDARY_PASTEL_VIOLET = "#DAE5FA"; /* Secondary/Pastel Violet */
export const REBRANDING_SECONDARY_PASTEL_PURPLE = "#F5DFF2"; /* Secondary/Paster Purple */
/* Interface */
export const REBRANDING_INTERFACE_UI_GREEN_GREEN = "#1EC887"; /* Interface/UI Green/Green */
export const REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25 = "#1B9967"; /* Interface/UI Green/Green Black 25% */
export const REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_85 = "#D9E6E2"; /* Interface/UI Green/Green White 85% */
export const REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95 = "#E8F9F3"; /* Interface/UI Green/Green White 95% */
export const REBRANDING_INTERFACE_UI_BLUE_BLUE = "#6ED4D4"; /* Interface/UI Blue/Blue */
export const REBRANDING_INTERFACE_UI_BLUE_BLUE_BLACK_25 = "#58A2A1"; /* Interface/UI Blue/Blue Black 25% */
export const REBRANDING_INTERFACE_UI_BLUE_BLUE_WHITE_95 = "#E9F9F9"; /* Interface/UI Blue/Blue White 95% */
export const REBRANDING_INTERFACE_UI_YELLOW_YELLOW = "#FCB750"; /* Interface/UI Yellow/Yellow */
export const REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25 = "#C28C3E"; /* Interface/UI Yellow/Yellow Black 25% */
export const REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95 = "#FFF4E5"; /* Interface/UI Yellow/Yellow White 95% */
export const REBRANDING_INTERFACE_UI_RED_RED = "#EA0B29"; /* Interface/UI Red/Red */
export const REBRANDING_INTERFACE_UI_RED_RED_BLACK_25 = "#B50B21"; /* Interface/UI Red/Red Black 25% */
export const REBRANDING_INTERFACE_UI_RED_RED_WHITE_95 = "#FEF3F4"; /* Interface/UI Red/Red White 95% */
/* Black */
export const REBRANDING_BLACK_100 = "#140D08"; /* Black/100% */
export const REBRANDING_BLACK_60 = "#706961"; /* Black/60% */
export const REBRANDING_BLACK_40 = "#9C968F"; /* Black/40% */
export const REBRANDING_BLACK_20 = "#C9C4BD"; /* Black/20% */
export const REBRANDING_BLACK_10 = "#E0DCD3"; /* Black/10% */
export const REBRANDING_BLACK_5 = "#ECE7DF"; /* Black/5% */
/* Shadow */
export const REBRANDING_SHADOW_PANEL = "0px 1px 2px rgba(0, 0, 0, 0.15)"; /* Black/5% */
export const REBRANDING_SHADOW_OVERLAY = "0px 4px 16px rgba(0, 0, 0, 0.1)"; /* Black/5% */
export const REBRANDING_SHADOW_POPOUT = "0px 8px 24px rgba(0, 0, 0, 0.1)"; /* Black/5% */

/* Overlay */
export const REBRANDING_BACKGROUND_OVERLAY = "rgba(0, 0, 0, 0.6)"; /* Black/60% */
