import * as React from "react";
import { BoxPrimitiveProps, IconPrimitive, IconPrimitiveProps } from "../../primitives";
import { Box } from "../Layout";
import { IconProps } from "./types";

const mapBoxVariationToStyle: Record<IconProps["variation"], BoxPrimitiveProps> = {
  primary: {
    width: "20px",
    height: "20px",
  },
  secondary: {
    width: "20px",
    height: "20px",
  },
  tertiary: {
    width: "20px",
    height: "20px",
  },
  menu: {
    width: "20px",
    height: "20px",
  },
  menuLinkActive: {
    width: "20px",
    height: "20px",
  },
};

const mapIconVariationToStyle: Record<IconProps["variation"], Omit<IconPrimitiveProps, "className">> = {
  primary: {
    color: "blue-palatinate",
    fontSize: "14px",
  },
  secondary: {
    color: "white",
    fontSize: "14px",
  },
  tertiary: {
    color: "blue-vivid-sky",
    fontSize: "14px",
  },
  menu: {
    color: "black",
    fontSize: "14px",
  },
  menuLinkActive: {
    color: "blue-vivid-sky",
    fontSize: "14px",
  },
};

const getIconType = (type: IconProps["type"]): string => {
  switch (type) {
    case "brands":
      return "fab";
    case "duotone":
      return "fad";
    case "light":
      return "fal";
    case "regular":
      return "far";
    case "solid":
      return "fas";
    default: {
      const never: never = type;
      return never;
    }
  }
};

/**
 * `Icon` is a display component used to display an icon.
 */

export const Icon = ({ name = "bell", variation = "primary", type = "brands", ...props }: IconProps): React.ReactElement => {
  return (
    <Box {...mapBoxVariationToStyle[variation]} alignItems="center" justifyContent="center" {...props}>
      <IconPrimitive {...mapIconVariationToStyle[variation]} className={`${getIconType(type)} fa-${name}`} />
    </Box>
  );
};

export type { IconProps };
