import * as React from "react";
import { FullPageCarouselFooterButtonProps } from "./types";

export const useFullPageCarouselFooterButtonText = ({
  onClick,
}: FullPageCarouselFooterButtonProps): {
  onBeforeClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} => {
  function onBeforeClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();
    onClick?.();
  }
  return {
    onBeforeClick,
  };
};
