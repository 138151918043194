import { FrLegalInformation, LegalInformation } from "@libeo/graphql";

export function areLegalInformationFrench(legalInformation: LegalInformation | undefined | null): legalInformation is FrLegalInformation {
  return Boolean(legalInformation && Object.prototype.hasOwnProperty.call(legalInformation, "siren"));
}

export function assertLegalInformationAreFrench(
  legalInformation: LegalInformation | undefined | null,
): asserts legalInformation is FrLegalInformation {
  const hasLegalInformationSiren = legalInformation && Object.prototype.hasOwnProperty.call(legalInformation, "siren");
  if (hasLegalInformationSiren) {
    return;
  }
  throw new TypeError(`Legal informations are not french`);
}
