import * as React from "react";
import { useState } from "react";
import { useHover } from "../../hooks/useHover.hook";
import { ChoiceButtonProp } from "./types";

export const useChoiceButton = (
  props: ChoiceButtonProp,
): {
  isClicked: boolean;
  isLoading: boolean;
  isHovered: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => Promise<void>;
  onMouseOver: () => void;
  onMouseOut: () => void;
} => {
  const { onClick } = props;
  const { isHovered, onMouseOver, onMouseOut } = useHover();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  async function beforeOnClick(event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): Promise<void> {
    if (onClick) {
      setIsClicked(!isClicked);
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(true);
      await onClick(props);
      setIsLoading(false);
    }
  }

  return {
    isClicked,
    isLoading,
    isHovered,
    onClick: beforeOnClick,
    onMouseOver,
    onMouseOut,
  };
};
