import * as React from "react";
import { useHover } from "../hooks/useHover.hook";
import { trStyle } from "./styles";

export const TableTr: React.FunctionComponent<{
  styles?: React.CSSProperties;
  rowHeight?: React.CSSProperties["height"];
  isSelected?: boolean;
  onClick?: () => void;
  hasOnClickRow?: boolean;
}> = ({ children, styles, rowHeight, isSelected = false, hasOnClickRow, onClick }) => {
  const { isHovered, ...hoverProps } = useHover();

  return (
    <tr onClick={onClick} style={trStyle(Boolean(hasOnClickRow) && isHovered, isSelected, rowHeight, styles)} {...hoverProps}>
      {children}
    </tr>
  );
};
