import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_MAASTRICHT, FONT_FAMILY_DEFAULT, FONT_SIZE_BASE, FONT_WEIGHT_NORMAL, SHADOW_PANEL, WHITE } from "../theme/ui-constants";

export const childrenButton: CSSProperties = {
  cursor: "pointer",
};

export const dropdown = (open: boolean): CSSProperties => ({
  fontFamily: FONT_FAMILY_DEFAULT,
  display: "inline-block",
  position: open ? "relative" : "static",
  userSelect: "none",
  width: "inherit",
  transition: "all 0.3s ease",
});

export const dropdownMenu = (isMenuOpen: boolean, menuPosition: "left" | "right", top?: boolean): CSSProperties => {
  return {
    position: "absolute",
    minWidth: "200px",
    zIndex: 10,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: WHITE,
    backgroundClip: "padding-box",
    borderRadius: "2px",
    outline: "none",
    boxShadow: SHADOW_PANEL,
    boxSizing: "border-box",
    color: BLUE_MAASTRICHT,
    fontSize: FONT_SIZE_BASE,
    fontVariant: "tabular-nums",
    lineHeight: "1.5",
    listStyle: "none",
    margin: "4px 0px 0px 0px",
    display: isMenuOpen ? "block" : "none",
    bottom: top ? "100%" : "",
    top: top ? "auto" : "",
    left: menuPosition === "left" ? 0 : "auto",
    right: menuPosition === "right" ? 0 : "auto",
  };
};

export const menuItem: CSSProperties = {
  padding: spacing(2),
  display: "flex",
  alignItems: "center",
  clear: "both",
  margin: spacing(0),
  color: BLUE_MAASTRICHT,
  backgroundColor: WHITE,
  fontWeight: FONT_WEIGHT_NORMAL,
  fontSize: FONT_SIZE_BASE,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  cursor: "pointer",
  transition: "all 0.3s",
};
