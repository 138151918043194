import * as React from "react";
import { Box } from "../Layout";
import { TimelineButton } from "./TimelineButton";
import { TimelineItem as TimelineItemComponent } from "./TimelineItem";
import { TimelineProps, TimelineItemProps, TimelineItem } from "./types";

/**
 * `Timeline` is a display component used to display a series of timed actions and updates.
 */
export const Timeline = ({ items, locale, i18n }: TimelineProps): React.ReactElement => {
  const shouldShowFoldButton = items.length > 2;
  const [isFolded, setIsFolded] = React.useState(shouldShowFoldButton);

  const { 0: firstItem, length, [length - 1]: lastItem } = items;

  const toggleIsFolded = (): void => {
    setIsFolded((isFolded) => !isFolded);
  };

  return (
    <Box>
      {isFolded ? (
        <>
          <TimelineItemComponent
            isFolded={isFolded}
            moreCommentLabel={i18n.moreComment}
            lessCommentLabel={i18n.lessComment}
            isFirstItem
            locale={locale}
            {...firstItem}
          />
          {shouldShowFoldButton && (
            <Box marginTop={1}>
              <TimelineButton isFolded={isFolded} onPress={toggleIsFolded}>{`${i18n.folded} (${items.length - 2})`}</TimelineButton>
            </Box>
          )}
          <TimelineItemComponent isFolded={isFolded} isLastItem locale={locale} {...lastItem} />
        </>
      ) : (
        <>
          {items.map((item, index) => (
            <TimelineItemComponent
              key={item.date.toISOString()}
              isFolded={isFolded}
              isFirstItem={index === 0}
              isLastItem={index === items.length - 1}
              locale={locale}
              moreCommentLabel={i18n.moreComment}
              lessCommentLabel={i18n.lessComment}
              {...item}
            />
          ))}
          {shouldShowFoldButton && (
            <TimelineButton isFolded={isFolded} onPress={toggleIsFolded}>
              {i18n.unfolded}
            </TimelineButton>
          )}
        </>
      )}
    </Box>
  );
};

export type { TimelineProps, TimelineItemProps, TimelineItem };
