import StateManager, { ActionMeta } from "react-select";
import { CreatableProps } from "react-select/creatable";
import { DefaultOptionsType, SelectProps } from "../types";
import { useSelectDefaultValue } from "./useDefaultFormValue.hook";

const isHTMLInputElement = (element: HTMLElement): element is HTMLInputElement => {
  if (((element as unknown) as HTMLInputElement).value) {
    return true;
  }
  return false;
};

export const useSelect = <OptionType extends DefaultOptionsType>(
  props: SelectProps<OptionType>,
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
) => {
  const { onChangeOption, onScrollEnd, defaultValue, value, keepValueOnBlur } = props;
  const { selectRef, selectCreateRef, selectAsyncCreatableRef, defaultInputOption, optionValue, updateOptionValue } = useSelectDefaultValue(
    defaultValue,
  );

  const onChange = (option: OptionType, actionMeta: ActionMeta<OptionType>) => {
    updateOptionValue(option);
    if (onChangeOption) {
      onChangeOption(option, actionMeta);
    }
  };

  const onScrollToBottom = (): void => {
    if (onScrollEnd) {
      onScrollEnd();
    }
  };

  const onCreatableSelectBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (keepValueOnBlur) {
      const creatableSelect = selectCreateRef?.current ?? null;
      if (event.currentTarget && isHTMLInputElement(event.currentTarget)) {
        // creatable select has a ref bug
        // https://github.com/JedWatson/react-select/issues/2181#issuecomment-502894404
        const creatableSelectRef = (creatableSelect?.select as unknown) as StateManager<OptionType>;
        if (creatableSelectRef && creatableSelectRef.props) {
          const createableProps = creatableSelectRef.props as CreatableProps<OptionType>;
          const newOptionData = createableProps.getNewOptionData?.(event.currentTarget.value, event.currentTarget.value) as OptionType;
          if (newOptionData) {
            updateOptionValue(newOptionData, creatableSelectRef.select);
            creatableSelectRef.select.setValue(newOptionData, "select-option");
          } else {
            updateOptionValue(undefined, creatableSelectRef.select);
          }
        }
      }
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  return {
    selectRef,
    selectCreateRef,
    selectAsyncCreatableRef,
    defaultInputOption,
    value,
    optionValue,
    onChange,
    onCreatableSelectBlur,
    onScrollToBottom,
  };
};
