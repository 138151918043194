import * as React from "react";
import { Box, BoxProps } from "../Layout";
import { ColumnProps } from "./types";
import { mapAlignToAlignItems } from "./utils";

type TableCellProps<TableItem extends object> = Pick<ColumnProps<TableItem>, "align" | "width"> &
  BoxProps & {
    children: React.ReactNode;
    hasBorder?: boolean;
    isFirstColumn?: boolean;
    isLastColumn?: boolean;
    colSpan?: number;
    onClick?: (event: React.MouseEvent) => void;
  };

export const TableCell = <TableItem extends object>(props: TableCellProps<TableItem>): React.ReactElement => {
  const { children, align, width, hasBorder, isFirstColumn, isLastColumn, colSpan, onClick } = props;

  return (
    <Box as="td" display="table-cell" verticalAlign="middle" width={width} colSpan={colSpan} borderBottomWidth={hasBorder ? 1 : 0} onClick={onClick}>
      <Box
        height="100%"
        paddingLeft={isFirstColumn ? 3 : 2}
        paddingRight={isLastColumn ? 3 : 2}
        justifyContent="center"
        alignItems={mapAlignToAlignItems(align)}
        cursor={onClick ? "pointer" : undefined}
      >
        {children}
      </Box>
    </Box>
  );
};
