export interface DialogButtonProps {
  display?: DialogButtonDisplay;
  primaryButton: React.ReactNode;
  secondaryButton?: React.ReactNode;
}

export interface DialogPropsType {
  visible?: boolean;
  onClose?: () => void;
  title: React.ReactNode;
  illustration?: React.ReactNode;
  description?: React.ReactNode;
  button?: DialogButtonProps;
  extraInfo?: React.ReactNode;
  fullPage?: boolean;
  isEmbed?: boolean;
}

export type ButtonProps = {
  id?: string;
  onClick?: () => void;
  loading?: boolean;
  message: React.ReactNode;
  disabled?: boolean;
  href?: string;
};

export enum DialogButtonDisplay {
  CENTER,
  VERTICAL_CENTER,
  RIGHT,
  SEPARATE,
}
