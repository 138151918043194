import * as React from "react";

const CARD_ELEVATION_HOVER = 2;

export const CardElevation: React.FunctionComponent<{
  style?: React.CSSProperties;
  id?: string;
  isHovered?: boolean;
}> = ({ id, children, style, isHovered }) => {
  return (
    <div
      id={id}
      style={{
        paddingTop: `${CARD_ELEVATION_HOVER}px`,
        transform: isHovered ? `translate(0, -${CARD_ELEVATION_HOVER}px)` : "translate(0, 0)",
        transition: "all 0.2s ease-out",
        height: "100%",
        position: "relative",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
