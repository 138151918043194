import * as React from "react";
import useOnClickOutside from "use-onclickoutside";
import { useToggle } from "../hooks/useToggle.hook";
import * as styles from "./styles";

export type MenuItem = {
  item: React.ReactNode;
  onClick: () => void;
  key: string;
};

export const MenuOnClick: React.FunctionComponent<{
  menuItems: MenuItem[];
}> = ({ children, menuItems }) => {
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const toggleRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(toggleRef, () => {
    if (isMenuOpen) {
      toggleMenu();
    }
  });

  return (
    <div ref={toggleRef} style={styles.dropdown(isMenuOpen)} onClick={toggleMenu}>
      <div style={styles.childrenButton}>{children}</div>

      <ul style={styles.dropdownMenu(isMenuOpen, "right", false)}>
        {menuItems.map((menuItem) => (
          <li onClick={menuItem.onClick} key={menuItem.key} style={styles.menuItem}>
            {menuItem.item}
          </li>
        ))}
      </ul>
    </div>
  );
};
