import { isBusinessDay } from "../date-compute-business-days/is-business-day";
import { subtractNBusinessDaysToDate } from "../date-compute-business-days/substract-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../date-without-time/date-without-time";

export const getCloserBusinessDate = (paymentAtDate: DateWithoutTime): DateWithoutTime => {
  if (isBusinessDay(paymentAtDate)) {
    return paymentAtDate;
  }
  return getCloserBusinessDate(formatDateToDateWithoutTime(subtractNBusinessDaysToDate(new Date(paymentAtDate), 1)));
};
