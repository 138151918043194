import * as React from "react";
import { useHover } from "../../hooks";
import { InputProps } from "../types";
import { InputBorder } from "./InputBorder";
import { InputDescription } from "./InputDescription";
import { InputLabel } from "./InputLabel";
import { InputMessage } from "./InputMessage";
import { InputSpacer } from "./InputSpacer";
import { InputSuffix } from "./InputSuffix";
import * as styles from "./styles";

export const FormItemWrapper: React.FunctionComponent<{
  color?: string;
  label?: InputProps["label"];
  required?: InputProps["required"];
  inputFocus?: boolean;
  inputHasValue?: boolean;
  suffix?: InputProps["suffix"];
  message?: InputProps["message"];
  description?: InputProps["description"];
  onClickSuffix?: () => void | Promise<void>;
  noBorder?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({
  style,
  color,
  disabled = false,
  description,
  label,
  required,
  inputFocus = false,
  suffix,
  message,
  inputHasValue = false,
  children,
  noBorder,
  onClickSuffix,
}) => {
  const { isHovered, ...hoverProps } = useHover();

  return (
    <>
      {description && <InputDescription>{description}</InputDescription>}
      <InputSpacer style={style}>
        {noBorder ? (
          <div style={styles.inputWrapper} {...hoverProps}>
            {label && (
              <InputLabel required={required} hasFocus={inputFocus} hasValue={inputHasValue}>
                {label}
              </InputLabel>
            )}
            {children}
            {suffix && <InputSuffix onClick={onClickSuffix}>{suffix}</InputSuffix>}
            {message && <InputMessage color={color}>{message}</InputMessage>}
          </div>
        ) : (
          <div style={styles.inputWrapper} {...hoverProps}>
            <InputBorder disabled={disabled} borderColor={color} borderActive={isHovered || inputFocus}>
              {label && (
                <InputLabel required={required} hasFocus={inputFocus} hasValue={inputHasValue}>
                  {label}
                </InputLabel>
              )}
              {children}
              {suffix && <InputSuffix onClick={onClickSuffix}>{suffix}</InputSuffix>}
            </InputBorder>
            {message && <InputMessage color={color}>{message}</InputMessage>}
          </div>
        )}
      </InputSpacer>
    </>
  );
};
