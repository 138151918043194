import { addNBusinessDaysToDate } from "../../date-compute-business-days/add-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";
import { getTriggeringDateAutoload } from "../triggering-date/get-triggering-date-autoload";

export const getCollectionDateAutoload = (payload: PaymentDatesPayload): DateWithoutTime | undefined => {
  const { payinCreationDate } = payload;

  if (payinCreationDate) {
    const collectionDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payinCreationDate), 2));
    return getCloserBusinessDate(collectionDate);
  }

  const collectionDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(getTriggeringDateAutoload(payload)), 2));
  return getCloserBusinessDate(collectionDate);
};
