import { capitalize } from "@libeo/utils";
import * as React from "react";
import { AvatarProps, AvatarSizeValues } from "../Avatar/type";
import { Icon, IconValue } from "../Icon/Icon";
import { TextWrapperWithIcon } from "../Text";
import { CardAvatar } from "./CardAvatar";
import { CardBigTitle } from "./CardBigTitle";
import { CardChildren } from "./CardChildren";
import { CardContent } from "./CardContent";
import { CardDeleteIcon } from "./CardDeleteIcon";
import { CardEditIcon } from "./CardEditIcon";
import { CardElevation } from "./CardElevation";
import { CardEvent } from "./CardEvent";
import { CardSubtitle } from "./CardSubtitle";
import { CardText } from "./CardText";
import { CardTitle } from "./CardTitle";
import { CardWrapper } from "./CardWrapper";

export const CardAvatarAndContent: React.FunctionComponent<{
  bigTitle?: string;
  title?: string;
  subtitle?: string;
  alignItems?: string;
  avatarBackgroundColor?: AvatarProps["backgroundColor"];
  avatarText?: AvatarProps["text"];
  avatarIcon?: AvatarProps["icon"];
  avatarDomainName?: AvatarProps["domainName"];
  complementaryInformations?: {
    text: React.ReactNode;
    iconValue?: IconValue;
  }[];
  editable?: boolean;
  onEdit?: () => void | Promise<void>;
  deletable?: boolean;
  onDelete?: () => void | Promise<void>;
  onClick?: () => void | Promise<void>;
  minHeight?: React.CSSProperties["minHeight"];
}> = ({
  bigTitle,
  title,
  subtitle,
  alignItems,
  avatarText,
  avatarDomainName,
  avatarIcon,
  complementaryInformations,
  children,
  editable,
  onEdit,
  deletable,
  onDelete,
  onClick,
  avatarBackgroundColor,
  minHeight,
}) => {
  async function onBeforeClick(event: React.MouseEvent<HTMLDivElement>) {
    if (onClick) {
      event.stopPropagation();
      event.preventDefault();
      await onClick();
    }
  }
  return (
    <CardEvent onClick={onBeforeClick} style={{ cursor: "pointer" }}>
      {({ isHovered }): React.ReactNode => (
        <CardElevation isHovered={isHovered}>
          <CardAvatar backgroundColor={avatarBackgroundColor} text={avatarText} icon={avatarIcon} domainName={avatarDomainName}>
            <CardWrapper style={{ display: "flex", minWidth: "150px", minHeight: minHeight }} hover={isHovered}>
              <CardContent>
                <div style={{ height: AvatarSizeValues.BIG / 2, width: "100%" }} />
                {editable && <CardEditIcon onClick={onEdit} visible={isHovered} />}
                {deletable && <CardDeleteIcon onClick={onDelete} visible={isHovered} />}
                {bigTitle && <CardBigTitle textAlign="center">{bigTitle}</CardBigTitle>}
                {title && <CardTitle textAlign="center">{capitalize(title)}</CardTitle>}
                {subtitle && <CardSubtitle textAlign="center">{subtitle}</CardSubtitle>}
                {complementaryInformations?.map((information, index) => {
                  if (information.iconValue) {
                    return information.text ? (
                      <CardText key={`information-${information.text}-${index}`}>
                        <TextWrapperWithIcon iconLeft={<Icon width={15} height={15} value={information.iconValue} />}>
                          {information.text}
                        </TextWrapperWithIcon>
                      </CardText>
                    ) : null;
                  }
                  return information.text ? <CardText key={`information-${information.text}-${index}`}>{information.text}</CardText> : null;
                })}
                {children && <CardChildren alignItems={alignItems}>{children}</CardChildren>}
              </CardContent>
            </CardWrapper>
          </CardAvatar>
        </CardElevation>
      )}
    </CardEvent>
  );
};
