import * as React from "react";
import { FormattedMessage } from "react-intl";
import { renderTitle } from "./styles";

export const renderHeaderTableComponent = (label: string) => {
  return <FormattedMessage id={label} />;
};

export const renderColumnTitle = (id: string) => (
  <div style={renderTitle}>
    <FormattedMessage id={id} />
  </div>
);
