import { Action, Location } from "history";
import { useEffect } from "react";
import { useLocation } from "react-router";
import history from "../store/history";

export const useHistoryListener = (onNavigate?: (location: Location, action?: Action) => void): void => {
  const routerLocation = useLocation();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      onNavigate?.(location, action);
    });
    onNavigate?.(routerLocation);
    return function cleanup(): void {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
