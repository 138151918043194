import * as React from "react";
import { WHITE, GRAY_ATHENS_2 } from "../theme/ui-constants";

export const PageFooter: React.FunctionComponent<{
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  noBorder?: true;
}> = ({ leftChildren, rightChildren, noBorder }) => {
  return (
    <div
      id="page-footer"
      style={{
        height: 60,
        background: noBorder ? "inherit" : WHITE,
        borderTop: noBorder ? "none" : `1px solid ${GRAY_ATHENS_2}`,
        zIndex: 10,
        padding: "0 15px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        width: "100vw",
        left: 0,
      }}
    >
      <div
        style={{
          flex: 1,
          paddingLeft: 235,
        }}
      >
        {leftChildren}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {rightChildren}
      </div>
    </div>
  );
};
