import * as React from "react";
import { useHover } from "../hooks/useHover.hook";
import { FONT_FAMILY_DEFAULT } from "../theme/ui-constants";

export const Bold: React.FunctionComponent<{
  style?: React.CSSProperties;
  color?: React.CSSProperties["color"];
  boldOnHoverOnly?: boolean;
}> = ({ children, style, boldOnHoverOnly, color }) => {
  const { isHovered, ...hoverProps } = useHover();
  const isBold = !boldOnHoverOnly || (boldOnHoverOnly && isHovered) ? true : false;

  return (
    <span
      style={{
        fontFamily: FONT_FAMILY_DEFAULT,
        color: color ? color : undefined,
        fontWeight: isBold ? "bold" : "normal",
        margin: 0,
        padding: 0,
        ...style,
      }}
      {...hoverProps}
    >
      {children}
    </span>
  );
};
