import { DateWithoutTime, getMaxDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getTriggeringFintecture = (
  payload: Pick<
    PaymentDatesPayload,
    "paymentStrategy" | "paymentAtDate" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate" | "clickToPayDate"
  >,
): DateWithoutTime => {
  const { paymentAtDate, clickToPayDate } = payload;

  return getCloserBusinessDate(clickToPayDate ? getMaxDateWithoutTime(clickToPayDate, paymentAtDate) : paymentAtDate);
};
