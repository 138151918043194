import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { GREEN_CARABBEAN } from "../theme/ui-constants";
import { checklistItemStyle } from "./styles";
import { ChecklistItemProps } from "./types";

export const ChecklistItem: React.FunctionComponent<ChecklistItemProps> = ({ children, disable = false }) => {
  return (
    <div style={checklistItemStyle(disable)}>
      {disable ? <span /> : <Icon width={17} height={17} color={GREEN_CARABBEAN} value={IconValue.Checkmark} />}
      {children}
    </div>
  );
};
