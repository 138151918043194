import { Heading, HeadingProps } from "@libeo/design-system";
import * as React from "react";

export const CardBigTitle: React.FunctionComponent<{
  id?: string;
  textAlign?: HeadingProps["textAlign"];
}> = ({ children, id, textAlign }) => (
  <Heading id={id} size="m" textAlign={textAlign}>
    {children}
  </Heading>
);
