import { Button, ThemeProvider } from "@libeo/design-system";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Dialog } from "../Dialog/Dialog";
import { Provider } from "./context";
import { ConfirmDialogType } from "./types";

const confirmRoot = document.querySelector("body");
let resolve: (confirm: boolean) => void;
const onCancel = (): void => {
  resolve?.(false);
};

const onConfirm = (): void => {
  resolve?.(true);
};

const setResolvePromise = (): Promise<boolean> => {
  return new Promise((res: (confirm: boolean) => void) => {
    resolve = res;
  });
};

const ConfirmProvider: React.FunctionComponent = ({ children }) => {
  const ask = async (options: ConfirmDialogType): Promise<boolean> => {
    if (confirmRoot) {
      const node = document.createElement("div");
      node.id = options.id;
      confirmRoot.appendChild(node);
      ReactDOM.render(
        <ThemeProvider>
          <Dialog
            visible
            illustration={options.illustration}
            title={options.title}
            onClose={onCancel}
            button={{ primaryButton: <Button onPress={onConfirm}>{options.confirmText}</Button> }}
            extraInfo={options.extraInfo}
          >
            {options.content}
          </Dialog>
        </ThemeProvider>,
        node,
      );

      const shouldConfirm = await setResolvePromise();

      if (node) {
        ReactDOM.unmountComponentAtNode(node);
        confirmRoot.removeChild(node);
      }
      return shouldConfirm;
    }
    return false;
  };

  const confirmDialog = {
    ask,
  };

  return <Provider value={{ confirmDialog }}>{children}</Provider>;
};

export default ConfirmProvider;
