import { MeQuery, Organisation } from "@libeo/graphql";
import { NotificationLevel, NotificationProps, useNotification as useNotificationContext } from "@libs/ui";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Notification, NotificationName } from "../components/Notification/constants";
import { alertNotificationClicked, alertNotificationViewed } from "../services/segment";
import { shouldDisplayNotification } from "../utils/notification/notification.helper";
import { useHistoryListener } from "./useHistoryListener";
import { useCurrentUser } from "./useMe.hook";

const dispatchEventOnAlertCtaClick = (notificationName: NotificationName, currentUser: MeQuery["me"]): void => {
  if (currentUser && currentUser.organisation) {
    alertNotificationClicked(currentUser.id, currentUser.organisation.id, notificationName);
  }
};

const dispatchEventOnAlertViewed = (notificationName: NotificationName, currentUser: MeQuery["me"]): void => {
  if (currentUser && currentUser.organisation) {
    alertNotificationViewed(currentUser.id, currentUser.organisation.id, notificationName);
  }
};

const NotificationToAlertPropsMapper = (level: NotificationLevel, currentUser: MeQuery["me"], notification: Notification): NotificationProps => {
  return {
    level: level,
    name: notification.name,
    title: notification.title,
    message: notification.message,
    messageValues: notification.messageValues,
    cta: notification.cta,
    ctaIcon: notification.ctaIcon,
    ctaColor: notification.ctaColor,
    onClick: (args?: unknown): void => {
      if (notification.name) {
        dispatchEventOnAlertCtaClick(notification.name, currentUser);
      }
      if (notification.onClick) {
        notification.onClick(args);
      }
    },
  };
};

export const useResetNotification = (): void => {
  const { reset } = useNotificationContext();

  const onNavigate = (): void => {
    reset();
  };
  useHistoryListener(onNavigate);
};

export const useNotification = (): {
  error: (config: Notification) => void;
  success: (config: Notification) => void;
  warning: (config: Notification) => void;
  info: (config: Notification) => void;
  emailSent: (config: Notification) => void;
} => {
  const { add } = useNotificationContext();
  const { formatMessage } = useIntl();
  const currentUser = useCurrentUser();
  const { organisationId } = useParams<{ organisationId: Organisation["id"] }>();

  const formatNotification = (option: Notification): void => {
    if (option.name) {
      dispatchEventOnAlertViewed(option.name, currentUser);
    }
    if (option.messageValues) {
      option.message = formatMessage({ id: option.message }, option.messageValues);
    } else {
      option.message = formatMessage({ id: option.message });
    }
  };

  const error = (option: Notification): void => {
    if (shouldDisplayNotification(organisationId, option)) {
      formatNotification(option);
      add(NotificationToAlertPropsMapper(NotificationLevel.ERROR, currentUser, option));
    }
  };

  const success = (option: Notification): void => {
    if (shouldDisplayNotification(organisationId, option)) {
      formatNotification(option);
      add(NotificationToAlertPropsMapper(NotificationLevel.SUCCESS, currentUser, option));
    }
  };

  const warning = (option: Notification): void => {
    if (shouldDisplayNotification(organisationId, option)) {
      formatNotification(option);
      add(NotificationToAlertPropsMapper(NotificationLevel.WARNING, currentUser, option));
    }
  };

  const info = (option: Notification): void => {
    if (shouldDisplayNotification(organisationId, option)) {
      formatNotification(option);
      add(NotificationToAlertPropsMapper(NotificationLevel.INFO, currentUser, option));
    }
  };

  const emailSent = (option: Notification): void => {
    if (shouldDisplayNotification(organisationId, option)) {
      formatNotification(option);
      add(NotificationToAlertPropsMapper(NotificationLevel.EMAIL, currentUser, option));
    }
  };

  return {
    error,
    success,
    warning,
    info,
    emailSent,
  };
};
