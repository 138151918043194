import * as React from "react";
import { useIntl } from "react-intl";
import { Icon } from "../../Icon/Icon";
import { buttonCallToAction } from "../styles/Banner.styles";
import { BannerCallToActionProps } from "../types/Banner.types";

export const BannerCallToAction: React.FunctionComponent<BannerCallToActionProps> = ({ color, cta, onClick, icon }) => {
  const { formatMessage } = useIntl();

  if (!cta) {
    return null;
  }

  return (
    <span style={buttonCallToAction(color)} onClick={onClick}>
      {formatMessage({ id: cta })}
      {icon && <Icon color={color} width="15px" height="15px" value={icon} />}
    </span>
  );
};
