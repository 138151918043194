import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { AlignRight } from "../Layout";
import { PaddingBig, PaddingMedium } from "../Padding";
import { spacing } from "../theme/spacing";
import { BLUE_PALATINATE } from "../theme/ui-constants";

export const DialogClose: React.FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div style={{ gridArea: "dialog-close" }} id="dialog-close" onClick={onClose}>
      <PaddingMedium top right>
        <PaddingBig left>
          <AlignRight>
            <Icon noMargin style={{ cursor: "pointer" }} height={spacing(2)} width={spacing(2)} value={IconValue.Cross} color={BLUE_PALATINATE} />
          </AlignRight>
        </PaddingBig>
      </PaddingMedium>
    </div>
  );
};
