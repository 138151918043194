import * as React from "react";
import { Icon, IconValue } from "../../Icon/Icon";
import { BLACK } from "../../theme/ui-constants";
import { buttonClose, ICON_SIZE } from "../styles/Banner.styles";
import { BannerCloseProps } from "../types/Banner.types";

export const BannerClose: React.FunctionComponent<BannerCloseProps> = ({ onClick }) => {
  return (
    <div onClick={onClick} style={buttonClose}>
      <Icon noMargin color={BLACK} width={ICON_SIZE} height={ICON_SIZE} value={IconValue.Cross} />
    </div>
  );
};
