import * as React from "react";
import { ORANGE_CORAL } from "../theme/ui-constants";

export const TextOrangeCoral: React.FunctionComponent<{ id?: string; children?: React.ReactNode }> = ({ id, children }) => {
  return (
    <span
      id={id}
      style={{
        color: ORANGE_CORAL,
      }}
    >
      {children}
    </span>
  );
};
