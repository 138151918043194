import { Box } from "@libeo/design-system";
import { Property } from "csstype";
import * as React from "react";
import { MessageDescriptor } from "react-intl";
import { LandingButton } from "../LandingButton";
import { LandingFormattedText } from "../LandingFormattedText";
import { QUICKBOOKS_FONT, QUICKBOOKS_FONT_SIZE_REGULAR, QUICKBOOKS_GREEN, QUICKBOOKS_GREEN_01, QUICKBOOKS_WHITE } from "./qbo-constants";

export const QuickbooksButton: React.FunctionComponent<{
  contentId: MessageDescriptor["id"];
  onClick: () => void;
  backgroundColor?: string;
  hoverColor?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
  justifyContent?: Property.JustifyContent;
  borderRadius?: string;
  padding?: string;
}> = ({ contentId, onClick, backgroundColor, hoverColor, textColor, fontSize, fontWeight, justifyContent, borderRadius, padding }) => {
  const defaultBackgroundColor = backgroundColor ?? QUICKBOOKS_GREEN;
  const defaultHoverColor = hoverColor ?? QUICKBOOKS_GREEN_01;
  const defaultTextColor = textColor ?? QUICKBOOKS_WHITE;
  const defaultFontSize = fontSize ?? QUICKBOOKS_FONT_SIZE_REGULAR;
  const defaultJustifyContent = justifyContent ?? "center";
  const defaultBorderRadius = borderRadius ?? "100px";
  const defaultPadding = padding ?? "10px 16px";

  return (
    <Box flexDirection="row" justifyContent={defaultJustifyContent}>
      <LandingButton
        onClick={onClick}
        backgroundColor={defaultBackgroundColor}
        hoverColor={defaultHoverColor}
        borderRadius={defaultBorderRadius}
        padding={defaultPadding}
      >
        <LandingFormattedText
          contentId={contentId}
          fontFamily={QUICKBOOKS_FONT}
          fontSize={defaultFontSize}
          fontWeight={fontWeight}
          textColor={defaultTextColor}
        />
      </LandingButton>
    </Box>
  );
};
