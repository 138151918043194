import { DateWithoutTime } from "../../date-without-time/date-without-time";
import { PaymentDatesPayload, PaymentStrategy } from "../payment-date.types";
import { getCollectionDateAutoload } from "./get-collection-date-autoload";
import { getCollectionDateFastpay } from "./get-collection-date-fastpay";
import { getCollectionDateFintecture } from "./get-collection-date-fintecture";
import { getCollectionDateTopup } from "./get-collection-date-topup";

export const getCollectionDate = (payload: PaymentDatesPayload): DateWithoutTime | undefined => {
  const { paymentStrategy } = payload;

  const paymentStrategyMapper: {
    [key in PaymentStrategy]: (payload: PaymentDatesPayload) => ReturnType<typeof getCollectionDate>;
  } = {
    [PaymentStrategy.TREEZOR_AUTOLOAD]: getCollectionDateAutoload,
    [PaymentStrategy.TREEZOR_TOPUP]: getCollectionDateTopup,
    [PaymentStrategy.TREEZOR_FAST_PAYMENT]: getCollectionDateFastpay,
    [PaymentStrategy.FINTECTURE]: getCollectionDateFintecture,
  };

  return paymentStrategyMapper[paymentStrategy](payload);
};
