import * as React from "react";
import { Icon } from "../Icon/Icon";
import { IconValue } from "../Icon/icon.type";
import { spacing } from "../theme/spacing";
import { PINK_PICTORIAL_CARMINE } from "../theme/ui-constants";

export const CardDeleteIcon: React.FunctionComponent<{
  visible?: boolean;
  onClick?: () => void;
}> = ({ visible, onClick }) => {
  const onBeforeClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      event.stopPropagation();
      event.preventDefault();
      onClick();
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        right: spacing(1),
        top: spacing(1),
        zIndex: 1,
        opacity: visible ? 1 : 0,
        cursor: "pointer",
      }}
      onClick={visible ? onBeforeClick : undefined}
    >
      <Icon value={IconValue.Trash} color={PINK_PICTORIAL_CARMINE} width="15px" height="15px"></Icon>
    </div>
  );
};
