import { useState, useCallback, useEffect } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { UploadProps } from "../types";

export const useUpload = (props: UploadProps) => {
  const { onChangeFileValue, defaultValue, accept, maxSize } = props;
  const [base64, setBase64] = useState<string | undefined>(defaultValue);

  useEffect(() => {
    setBase64(defaultValue);
  }, [defaultValue, setBase64]);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      props.onRejectFile?.(fileRejections);
    }
    const file = acceptedFiles && acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (onChangeFileValue) {
          onChangeFileValue(file);
        }
        setBase64(reader.result as string);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxSize });

  const onRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChangeFileValue) {
      onChangeFileValue(undefined);
    }
    setBase64(undefined);
  };

  return {
    base64,
    setBase64,
    getRootProps,
    getInputProps,
    isDragActive,
    onRemove,
  };
};
