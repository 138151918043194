import * as React from "react";
import { Box } from "../Layout";
import { Loader } from "../Loader";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { TableProps } from "./types";

export * from "./types";

export const Table = <TableItem extends object>(props: TableProps<TableItem>): React.ReactElement => {
  const {
    id,
    columns,
    data,
    isInline,
    isSticky,
    onClickRow,
    onSortChange,
    sortFieldName,
    sortDirection,
    isLoading,
    isActiveRow,
    hasRowSelection,
    onUpdateRowSelection,
    selectedRowKeys,
    rowCanBeSelected,
    rowDisabledReason,
    renderPanel,
  } = props;

  if (isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      as={(props: React.HTMLAttributes<HTMLTableElement>): React.ReactElement => (
        <table
          {...props}
          style={{
            boxShadow: !isInline ? "0px 1px 2px 0px rgba(0, 0, 0, 0.15)" : undefined,
            borderSpacing: "0px",
            borderCollapse: "separate",
            tableLayout: "fixed",
          }}
        />
      )}
      id={id}
      display="table"
      width="100%"
      backgroundColor="white"
      borderRadius={isInline ? "large" : "small"}
      overflow={isInline ? "hidden" : undefined}
      borderWidth={isInline ? 1 : 0}
    >
      <TableHeader
        columns={columns}
        data={data}
        isInline={isInline}
        isSticky={isSticky}
        onSortChange={onSortChange}
        sortFieldName={sortFieldName}
        sortDirection={sortDirection}
        hasRowSelection={hasRowSelection}
        onUpdateRowSelection={onUpdateRowSelection}
        selectedRowKeys={selectedRowKeys}
      />
      <TableBody
        columns={columns}
        data={data}
        isInline={isInline}
        onClickRow={onClickRow}
        hasRowSelection={hasRowSelection}
        onUpdateRowSelection={onUpdateRowSelection}
        selectedRowKeys={selectedRowKeys}
        rowCanBeSelected={rowCanBeSelected}
        rowDisabledReason={rowDisabledReason}
        renderPanel={renderPanel}
        isActiveRow={isActiveRow}
      />
    </Box>
  );
};
