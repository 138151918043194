import { Box } from "@libeo/design-system";
import * as React from "react";
import { MessageDescriptor } from "react-intl";
import { LandingFormattedText } from "./LandingFormattedText";

export const LandingVerticalCard: React.FunctionComponent<{
  contentId: MessageDescriptor["id"];
  imageSrc: string;
  imageWidth: string;
  fontFamily: string;
  fontSize: string;
  textColor: string;
  stressColor: string;
  lineHeight?: number;
}> = ({ contentId, imageSrc, imageWidth, fontFamily, fontSize, textColor, stressColor, lineHeight }) => {
  return (
    <Box alignItems="center">
      <Box marginBottom="20px">
        <img width={imageWidth} alt="" src={imageSrc} />
      </Box>
      <Box>
        <LandingFormattedText
          contentId={contentId}
          fontFamily={fontFamily}
          fontSize={fontSize}
          textColor={textColor}
          stressColor={stressColor}
          lineHeight={lineHeight}
          textAlign="center"
        />
      </Box>
    </Box>
  );
};
