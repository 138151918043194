const LAZY_CAUGHT_ERROR_DURING_SESSION_STORAGE_KEY = "lazyCatchedErrorDuringSession";

const getLazyCaughtErrorDuringSession = (): boolean => {
  try {
    return sessionStorage.getItem(LAZY_CAUGHT_ERROR_DURING_SESSION_STORAGE_KEY) === "true";
  } catch (e) {
    return true;
  }
};

const hasLazyCaughtErrorDuringSession = getLazyCaughtErrorDuringSession();

export function lazyCatchError<T>(fn: () => Promise<{ default: T }>): Promise<{ default: T }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: unknown) => {
        if (hasLazyCaughtErrorDuringSession) {
          const errorMessage = `lazyCatchError: NETWORK STATUS ONLINE: ${navigator.onLine ? "true" : "false"}\n${error}`;
          reject(errorMessage);
          throw new Error(errorMessage);
        } else {
          sessionStorage.setItem(LAZY_CAUGHT_ERROR_DURING_SESSION_STORAGE_KEY, "true");
          window.location.reload(true);
        }
      });
  });
}
