import * as React from "react";
import { Box } from "../Layout";
import { Text } from "../Typography";
import { PillProps } from "./types";

const getLabel = (count: number, overflowCount: number): string => {
  return count > overflowCount ? `+${overflowCount}` : `${count}`;
};

/**
 * `Pill` is a display component used to display a number.
 */
export const Pill = ({ count, overflowCount = 99, ...props }: PillProps): React.ReactElement => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      backgroundColor="orange-coquelicot"
      paddingX={1}
      borderRadius="pill"
      minWidth={16}
      flexShrink={0}
      {...props}
    >
      <Text size="small" color="white">
        {getLabel(count, overflowCount)}
      </Text>
    </Box>
  );
};

export type { PillProps };
