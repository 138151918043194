import { Property } from "csstype";
import * as React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { LandingText } from "./LandingText";

export const LandingFormattedText: React.FunctionComponent<{
  contentId: MessageDescriptor["id"];
  values?: Record<string, React.ReactNode>;
  fontFamily: string;
  fontSize: string;
  fontWeight?: number;
  textColor?: string;
  stressColor?: string;
  lineHeight?: number;
  textAlign?: Property.TextAlign;
}> = ({ contentId, values, fontFamily, fontSize, fontWeight, textColor, stressColor, lineHeight, textAlign }) => {
  const defaultFontWeight = fontWeight ?? 700;

  return (
    <FormattedMessage
      id={contentId}
      values={{
        ...values,
        br: function br(...chunks: string[]): JSX.Element {
          return (
            <div>
              <LandingText
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                color={textColor}
                lineHeight={lineHeight}
                textAlign={textAlign}
              >
                {chunks}
              </LandingText>
            </div>
          );
        },
        b: function b(...chunks: string[]): JSX.Element {
          return (
            <LandingText
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={defaultFontWeight}
              color={textColor}
              lineHeight={lineHeight}
              textAlign={textAlign}
            >
              {chunks}
            </LandingText>
          );
        },
        c: function c(...chunks: string[]): JSX.Element {
          return (
            <LandingText
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              color={stressColor}
              lineHeight={lineHeight}
              textAlign={textAlign}
            >
              {chunks}
            </LandingText>
          );
        },
      }}
    >
      {(txt): React.ReactNode => (
        <LandingText
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={textColor}
          lineHeight={lineHeight}
          textAlign={textAlign}
        >
          {txt}
        </LandingText>
      )}
    </FormattedMessage>
  );
};
