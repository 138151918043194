import * as React from "react";
import { Box } from "../Layout";

interface TableRowProps {
  children: React.ReactNode;
  height?: number;
  onClick?: () => void;
  isActive?: boolean;
}

export const TableRow = (props: TableRowProps): React.ReactElement => {
  const { children, height, onClick, isActive = false } = props;

  return (
    <Box
      as="tr"
      display="table-row"
      height={height}
      onClick={onClick}
      cursor={onClick ? "pointer" : undefined}
      backgroundColor={isActive ? "gray-athens-1" : "inherit"}
      _hover={onClick && !isActive ? { backgroundColor: "gray-athens-2" } : {}}
    >
      {children}
    </Box>
  );
};
