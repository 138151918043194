import * as React from "react";
import { spacing } from "../theme/spacing";
export const AlignLeft: React.FunctionComponent<{
  before?: React.ReactNode;
}> = ({ children, before }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "left",
        alignItems: "center",
        whiteSpace: "break-spaces",
      }}
    >
      {before && <div style={{ paddingRight: spacing(1) }}>{before}</div>}
      {children}
    </div>
  );
};
