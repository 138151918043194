import * as React from "react";
import { BLUE_MAASTRICHT, FONT_FAMILY_DEFAULT, FONT_SIZE_SMALLER } from "../theme/ui-constants";

export const ExtraSmall: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <small
    style={{
      fontFamily: FONT_FAMILY_DEFAULT,
      fontStyle: "normal",
      fontSize: FONT_SIZE_SMALLER,
      color: BLUE_MAASTRICHT,
      ...style,
    }}
  >
    {children}
  </small>
);
