import { useEffect, useRef, useState } from "react";

function getScrollParent(node: HTMLElement | null | undefined): HTMLElement | null | undefined {
  if (!node) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else if (node.parentNode) {
    return getScrollParent(node.parentElement);
  }
  return null;
}

export const useOffsetTop = <T extends HTMLTableElement>(): {
  refElement: React.RefObject<T>;
  offsetTop: string;
} => {
  const [offsetTop, setOffsetTop] = useState("0px");
  const refElement = useRef<T>(null);

  const handleScroll = (): void => {
    const offset = (refElement.current?.parentElement?.offsetTop || 1) - 1;
    setOffsetTop(`${offset}px`);
  };

  useEffect((): (() => void) | undefined => {
    if ("MutationObserver" in window) {
      const scrollable = getScrollParent(refElement.current);
      const observer = new MutationObserver(handleScroll);
      if (scrollable) {
        observer.observe(scrollable, { attributes: true, childList: true, subtree: true });
      }

      return (): void => {
        observer.disconnect();
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refElement.current]);

  return { refElement, offsetTop };
};
