import * as React from "react";
export const JustifyAndAlignCenter: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
