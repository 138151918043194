import * as React from "react";
import { BackgroundGrey } from "../BackgroundColors";
import { BackgroundGreen } from "../BackgroundColors/BackgroundGreen";
import { AlignCenter, VerticalCenter } from "../Layout";
import { PaddingSmall, PaddingSmaller } from "../Padding";
import { Bold } from "../Text/Bold";
import { Smaller } from "../Text/Smaller";
import { TextGrayShuttle, TextWhite } from "../TextColors";
import { spacing } from "../theme/spacing";
import { PaneLevel } from "./types";

export const PaneLabel: React.FunctionComponent<{
  level?: PaneLevel;
  label?: React.ReactNode;
}> = ({ level, label }) => {
  const labelText = <Bold>{level === PaneLevel.RECOMMENDED ? <TextWhite>{label}</TextWhite> : <TextGrayShuttle>{label}</TextGrayShuttle>}</Bold>;
  const content = (
    <PaddingSmall left right>
      <PaddingSmaller top bottom>
        <VerticalCenter>
          <Smaller>{labelText}</Smaller>
        </VerticalCenter>
      </PaddingSmaller>
    </PaddingSmall>
  );
  return (
    <div style={{ height: 0, transform: `translateY(-${spacing(1)})` }}>
      <AlignCenter>
        {level === PaneLevel.RECOMMENDED ? (
          <div style={{ borderRadius: spacing(0.5) }}>
            <BackgroundGreen>{content}</BackgroundGreen>
          </div>
        ) : (
          <BackgroundGrey>{content}</BackgroundGrey>
        )}
      </AlignCenter>
    </div>
  );
};
