import { removeToken, removeRefreshToken } from "../hooks/useToken.hook";

export const JWTDecode = <Payload = { exp: number }>(token: string | undefined): { header: Record<string, string>; payload: Payload } => {
  if (typeof token !== "string") {
    throw new TypeError("Invalid token specified");
  }

  try {
    const header = JSON.parse(atob(token.split(".")[0])) as Record<string, string>;
    const payload = JSON.parse(atob(token.split(".")[1])) as Payload;

    return { header, payload };
  } catch (e) {
    removeToken();
    removeRefreshToken();
    throw new Error(`Invalid token specified: ${(e as { message: string }).message}`);
  }
};
