import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { TextPrimitive } from "../../../primitives";
import { LinkProps } from "../types";

const DEFAULT_TAG = "a";
type LinkOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof TextPrimitive>, LinkProps>;
type LinkPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, LinkOwnProps>;

/**
 * This primary component must be used for every links.
 */
export const Link = React.forwardRef(({ as = DEFAULT_TAG, size = "standard", ...props }, ref) => {
  const fontSize = size === "standard" ? "14px" : "12px";
  const lineHeight = size === "standard" ? "24px" : "16px";

  return (
    <TextPrimitive
      ref={ref}
      as={as}
      {...props}
      color="blue-palatinate"
      fontFamily="texts"
      fontSize={fontSize}
      fontWeight={600}
      lineHeight={lineHeight}
      textDecoration="underline"
      cursor="pointer"
      _hover={{ color: "blue-hover", textDecoration: "underline" }}
      _active={{ color: "blue-hover", textDecoration: "underline" }}
    />
  );
}) as LinkPrimitive;

export type { LinkProps };
