/* eslint-disable @typescript-eslint/naming-convention */

import { Dispatch, SetStateAction, useState } from "react";

export function useToggle(initialValue: boolean, onToggle?: (value: boolean) => void): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggleValue = (): void => {
    const toggledValue = !value;
    setValue(toggledValue);
    if (onToggle) {
      onToggle(toggledValue);
    }
  };

  return [value, toggleValue, setValue];
}
