import lottie from "lottie-web";
import * as React from "react";

export const Animation: React.FunctionComponent<{
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  jsonStaticUrl: string;
  loop?: boolean;
  onComplete?: () => void;
  autoplay?: boolean;
}> = ({ width, height, loop = false, jsonStaticUrl, onComplete, autoplay = true }) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const fetchAndAnimate = async () => {
    if (divRef.current) {
      const json: JSON = await (await fetch(jsonStaticUrl)).json();
      const animation = lottie.loadAnimation({
        container: divRef.current,
        renderer: "svg",
        loop: loop,
        autoplay,
        animationData: json,
      });

      if (onComplete) {
        animation.addEventListener("complete", () => {
          onComplete();
        });
      }
    }
  };

  React.useEffect(() => {
    if (divRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchAndAnimate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef]);

  return <div style={{ width, height }} ref={divRef}></div>;
};
