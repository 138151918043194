import * as React from "react";
import { Box } from "../Layout";
import { Text, Link } from "../Typography";
import { ItemInfoProps } from "./types";

/**
 * `ItemInfo` is a display component used to display a label with a value.
 */
export const ItemInfo = ({ id, title, info, size = "default", variation = "default", onClick }: ItemInfoProps): React.ReactElement => {
  return (
    <Box id={id}>
      {title && (
        <Text size="small" color={variation === "warning" ? "orange-coral" : "gray-bombay"}>
          {title}
        </Text>
      )}
      {onClick ? (
        <Link size="small" onClick={onClick}>
          {info}
        </Link>
      ) : (
        <Text color={variation === "warning" ? "orange-coral" : undefined} size={size === "small" ? "small" : "standard"}>
          {info}
        </Text>
      )}
    </Box>
  );
};

export type { ItemInfoProps };
