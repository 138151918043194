import { DateWithoutTime } from "../../date-without-time/date-without-time";
import { PaymentDatesPayload, PaymentStrategy } from "../payment-date.types";
import { getTriggeringDateAutoload } from "./get-triggering-date-autoload";
import { getTriggeringDateFastpay } from "./get-triggering-date-fastpay";
import { getTriggeringFintecture } from "./get-triggering-date-fintecture";
import { getTriggeringDateTopup } from "./get-triggering-date-topup";

export const getTriggeringDate = (
  payload: Pick<
    PaymentDatesPayload,
    "paymentStrategy" | "paymentAtDate" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate" | "clickToPayDate"
  >,
): DateWithoutTime => {
  const { paymentStrategy } = payload;

  const paymentStrategyMapper: {
    [key in PaymentStrategy]: (
      payload: Pick<
        PaymentDatesPayload,
        "paymentStrategy" | "paymentAtDate" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate" | "clickToPayDate"
      >,
    ) => ReturnType<typeof getTriggeringDate>;
  } = {
    [PaymentStrategy.TREEZOR_AUTOLOAD]: getTriggeringDateAutoload,
    [PaymentStrategy.TREEZOR_TOPUP]: getTriggeringDateTopup,
    [PaymentStrategy.TREEZOR_FAST_PAYMENT]: getTriggeringDateFastpay,
    [PaymentStrategy.FINTECTURE]: getTriggeringFintecture,
  };

  return paymentStrategyMapper[paymentStrategy](payload);
};
