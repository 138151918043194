import * as React from "react";
import { useFullPageCarouselButton } from "./FullPageCarouselButton.hook";
import { fullPageCarouselButtonInnerStyles, fullPageCarouselButtonWrapperStyles } from "./styles";
import { FullPageCarouselButtonProps } from "./types";

export const FullPageCarouselButton: React.FunctionComponent<FullPageCarouselButtonProps> = (props) => {
  const { buttonIndex, currentIndex } = props;
  const { onClickStep } = useFullPageCarouselButton(props);
  return (
    <div onClick={onClickStep} style={fullPageCarouselButtonWrapperStyles}>
      <div style={fullPageCarouselButtonInnerStyles(buttonIndex, currentIndex)} />
    </div>
  );
};
