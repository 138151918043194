import * as React from "react";
import { FONT_MEDIUM } from "../theme/ui-constants";

export const TextMedium: React.FunctionComponent = ({ children }) => (
  <h2
    style={{
      fontWeight: FONT_MEDIUM,
    }}
  >
    {children}
  </h2>
);
