import * as React from "react";
import { useIntl } from "react-intl";
import { VerticalCenter } from "../Layout";
import { NotificationLevel } from "../Notification/notification.enum";
import { useNotification } from "../Notification/notification.hook";
import { Bold } from "../Text";
import { spacing } from "../theme/spacing";
import { NotificationLevelToColorMap, NotificationLevelToIconMap } from "./Banner.constants";
import { BannerCallToAction } from "./components/BannerCallToAction";
import { BannerClose } from "./components/BannerClose";
import { BannerIcon } from "./components/BannerIcon";
import { BannerWrapper } from "./components/BannerWrapper";
import { BannerProps } from "./types/Banner.types";

export const Banner: React.FunctionComponent<BannerProps> = ({
  level = NotificationLevel.INFO,
  title,
  message,
  messageValues,
  cta,
  ctaColor,
  ctaIcon,
  onClick,
  onClose,
}) => {
  const { reset } = useNotification();
  const { formatMessage } = useIntl();
  const color = NotificationLevelToColorMap[level];
  const icon = NotificationLevelToIconMap[level];

  return (
    <BannerWrapper color={color}>
      <BannerIcon color={color} iconValue={icon} />
      <VerticalCenter>
        <div>
          {title && <Bold style={{ marginRight: spacing(1) }}>{formatMessage({ id: title })}</Bold>}
          {message && <span>{formatMessage({ id: message }, messageValues)}</span>}
        </div>
      </VerticalCenter>
      <BannerCallToAction icon={ctaIcon} color={ctaColor || color} cta={cta} onClick={onClick} />
      <BannerClose onClick={onClose || reset} />
    </BannerWrapper>
  );
};
