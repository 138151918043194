import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import * as React from "react";
import { Icon } from "../Icon";
import { Box } from "../Layout";

interface CollapsibleProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

/**
 * `Collapsible` is an interactive component which expands/collapses a panel.
 */
export const Collapsible = ({ children, title }: CollapsibleProps): React.ReactElement => {
  const [isOpen, setOpen] = React.useState<boolean | undefined>(false);
  return (
    <CollapsiblePrimitive.Root open={isOpen} onOpenChange={setOpen}>
      <CollapsiblePrimitive.Trigger asChild>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" cursor="pointer">
          {title}
          <Icon name={isOpen ? "caret-up" : "caret-down"} type="solid" variation="primary" />
        </Box>
      </CollapsiblePrimitive.Trigger>

      <CollapsiblePrimitive.Content>{children}</CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
};
