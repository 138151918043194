import { Box, Text } from "@libeo/design-system";
import * as React from "react";

export const CardSubtitle: React.FunctionComponent<{
  id?: string;
  textAlign?: React.CSSProperties["textAlign"];
  textTransform?: React.CSSProperties["textTransform"];
}> = ({ children, id, textAlign }) => (
  <Box id={id} paddingBottom={2}>
    <Text size="standard" variation="regular" textAlign={textAlign} color="gray-bright">
      {children}
    </Text>
  </Box>
);
