import { CSSProperties } from "react";
import { BLUE_MAASTRICHT, BORDER_RADIUS_INPUT, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT, GRAY_ATHENS_2, WHITE } from "../../theme/ui-constants";

export const inputWrapper = ({ isHovered }: { isHovered: boolean }): CSSProperties => ({
  backgroundColor: isHovered ? GRAY_ATHENS_2 : WHITE,
  borderRadius: BORDER_RADIUS_INPUT,
  width: "fit-content",
  cursor: isHovered ? "pointer" : "initial",
});

export const inputStyle: CSSProperties = {
  border: "none",
  background: "transparent",
  fontFamily: FONT_FAMILY_DEFAULT,
  color: BLUE_MAASTRICHT,
  fontSize: FONT_SIZE_DEFAULT,
};
