import * as React from "react";
import { spacing } from "../theme/spacing";

export const HorizontalButtonSpacing: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        padding: spacing(0, 1, 0, 1),
      }}
    >
      {children}
    </div>
  );
};
