import { Box } from "@libeo/design-system";
import * as React from "react";
import { LandingFormattedText } from "./LandingFormattedText";

export const LandingHorizontalCard: React.FunctionComponent<{
  imageSrc: string;
  imageWidth: string;
  fontFamily: string;
  title: string;
  description?: string;
  titleColor: string;
  titleSize: string;
  titleWeight: number;
  descriptionColor?: string;
  descriptionSize?: string;
  lineHeight?: number;
}> = ({
  imageSrc,
  imageWidth,
  fontFamily,
  title,
  titleColor,
  titleSize,
  titleWeight,
  description,
  descriptionColor,
  descriptionSize,
  lineHeight,
}) => {
  return (
    <Box flexDirection="row">
      <Box marginRight="14px">
        <img width={imageWidth} alt="" src={imageSrc} />
      </Box>
      <Box justifyContent="center">
        <Box marginBottom="9px">
          <LandingFormattedText
            contentId={title}
            fontFamily={fontFamily}
            fontSize={titleSize}
            textColor={titleColor}
            fontWeight={titleWeight}
            lineHeight={lineHeight}
          />
        </Box>
        {descriptionSize && (
          <Box>
            <LandingFormattedText
              contentId={description}
              fontFamily={fontFamily}
              fontSize={descriptionSize}
              textColor={descriptionColor}
              lineHeight={lineHeight}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
