import { removeWhiteSpaces, removeDotsFromString, removeDashesFromString } from "../string/string";

export const PHONE_NUMBER_REGEX = /^\+?[0-9]{08,12}$/;
const FRENCH_CODE_NUMBER = "+33";

const shouldPrefixPhoneNumber = (phoneNumber: string): boolean => {
  const isAlreadyPrefixed = phoneNumber[0] === "+";
  const isStartingWithDoubleZero = phoneNumber.startsWith("00");
  if (isAlreadyPrefixed || isStartingWithDoubleZero) {
    return false;
  }

  return PHONE_NUMBER_REGEX.test(phoneNumber);
};

export const prefixWithFrenchCodeNumber = (phoneNumber: string): string => {
  const cleanPhoneNumber = removeDashesFromString(removeDotsFromString(removeWhiteSpaces(phoneNumber)));
  if (!shouldPrefixPhoneNumber(cleanPhoneNumber)) {
    return phoneNumber;
  }
  return `${FRENCH_CODE_NUMBER}${cleanPhoneNumber.slice(1)}`;
};
