import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Icon } from "../../Icon/Icon";
import { IconValue } from "../../Icon/icon.type";
import { ToolTip } from "../../Tooltip/Tooltip";
import { BLUE_PALATINATE, GRAY_BOMBAY, INPUT_ICON_SIZE, MOBILE_MEDIA_QUERY, WHITE } from "../../theme/ui-constants";

export const CheckboxIcon: React.FunctionComponent<{ disabled?: boolean }> = ({ disabled }) => (
  <Icon width={INPUT_ICON_SIZE - 5} height={INPUT_ICON_SIZE - 5} color={disabled ? GRAY_BOMBAY : WHITE} value={IconValue.Checkmark} />
);

export const PasswordIcon: React.FunctionComponent<{ active: boolean; onClick: () => void }> = ({ active, onClick }) => (
  <div onClick={onClick}>
    <Icon
      width={INPUT_ICON_SIZE + 3}
      height={INPUT_ICON_SIZE + 3}
      style={{
        cursor: "pointer",
        boxSizing: "content-box",
      }}
      value={IconValue.EyeOpen}
      color={!active ? "#AEAEAE" : BLUE_PALATINATE}
    />
  </div>
);

export const TooltipIcon: React.FunctionComponent<{ visible: boolean; title: React.ReactNode }> = ({ visible, title }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MEDIA_QUERY });

  return (
    <ToolTip visible={visible} placement={isMobile ? "top" : "left"} title={title}>
      <Icon width={INPUT_ICON_SIZE} height={INPUT_ICON_SIZE} value={IconValue.Information} />
    </ToolTip>
  );
};

export const CrossIcon: React.FunctionComponent = () => (
  <Icon
    width={INPUT_ICON_SIZE - 3}
    height={INPUT_ICON_SIZE - 3}
    style={{
      cursor: "pointer",
    }}
    value={IconValue.Cross}
  />
);

export const DropDownIcon: React.FunctionComponent<{ isFocused?: boolean }> = ({ isFocused }) => (
  <Icon
    width={INPUT_ICON_SIZE}
    height={INPUT_ICON_SIZE}
    style={{
      cursor: "pointer",
      transition: "rotate 0.3s ease",
      transform: `rotate(${isFocused ? -180 : 0}deg)`,
    }}
    value={IconValue.ChevronDown}
  />
);

export const DatePickerIcon: React.FunctionComponent<{ isFocused?: boolean }> = () => (
  <Icon
    width={INPUT_ICON_SIZE}
    height={INPUT_ICON_SIZE}
    style={{
      pointerEvents: "none",
    }}
    value={IconValue.Calendar}
  />
);
