import * as React from "react";
import { toast } from "react-toastify";
import { Animation } from "../Animations/Lottie/Animation";
import { NotificationLevel } from "../Notification/notification.enum";
import { Bold } from "../Text";
import { BLUE_PALATINATE, GREEN_CARABBEAN, ORANGE_CORAL, PINK_PICTORIAL_CARMINE } from "../theme/ui-constants";
import { toastSuccessIconWrapper, toastTextDescriptionStyle, toastTextStyle } from "./styles";
import { ToastConfig, ToastFunction } from "./toast.types";

const TOAST_AUTO_CLOSE_DURATION = 7500;
const infoToast: ToastFunction = ({ message, title, id }: ToastConfig): string => {
  const toastId = toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={toastSuccessIconWrapper}>
        <Animation width="50px" height="50px" jsonStaticUrl="/lottie/info.json" />
      </div>
      <div style={toastTextStyle}>
        {message && (
          <p style={toastTextDescriptionStyle}>
            {title && <Bold style={{ display: "inline" }}>{title}</Bold>} {message}
          </p>
        )}
      </div>
    </div>,
    {
      className: "toast",
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      progressStyle: {
        background: BLUE_PALATINATE,
      },
      pauseOnHover: true,
      toastId: id,
      containerId: 1,
      draggable: false,
      autoClose: TOAST_AUTO_CLOSE_DURATION,
      closeOnClick: false,
      closeButton: true,
    },
  );
  return toastId.toString();
};
const warnToast: ToastFunction = ({ message, title, id }: ToastConfig): string => {
  const toastId = toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={toastSuccessIconWrapper}>
        <Animation width="100%" height="100%" jsonStaticUrl="/lottie/warning.json" />
      </div>
      <div style={toastTextStyle}>
        {message && (
          <p style={toastTextDescriptionStyle}>
            {title && <Bold style={{ display: "inline" }}>{title}</Bold>} {message}
          </p>
        )}
      </div>
    </div>,
    {
      className: "toast",
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      progressStyle: {
        background: ORANGE_CORAL,
      },
      pauseOnHover: true,
      toastId: id,
      containerId: 1,
      draggable: false,
      autoClose: TOAST_AUTO_CLOSE_DURATION,
      closeOnClick: false,
      closeButton: true,
    },
  );
  return toastId.toString();
};

const successToast: ToastFunction = ({ message, title, id }: ToastConfig): string => {
  const toastId = toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={toastSuccessIconWrapper}>
        <Animation width="100%" height="100%" jsonStaticUrl="/lottie/checkmark.json" />
      </div>
      <div style={toastTextStyle}>
        {message && (
          <p style={toastTextDescriptionStyle}>
            {title && <Bold style={{ display: "inline" }}>{title}</Bold>} {message}
          </p>
        )}
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      progressStyle: {
        background: GREEN_CARABBEAN,
      },
      pauseOnHover: true,
      toastId: id,
      progressClassName: "success-progressbar",
      containerId: 1,
      draggable: false,
      autoClose: TOAST_AUTO_CLOSE_DURATION,
      closeOnClick: false,
      closeButton: true,
    },
  );
  return toastId.toString();
};

const emailSentToast: ToastFunction = ({ message, title, id }: ToastConfig): string => {
  const toastId = toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={toastSuccessIconWrapper}>
        <Animation width="100%" height="100%" jsonStaticUrl="/lottie/email-sent.json" />
      </div>
      <div style={toastTextStyle}>
        {message && (
          <p style={toastTextDescriptionStyle}>
            {title && <Bold style={{ display: "inline" }}>{title}</Bold>} {message}
          </p>
        )}
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      progressStyle: {
        background: BLUE_PALATINATE,
      },
      pauseOnHover: true,
      toastId: id,
      progressClassName: "send-email-progressbar",
      containerId: 1,
      draggable: false,
      autoClose: TOAST_AUTO_CLOSE_DURATION,
      closeOnClick: false,
      closeButton: true,
    },
  );

  return toastId.toString();
};

const errorToast: ToastFunction = ({ message, title, id }: ToastConfig): string => {
  const toastId = toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={toastSuccessIconWrapper}>
        <Animation width="100%" height="100%" jsonStaticUrl="/lottie/cross-error.json" />
      </div>
      <div style={toastTextStyle}>
        {message && (
          <p style={toastTextDescriptionStyle}>
            {title && <Bold style={{ display: "inline" }}>{title}</Bold>} {message}
          </p>
        )}
      </div>
    </div>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      progressStyle: {
        background: PINK_PICTORIAL_CARMINE,
      },
      pauseOnHover: true,
      toastId: id,
      progressClassName: "error-progressbar",
      containerId: 1,
      draggable: false,
      autoClose: TOAST_AUTO_CLOSE_DURATION,
      closeOnClick: false,
      closeButton: true,
    },
  );

  return toastId.toString();
};

const show = (toastConfig: ToastConfig): void => {
  switch (toastConfig.level) {
    case NotificationLevel.SUCCESS:
      successToast(toastConfig);
      break;
    case NotificationLevel.WARNING:
      warnToast(toastConfig);
      break;
    case NotificationLevel.ERROR:
      errorToast(toastConfig);
      break;
    case NotificationLevel.INFO:
      infoToast(toastConfig);
      break;
    case NotificationLevel.EMAIL:
      emailSentToast(toastConfig);
      break;
    default:
      infoToast(toastConfig);
  }
};

export const useToast = (): ((toast: ToastConfig) => void) => {
  return show;
};
