import { Button } from "@libeo/design-system";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Textarea } from "../../Form/Textarea";
import { AlignRight } from "../../Layout";
import { MOBILE_MEDIA_QUERY } from "../../theme/ui-constants";
import { ChoiceButton } from "./ChoiceButton";
import { MultipleChoiceButtonLaterStyles, MultipleChoiceButtonSpacinStyles, optionsContainer } from "./styles";
import { ChoiceButtonProp } from "./types";

function renderChoice(onClickChoice?: ChoiceButtonProp["onClick"]) {
  return function renderChoiceButton(choice: ChoiceButtonProp): React.ReactNode {
    return (
      <div key={`${choice.id}-${choice.label}`} style={MultipleChoiceButtonSpacinStyles}>
        <ChoiceButton {...choice} onClick={onClickChoice} highlightOnClick />
      </div>
    );
  };
}

export const ChoiceGroup: React.FunctionComponent<{
  choices: ChoiceButtonProp[];
  onValidate: (selectedChoices: ChoiceButtonProp[]) => Promise<void>;
  submitCtaLabel?: string;
  isMultipleChoiceAllowed?: boolean;
  isCustomChoiceAllowed?: boolean;
  customChoiceLabel?: string;
  customChoiceTextAreaPlaceholderLabel?: string;
}> = ({
  choices,
  onValidate,
  isMultipleChoiceAllowed,
  submitCtaLabel,
  isCustomChoiceAllowed,
  customChoiceLabel,
  customChoiceTextAreaPlaceholderLabel,
}) => {
  const [selectedChoices, setSelectedChoices] = React.useState<ChoiceButtonProp[]>([]);
  const [isCustomAnswer, setIsCustomAnswer] = React.useState(false);
  const [userCustomAnswer, setUserCustomAnswer] = React.useState<string | undefined>(undefined);
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MEDIA_QUERY });

  const saveChoice = (choice: ChoiceButtonProp): void => {
    if (choice.customValue) {
      setIsCustomAnswer(true);
      return;
    }
    const choiceIndex = selectedChoices.findIndex((existingChoice) => choice.id === existingChoice.id);
    if (choiceIndex === -1) {
      setSelectedChoices(selectedChoices.concat(choice));
    } else {
      const copyOfSelected = selectedChoices.slice();
      copyOfSelected.splice(choiceIndex, 1);
      setSelectedChoices(copyOfSelected);
    }
  };

  const onSingleChoiceClick = async (choice: ChoiceButtonProp): Promise<void> => {
    if (choice.customValue) {
      setIsCustomAnswer(true);
      return;
    }
    await onValidate([choice]);
  };

  async function onCustomChoiceValidate(): Promise<void> {
    await onSingleChoiceClick({ id: "custom", value: userCustomAnswer });
  }

  async function onMultipleChoiceClick(): Promise<void> {
    await onValidate(selectedChoices);
  }

  const onClickChoice = isMultipleChoiceAllowed ? saveChoice : onSingleChoiceClick;
  if (isCustomChoiceAllowed && customChoiceLabel) {
    const choiceIndex = choices.findIndex((existingChoice) => "CustomChoice" === existingChoice.id);
    if (choiceIndex === -1) {
      choices.push({
        id: "CustomChoice",
        label: customChoiceLabel,
        value: customChoiceLabel,
        customValue: true,
      });
    }
  }

  if (isCustomAnswer) {
    return (
      <>
        <Textarea
          error={false}
          onChangeStringValue={setUserCustomAnswer}
          required
          key="custom-third-qualifying-question-fourth-choice"
          id="custom-third-qualifying-question-fourth-choice"
          label={customChoiceTextAreaPlaceholderLabel}
        />
        <AlignRight>
          <Button id="button-claim-company" onPress={onCustomChoiceValidate}>
            {submitCtaLabel}
          </Button>
        </AlignRight>
      </>
    );
  }

  return (
    <>
      <div style={optionsContainer(isMobile)}>{choices.map(renderChoice(onClickChoice))}</div>
      {isMultipleChoiceAllowed && submitCtaLabel && (
        <div style={MultipleChoiceButtonLaterStyles}>
          <Button data-test-id="submit-multiple-choice" id="submit-multiple-choice" onPress={onMultipleChoiceClick}>
            {submitCtaLabel}
          </Button>
        </div>
      )}
    </>
  );
};
