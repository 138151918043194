import { ReactNode } from "react";

export enum PaneLevel {
  CURRENT,
  RECOMMENDED,
}

export type PaneProps = {
  label?: ReactNode;
  level: PaneLevel;
  title: ReactNode;
  items: ReactNode[];
  button?: ReactNode;
};
