import * as dateFns from "date-fns";
import moment from "moment";

function onChangeYear(changeYear: (year: number) => void) {
  return (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeYear(parseInt(e.target.value, 10));
  };
}

export const useDatePickerCustomHeader = () => {
  const months = moment.months();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const years = Array.from(Array(200).keys()).map(function (v: number, i: number) {
    return i + (dateFns.getYear(new Date()) - 100);
  });

  return {
    months,
    years,
    onChangeYear,
  };
};
