import moment from "moment";
import * as React from "react";
import { useDetectOverflow } from "../../hooks/useDetectOverflow.hook";
import { Box } from "../Layout";
import { Tooltip } from "../Tooltip";
import { Link, Text } from "../Typography";
import { TimelineItemProps } from "./types";

export const TimelineItem = ({
  author,
  description,
  comment,
  date,
  isFirstItem,
  isLastItem,
  isFolded,
  locale,
  moreCommentLabel,
  lessCommentLabel,
}: TimelineItemProps): React.ReactElement => {
  const commentRef = React.useRef(null);
  const { isRefXOverflowing } = useDetectOverflow(commentRef);
  const [isCommentFolded, setIsCommentFolded] = React.useState(true);
  const isDateMoreThanThreeDaysOld = moment().diff(moment(date).locale(locale), "days") >= 3;
  return (
    <Box>
      <Box flexDirection="row" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          <Box width={8} height={8} borderRadius="pill" backgroundColor="green-generic-viridian" marginRight="12px" />
          <Text size="small" variation="medium">
            {author}
          </Text>
        </Box>
        <Tooltip
          align="end"
          side="bottom"
          content={isDateMoreThanThreeDaysOld ? moment(date).locale(locale).format("LT") : moment(date).locale(locale).format("LLL")}
        >
          <Text size="small" color="gray-shuttle">
            {isDateMoreThanThreeDaysOld ? moment(date).locale(locale).format("LL") : moment(date).locale(locale).fromNow()}
          </Text>
        </Tooltip>
      </Box>
      <Box position="relative" flexDirection="row" paddingTop={1} paddingBottom={(isFirstItem && isFolded) || isLastItem ? 2 : 3} width="100%">
        {!isLastItem && <Box width={1} position="absolute" top="0px" left="4px" height="100%" backgroundColor="gray-athens-1" />}
        <Box marginLeft="20px" width="100%">
          {typeof description === "string" ? (
            <Text size="standard" variation="regular">
              {description}
            </Text>
          ) : (
            description
          )}
          <Box flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
            <Box maxWidth="calc(100% - 70px)">
              <Text as="span" ref={commentRef} hasEllipsis={isCommentFolded} size="standard" variation="regular" color="gray-shuttle">
                {comment}
              </Text>
              {isRefXOverflowing && !isCommentFolded && (
                <Link as="span" size="standard" onClick={(): void => setIsCommentFolded(!isCommentFolded)}>
                  {lessCommentLabel}
                </Link>
              )}
            </Box>
            {isRefXOverflowing && isCommentFolded && (
              <Link size="standard" onClick={(): void => setIsCommentFolded(!isCommentFolded)}>
                {moreCommentLabel}
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
