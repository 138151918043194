import * as React from "react";
import { spacing } from "../../theme/spacing";
import { BLUE_PALATINATE, FONT_BOLD } from "../../theme/ui-constants";

export const copyButtonStyle: React.CSSProperties = {
  fontWeight: FONT_BOLD,
  fontSize: "11px",
  color: BLUE_PALATINATE,
  textAlign: "center",
  padding: spacing(1),
  textTransform: "uppercase",
  cursor: "pointer",
  width: "70px",
};
