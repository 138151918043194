import { useState, useRef, useEffect } from "react";
import { RadioButtonProps } from "../RadioButtons/types";

export const useRadioButton = (props: RadioButtonProps) => {
  const { onChangeBooleanValue, defaultChecked } = props;
  const [checked, setChecked] = useState(props.defaultChecked);
  const radioButtonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (radioButtonRef && radioButtonRef.current) {
      radioButtonRef.current.checked = Boolean(defaultChecked);
      setChecked(Boolean(defaultChecked));
    }
  }, [defaultChecked, radioButtonRef]);

  const onCheck = () => {
    setChecked(true);

    if (onChangeBooleanValue) {
      onChangeBooleanValue();
    }
  };

  return {
    checked,
    onCheck,
    radioButtonRef,
  };
};
