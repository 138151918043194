import React from "react";
import { Link } from "react-router-dom";
import { Icon, IconValue } from "../Icon";
import { useHover } from "../hooks/useHover.hook";
import { BLUE_HOVER, BLUE_PALATINATE, GRAY_SHUTTLE } from "../theme/ui-constants";
import * as styles from "./styles";
import { AlertLinkProps, AlertProps, AlertVariation } from "./types";

const disabledLinkStyle = {
  color: GRAY_SHUTTLE,
  textDecoration: "underline",
  cursor: "not-allowed",
};

const AlertLink = React.forwardRef<HTMLAnchorElement, AlertLinkProps>((props, ref) => {
  const { isHovered, ...hoverProps } = useHover();

  const style = {
    color: isHovered ? BLUE_HOVER : BLUE_PALATINATE,
    textDecoration: "underline",
  };

  if (props.disabled) {
    return <span style={disabledLinkStyle} {...props} />;
  }

  if ("to" in props) {
    return <Link ref={ref} style={style} {...hoverProps} {...props} />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a ref={ref} style={style} {...hoverProps} {...props} />
  );
});

const icons: Record<AlertVariation, IconValue> = {
  info: IconValue.Information,
  success: IconValue.Checkmark,
  warning: IconValue.Warning,
};

const Alert = ({ children, variation = "info" }: AlertProps): React.ReactElement => {
  const icon = icons[variation];

  return (
    <div style={styles.alertContainer(variation)}>
      <div style={styles.iconContainer}>
        <Icon value={icon} color="inherit" width={12} height={12} noMargin />
      </div>
      <div>{children}</div>
    </div>
  );
};

Alert.Link = AlertLink;

export { Alert };
