import { parseZone, updateLocale } from "moment-business-days";
import { DateWithoutTime } from "../date-without-time";
import { frenchHolidays } from "../french-holidays/french-holidays";

export const isBusinessDay = (date: DateWithoutTime): boolean => {
  updateLocale("fr", {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidayFormat: "YYYY-MM-DD",
    holidays: frenchHolidays(new Date(date).getFullYear()),
  });

  return parseZone(date).utc().startOf("day").isBusinessDay();
};
