import * as React from "react";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { textareaStyle } from "./styles";
import { InputProps } from "./types";
export const Textarea: React.FunctionComponent<InputProps> = (props) => {
  const { isDisabled: disabled, id, style, required, label, message, suffix, defaultValue, autocomplete, description, value } = props;
  const { color, onFocus, onBlur, inputFocus, onChange, error, inputHasValue } = useInput(props);

  return (
    <FormItemWrapper
      style={style}
      color={color}
      label={label}
      required={required}
      inputFocus={inputFocus}
      inputHasValue={inputHasValue}
      suffix={suffix}
      message={message}
      description={description}
    >
      <textarea
        disabled={disabled}
        data-error={error}
        autoComplete={autocomplete}
        id={id}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        style={textareaStyle(disabled, style?.height || "100px")}
      />
    </FormItemWrapper>
  );
};
