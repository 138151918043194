import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { BLUE_MAASTRICHT, FONT_FAMILY_DEFAULT, FONT_SIZE_H1 } from "../theme/ui-constants";

export const TextH1Style: CSSProperties = {
  fontSize: FONT_SIZE_H1,
  fontStyle: "normal",
  fontWeight: "bold",
  fontFamily: FONT_FAMILY_DEFAULT,
  color: BLUE_MAASTRICHT,
  margin: 0,
  padding: spacing(0, 0, 3, 0),
};

export const textEllipsisStyle = {
  fontFamily: FONT_FAMILY_DEFAULT,
  cursor: "pointer",
};

export const textOverflowEllipsisStyle: CSSProperties = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "100%",
};
