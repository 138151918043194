import { removeUUIDAndNumberFromUrl } from "@libeo/utils";
import { transformUrlToPageTitle } from "@libeo/utils";
import { Location } from "history";
import { trackPageView } from "../services/segment";
import { useHistoryListener } from "./useHistoryListener";

const HOMEPAGE_TITLE = "homepage";
const onNavigate = (location: Location): void => {
  const pageTitle = transformUrlToPageTitle(removeUUIDAndNumberFromUrl(location.pathname));
  const pageTitleWithHomePage = pageTitle === "" ? HOMEPAGE_TITLE : pageTitle;
  trackPageView(pageTitleWithHomePage, document.location.href);
};

export const useTrackingPageVisit = (): void => {
  useHistoryListener(onNavigate);
};
