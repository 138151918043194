import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { TextPrimitive, TextPrimitiveProps } from "../../../primitives";
import { TextProps, TextSize, TextVariation } from "../types";

const mapTextSizeAndVariationToStyle: Record<TextSize, Record<TextVariation, TextPrimitiveProps>> = {
  standard: {
    inherit: {
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit",
    },
    regular: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "24px",
    },
    medium: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
    },
    bold: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
    },
    uppercase: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
      textTransform: "none",
    },
  },
  small: {
    inherit: {
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit",
    },
    regular: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
    medium: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
    },
    bold: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
    },
    uppercase: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
      textTransform: "none",
    },
  },
};

const DEFAULT_TAG = "span";

type TextOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof TextPrimitive>, TextProps>;
type TextPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, TextOwnProps>;

/**
 * This primary component must be used for every texts.
 */
export const Text = React.forwardRef(({ as = DEFAULT_TAG, size = "standard", variation = "regular", hasLineThrough = false, ...props }, ref) => {
  if (props.color === "gray-bombay") {
    props.color = "gray-shuttle";
  }

  return (
    <TextPrimitive
      ref={ref}
      as={as}
      {...props}
      fontFamily="texts"
      {...(hasLineThrough ? { textDecoration: "line-through" } : {})}
      {...mapTextSizeAndVariationToStyle[size][variation]}
    />
  );
}) as TextPrimitive;

export type { TextProps };
