import { Moment, parseZone } from "moment";
import { DateWithoutTime } from "../date-without-time";

const dateFormat = "YYYY-MM-DD";

export const frenchHolidayDate = {
  newYearsDay: (year: number): DateWithoutTime => parseZone(year + "-01-01", dateFormat).format(dateFormat),
  easterMonday: (year: number): DateWithoutTime => frenchHolidayDate.easterDay(year).add(1, "days").format(dateFormat),
  labourDay: (year: number): DateWithoutTime => parseZone(year + "-05-01", dateFormat).format(dateFormat),
  victoryDay: (year: number): DateWithoutTime => parseZone(year + "-05-08", dateFormat).format(dateFormat),
  goodFriday: (year: number): DateWithoutTime => frenchHolidayDate.easterDay(year).subtract(2, "days").format(dateFormat),
  ascensionDay: (year: number): DateWithoutTime => frenchHolidayDate.easterDay(year).add(39, "days").format(dateFormat),
  whitMonday: (year: number): DateWithoutTime => frenchHolidayDate.easterDay(year).add(50, "days").format(dateFormat),
  bastilleDay: (year: number): DateWithoutTime => parseZone(year + "-07-14", dateFormat).format(dateFormat),
  assumptionDay: (year: number): DateWithoutTime => parseZone(year + "-08-15", dateFormat).format(dateFormat),
  allSaintsDay: (year: number): DateWithoutTime => parseZone(year + "-11-01", dateFormat).format(dateFormat),
  armisticeDay: (year: number): DateWithoutTime => parseZone(year + "-11-11", dateFormat).format(dateFormat),
  christmasDay: (year: number): DateWithoutTime => parseZone(year + "-12-25", dateFormat).format(dateFormat),
  christmasHoliday: (year: number): DateWithoutTime => parseZone(year + "-12-26", dateFormat).format(dateFormat),
  easterDay: (year: number): Moment => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = h + l + 7 * m + 114;
    const n = Math.floor(n0 / 31) - 1;
    const p = (n0 % 31) + 1;
    const date = new Date(year, n, p);
    return parseZone(date);
  },
};

const memoizedHolidaysByYear: Record<number, DateWithoutTime[]> = [];

export const frenchHolidays = (year: number): DateWithoutTime[] => {
  if (!memoizedHolidaysByYear[year]) {
    memoizedHolidaysByYear[year] = [
      frenchHolidayDate.newYearsDay(year - 1),
      frenchHolidayDate.goodFriday(year - 1),
      frenchHolidayDate.easterMonday(year - 1),
      frenchHolidayDate.labourDay(year - 1),
      frenchHolidayDate.victoryDay(year - 1),
      frenchHolidayDate.ascensionDay(year - 1),
      frenchHolidayDate.whitMonday(year - 1),
      frenchHolidayDate.bastilleDay(year - 1),
      frenchHolidayDate.assumptionDay(year - 1),
      frenchHolidayDate.allSaintsDay(year - 1),
      frenchHolidayDate.armisticeDay(year - 1),
      frenchHolidayDate.christmasDay(year - 1),
      frenchHolidayDate.christmasHoliday(year - 1),
      frenchHolidayDate.newYearsDay(year),
      frenchHolidayDate.goodFriday(year),
      frenchHolidayDate.easterMonday(year),
      frenchHolidayDate.labourDay(year),
      frenchHolidayDate.victoryDay(year),
      frenchHolidayDate.ascensionDay(year),
      frenchHolidayDate.whitMonday(year),
      frenchHolidayDate.bastilleDay(year),
      frenchHolidayDate.assumptionDay(year),
      frenchHolidayDate.allSaintsDay(year),
      frenchHolidayDate.armisticeDay(year),
      frenchHolidayDate.christmasDay(year),
      frenchHolidayDate.christmasHoliday(year),
      frenchHolidayDate.newYearsDay(year + 1),
      frenchHolidayDate.goodFriday(year + 1),
      frenchHolidayDate.easterMonday(year + 1),
      frenchHolidayDate.labourDay(year + 1),
      frenchHolidayDate.victoryDay(year + 1),
      frenchHolidayDate.ascensionDay(year + 1),
      frenchHolidayDate.whitMonday(year + 1),
      frenchHolidayDate.bastilleDay(year + 1),
      frenchHolidayDate.assumptionDay(year + 1),
      frenchHolidayDate.allSaintsDay(year + 1),
      frenchHolidayDate.armisticeDay(year + 1),
      frenchHolidayDate.christmasDay(year + 1),
      frenchHolidayDate.christmasHoliday(year + 1),
    ];
  }

  return memoizedHolidaysByYear[year];
};
