import * as React from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";
import { BLUE_PALATINATE, WHITE, GRAY_BOMBAY } from "../theme/ui-constants";

export const Switch: React.FunctionComponent<{
  defaultChecked: ReactSwitchProps["checked"];
  disabled?: boolean;
  onSwitchOn?: () => void;
  onSwitchOff?: () => void;
}> = ({ defaultChecked, onSwitchOn, onSwitchOff, disabled }) => {
  const [checked, setChecked] = React.useState(defaultChecked);
  function onChange(checked: boolean) {
    setChecked(checked);
    if (checked && onSwitchOn) {
      onSwitchOn();
    } else if (onSwitchOff) {
      onSwitchOff();
    }
  }

  const offColor = GRAY_BOMBAY;
  const offHandleColor = WHITE;
  const onColor = BLUE_PALATINATE;
  const onHandleColor = WHITE;

  return (
    <ReactSwitch
      disabled={Boolean(disabled)}
      offColor={offColor}
      offHandleColor={offHandleColor}
      onColor={onColor}
      onHandleColor={onHandleColor}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="none"
      activeBoxShadow="none"
      handleDiameter={22}
      height={24}
      width={40}
      checked={checked}
      onChange={onChange}
    />
  );
};
