import { TableCellAlign } from "./types";

export const mapAlignToAlignItems = (align?: TableCellAlign): string => {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "flex-start";
  }
};
