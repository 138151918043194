import { subtractNBusinessDaysToDate } from "../../date-compute-business-days/substract-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime, getMaxDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getTriggeringDateFastpay = (
  payload: Pick<
    PaymentDatesPayload,
    "paymentStrategy" | "paymentAtDate" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate" | "clickToPayDate"
  >,
): DateWithoutTime => {
  const { paymentAtDate, clickToPayDate } = payload;

  const triggeringDate = formatDateToDateWithoutTime(subtractNBusinessDaysToDate(new Date(getCloserBusinessDate(paymentAtDate)), 2));
  return getCloserBusinessDate(clickToPayDate ? getMaxDateWithoutTime(clickToPayDate, triggeringDate) : triggeringDate);
};
