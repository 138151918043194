export const areNumbersApproximately = (numberOne: number, numberTwo: number, proximity: number): boolean => {
  if (numberOne === numberTwo) {
    return true;
  }
  if (numberOne - proximity <= numberTwo && numberOne + proximity >= numberTwo) {
    return true;
  }

  return false;
};
