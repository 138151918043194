import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import { MAX_CONTENT, SHADOW_PANEL } from "../theme/ui-constants";

function getTemplateAreas({
  content,
  button,
  extraInfo,
}: {
  content: boolean;
  button: boolean;
  extraInfo: boolean;
}): CSSProperties["gridTemplateAreas"] {
  const closeArea = `"dialog-close"`;
  const headerArea = `"dialog-header"`;
  const contentArea = content ? `\n"dialog-content"` : "";
  const footerArea = button || extraInfo ? `\n"dialog-footer"` : "";

  return `${closeArea}\n${headerArea}${contentArea}${footerArea}`;
}

function getGridTemplateRows(
  {
    content,
    button,
    extraInfo,
  }: {
    content: boolean;
    button: boolean;
    extraInfo: boolean;
  },
  fullPage?: boolean,
): CSSProperties["gridTemplateRows"] {
  const closeRow = spacing(3);
  const headerRow = MAX_CONTENT;
  const contentRow = content ? ` ${fullPage ? "auto" : "1fr"}` : "";
  const footerRow = button || extraInfo ? ` ${MAX_CONTENT}` : "";

  return `${closeRow} ${headerRow}${contentRow}${footerRow}`;
}

const dialogCentering: CSSProperties = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
};

export const dialogGrid = (
  dialogIncludes: {
    illustration: boolean;
    description: boolean;
    content: boolean;
    button: boolean;
    extraInfo: boolean;
  },
  fullPage?: boolean,
  isEmbed?: boolean,
): CSSProperties => ({
  ...dialogCentering,
  height: fullPage ? "90vh" : "auto",
  width: fullPage ? "90vw" : isEmbed ? "90%" : spacing(7.5),
  maxHeight: "90%",
  padding: `0 0 ${spacing(3)} 0`,
  display: "grid",
  gridTemplateAreas: getTemplateAreas(dialogIncludes),
  gridTemplateRows: getGridTemplateRows(dialogIncludes, fullPage),
  borderRadius: "2px",
  boxShadow: SHADOW_PANEL,
  border: "none",
  overflow: "auto",
});
