import { addNBusinessDaysToDate } from "../../date-compute-business-days/add-n-business-days-to-date";
import { DateWithoutTime, formatDateToDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCollectionDateAutoload } from "../collection-date/get-collection-date-autoload";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getReceptionOfFundsDateAutoload = (payload: PaymentDatesPayload): DateWithoutTime => {
  const { payoutValidationDate, payoutCreationDate, payinTransferDate } = payload;

  if (payoutValidationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payoutValidationDate), 2));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  if (payoutCreationDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payoutCreationDate), 3));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  if (payinTransferDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(payinTransferDate), 4));
    return getCloserBusinessDate(receptionOfFundsDate);
  }

  const collectionDate = getCollectionDateAutoload(payload);

  if (collectionDate) {
    const receptionOfFundsDate = formatDateToDateWithoutTime(addNBusinessDaysToDate(new Date(collectionDate), 4));
    return getCloserBusinessDate(receptionOfFundsDate);
  }
  throw Error("Collection date is lacking");
};
