import * as React from "react";

export type CardEventChildrenFunction = (props: { isHovered: boolean }) => React.ReactNode;

function isCardEventChildrenFunction(children?: CardEventChildrenFunction | React.ReactNode): children is CardEventChildrenFunction {
  if (!children) {
    return false;
  }
  if (typeof children !== "function") {
    return false;
  }

  return true;
}

export const CardEvent: React.FunctionComponent<{
  style?: React.CSSProperties;
  id?: string;
  onClick?: React.DOMAttributes<HTMLDivElement>["onClick"];
  onMouseOver?: React.DOMAttributes<HTMLDivElement>["onMouseOver"];
  onMouseOut?: React.DOMAttributes<HTMLDivElement>["onMouseOut"];
  children?: CardEventChildrenFunction | React.ReactNode;
}> = ({ id, children, style, onClick, onMouseOver, onMouseOut }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  function onMouseOutEvent(event: React.MouseEvent<HTMLDivElement>) {
    setIsHovered(false);
    if (onMouseOut) {
      onMouseOut(event);
    }
  }

  function onMouseOverEvent(event: React.MouseEvent<HTMLDivElement>) {
    setIsHovered(true);
    if (onMouseOver) {
      onMouseOver(event);
    }
  }

  return (
    <div
      onClick={onClick}
      onMouseOver={onMouseOverEvent}
      onMouseOut={onMouseOutEvent}
      id={id}
      style={{
        height: "100%",
        position: "relative",
        ...style,
      }}
    >
      {isCardEventChildrenFunction(children) ? children({ isHovered }) : children}
    </div>
  );
};
