import { Box, Text } from "@libeo/design-system";
import * as React from "react";

export const CardTitle: React.FunctionComponent<{
  id?: string;
  justifyContent?: React.CSSProperties["justifyContent"];
  textAlign?: React.CSSProperties["textAlign"];
  textTransform?: React.CSSProperties["textTransform"];
}> = ({ children, id, justifyContent = "center", textAlign }) => (
  <Box id={id} paddingTop={2} justifyContent={justifyContent}>
    <Text size="standard" variation="medium" textAlign={textAlign}>
      {children}
    </Text>
  </Box>
);
