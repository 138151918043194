/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { border, color, createSystem, flexbox, grid, layout, position, shadow, space } from "system-props";
import { CSSAnimationPrimitiveProps } from "../../primitives/types";
import { filterDOMProps } from "../../utils/dom";

const DEFAULT_TAG = "span";

const notBusyOrActive = ':not([aria-busy="true"]):not([data-state="active"])';

const system = createSystem({
  strict: false,
  tokenPrefix: "noprefix",
  pseudoSelectors: {
    _hover: `&:hover${notBusyOrActive}`,
    _focus: "&:focus",
    _hoverAndFocus: "&:hover, &:focus",
    _disabled: `&[disabled], &[disabled]:hover${notBusyOrActive}, &[disabled]:focus, &[aria-disabled], &[aria-disabled]:hover${notBusyOrActive}, &[aria-disabled]:focus`,
    _active: '&[data-state="active"]',
  },
});

const extraProps = {
  cursor: true,
  outline: true,
  pointerEvents: true,
} as const;

const cssSystem = system({
  ...border,
  ...color,
  ...flexbox,
  ...grid,
  ...layout,
  ...position,
  ...shadow,
  ...space,
  ...extraProps,
});

export const CSSAnimation = React.forwardRef(
  ({ animation, as: Component = DEFAULT_TAG, ...props }, ref): React.ReactElement => {
    const theme = useTheme();
    const system = cssSystem({
      theme,
      ...props,
    });
    const componentProps = {
      ref,
      css: [animation, system],
      ...filterDOMProps({ ...props }, extraProps),
    };

    return <Component {...componentProps} />;
  },
) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, CSSAnimationPrimitiveProps>;

CSSAnimation.defaultProps = {
  display: "flex",
  flexDirection: "column",
  borderColor: "gray-athens-1",
  borderStyle: "solid",
};

export type { CSSAnimationPrimitiveProps };
