import * as React from "react";
import { FullPageCarouselButtonProps } from "./types";

export const useFullPageCarouselButton = ({
  buttonIndex,
  onClick,
}: FullPageCarouselButtonProps): {
  onClickStep: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} => {
  function onClickStep(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(buttonIndex);
  }
  return {
    onClickStep,
  };
};
