export const changeColorLightness = (hexaColor: string, colorShift: number): string => {
  if (colorShift > 255) {
    colorShift = 255;
  } else if (colorShift < -255) {
    colorShift = -255;
  }
  const hexaColorToChange = hexaColor.startsWith("#") ? hexaColor.split("#")[1] : hexaColor;
  const rgbMaxValue = 255;
  const changedHexaColor = (hexaColorToChange.match(/.{1,2}/g) ?? ["0", "0", "0"])
    .map((hexa: string) => parseInt(hexa, 16))
    .map((color) => Math.max(0, Math.min(rgbMaxValue, color + colorShift)))
    .map((newColor) => newColor.toString(16))
    .join("");
  return `#${changedHexaColor}`;
};
