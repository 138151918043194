import * as React from "react";
import { ToastContainer } from "react-toastify";
import { notificationContext } from "./notification.context";
import { useNotificationProvider } from "./useNotificationProvider.hook";

export const NotificationProvider: React.FunctionComponent = ({ children }) => {
  const notificationState = useNotificationProvider();
  return (
    <notificationContext.Provider value={notificationState}>
      <ToastContainer />
      {children}
    </notificationContext.Provider>
  );
};
