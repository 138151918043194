import { useState, useRef } from "react";
import useOnClickOutside from "use-onclickoutside";
import { DropdownButtonOption } from "./types";

function stopPropagation(mouseEvent: React.MouseEvent<HTMLDivElement>): void {
  return mouseEvent.stopPropagation();
}

function onClickPrimaryAction(dropdownButtonOption: DropdownButtonOption): (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void {
  return (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    if (dropdownButtonOption.onClick) {
      dropdownButtonOption.onClick();
    }
  };
}

export const useDropdownButton = (): {
  dropDownButtonRef: React.RefObject<HTMLDivElement>;
  isMenuOpen: boolean;
  handleMenuClick: () => void;
  stopPropagation: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
  onClickPrimaryAction: (dropdownButtonOption: DropdownButtonOption) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickSecondaryAction: () => void;
} => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const dropDownButtonRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropDownButtonRef, () => {
    if (isMenuOpen) {
      toggleMenu(false);
    }
  });

  const handleMenuClick = (): void => {
    toggleMenu((prevValue) => !prevValue);
  };

  const onClickSecondaryAction = (): void => {
    toggleMenu(false);
  };

  return {
    dropDownButtonRef,
    isMenuOpen,
    handleMenuClick,
    stopPropagation,
    onClickPrimaryAction,
    onClickSecondaryAction,
  };
};
