import * as React from "react";
export const Area: React.FunctionComponent<{ area: React.CSSProperties["gridArea"] }> = ({ area, children }) => {
  return (
    <div
      style={{
        gridArea: area,
      }}
    >
      {children}
    </div>
  );
};
