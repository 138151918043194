import { DateWithoutTime, getMaxOrOneDateWithoutTime } from "../../date-without-time/date-without-time";
import { getCloserBusinessDate } from "../get-closer-business-date";
import { PaymentDatesPayload } from "../payment-date.types";

export const getConditionalDateTopup = (
  payload: Pick<PaymentDatesPayload, "paymentStrategy" | "ibanValidationDate" | "validationDate" | "sufficientFundsDate">,
): DateWithoutTime | undefined => {
  const { ibanValidationDate, validationDate, sufficientFundsDate } = payload;

  const conditionalDate = getMaxOrOneDateWithoutTime(getMaxOrOneDateWithoutTime(sufficientFundsDate, ibanValidationDate), validationDate);
  return conditionalDate ? getCloserBusinessDate(conditionalDate) : undefined;
};
