import * as React from "react";
import Select, { ActionMeta, OptionsType, ValueType } from "react-select";
import Creatable from "react-select/creatable";
import {
  LoadingMessageComponent,
  MenuListComponent,
  MultipleValueComponent,
  MultiValueContainerComponent,
  ValueContainerComponent,
  MultiValueRemoveComponent,
  NoOptionsMessageComponent,
  OptionComponent,
  SelectClearIndicator,
  SelectDropDownIndicator,
  SingleValueComponent,
} from "./Select/SelectComponents";
import { FormItemWrapper } from "./Wrapper";
import { useInput } from "./hooks/useInput.hook";
import { useMultiSelect } from "./hooks/useMultiSelect.hook";
import { inputStyle, multiSelectStyles } from "./styles";
import { DefaultOptionsType, InputProps, MultiSelectProps } from "./types";

export const MultiSelect = <OptionType extends DefaultOptionsType>(props: MultiSelectProps<OptionType>): React.ReactElement => {
  const {
    style,
    hideSelectedOptions,
    required,
    isDisabled: disabled,
    isLoading,
    label,
    message,
    suffix,
    options,
    renderValue,
    renderMultipleValue,
    hasOverflow = false,
    renderMultiValueContainer,
    renderOption,
    description,
    renderNoValue,
    renderMenuList,
    renderMenuListFooter,
    renderMenuListHeader,
    isValidNewOption,
    id,
    onKeyDown,
    isCreatable,
    menuPlacement,
  } = props;
  const { color, onFocus, onBlur, inputFocus } = useInput((props as unknown) as InputProps);
  const { value, defaultInputOption, onChange, onScrollToBottom, inputHasValue } = useMultiSelect(props);

  function beforeOnChange(value: ValueType<OptionType>, action: ActionMeta<OptionType>): void {
    const selectedOption = (value as unknown) as OptionType[];
    onChange(selectedOption, action);
  }

  function beforeIsValidNewOption(inputValue: string, value: ValueType<OptionType>, options: OptionsType<OptionType>): boolean {
    const selectedOption = (value as unknown) as OptionType;
    const allOptions = (options as unknown) as OptionType[];
    if (isValidNewOption) {
      return Boolean(isValidNewOption(inputValue, selectedOption, allOptions));
    }
    const optionAlreadyExists = options.findIndex((option) => option.value === inputValue.toLowerCase()) > -1;
    return Boolean(inputValue) && !optionAlreadyExists;
  }

  return (
    <FormItemWrapper
      style={style}
      color={color}
      label={label}
      required={required}
      description={description}
      inputFocus={inputFocus}
      inputHasValue={inputHasValue}
      suffix={suffix}
      message={message}
    >
      {isCreatable ? (
        <Creatable<OptionType>
          isMulti
          onKeyDown={onKeyDown}
          hideSelectedOptions={hideSelectedOptions}
          id={id}
          onMenuScrollToBottom={onScrollToBottom}
          defaultValue={defaultInputOption}
          value={value}
          styles={multiSelectStyles}
          isClearable={hasOverflow}
          escapeClearsValue={true}
          placeholder=""
          onChange={beforeOnChange}
          isLoading={isLoading}
          isValidNewOption={beforeIsValidNewOption}
          components={{
            DropdownIndicator: suffix ? undefined : SelectDropDownIndicator(),
            ClearIndicator: SelectClearIndicator,
            Option: OptionComponent(renderOption),
            SingleValue: SingleValueComponent(renderValue),
            MultiValue: MultipleValueComponent(renderMultipleValue),
            MultiValueRemove: (props): JSX.Element | null => MultiValueRemoveComponent(hasOverflow, props),
            MultiValueContainer: MultiValueContainerComponent(renderMultiValueContainer),
            ValueContainer: ValueContainerComponent(hasOverflow),
            LoadingMessage: LoadingMessageComponent(),
            NoOptionsMessage: NoOptionsMessageComponent(renderNoValue),
            MenuList: MenuListComponent(renderMenuListHeader, renderMenuList, renderMenuListFooter),
          }}
          isDisabled={disabled}
          style={inputStyle(true, disabled, Boolean(label))}
          onFocus={onFocus as React.FocusEventHandler}
          onBlur={onBlur as React.FocusEventHandler}
          options={options}
          menuPlacement={menuPlacement}
        />
      ) : (
        <Select<OptionType>
          isMulti
          onKeyDown={onKeyDown}
          hideSelectedOptions={hideSelectedOptions}
          id={id}
          onMenuScrollToBottom={onScrollToBottom}
          defaultValue={defaultInputOption}
          value={value}
          styles={multiSelectStyles}
          isClearable={hasOverflow}
          escapeClearsValue={true}
          placeholder=""
          onChange={beforeOnChange}
          isLoading={isLoading}
          isValidNewOption={beforeIsValidNewOption}
          components={{
            DropdownIndicator: suffix ? undefined : SelectDropDownIndicator(),
            ClearIndicator: SelectClearIndicator,
            Option: OptionComponent(renderOption),
            SingleValue: SingleValueComponent(renderValue),
            MultiValue: MultipleValueComponent(renderMultipleValue),
            MultiValueRemove: (props): JSX.Element | null => MultiValueRemoveComponent(hasOverflow, props),
            MultiValueContainer: MultiValueContainerComponent(renderMultiValueContainer),
            ValueContainer: ValueContainerComponent(hasOverflow),
            LoadingMessage: LoadingMessageComponent(),
            NoOptionsMessage: NoOptionsMessageComponent(renderNoValue),
            MenuList: MenuListComponent(renderMenuListHeader, renderMenuList, renderMenuListFooter),
          }}
          isDisabled={disabled}
          style={inputStyle(true, disabled, Boolean(label))}
          onFocus={onFocus as React.FocusEventHandler}
          onBlur={onBlur as React.FocusEventHandler}
          options={options}
          menuPlacement={menuPlacement}
        />
      )}
    </FormItemWrapper>
  );
};
