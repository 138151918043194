import mixpanel from "mixpanel-browser";

export const init = (): void => {
  try {
    if (__LIBEO__.ENV !== "test") {
      mixpanel.init(__LIBEO__.MIXPANEL_TOKEN);
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}
};
