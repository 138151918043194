import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { VerticalCenter } from "../Layout";
import { MarginSmall } from "../Margin";
import { Bold } from "../Text";
import { Smaller } from "../Text/Smaller";
import { GREEN_CARABBEAN, WHITE, YELLOW_BRIGHT } from "../theme/ui-constants";
import { StatusIndicatorProps, StatusIndicatorVariation } from "./types";

const iconsAndColors: Record<StatusIndicatorVariation, { iconValue: IconValue; color: string }> = {
  success: { iconValue: IconValue.Checkmark, color: GREEN_CARABBEAN },
  warning: { iconValue: IconValue.Warning, color: YELLOW_BRIGHT },
};

export const StatusIndicator: React.FunctionComponent<StatusIndicatorProps> = ({ children, variation = "success" }) => {
  const { iconValue, color } = iconsAndColors[variation];

  return (
    <VerticalCenter>
      <MarginSmall right>
        <Icon circleBorder color={WHITE} backgroundColor={color} value={iconValue} width="14px" height="14px" noMargin={true} />
      </MarginSmall>
      <Smaller style={{ textTransform: "uppercase" }}>
        <Bold>
          <span style={{ color }}>{children}</span>
        </Bold>
      </Smaller>
    </VerticalCenter>
  );
};
