import config from "../../utils/config";

export const getUrls = async (): Promise<[string, string]> => {
  const domain = await config.domain();
  const graphqlUrl = `${domain}/graphql`;
  const baseDomaine = domain.split("//").pop();
  const socketDomain = domain.startsWith("http://") ? `ws://${baseDomaine}` : `wss://${baseDomaine}`;
  const socketUrl = `${socketDomain}/subscriptions`;

  return [graphqlUrl, socketUrl];
};
