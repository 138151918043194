import { ThemeProvider as EmotionThemeProvider, ThemeProviderProps as EmotionThemeProviderProps } from "@emotion/react";
import * as React from "react";
import { theme as defaultTheme } from "../../theme/rebranding";
import { REBRANDING_FONT } from "../../utils/constants";

export interface ThemeProviderProps extends Omit<EmotionThemeProviderProps, "theme"> {
  theme?: EmotionThemeProviderProps["theme"];
}

export const ThemeProvider = ({ theme = defaultTheme, ...props }: ThemeProviderProps): React.ReactElement => {
  React.useEffect(() => {
    document.body.className = document.body.className + "libeo-rebranding";
    const favicon = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (favicon) {
      favicon.href = "/rebranding/favicon/favicon.ico";
    }
  }, []);

  return (
    <>
      <style>{`
      html,
      body,
      #root {
        font-family: ${REBRANDING_FONT};
      }
  `}</style>
      <EmotionThemeProvider theme={theme} {...props} />
    </>
  );
};
