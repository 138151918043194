import { CSSProperties } from "react";

export enum AvatarSizeValues {
  SMALL = 33,
  DEFAULT = 41,
  BIG = 61,
  HUGE = 150,
}

export interface AvatarProps {
  text?: string | null;
  domainName?: string | null;
  icon?: React.ReactNode;
  size?: AvatarSizeValues;
  backgroundColor?: CSSProperties["backgroundColor"];
  boxShadow?: CSSProperties["boxShadow"];
}
