import * as Polymorphic from "@radix-ui/react-polymorphic";
import * as React from "react";
import { BoxPrimitive, BoxPrimitiveProps, GridItemProps } from "../../../primitives";

const gridTemplateAreasValue = (value: GridProps["areas"]): BoxPrimitiveProps["gridTemplateAreas"] => value?.map((v) => `"${v}"`).join("\n");

const gridTemplateValue = (
  value: GridProps["columns"] | GridProps["rows"],
): BoxPrimitiveProps["gridTemplateColumns"] | BoxPrimitiveProps["gridTemplateRows"] => (Array.isArray(value) ? value.join(" ") : value);

export type GridProps = Omit<
  BoxPrimitiveProps,
  | keyof GridItemProps
  | "gridGap"
  | "gridColumnGap"
  | "gridRowGap"
  | "gridAutoFlow"
  | "gridAutoColumns"
  | "gridAutoRows"
  | "gridTemplateAreas"
  | "gridTemplateColumns"
  | "gridTemplateRows"
> & {
  gap?: BoxPrimitiveProps["gridGap"];
  columnGap?: BoxPrimitiveProps["gridColumnGap"];
  rowGap?: BoxPrimitiveProps["gridRowGap"];
  autoFlow?: BoxPrimitiveProps["gridAutoFlow"];
  autoColumns?: BoxPrimitiveProps["gridAutoColumns"];
  autoRows?: BoxPrimitiveProps["gridAutoRows"];
  areas?: BoxPrimitiveProps["gridTemplateAreas"][];
  columns?: BoxPrimitiveProps["gridTemplateColumns"] | BoxPrimitiveProps["gridTemplateColumns"][];
  rows?: BoxPrimitiveProps["gridTemplateRows"] | BoxPrimitiveProps["gridTemplateRows"][];
};

const DEFAULT_TAG = "div";

type GridOwnProps = Polymorphic.Merge<Polymorphic.OwnProps<typeof BoxPrimitive>, GridProps>;
type GridPrimitive = Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, GridOwnProps>;

/**
 * `Grid` is a general purpose layout container using CSS Grid.
 */
export const Grid = React.forwardRef(
  ({ as = DEFAULT_TAG, gap, columnGap, rowGap, autoFlow, autoColumns, autoRows, areas, columns, rows, ...props }, ref) => {
    return (
      <BoxPrimitive
        ref={ref}
        as={as}
        {...props}
        display="grid"
        gridGap={gap}
        gridColumnGap={columnGap}
        gridRowGap={rowGap}
        gridAutoFlow={autoFlow}
        gridAutoColumns={autoColumns}
        gridAutoRows={autoRows}
        gridTemplateAreas={gridTemplateAreasValue(areas)}
        gridTemplateColumns={gridTemplateValue(columns)}
        gridTemplateRows={gridTemplateValue(rows)}
      />
    );
  },
) as GridPrimitive;
