import * as dateFns from "date-fns";
import moment from "moment";
import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { DatepickerProps } from "../types";

const DATE_STRING_FORMAT = "DD/MM/YYYY";
const DATE_REGEX_FORMAT = /\d{2}\/\d{2}\/\d{4}/;

const years = Array.from(Array(200).keys()).map((_v: number, i: number) => {
  return i + (dateFns.getYear(new Date()) - 100);
});

function onChangeYear(changeYear: (year: number) => void) {
  return (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeYear(parseInt(e.target.value, 10));
  };
}

export const useDatePicker = (props: DatepickerProps) => {
  const { defaultValue, onChangeDateValue } = props;
  const calendarRef = useRef<ReactDatePicker>(null);
  const defaultValueString = defaultValue ? moment(defaultValue).format(DATE_STRING_FORMAT) : "";
  const [dateValue, setDateValue] = useState<Date | undefined>(defaultValue);

  const months = moment.months();

  const onChangeDateFromDatePicker = (date: Date | null) => {
    const dateString = date ? moment(date).format(DATE_STRING_FORMAT) : "";
    if (onChangeDateValue) {
      onChangeDateValue(date || undefined, dateString);
    }
    setDateValue(date || undefined);
  };

  const onChangeDateString = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.currentTarget.value;
    if (DATE_REGEX_FORMAT.test(dateString)) {
      const date = moment(dateString, DATE_STRING_FORMAT).isValid() ? moment(dateString, DATE_STRING_FORMAT).toDate() : undefined;
      if (onChangeDateValue) {
        onChangeDateValue(date || undefined, dateString);
      }
      setDateValue(date);
    }
  };

  const onClickCalendarIcon = () => {
    calendarRef.current?.setOpen(true);
  };

  return {
    dateValue,
    defaultValueString,
    calendarRef,
    months,
    years,
    onClickCalendarIcon,
    onChangeDateFromDatePicker,
    onChangeDateString,
    onChangeYear,
  };
};
