import { Loader } from "@libeo/design-system";
import * as React from "react";
import { IconValue } from "../Icon/Icon";
import { ButtonIcon } from "./ButtonIcon";
import { defaultButtonStyles } from "./styles";
import { useButton } from "./useButton.hook";

export const PrimaryButtonWithColor: React.FunctionComponent<{
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  iconValueBefore?: IconValue;
  iconValueAfter?: IconValue;
  color: string;
  backgroundColor: string;
  width?: React.CSSProperties["width"];
  padding?: React.CSSProperties["padding"];
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}> = ({ id, color, backgroundColor, iconValueBefore, iconValueAfter, loading, disabled, onClick, width, padding, children, type = "submit" }) => {
  const { stopAndCallOnClick } = useButton({ onClick });

  return (
    <button
      type={type}
      style={defaultButtonStyles({ disabled, color, backgroundColor, width, padding })}
      id={id}
      disabled={disabled || loading}
      onClick={stopAndCallOnClick}
    >
      {loading && <Loader variation="secondary" />}
      <ButtonIcon position="before" loading={loading} iconValue={iconValueBefore} color={color} />
      {children}
      <ButtonIcon position="after" loading={loading} iconValue={iconValueAfter} color={color} />
    </button>
  );
};
