import { useState } from "react";

export const usePassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return {
    passwordVisible,
    togglePassword,
  };
};
