import * as React from "react";

export const VerticalCenter: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        alignItems: "center",
        height: "100%",
        display: "flex",
      }}
    >
      {children}
    </div>
  );
};
