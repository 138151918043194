import * as React from "react";
import { ORANGE_COQUELICOT } from "../theme/ui-constants";

export const TextOrangeCoquelicot: React.FunctionComponent<{ id?: string; children?: React.ReactNode }> = ({ id, children }) => {
  return (
    <span
      id={id}
      style={{
        color: ORANGE_COQUELICOT,
      }}
    >
      {children}
    </span>
  );
};
