import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { BLUE_PALATINATE, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT } from "../theme/ui-constants";

export const TextExternalLink: React.FunctionComponent<{ externalLink: string }> = ({ children, externalLink }) => {
  function openLinkInNewTab() {
    const newTab = window.open(externalLink, "_blank");
    if (newTab) {
      newTab.focus();
    }
  }

  return (
    <span
      style={{
        width: "fit-content",
        margin: 0,
        padding: 0,
        display: "inline-flex",
        alignItems: "center",
        textDecoration: "underline",
        flexDirection: "row",
        cursor: "pointer",
        fontSize: FONT_SIZE_DEFAULT,
        fontFamily: FONT_FAMILY_DEFAULT,
        color: BLUE_PALATINATE,
      }}
      onClick={openLinkInNewTab}
    >
      {children}
      <Icon value={IconValue.ArrowTopRight} width={16} height={16} noMargin />
    </span>
  );
};
