import * as Tabs from "@radix-ui/react-tabs";
import * as React from "react";
import { Box } from "../Layout";
import { Pill } from "../Pill";
import { Text } from "../Typography";

interface TabsTabProps extends Tabs.TabsTabOwnProps {
  id?: string;
  children: React.ReactNode;
  count?: number;
}

export function TabsTab({ children, count, ...props }: TabsTabProps): React.ReactElement {
  return (
    <Tabs.Tab
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      as={Box}
      cursor="pointer"
      marginBottom="-1px"
      _hover={{ borderBottomWidth: 2, borderColor: "gray-bombay" }}
      _active={{ borderBottomWidth: 2, borderColor: "blue-palatinate", color: "blue-palatinate" }}
      {...props}
    >
      <Box flexDirection="row" alignItems="center" height="16px">
        <Text variation="uppercase" color="inherit">
          {children}
        </Text>
        {count ? <Pill count={count} marginLeft={2} /> : null}
      </Box>
    </Tabs.Tab>
  );
}
