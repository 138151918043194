import * as React from "react";
import { MessageDescriptor } from "react-intl";
import { LandingVerticalCard } from "../LandingVerticalCard";
import { QUICKBOOKS_DK_GRAY, QUICKBOOKS_FONT, QUICKBOOKS_FONT_SIZE_REGULAR, QUICKBOOKS_GREEN } from "./qbo-constants";

export const QuickbooksVerticalCard: React.FunctionComponent<{
  contentId: MessageDescriptor["id"];
  imageSrc: string;
  imageWidth: string;
  fontSize?: string;
  textColor?: string;
  lineHeight?: number;
}> = ({ contentId, imageSrc, imageWidth, fontSize, textColor, lineHeight }) => {
  const defaultFontSize = fontSize ?? QUICKBOOKS_FONT_SIZE_REGULAR;
  const defaultTextColor = textColor ?? QUICKBOOKS_DK_GRAY;

  return (
    <LandingVerticalCard
      contentId={contentId}
      imageSrc={imageSrc}
      imageWidth={imageWidth}
      fontFamily={QUICKBOOKS_FONT}
      fontSize={defaultFontSize}
      textColor={defaultTextColor}
      stressColor={QUICKBOOKS_GREEN}
      lineHeight={lineHeight}
    />
  );
};
