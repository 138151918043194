import { Slot } from "@radix-ui/react-slot";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { Box } from "../Layout";
import { Text } from "../Typography";

export type TooltipProps = TooltipPrimitive.TooltipContentOwnProps &
  Pick<TooltipPrimitive.TooltipOwnProps, "open" | "onOpenChange"> & {
    children?: React.ReactNode;
    content?: React.ReactNode;
  };

/**
 * `Tooltip` is a popup that displays information related to an element.
 */
export const Tooltip = ({ children, content, side = "top", align = "center", open, onOpenChange, ...props }: TooltipProps): React.ReactElement => {
  return (
    <TooltipPrimitive.Root open={open} onOpenChange={onOpenChange} delayDuration={0}>
      <TooltipPrimitive.Trigger as={Slot}>{children}</TooltipPrimitive.Trigger>
      {content && (
        <TooltipPrimitive.Content
          as={Box}
          {...props}
          side={side}
          align={align}
          sideOffset={8}
          backgroundColor="blue-maastricht"
          padding={2}
          borderRadius="large"
          maxWidth="280px"
        >
          <Text size="small" color="white">
            {content}
          </Text>
        </TooltipPrimitive.Content>
      )}
    </TooltipPrimitive.Root>
  );
};
