import * as React from "react";
import ReactDatePicker from "react-datepicker";
import "./datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { useCustomHeader } from "./Date/useCustomHeader";
import { DatePickerIcon } from "./Icon/icons";
import { FormItemWrapper } from "./Wrapper";
import { useDateRangePicker } from "./hooks/useDateRangePicker.hook";
import { useInput } from "./hooks/useInput.hook";
import { inputStyle } from "./styles";
import { DateRangePickerProps, InputProps } from "./types";

export const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (props) => {
  const { required, id, label, isDisabled, message, minDate, description, style, renderDate, ...rest } = props;
  const { color, inputFocus } = useInput(props as InputProps);
  const { onChangeDateFromDatePicker, calendarRef, openDatePicker, startDate, endDate } = useDateRangePicker(props);
  const customHeader = useCustomHeader();

  return (
    <div>
      <FormItemWrapper
        style={style}
        color={color}
        label={label}
        description={description}
        required={required}
        inputFocus={inputFocus}
        inputHasValue={Boolean(startDate && endDate)}
        onClickSuffix={openDatePicker}
        suffix={<DatePickerIcon />}
        message={message}
        disabled={isDisabled}
      >
        <div className="date-picker" onClick={openDatePicker}>
          <ReactDatePicker
            id={id}
            ref={calendarRef}
            minDate={minDate}
            selectsRange
            locale="fr"
            autoComplete="off"
            disabled={isDisabled}
            renderCustomHeader={customHeader}
            dateFormat="dd/MM/yyyy"
            onChange={onChangeDateFromDatePicker}
            selected={endDate}
            openToDate={startDate}
            shouldCloseOnSelect={false}
            portalId="date-picker"
            popperModifiers={{ flip: { enabled: false } }}
            customInput={
              <div className="input-date">
                {startDate && endDate && (
                  <div style={inputStyle(true, Boolean(isDisabled), Boolean(label))}>
                    <span style={{ fontSize: 11 }}>{`${renderDate(startDate)} - ${renderDate(endDate)}`}</span>
                  </div>
                )}
              </div>
            }
            {...rest}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </FormItemWrapper>
    </div>
  );
};
