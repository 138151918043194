import { Box, BoxProps } from "@libeo/design-system";
import * as React from "react";
import { BACKGROUND_OVERLAY } from "../theme";

export const Overlay: React.FunctionComponent<BoxProps> = ({ children, ...props }) => {
  return (
    <Box position="fixed" top={0} left={0} width="100vw" height="100vh" backgroundColor={BACKGROUND_OVERLAY} zIndex={99} {...props}>
      {children}
    </Box>
  );
};
