/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/dynamic-import-chunkname */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Loader } from "@libeo/design-system";
import * as React from "react";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import { RenderRouteProps } from "./components/Route/type";
import { useResetNotification } from "./hooks/notification.hook";
import { useTrackingPageVisit } from "./hooks/useTrackingPageVisit";
import { claimRoute, emptyRoute, emptyRoutes, kycRoutes, privateRoutes, publicRoutes, externalRoutes, assistedOnboardingRoutes } from "./routes";
import { ROUTE_PATH } from "./routes-constants";

const PublicRoute = Loadable({
  loading: Loader,
  loader: () => import("./components/Route/PublicRoute"),
});

const PrivateRoute = Loadable({
  loading: Loader,
  loader: () => import("./components/Route/PrivateRoute"),
});

const KycRoute = Loadable({
  loading: Loader,
  loader: () => import("./components/Route/KycRoute"),
});

const ExternalRoute = Loadable({
  loading: Loader,
  loader: () => import("./components/Route/ExternalRoute"),
});

const PrivateLayout = Loadable({
  loading: Loader,
  loader: () => import("./components/Layout/PrivateLayout"),
});

if (__LIBEO__.ENV !== "test") {
  const tagManagerArgs = {
    gtmId: __LIBEO__.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

const renderRoute = (AppRoute: RenderRouteProps, index: number): JSX.Element => {
  return (
    <Route key={`${AppRoute.path}-${index}`} path={AppRoute.path} exact={AppRoute.exact}>
      <React.Suspense fallback={AppRoute.RenderComponentFallBack ? <AppRoute.RenderComponentFallBack /> : ""}>
        <AppRoute.RenderComponent />
      </React.Suspense>
    </Route>
  );
};

const renderRouteEmbed = (AppRoute: RenderRouteProps, index: number): JSX.Element => {
  return (
    <Route key={`${ROUTE_PATH.EMBED}${AppRoute.path}-${index}`} path={`${ROUTE_PATH.EMBED}${AppRoute.path}`} exact={AppRoute.exact}>
      <React.Suspense fallback={AppRoute.RenderComponentFallBack ? <AppRoute.RenderComponentFallBack /> : ""}>
        <AppRoute.RenderComponent />
      </React.Suspense>
    </Route>
  );
};

export const AppRouter: React.FunctionComponent = () => {
  useTrackingPageVisit();
  useResetNotification();
  return (
    <Route path={ROUTE_PATH.EMPTY_ROUTE}>
      <Switch>
        {emptyRoutes.map((AppRoute, i) => {
          const pathKey = `${AppRoute.path?.toString()}-${i}`;
          return (
            <Route key={pathKey} path={AppRoute.path} exact={AppRoute.exact}>
              <PrivateLayout>{renderRoute(AppRoute, 0)}</PrivateLayout>
            </Route>
          );
        })}
        <Route path={`${ROUTE_PATH.EMBED}${ROUTE_PATH.HOME}`}>
          <PrivateLayout>
            <PrivateRoute>{privateRoutes.map(renderRouteEmbed)}</PrivateRoute>
          </PrivateLayout>
        </Route>
        <Route path={ROUTE_PATH.HOME}>
          <PrivateLayout>
            <PrivateRoute>{privateRoutes.map(renderRoute)}</PrivateRoute>
          </PrivateLayout>
        </Route>
        <Route path={ROUTE_PATH.CLAIM}>
          <PrivateLayout>
            <PrivateRoute>{claimRoute.map(renderRoute)}</PrivateRoute>
          </PrivateLayout>
        </Route>
        <Route path={ROUTE_PATH.KYC}>
          <KycRoute>{kycRoutes.map(renderRoute)}</KycRoute>
        </Route>
        {publicRoutes.map((AppRoute, i) => {
          const pathKey = `${AppRoute.path?.toString()}-${i}`;
          return (
            <Route key={pathKey} path={AppRoute.path} exact={AppRoute.exact}>
              <PublicRoute>{renderRoute(AppRoute, 0)}</PublicRoute>
            </Route>
          );
        })}
        {externalRoutes.concat(assistedOnboardingRoutes).map((AppRoute, i) => {
          const pathKey = `${AppRoute.path?.toString()}-${i}`;
          return (
            <Route key={pathKey} path={AppRoute.path} exact={AppRoute.exact}>
              <ExternalRoute>{renderRoute(AppRoute, 0)}</ExternalRoute>
            </Route>
          );
        })}
        <Route path={emptyRoute.path}>
          <PublicRoute>
            <React.Suspense fallback={emptyRoute.RenderComponentFallBack ? <emptyRoute.RenderComponentFallBack /> : ""}>
              <emptyRoute.RenderComponent />
            </React.Suspense>
          </PublicRoute>
        </Route>
      </Switch>
    </Route>
  );
};
