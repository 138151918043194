import * as React from "react";
import { useState } from "react";
import Select from "react-select";
import { useDebounce } from "../../hooks/useDebounce.hook";
import { SelectCompanyRow } from "./SelectCompanyRow";
import { SelectCompanyOptionsType, SelectCompanyType } from "./types";

export const useSelectCompany = (props: SelectCompanyType): { loading: boolean } & any => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { defaultValue, value, onInputValueChange } = props;
  const selectCreateRef = React.useRef<Select<SelectCompanyOptionsType>>(null);
  const select = selectCreateRef.current?.select;
  const hasValue = select ? Boolean(select?.state?.selectValue?.length) : Boolean(defaultValue) || Boolean(value);
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue, 300);

  React.useEffect(() => {
    if (props.forwardRef) {
      props.forwardRef(selectCreateRef);
    }
  }, [defaultValue, props, selectCreateRef]);

  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    props.onFocus && props.onFocus(event);
    setIsInputFocused(true);
  };
  const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    setIsInputFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBeforeInputValueChangeAsync = async (value: string): Promise<void> => {
    if (onInputValueChange) {
      await onInputValueChange(value);
    }
  };

  React.useEffect(() => {
    if (debouncedInputValue !== "") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onBeforeInputValueChangeAsync(debouncedInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  const onBeforeInputValueChange = (value: string): void => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  };

  const renderOption = (editable: boolean) => {
    return function (option: SelectCompanyOptionsType): JSX.Element {
      return SelectCompanyRow(option, editable, props.renderButton, props.renderTag, props.siretText, props.alreadyInLibeoText);
    };
  };

  return {
    renderOption,
    inputValue,
    selectCreateRef,
    isLoading: props.isLoading,
    isInputFocused,
    hasValue,
    onFocus,
    onBlur,
    onBeforeInputValueChangeAsync,
    onBeforeInputValueChange,
  };
};
