import { useState } from "react";

export const useLoading = (
  defaultLoading?: boolean,
): {
  isLoading: boolean;
  loadBegin: () => void;
  loadEnd: () => void;
} => {
  const [isLoading, setIsLoading] = useState(Boolean(defaultLoading));

  const loadBegin = (): void => {
    setIsLoading(true);
  };

  const loadEnd = (): void => {
    setIsLoading(false);
  };

  return {
    isLoading: isLoading,
    loadBegin,
    loadEnd,
  };
};
