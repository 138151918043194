import { useEffect } from "react";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";
import { setSessionStorage } from "../../utils/sessionStorage";
import { sleep } from "../../utils/sleep.utils";
import { extractParamFromUrl } from "../../utils/urlParametersExtractor";
import { EventActionToParentWindow, EventMessageToParentWindow, useInvoiceEmbedPostMessage } from "../useInvoiceEmbedPostMessage.hook";
import { RedirectThirdPartyContext } from "./redirectThirdParty.types";

export const usePayInvoiceThirdPartyActions = (): RedirectThirdPartyContext => {
  const redirectThirdParty = getLocalStorage<"redirectThirdParty">("redirectThirdParty");
  const shouldCloseWindowThirdParty = getLocalStorage<"closeWindowThirdParty">("closeWindowThirdParty");
  const { triggerMessageToParentWindow } = useInvoiceEmbedPostMessage();
  const isUserRedirectAfterPayment = Boolean(redirectThirdParty) || Boolean(shouldCloseWindowThirdParty);

  const queryStringParams = extractParamFromUrl<{
    redirectThirdParty?: string;
    closeWindowThirdParty?: boolean;
    thirdPartyConsumer?: string;
  }>(window.location.search);

  useEffect(() => {
    if (queryStringParams.redirectThirdParty) {
      setLocalStorage<"redirectThirdParty">("redirectThirdParty", queryStringParams.redirectThirdParty);
    }
    if (queryStringParams.closeWindowThirdParty) {
      setLocalStorage<"closeWindowThirdParty">("closeWindowThirdParty", Boolean(queryStringParams.closeWindowThirdParty));
    }
    if (queryStringParams.thirdPartyConsumer) {
      setSessionStorage<"thirdPartyConsumer">("thirdPartyConsumer", queryStringParams.thirdPartyConsumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postEventMessageWithAction = async (
    eventName: EventMessageToParentWindow,
    action?: EventActionToParentWindow,
    reason?: string,
  ): Promise<void> => {
    await sleep(eventName === "success" ? 3000 : 0);
    triggerMessageToParentWindow(eventName, action, reason);
  };

  const handleUserFinishedActions = async (eventName?: EventMessageToParentWindow): Promise<void> => {
    await sleep(eventName === "success" ? 3000 : 0);

    if (redirectThirdParty) {
      document.location.href = redirectThirdParty;
      setLocalStorage<"redirectThirdParty">("redirectThirdParty", null);
    }

    if (shouldCloseWindowThirdParty) {
      setLocalStorage<"closeWindowThirdParty">("closeWindowThirdParty", false);
      window.close();
    }
  };

  return {
    handleUserFinishedActions,
    postEventMessageWithAction,
    isUserRedirectAfterPayment,
  };
};
