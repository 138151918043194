import { CSSProperties } from "react";
import { spacing } from "../theme/spacing";
import {
  SHADOW_PANEL,
  FONT_EXTRA_BOLD,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_DEFAULT,
  GRAY_ATHENS_2,
  GRAY_BOMBAY,
  GRAY_BRIGHT,
  WHITE,
} from "../theme/ui-constants";

// special gray used for selected row
const GRAY_SELECTED = "#EAEBEF";

export const renderTitle: CSSProperties = {
  textTransform: "uppercase",
  fontFamily: FONT_FAMILY_DEFAULT,
  fontWeight: FONT_EXTRA_BOLD,
  fontSize: "10px",
  color: GRAY_BOMBAY,
  backgroundColor: WHITE,
  padding: spacing(0, 2, 0, 2),
};
export const trStyle = (
  isHovered: boolean,
  isSelected: boolean,
  rowHeight: React.CSSProperties["height"],
  styles?: React.CSSProperties,
): React.CSSProperties => ({
  height: rowHeight || "60px",
  background: isSelected ? GRAY_SELECTED : isHovered ? GRAY_ATHENS_2 : "transparent",
  cursor: isHovered ? "pointer" : "default",
  ...styles,
});

export const tdStyle = (styles?: React.CSSProperties, textAlign: React.CSSProperties["textAlign"] = "left"): React.CSSProperties => ({
  verticalAlign: "middle",
  textAlign: textAlign,
  position: "static",
  fontSize: FONT_SIZE_DEFAULT,
  color: GRAY_BRIGHT,
  ...styles,
});

export const tableStyle = (isMobile: boolean, hasBackground: boolean, hasBoxShadow: boolean): React.CSSProperties => ({
  borderSpacing: "0px",
  borderCollapse: "separate",
  width: "100%",
  background: hasBackground ? WHITE : "transparent",
  boxShadow: hasBoxShadow ? SHADOW_PANEL : "none",
  tableLayout: isMobile ? "auto" : "fixed",
});
