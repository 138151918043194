import { useState } from "react";
import { InputProps } from "../components/Input/types";

interface UseInput {
  hasValue: boolean;
  isFocus: boolean;
  onFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const useInput = (props: InputProps): UseInput => {
  const [hasValue, setInputHasValue] = useState(Boolean(props.value) || Boolean(props.defaultValue));
  const [isFocus, setInputFocus] = useState(false);

  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setInputFocus(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setInputFocus(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const newValue = event.currentTarget.value;
    setInputHasValue(newValue === "" ? false : true);
    if (props.onChangeStringValue) {
      props.onChangeStringValue(newValue);
    }
  };

  const hasValueGlobal = hasValue || Boolean(props.value);

  return {
    hasValue: hasValueGlobal,
    isFocus,
    onFocus,
    onBlur,
    onChange,
  };
};
