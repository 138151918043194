import * as React from "react";
import { TextPrimitive, TextPrimitiveProps } from "../../../primitives";
import { REBRANDING_FONT_BOLD } from "../../../utils/constants";
import { HeadingLevel, HeadingProps, HeadingSize } from "../types";

type HeadingTag = `h${HeadingLevel}`;

const mapHeadingSizeToStyle: Record<"rebranding" | "default", Record<HeadingSize, TextPrimitiveProps>> = {
  rebranding: {
    xxl: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1,
    },
    xl: {
      fontSize: 32,
      fontWeight: REBRANDING_FONT_BOLD,
      lineHeight: 1,
    },
    l: {
      fontSize: 24,
      fontWeight: REBRANDING_FONT_BOLD,
      lineHeight: "32px",
    },
    m: {
      fontSize: 20,
      fontWeight: REBRANDING_FONT_BOLD,
      lineHeight: 1.2,
    },
    s: {
      fontSize: 20,
      fontWeight: REBRANDING_FONT_BOLD,
      lineHeight: 1.2,
    },
    xs: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.5,
    },
  },
  default: {
    xxl: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1,
    },
    xl: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    l: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    m: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    s: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    xs: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.5,
    },
  },
};

/**
 * This primary component must be used for every titles.
 */
export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ size = "s", level = "2", hasLineThrough = false, ...props }, ref) => {
  return (
    <TextPrimitive
      ref={ref}
      as={`h${level}` as HeadingTag}
      fontFamily="headings"
      {...(hasLineThrough ? { textDecoration: "line-through" } : {})}
      {...mapHeadingSizeToStyle["rebranding"][size]}
      {...props}
    />
  );
});

export type { HeadingProps };
