type AmountInCent = number & { __int__: void };

type Amount = number;

// eslint-disable-next-line libeo/no-unsafe-currency-calculus
const roundFloatAmountToAmountInCent = (floatingNumber: number): AmountInCent => Math.round(floatingNumber * 100) as AmountInCent;

const addAmountInCents = (amountInCent1: AmountInCent, amountInCent2: AmountInCent): AmountInCent =>
  // eslint-disable-next-line libeo/no-unsafe-currency-calculus
  Math.round(amountInCent1 + amountInCent2) as AmountInCent;

const substractAmountInCents = (amountInCent: AmountInCent, amountInCentToSubstract: AmountInCent): AmountInCent =>
  // eslint-disable-next-line libeo/no-unsafe-currency-calculus
  Math.round(amountInCent - amountInCentToSubstract) as AmountInCent;

const multiplyAmountInCents = (AmountInCent: AmountInCent, amountInCentToMultiply: AmountInCent): AmountInCent =>
  // eslint-disable-next-line libeo/no-unsafe-currency-calculus
  (AmountInCent * amountInCentToMultiply) as AmountInCent;

const divideAmountInCents = (AmountInCent: AmountInCent, amountInCentToDivide: AmountInCent): AmountInCent =>
  // eslint-disable-next-line libeo/no-unsafe-currency-calculus
  (AmountInCent / amountInCentToDivide) as AmountInCent;

// eslint-disable-next-line libeo/no-unsafe-currency-calculus
const transformAmountInCentToFloatAmount = (amountInCents: AmountInCent): Amount => amountInCents / 100;

// eslint-disable-next-line libeo/no-unsafe-currency-calculus
const transformAmountInHundredthOfACentToFloatAmount = (amountInCents: AmountInCent): Amount => amountInCents / 10000;

export const safelyAddNumbers = (floatingNumbers: number[]): number => {
  const centTotal = floatingNumbers.reduce(
    (previousTotal: AmountInCent, number) => addAmountInCents(previousTotal, roundFloatAmountToAmountInCent(number)),
    roundFloatAmountToAmountInCent(0),
  );
  return transformAmountInCentToFloatAmount(centTotal);
};

export const safelySubstractNumbers = (floatingNumber1: number, floatingNumber2: number): number => {
  const centTotal = substractAmountInCents(roundFloatAmountToAmountInCent(floatingNumber1), roundFloatAmountToAmountInCent(floatingNumber2));
  return transformAmountInCentToFloatAmount(centTotal);
};

export const safelyMultiplyNumbers = (floatingNumber1: number, floatingNumber2: number): number => {
  const centTotal = multiplyAmountInCents(roundFloatAmountToAmountInCent(floatingNumber1), roundFloatAmountToAmountInCent(floatingNumber2));
  return transformAmountInHundredthOfACentToFloatAmount(centTotal) as AmountInCent;
};

export const safelyDivideNumbers = (floatingNumber1: number, floatingNumber2: number): number => {
  const centTotal = divideAmountInCents(roundFloatAmountToAmountInCent(floatingNumber1), roundFloatAmountToAmountInCent(floatingNumber2));
  return centTotal;
};

export const roundToTwoDecimals = (floatingNumber: number): number => {
  return transformAmountInCentToFloatAmount(roundFloatAmountToAmountInCent(floatingNumber));
};
