import * as React from "react";
import { LandingHorizontalCard } from "../LandingHorizontalCard";
import { QUICKBOOKS_DK_GRAY, QUICKBOOKS_FONT, QUICKBOOKS_FONT_SIZE_REGULAR, QUICKBOOKS_FONT_WEIGHT_PLUS, QUICKBOOKS_GRAY_03 } from "./qbo-constants";

export const QuickbooksHorizontalCard: React.FunctionComponent<{
  imageSrc: string;
  imageWidth: string;
  title: string;
  description: string;
  titleColor?: string;
  titleSize?: string;
  titleWeight?: number;
  descriptionColor?: string;
  descriptionSize?: string;
  lineHeight?: number;
}> = ({ imageSrc, imageWidth, title, titleColor, titleSize, titleWeight, description, descriptionColor, descriptionSize, lineHeight }) => {
  const defaultTitleColor = titleColor ?? QUICKBOOKS_DK_GRAY;
  const defaultTitleSize = titleSize ?? QUICKBOOKS_FONT_SIZE_REGULAR;
  const defaultDescriptionSize = descriptionSize ?? QUICKBOOKS_FONT_SIZE_REGULAR;
  const defaultTitleWeight = titleWeight ?? QUICKBOOKS_FONT_WEIGHT_PLUS;
  const defaultDescriptionColor = descriptionColor ?? QUICKBOOKS_GRAY_03;

  return (
    <LandingHorizontalCard
      imageSrc={imageSrc}
      imageWidth={imageWidth}
      fontFamily={QUICKBOOKS_FONT}
      title={title}
      description={description}
      titleColor={defaultTitleColor}
      titleSize={defaultTitleSize}
      titleWeight={defaultTitleWeight}
      descriptionColor={defaultDescriptionColor}
      descriptionSize={defaultDescriptionSize}
      lineHeight={lineHeight}
    ></LandingHorizontalCard>
  );
};
