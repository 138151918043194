import * as React from "react";
import { BLUE_PALATINATE } from "../theme/ui-constants";

export const HorizontalProgressBar: React.FunctionComponent<{
  currentStep: number;
  totalSteps: number;
}> = ({ currentStep, totalSteps }) => {
  const progressRatio = Math.round((currentStep / totalSteps) * 100);

  return (
    <div
      style={{
        width: `${progressRatio}vw`,
        height: "5px",
        backgroundColor: BLUE_PALATINATE,
        position: "absolute",
        top: "0px",
        left: "0px",
      }}
    />
  );
};
