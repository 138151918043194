import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../Icon";
import { IconValue } from "../../Icon/icon.type";
import { copyButtonStyle } from "./styles";

export const CopyButton: React.FunctionComponent<{ copy: () => void; isCopied: boolean }> = ({ copy, isCopied }) => {
  return (
    <div style={copyButtonStyle} onClick={copy}>
      {isCopied ? <Icon value={IconValue.Checkmark} color="@blue" /> : <FormattedMessage id="common.transfer.copy_btn" />}
    </div>
  );
};
