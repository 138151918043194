import {
  REBRANDING_BLACK_5,
  REBRANDING_BLACK_60,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95,
  REBRANDING_INTERFACE_UI_RED_RED_BLACK_25,
  REBRANDING_INTERFACE_UI_RED_RED_WHITE_95,
} from "@libeo/design-system";
import * as React from "react";
import { spacing } from "../theme/spacing";
import { FONT_SIZE_SMALL, FONT_FAMILY_DEFAULT } from "../theme/ui-constants";

export type TagVariation = "default" | "success" | "warning" | "error";

export interface TagProps {
  label: React.ReactNode;
  variation?: TagVariation;
  id?: string;
  width?: React.CSSProperties["width"];
}

const mapVariationToStyle: Record<TagVariation, React.CSSProperties> = {
  default: {
    color: REBRANDING_BLACK_60,
    backgroundColor: REBRANDING_BLACK_5,
  },
  success: {
    color: REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25,
    backgroundColor: REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95,
  },
  warning: {
    color: REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
    backgroundColor: REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95,
  },
  error: {
    color: REBRANDING_INTERFACE_UI_RED_RED_BLACK_25,
    backgroundColor: REBRANDING_INTERFACE_UI_RED_RED_WHITE_95,
  },
};

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(({ label, variation = "default", id, width, ...props }, ref) => {
  return (
    <div
      ref={ref}
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: FONT_SIZE_SMALL,
        fontFamily: FONT_FAMILY_DEFAULT,
        maxWidth: "100%",
        width: width ? width : "fit-content",
        minHeight: "24px",
        lineHeight: "24px",
        textAlign: "center",
        whiteSpace: "nowrap",
        padding: spacing(0, 1),
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...mapVariationToStyle[variation],
      }}
      {...props}
    >
      {label}
    </div>
  );
});
