import { Theme } from "@emotion/react";
import {
  REBRANDING_GREEN_INK_GREEN_BLACK_20,
  REBRANDING_GREEN_INK_GREEN,
  REBRANDING_FONT,
  REBRANDING_SHADOW_OVERLAY,
  REBRANDING_SHADOW_PANEL,
  REBRANDING_SHADOW_POPOUT,
  REBRANDING_GREEN_INK_GREEN_WHITE_95,
  REBRANDING_BLACK_100,
  REBRANDING_WHITE_PAPER,
  REBRANDING_WHITE_SAND,
  REBRANDING_BLACK_5,
  REBRANDING_BLACK_10,
  REBRANDING_BLACK_20,
  REBRANDING_BLACK_40,
  REBRANDING_BLACK_60,
  REBRANDING_INTERFACE_UI_GREEN_GREEN,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25,
  REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
  REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95,
  REBRANDING_INTERFACE_UI_RED_RED,
  REBRANDING_INTERFACE_UI_RED_RED_BLACK_25,
  REBRANDING_INTERFACE_UI_RED_RED_WHITE_95,
  REBRANDING_FONT_HEADLINE,
  REBRANDING_PEN_ORANGE_PEN_ORANGE,
  REBRANDING_GREEN_INK_GREEN_BLACK_40,
  REBRANDING_GREEN_INK_GREEN_WHITE_85,
} from "../utils/constants";

const isQuickbooksEmbeded = window.location.href.includes("embed/quickbooksLibeoPay");

export const theme: Theme = {
  fonts: {
    headings: REBRANDING_FONT_HEADLINE,
    texts: REBRANDING_FONT,
  },
  colors: {
    black: REBRANDING_BLACK_100,
    "black-5": REBRANDING_BLACK_5,
    "black-10": REBRANDING_BLACK_10,
    "black-60": REBRANDING_BLACK_60,
    "black-40": REBRANDING_BLACK_40,
    white: REBRANDING_WHITE_PAPER,
    "white-sand": REBRANDING_WHITE_SAND,

    "gray-bright": REBRANDING_BLACK_60,
    "gray-shuttle": REBRANDING_BLACK_40,
    "gray-bombay": REBRANDING_BLACK_20,
    "gray-athens-1": REBRANDING_GREEN_INK_GREEN_WHITE_85,
    "gray-athens-2": REBRANDING_GREEN_INK_GREEN_WHITE_95,

    "blue-maastricht": REBRANDING_BLACK_100,
    "blue-midnight": REBRANDING_GREEN_INK_GREEN_BLACK_40,
    "blue-hover": isQuickbooksEmbeded ? "#108000" : REBRANDING_GREEN_INK_GREEN_BLACK_20,
    "blue-palatinate": isQuickbooksEmbeded ? "#29A01C" : REBRANDING_GREEN_INK_GREEN,
    "blue-vivid-sky": REBRANDING_PEN_ORANGE_PEN_ORANGE,
    "blue-titan-white": "#F5F5FF",

    "green-british-racing": REBRANDING_INTERFACE_UI_GREEN_GREEN,
    "green-generic-viridian": REBRANDING_INTERFACE_UI_GREEN_GREEN,
    "green-carabbean": REBRANDING_INTERFACE_UI_GREEN_GREEN,
    "green-medium-aquamarine": REBRANDING_INTERFACE_UI_GREEN_GREEN_BLACK_25,
    "green-aqua-squeeze": REBRANDING_INTERFACE_UI_GREEN_GREEN_WHITE_95,

    "pink-dark-scarlet": REBRANDING_INTERFACE_UI_RED_RED,
    "pink-pictorial-carmine": REBRANDING_INTERFACE_UI_RED_RED,
    "pink-vivid-raspberry": REBRANDING_INTERFACE_UI_RED_RED_BLACK_25,
    "pink-hot": REBRANDING_INTERFACE_UI_RED_RED_WHITE_95,

    "orange-coquelicot": "#E63900",
    "orange-orioles": "#E84B17",
    "orange-smashed-pumpkin": "#FF6633",
    "orange-coral": "#FF8158",
    "orange-provincial-pink": "#FEF5F2",

    "yellow-bright": REBRANDING_INTERFACE_UI_YELLOW_YELLOW,
    "yellow-orange": REBRANDING_INTERFACE_UI_YELLOW_YELLOW,
    "yellow-usc-gold": REBRANDING_INTERFACE_UI_YELLOW_YELLOW_BLACK_25,
    "yellow-gargoyle-gas": REBRANDING_INTERFACE_UI_YELLOW_YELLOW_WHITE_95,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: {
    small: "2px",
    medium: "4px",
    large: "8px",
    pill: "50vw",
    round: "100%",
  },
  shadows: {
    panel: REBRANDING_SHADOW_PANEL,
    overlay: REBRANDING_SHADOW_OVERLAY,
    popout: REBRANDING_SHADOW_POPOUT,
  },
  breakpoints: ["768px", "1280px", "1536px"],
};
