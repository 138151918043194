import { Box } from "@libeo/design-system";
import * as React from "react";

export const CardChildren: React.FunctionComponent<{
  alignItems?: string;
}> = ({ children, alignItems = "flex-end" }) => (
  <Box flexDirection="row" justifyContent="center" alignItems={alignItems} flex={1}>
    {children}
  </Box>
);
