import * as React from "react";
import { ConfirmDialogType } from "./types";

interface ConfirmProps {
  ask: (options: ConfirmDialogType) => Promise<boolean>;
}

interface ConfirmInterface {
  confirmDialog?: ConfirmProps;
}

const Context = React.createContext<ConfirmInterface>({
  confirmDialog: {
    ask: async () => false,
  },
});

const { Provider, Consumer } = Context;
export { Context, Provider, Consumer };
