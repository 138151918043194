import * as React from "react";
import { InputMultipleEmails } from "../Form/InputMultipleEmails";
import { Textarea } from "../Form/Textarea";
import { DefaultOptionsType } from "../Form/types";

export const EmailForm: React.FunctionComponent<{
  emailsInputLabel: React.ReactNode;
  textAreaLabel: React.ReactNode;
  onEmailChange: (emails: DefaultOptionsType[]) => void;
  onCommentChange: (comment: string) => void;
  defaultComment?: string;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
}> = ({ emailsInputLabel, textAreaLabel, onEmailChange, onCommentChange, defaultComment, onSubmit }) => {
  return (
    <div style={{ minHeight: "100px", width: "100%" }}>
      <form id="email-form" onSubmit={onSubmit}>
        <InputMultipleEmails key="email-form-emails-input" id="email-form-emails-input" label={emailsInputLabel} onChangeOptions={onEmailChange} />

        <Textarea
          onChangeStringValue={onCommentChange}
          key="email-form-comment-text-area"
          id="email-form-comment-text-area"
          defaultValue={defaultComment || ""}
          required={false}
          label={textAreaLabel}
        />
      </form>
    </div>
  );
};
