import { useEffect, useState } from "react";
import { CodeProps } from "../types";
function isNewStringValueLengthGreaterThanOneChar(inputRef: HTMLInputElement | null, value: string) {
  if (value !== "") {
    const values = value.split("");
    if (inputRef) {
      inputRef.value = values[0];
      values.shift();
      return values.join("");
    }
  }
  return "";
}

export const useCode = (props: CodeProps, defaultValue?: string) => {
  const { onChangeCode } = props;
  const [inputRef1, setInputRef1] = useState<HTMLInputElement | null>(null);
  const [inputRef2, setInputRef2] = useState<HTMLInputElement | null>(null);
  const [inputRef3, setInputRef3] = useState<HTMLInputElement | null>(null);
  const [inputRef4, setInputRef4] = useState<HTMLInputElement | null>(null);
  const [inputRef5, setInputRef5] = useState<HTMLInputElement | null>(null);
  const [inputRef6, setInputRef6] = useState<HTMLInputElement | null>(null);
  // const [inputRef1, setInputRef1] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);
  // const [inputRef2, setInputRef2] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);
  // const [inputRef3, setInputRef3] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);
  // const [inputRef4, setInputRef4] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);
  // const [inputRef5, setInputRef5] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);
  // const [inputRef6, setInputRef6] = useState<React.MutableRefObject<HTMLInputElement | null> | null>(null);

  const onChangeFullCode = () => {
    if (onChangeCode) {
      const code1 = inputRef1?.value;
      const code2 = inputRef2?.value;
      const code3 = inputRef3?.value;
      const code4 = inputRef4?.value;
      const code5 = inputRef5?.value;
      const code6 = inputRef6?.value;
      if (
        code1 &&
        code2 &&
        code3 &&
        code4 &&
        code5 &&
        code6 &&
        code1 !== "" &&
        code2 !== "" &&
        code3 !== "" &&
        code4 !== "" &&
        code5 !== "" &&
        code6 !== ""
      ) {
        onChangeCode(`${code1}${code2}${code3}${code4}${code5}${code6}`);
        return;
      }
      onChangeCode("");
    }
  };

  useEffect(() => {
    if (onChangeCode && defaultValue && defaultValue?.length === 6) {
      onChangeCode(defaultValue);
    }
  }, [defaultValue, onChangeCode]);

  const onChangeValueAtIndex = (value: string, index: number) => {
    if (value && value !== "") {
      if (index === 1) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef1, value), index + 1);
        inputRef2?.focus();
      }
      if (index === 2) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef2, value), index + 1);
        inputRef3?.focus();
      }
      if (index === 3) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef3, value), index + 1);
        inputRef4?.focus();
      }
      if (index === 4) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef4, value), index + 1);
        inputRef5?.focus();
      }
      if (index === 5) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef5, value), index + 1);
        inputRef6?.focus();
      }
      if (index === 6) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef6, value), index + 1);
      }
    }

    if (!value) {
      if (index === 1) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef1, value), index - 1);
        inputRef1?.focus();
      }
      if (index === 2) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef2, value), index - 1);
        inputRef1?.focus();
      }
      if (index === 3) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef3, value), index - 1);
        inputRef2?.focus();
      }
      if (index === 4) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef4, value), index - 1);
        inputRef3?.focus();
      }
      if (index === 5) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef5, value), index - 1);
        inputRef4?.focus();
      }
      if (index === 6) {
        onChangeValueAtIndex(isNewStringValueLengthGreaterThanOneChar(inputRef6, value), index - 1);
        inputRef5?.focus();
      }
    }
    onChangeFullCode();
  };

  const onChange = (index: number) => {
    return (value: string) => {
      onChangeValueAtIndex(value, index);
    };
  };

  return {
    onChange,
    setInputRef1,
    setInputRef2,
    setInputRef3,
    setInputRef4,
    setInputRef5,
    setInputRef6,
  };
};
