export type SepaRefundCodeMapper = {
  [key: string]: string | undefined;
};

export enum SepaRefundCode {
  AC01 = "AC01",
  AC04 = "AC04",
  MS03 = "MS03",
  AC06 = "AC06",
  AG01 = "AG01",
  AG02 = "AG02",
  AM04 = "AM04",
  AM05 = "AM05",
  BE04 = "BE04",
  MD01 = "MD01",
  MD02 = "MD02",
  FF01 = "FF01",
  MD06 = "MD06",
  MD07 = "MD07",
  MS02 = "MS02",
  RC01 = "RC01",
  TM01 = "TM01",
  SL01 = "SL01",
  FOCR = "FOCR",
  DUPL = "DUPL",
  TECH = "TECH",
  FRAD = "FRAD",
  AGNT = "AGNT",
  CURR = "CURR",
  CUST = "CUST",
  CUTA = "CUTA",
  UPAY = "UPAY",
  BE05 = "BE05",
  AC13 = "AC13",
  FF05 = "FF05",
  DNOR = "DNOR",
  CNOR = "CNOR",
  RR0104 = "RR01-04",
}

export enum SepaRefundMessageLanguage {
  EN = "EN",
  FR = "FR",
}
