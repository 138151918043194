import { Heading, Text } from "@libeo/design-system";
import * as React from "react";
import { Icon } from "../Icon/Icon";
import { IconValue } from "../Icon/icon.type";
import { BORDER_RADIUS_CARD_ICON, WHITE } from "../theme/ui-constants";
import { CardContent } from "./CardContent";
import { CardElevation } from "./CardElevation";
import { CardEvent } from "./CardEvent";
import { CardWrapper } from "./CardWrapper";

export const CardBlocInfo: React.FunctionComponent<{
  infoNumber?: React.ReactNode;
  description?: React.ReactNode;
  iconBackgroundColor?: React.CSSProperties["backgroundColor"];
  iconValue?: IconValue;
  id?: HTMLElement["id"];
  onClick?: () => void;
}> = ({ infoNumber, description, iconBackgroundColor, iconValue, onClick, id }) => {
  return (
    <CardEvent onClick={onClick} style={{ cursor: "pointer" }}>
      {({ isHovered }) => (
        <CardElevation isHovered={isHovered}>
          <CardWrapper id={id}>
            <CardContent
              style={{
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  {infoNumber !== undefined && (
                    <Heading size="l" marginBottom={0}>
                      {infoNumber}
                    </Heading>
                  )}
                  {description && <Text>{description}</Text>}
                </div>
                {iconValue && (
                  <div
                    style={{
                      backgroundColor: iconBackgroundColor,
                      minWidth: "53px",
                      width: "53px",
                      minHeight: "53px",
                      height: "100%",
                      borderRadius: BORDER_RADIUS_CARD_ICON,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Icon width="30" height="30" color={WHITE} value={iconValue} />
                  </div>
                )}
              </div>
            </CardContent>
          </CardWrapper>
        </CardElevation>
      )}
    </CardEvent>
  );
};
