import { CSSProperties } from "react";

export const fadeIn = (time: number): CSSProperties => ({
  opacity: 100,
  transform: "translateY(0px)",
  transitionDelay: `${time}s`,
  transition: `transform, opacity, ${time}s`,
});

export const fadeOut = (time: number): CSSProperties => ({
  opacity: 0,
  transform: "translateY(10px)",
  transition: `transform, opacity, ${time}s`,
});
