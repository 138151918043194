// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasFiles = (node: Record<string, any>, found: Record<string, any>[] = []): boolean => {
  Object.keys(node).forEach((key) => {
    if (!(typeof node[key] === "object" && node[key] !== null) || found.length > 0) {
      return;
    }

    if ((typeof File !== "undefined" && node[key] instanceof File) || (typeof Blob !== "undefined" && node[key] instanceof Blob)) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};
