import * as React from "react";
import { alertBackground, alertContent, alertWrapper } from "../styles/Banner.styles";
import { BannerColor } from "../types/Banner.types";

export const BannerWrapper: React.FunctionComponent<BannerColor> = ({ color, children }) => {
  return (
    <div data-alert="true" style={alertWrapper(color)}>
      <div style={alertBackground(color)}></div>
      <div style={alertContent}>{children}</div>
    </div>
  );
};
