import * as React from "react";
import { GRAY_ATHENS_2 } from "../theme/ui-constants";

export interface HorizontalLineProps {
  padding?: React.CSSProperties["padding"];
}

export const HorizontalLine: React.FunctionComponent<HorizontalLineProps> = ({ padding }) => (
  <div style={{ width: "100%", height: "1px", borderBottom: `1px solid ${GRAY_ATHENS_2}`, padding }} />
);
