import * as React from "react";
import { Icon, IconValue } from "../Icon/Icon";
import { MarginSmall } from "../Margin";
import { Small } from "../Text";
import { TextGrayShuttle } from "../TextColors/TextGrayShuttle";
import { spacing } from "../theme/spacing";
import { GRAY_SHUTTLE, GREEN_CARABBEAN } from "../theme/ui-constants";
import { PaneLevel } from "./types";

export const PaneItem: React.FunctionComponent<{ level?: PaneLevel; text: React.ReactNode }> = ({ level, text }) => {
  const iconSize = spacing(2);
  return (
    <MarginSmall bottom>
      <div style={{ display: "flex" }}>
        {level === PaneLevel.RECOMMENDED ? (
          <Icon height={iconSize} width={iconSize} value={IconValue.Checkmark} color={GREEN_CARABBEAN} />
        ) : (
          <Icon height={iconSize} width={iconSize} value={IconValue.Dot} color={GRAY_SHUTTLE} />
        )}
        <TextGrayShuttle>
          <Small>{text}</Small>
        </TextGrayShuttle>
      </div>
    </MarginSmall>
  );
};
