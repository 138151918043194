import { GbLegalInformation, LegalInformation } from "@libeo/graphql";

export function areLegalInformationBritish(legalInformation: LegalInformation | undefined | null): legalInformation is GbLegalInformation {
  return Boolean(legalInformation && Object.prototype.hasOwnProperty.call(legalInformation, "companyNumber"));
}

export function assertLegalInformationAreBritish(
  legalInformation: LegalInformation | undefined | null,
): asserts legalInformation is GbLegalInformation {
  const hasLegalInformationCompanyNumber = legalInformation && Object.prototype.hasOwnProperty.call(legalInformation, "companyNumber");
  if (hasLegalInformationCompanyNumber) {
    return;
  }
  throw new TypeError(`Legal informations are not british`);
}
