/* eslint-disable max-lines */
import { lazy } from "@loadable/component";
import * as React from "react";
import { RenderRouteProps } from "./components/Route/type";
import { ROUTE_PATH } from "./routes-constants";
import { lazyCatchError } from "./utils/lazy-catch-error";

const ContentLayoutAside = lazy(() => import(/* webpackChunkName: "ContentLayoutAside" */ "./components/Common/Layout/ContentLayoutAside"));

const AccountingExportList = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AccountingExportList" */ "./screens/Company/Accounting/AccountingExportList"),
  ),
);
const AccountingExport = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AccountingExport" */ "./screens/Company/Accounting/AccountingExport"),
  ),
);
const AccountingExportSettings = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AccountingExportSettings" */ "./screens/Company/Accounting/AccountingExportSettings"),
  ),
);
const AccountingOptions = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AccountingOptions" */ "./screens/Company/Accounting/AccountingOptions"),
  ),
);

const AssistedOnboardingClaim = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "AssistedOnboardingClaim" */ "./screens/AssistedOnboarding/Claim")),
);

const AssistedSignUpScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "AssistedSignUp" */ "./screens/AssistedOnboarding/SignUp")),
);

const AssistedOnboardingPricing = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AssistedOnboardingPricing" */ "./screens/AssistedOnboarding/Pricing"),
  ),
);

const Balance = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Balance" */ "./screens/Profile/Balance")),
);
const Bank = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Bank" */ "./screens/Company/Bank")));

const CreditNotes = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "CreditNotes" */ "./screens/CreditNotes/index")),
);
const CreditNotesDetails = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "CreditNoteDetails" */ "./screens/CreditNotes/CreditNoteDetails")),
);

const Dashboard = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Dashboard" */ "./screens/Dashboard/Dashboard")),
);
const PrependOrganisationAndRedirect = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PrependOrganisationAndRedirect" */ "./screens/PrependOrganisationAndRedirect/PrependOrganisationAndRedirect"),
  ),
);
const Infos = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Infos" */ "./screens/Company/Infos")));
const InfosSideBarEditContact = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "InfosSideBarEditContact" */ "./screens/Company/InfosSideBarEditContact"),
  ),
);
const InfosSideBarEditAddress = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "InfosSideBarEditAddress" */ "./screens/Company/InfosSideBarEditAddress"),
  ),
);
const BusinessApproval = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Infos" */ "./screens/Company/BusinessApproval/BusinessApproval")),
);
const ControlInvoice = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ControlInvoice" */ "./screens/Control/ControlInvoice")),
);
const SplitInvoice = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "SplitInvoice" */ "./screens/Control/ControlInvoice/ControlInvoiceForm/SplitInvoice"),
  ),
);
const ControlCreditNoteDocument = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ControlCreditNoteDocument" */ "./screens/Control/ControlCreditNoteDocument/ControlCreditNoteDocument"),
  ),
);
const ControlPurchaseOrder = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ControlPurchaseOrder" */ "./screens/Control/ControlPurchaseOrder/ControlPurchaseOrder"),
  ),
);
const ControlReceivableInvoice = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ControlPurchaseOrder" */ "./screens/Control/ControlReceivableInvoice/ControlReceivableInvoice"),
  ),
);
const Kyc = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Kyc" */ "./screens/Kyc")));
const Login = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Login" */ "./screens/Login")));

const EvolizOnboarding = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "EvolizLibeoPayOnboarding" */ "./screens/External/Partnership/EvolizOnboarding/EvolizOnboarding"),
  ),
);

const NetworkSupplier = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "NetworkSupplier" */ "./screens/Network/NetworkSupplier")),
);
const NetworkAddSupplier = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "NetworkAddSupplier" */ "./screens/Network/NetworkAddSupplier")),
);
const NetworkAddCustomer = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "NetworkAddCustomerSidebar" */ "./screens/Network/NetworkAddCustomerSidebar"),
  ),
);
const NetworkCustomer = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "NetworkCustomer" */ "./screens/Network/NetworkCustomer")),
);
const NotFound = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "NotFound" */ "./screens/NotFound")));
const Supplier = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Supplier" */ "./screens/Supplier/Supplier")),
);
const PartnerSidebarEditIban = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PartnerSidebarEditIban" */ "./screens/Supplier/PartnerSidebarEditIban"),
  ),
);
const PartnerSidebarUpdateSiren = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PartnerSidebarUpdateSiren" */ "./screens/Supplier/PartnerSidebarUpdateSiren"),
  ),
);
const PartnerSidebarEditAddress = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PartnerSidebarEditAddress" */ "./screens/Supplier/PartnerSidebarEditAddress"),
  ),
);
const SupplierActivitySideBar = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "SupplierSideBar" */ "./screens/Supplier/SupplierSideBar")),
);
const PartnerSidebarEditContact = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PartnerSidebarEditContact" */ "./screens/Supplier/PartnerSidebarEditContact"),
  ),
);
const CustomerSidebarEditContact = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "CustomerSidebarEditContact" */ "./screens/Customer/CustomerSidebarEditContact"),
  ),
);

const SupplierSidebarUpdateCompanyNumber = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "SupplierSidebarUpdateCompanyNumber" */ "./screens/Supplier/SupplierSidebarUpdateCompanyNumber"),
  ),
);
const Customer = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Customer" */ "./screens/Customer/Customer")),
);
const PartnersIntegration = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PartnersIntegration" */ "./screens/PartnersIntegration/PartnersIntegration"),
  ),
);

const PurchaseOrders = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "PurchaseOrders" */ "./screens/PurchaseOrders/index")),
);

const PurchaseOrderDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PurchaseOrderDetail" */ "./screens/PurchaseOrders/PurchaseOrderDetail"),
  ),
);

const EditPurchaseOrder = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "EditPurchaseOrder" */ "./screens/Control/EditPurchaseOrder/EditPurchaseOrder"),
  ),
);

const UnvalidatedPayments = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "UnvalidatedPayments" */ "./screens/UnvalidatedPayments")),
);
const UnvalidatedPaymentsSidebarDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "UnvalidatedPaymentsSidebarDetail" */ "./screens/UnvalidatedPayments/UnvalidatedPaymentsSidebarDetail"),
  ),
);
const UnvalidatedPaymentsSidebarHold = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "UnvalidatedPaymentsSidebarHold" */ "./screens/UnvalidatedPayments/UnvalidatedPaymentsSidebarHold"),
  ),
);
const Payout = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Payout" */ "./screens/Purchase/Payout")),
);
const PayoutSidebarFormPayment = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarFormPayment" */ "./screens/Purchase/Payout/PayoutSidebarFormPayment"),
  ),
);

const PayoutSidebarUSDFormPayment = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarUSDFormPayment" */ "./screens/Purchase/Payout/PayoutSidebarUSDFormPayment"),
  ),
);

const PayoutSidebarPayWithFintecture = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarPayWithFintecture" */ "./screens/Purchase/Payout/PayoutSidebarPayWithFintecture"),
  ),
);

const PayoutSidebarSupplierAddress = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarSupplierAddress" */ "./screens/Purchase/Payout/PayoutSidebarSupplierAddress"),
  ),
);

const PayoutSidebarPayPartially = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarPayPartially" */ "./screens/Purchase/Payout/PayoutSidebarPayPartially"),
  ),
);

const PayoutSidebarPayDepositFromPurchaseOrder = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(/* webpackChunkName: "PayoutSidebarPayDepositFromPurchaseOrder" */ "./screens/Purchase/Payout/PayoutSidebarPayDepositFromPurchaseOrder"),
  ),
);

const PayoutSidebarPayLater = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarPayLater" */ "./screens/Purchase/Payout/PayoutSidebarPayLater"),
  ),
);
const PayoutSidebarPayExternally = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarPayExternally" */ "./screens/Purchase/Payout/PayoutSidebarPayExternally"),
  ),
);
const PayoutSidebarHoldPayment = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarHoldPayment" */ "./screens/Purchase/Payout/PayoutSidebarHoldPayment"),
  ),
);
const PayoutSidebarRequestBusinessApproval = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "PayoutSidebarRequestBusinessApproval" */ "./screens/Purchase/Payout/RequestBusinessApproval/PayoutSidebarRequestBusinessApproval"
      ),
  ),
);
const PayoutSidebarInvoiceDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarInvoiceDetail" */ "./screens/Purchase/Payout/PayoutSidebarInvoiceDetail"),
  ),
);
const PayoutSidebarFilters = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarInvoiceDetail" */ "./screens/Purchase/Payout/PayoutSidebarFilters"),
  ),
);

const PayoutSidebarTestInvoiceIban = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarInvoiceDetail" */ "./screens/Purchase/Payout/PayoutSidebarTestInvoiceIban"),
  ),
);

const PayoutSidebarUpdateControlledInvoice = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PayoutSidebarUpdateControlledInvoice" */ "./screens/Purchase/Payout/PayoutSidebarUpdateControlledInvoice"),
  ),
);
const Pricing = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Pricing" */ "./screens/Pricing/")));
const ProfileEdit = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Edit" */ "./screens/Profile/Edit")),
);
const CompanySettings = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "CompanySettings" */ "./screens/Company/Settings")),
);
const PurchaseDraft = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Draft" */ "./screens/Draft/PurchaseDraft/index")),
);
const SalesDraft = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Draft" */ "./screens/Draft/SalesDraft/index")),
);
const Quickbooks = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Quickbook" */ "./screens/Quickbooks/Quickbooks")),
);
const QuickBooksLogout = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "QuickBooksLogout" */ "./screens/QuickBooksLogout/QuickBooksLogout")),
);
const QuickBooksOptions = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "QuickBooksOptions" */ "./screens/QuickBooksOptions/QuickBooksOptions"),
  ),
);
const ResendEmailVerification = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ResendVerificationEmail" */ "./screens/ResendVerificationEmail")),
);
const ResetPassword = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ResetPassword" */ "./screens/ResetPassword")),
);
const ResetPasswordEmailSent = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ResetPasswordEmailSent" */ "./screens/ResetPasswordEmailSent")),
);
const ResetPasswordRequest = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ResetPasswordRequest" */ "./screens/ResetPasswordRequest")),
);
const NetworkIban = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Security" */ "./screens/Network/IbanValidation/NetworkIban")),
);
const SignOut = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "SignOut" */ "./screens/SignOut")));
const Signup = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Signup" */ "./screens/Signup")));
const SignUpSuccess = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "SignupSuccess" */ "./screens/SignupSuccess")),
);
const Team = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Team" */ "./screens/Team/index")));
const TeamInviteSidebar = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "TeamInviteSidebar" */ "./screens/Team/TeamInviteSidebar")),
);

export const UnvalidatedIbanDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "UnvalidatedIbanDetail" */ "./screens/Network/IbanValidation/UnvalidatedIbanDetail"),
  ),
);

const InvoiceExports = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "InvoiceExports" */ "./screens/InvoiceExports/InvoiceExports")),
);

const ExternalIbanlessPage = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ExternalIbanlessPage" */ "./screens/External/ExternalIbanlessPage/ExternalIbanlessPage"),
  ),
);

const ExternalQuickbooksIframe = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ExternalQuickbooksIframe" */ "./screens/External/ExternalQuickbooksIframe"),
  ),
);

const DeprecatedExternalSupplierPaymentProof = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(/* webpackChunkName: "ExternalSupplierPaymentProof" */ "./screens/External/ExternalSupplierPaymentProof/ExternalSupplierPaymentProof"),
  ),
);

const ExternalPaymentStatusTracker = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "ExternalPaymentStatusTracker" */ "./screens/External/ExternalPaymentStatusTrackerPage/ExternalPaymentStatusTracker"
      ),
  ),
);

const ExternalPayReceivablePage = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ExternalPayReceivablePage" */ "./screens/External/ExternalPayReceivable/ExternalPayReceivablePage"),
  ),
);

const ExternalPayReceivableSuccessPage = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(/* webpackChunkName: "ExternalPayReceivableSuccessPage" */ "./screens/External/ExternalPayReceivable/ExternalPayReceivableSuccessPage"),
  ),
);

const ExternalBusinessApprovalInvoice = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ExternalBusinessApprovalInvoice" */ "./screens/External/ExternalBusinessApprovalInvoice"),
  ),
);
const ExternalBusinessApprovalApproved = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "BusinessApprovalApproved" */ "./components/External/BusinessApproval/BusinessApprovalApproved"),
  ),
);
const ExternalBusinessApprovalRefused = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "BusinessApprovalRefused" */ "./components/External/BusinessApproval/BusinessApprovalRefused"),
  ),
);

const GetStarted = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "GetStartedScreen" */ "./screens/GetStarted/GetStartedScreen")),
);
const QuickbooksLibeoPayOnboarding = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "QuickbooksLibeoPayOnboarding" */ "./screens/External/Partnership/QuickbooksLibeoPayOnboarding/QuickbooksLibeoPayOnboarding"
      ),
  ),
);

const Claim = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Claim" */ "./screens/Claim")));
const ClaimCompanyQualifying1Screen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyQualifying1Screen" */ "./screens/Claim/ClaimCompanyQualifyingEmployeeCountScreen"),
  ),
);
const ClaimCompanyQualifying2Screen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyQualifying2Screen" */ "./screens/Claim/ClaimCompanyQualifyingUserPositionScreen"),
  ),
);
const ClaimCompanyQualifying3Screen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyQualifying3Screen" */ "./screens/Claim/ClaimCompanyQualifyingMonthlyInvoiceScreen"),
  ),
);
const ClaimCompanyQualifying4Screen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyQualifying4Screen" */ "./screens/Claim/ClaimCompanyQualifyingOrganisationCountScreen"),
  ),
);
const ClaimCompanyQualifying5Screen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyQualifying5Screen" */ "./screens/Claim/ClaimCompanyQualifyingPlatformUsageScreen"),
  ),
);
const ClaimCompanyWelcomeScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ClaimCompanyWelcomeScreen" */ "./screens/Claim/ClaimCompanyWelcomeScreen"),
  ),
);

const OnboardingVideoScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "OnboardingVideoScreen" */ "./screens/Claim/OnboardingVideoScreen")),
);

const SwitchOrganisationScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "SwitchOrganisationScreen" */ "./screens/Organisation/SwitchOrganisationScreen"),
  ),
);
const EmptyScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "InvoiceExports" */ "./screens/EmptyScreen/EmptyScreen")),
);

const BankDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "BankDetail" */ "./screens/Company/Bank/BankDetailSidebar")),
);

const BankAdd = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "BankAdd" */ "./screens/Company/Bank/Add")),
);

const BankPayment = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "BankPayment" */ "./screens/Company/Bank/Payment")),
);

const BankSync = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "BankSync" */ "./screens/Company/BankSync/index")),
);

const Mandate = React.lazy(() =>
  lazyCatchError<React.FunctionComponent>(() => import(/* webpackChunkName: "MandateAdd" */ "./screens/Company/Bank/Mandate")),
);
const MandateCodeScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent>(() => import(/* webpackChunkName: "MandateCodeScreen" */ "./screens/Company/Bank/MandateCodeScreen")),
);

const BankSidebarTransactionDetail = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "BankSidebarTransactionDetail" */ "./screens/Company/BankSync/BankSidebarTransactionDetail"),
  ),
);

const Onboarding = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "OnboardingScreen" */ "./screens/Onboarding/OnboardingScreen")),
);
const OnboardingMobile = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "OnboardingScreenMobile" */ "./screens/Onboarding/OnboardingScreenMobile"),
  ),
);

const AnalyticsTreasuryScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AnalyticsTreasuryScreen" */ "./screens/Analytics/AnalyticsTreasuryScreen"),
  ),
);

const AnalyticsInvoiceTagsScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AnalyticsInvoiceTagsScreen" */ "./screens/Analytics/AnalyticsInvoiceTagsScreen"),
  ),
);

const AnalyticsPaymentScheduleScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AnalyticsPaymentScheduleScreen" */ "./screens/Analytics/AnalyticsPaymentScheduleScreen"),
  ),
);

const AnalyticsAmountPerAccountingAccountScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AnalyticsAmountPerAccountingAccountScreen" */ "./screens/Analytics/AnalyticsAmountPerAccountingAccountScreen"),
  ),
);

const SalesScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "SalesScreen" */ "./screens/Sales/index")),
);

const ReceivablesScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "ReceivablesScreen" */ "./screens/Receivables/index")),
);

const ReceivableDetailSideBar = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "ReceivableDetailSideBar" */ "./screens/Receivables/ReceivableDetailSideBar/ReceivableDetailSideBar"),
  ),
);

const SponsorshipInviteScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "SponsorshipInviteScreen" */ "./screens/Sponsorship/SponsorshipInvite"),
  ),
);

const SponsorshipInviteSidebar = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "SponsorshipInviteSidebar" */ "./screens/Sponsorship/SponsorshipInviteSidebar"),
  ),
);

const AutomationsScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "AutomationsScreen" */ "./screens/Automations/AutomationsScreen")),
);
const AddNewAutomationRuleSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "AddNewAutomationRuleSidebarWithProvider" */ "./screens/Automations/AddNewAutomationRuleSidebarWithProvider"),
  ),
);

const EditAutomationRuleSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "EditAutomationRuleSidebarWithProvider" */ "./screens/Automations/EditAutomationRuleSidebarWithProvider"),
  ),
);

const EditSupplierSettingsDueDateRuleSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditSupplierSettingsDueDateRuleSidebarWithProvider" */ "./screens/Automations/EditSupplierSettingsDueDateRuleSidebarWithProvider"
      ),
  ),
);

const EditSupplierSettingsPaymentMethodSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditSupplierSettingsPaymentMethodSidebarWithProvider" */ "./screens/Automations/EditSupplierSettingsPaymentMethodSidebarWithProvider"
      ),
  ),
);

const EditCategoryAutomationRuleOnBusinessApprovalSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditCategoryAutomationRuleOnBusinessApprovalSidebarWithProvider" */ "./screens/Automations/EditCategoryAutomationRuleOnBusinessApprovalSidebarWithProvider"
      ),
  ),
);

const EditSupplierAutomationRuleOnPurchaseAccountSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditSupplierAutomationRuleOnPurchaseAccountSidebarWithProvider" */ "./screens/Automations/EditSupplierAutomationRuleOnPurchaseAccountSidebarWithProvider"
      ),
  ),
);

const EditSupplierAutomationRuleOnTagsSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditSupplierAutomationRuleOnTagsSidebarWithProvider" */ "./screens/Automations/EditSupplierAutomationRuleOnTagsSidebarWithProvider"
      ),
  ),
);

const EditSupplierAutomationRuleOnBusinessApprovalSidebarWithProvider = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () =>
      import(
        /* webpackChunkName: "EditSupplierAutomationRuleOnBusinessApprovalSidebarWithProvider" */ "./screens/Automations/EditSupplierAutomationRuleOnBusinessApprovalSidebarWithProvider"
      ),
  ),
);

const OrganisationPromotionalCodeScreen = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(
    () => import(/* webpackChunkName: "PromotionalCodeToValidate" */ "./screens/OrganisationPromotionalCode/OrganisationPromotionalCodeScreen"),
  ),
);

const PocWise = React.lazy(() => lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Wise" */ "./screens/Wise")));

const WiseAuthCallback = React.lazy(() =>
  lazyCatchError<React.FunctionComponent<{}>>(() => import(/* webpackChunkName: "Wise" */ "./screens/Wise/WiseAuthCallback/WiseAuthCallback")),
);

export const assistedOnboardingRoutes: RenderRouteProps[] = [
  { path: ROUTE_PATH.ASSISTED_ONBOARDING_CLAIM, RenderComponent: AssistedOnboardingClaim },
  { path: ROUTE_PATH.ASSISTED_ONBOARDING_SIGNUP, RenderComponent: AssistedSignUpScreen },
  { path: ROUTE_PATH.ASSISTED_ONBOARDING_PRICING, RenderComponent: AssistedOnboardingPricing },
];

export const externalRoutes: RenderRouteProps[] = [
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_RECEIVE_PAYMENTS,
    RenderComponent: ExternalIbanlessPage,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_SUPPLIER_PAYMENT_PROOF,
    RenderComponent: DeprecatedExternalSupplierPaymentProof,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_PAYMENT_STATUS_TRACKER,
    RenderComponent: ExternalPaymentStatusTracker,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_PAY_RECEIVABLE,
    exact: true,
    RenderComponent: ExternalPayReceivablePage,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_PAY_RECEIVABLE_FINTECTURE_SUCCESS,
    RenderComponent: ExternalPayReceivableSuccessPage,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_BUSINESS_APPROVED,
    RenderComponent: ExternalBusinessApprovalApproved,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_BUSINESS_REFUSED,
    RenderComponent: ExternalBusinessApprovalRefused,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_BUSINESS_APPROVAL,
    RenderComponent: ExternalBusinessApprovalInvoice,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_LIBEO_PAY,
    RenderComponent: QuickbooksLibeoPayOnboarding,
  },
  {
    path: ROUTE_PATH.QUICKBOOKS_IFRAME,
    RenderComponent: ExternalQuickbooksIframe,
  },
  {
    path: ROUTE_PATH.EXTERNAL_ROUTE_EVOLIZ,
    RenderComponent: EvolizOnboarding,
  },
];

export const publicRoutes: RenderRouteProps[] = [
  { path: ROUTE_PATH.LOGIN, RenderComponent: Login },
  { path: ROUTE_PATH.SIGNUP, RenderComponent: Signup },
  { path: ROUTE_PATH.LOGOUT, RenderComponent: SignOut },
  { path: ROUTE_PATH.SIGNUP_SUCCESS, RenderComponent: SignUpSuccess },
  { path: ROUTE_PATH.SIGNUP_CONFIRM_EMAIL, RenderComponent: ResendEmailVerification },
  { path: ROUTE_PATH.RESET_PASSWORD_REQUEST, RenderComponent: ResetPasswordRequest },
  { path: ROUTE_PATH.RESET_PASSWORD_EMAIL_SENT, RenderComponent: ResetPasswordEmailSent },
  { path: ROUTE_PATH.RESET_PASSWORD, RenderComponent: ResetPassword },
  { path: ROUTE_PATH.POC_WISE, RenderComponent: PocWise },
  { path: ROUTE_PATH.WISE_AUTH_CALLBACK, RenderComponent: WiseAuthCallback },
];

export const emptyRoutes: RenderRouteProps[] = [
  {
    path: ROUTE_PATH.EMPTY_ROUTE,
    RenderComponent: EmptyScreen,
    exact: true,
  },
];

export const claimRoute: RenderRouteProps[] = [
  { path: ROUTE_PATH.CLAIM_USER_QUALIFYING_QUESTION_PLATFORM_USAGE, exact: true, RenderComponent: ClaimCompanyQualifying5Screen },
  { path: ROUTE_PATH.CLAIM_USER_QUALIFYING_QUESTION_POSITION, exact: true, RenderComponent: ClaimCompanyQualifying2Screen },
  {
    path: ROUTE_PATH.CLAIM_ONBOARDING_MOBILE,
    exact: true,
    RenderComponent: OnboardingMobile,
  },
  {
    path: ROUTE_PATH.CLAIM_GB_COMPANY,
    RenderComponent: Claim,
  },
  {
    path: ROUTE_PATH.CLAIM_COMPANY,
    RenderComponent: Claim,
  },
];

export const privateRoutes: RenderRouteProps[] = [
  { path: ROUTE_PATH.HOME, exact: true, RenderComponent: Dashboard },
  { path: ROUTE_PATH.CLAIM_COMPANY_EMPLOYEE_COUNT, exact: true, RenderComponent: ClaimCompanyQualifying1Screen },
  { path: ROUTE_PATH.CLAIM_COMPANY_QUALIFYING_QUESTION_INVOICE_COUNT, exact: true, RenderComponent: ClaimCompanyQualifying3Screen },
  { path: ROUTE_PATH.CLAIM_COMPANY_QUALIFYING_QUESTION_ENTITY_COUNT, exact: true, RenderComponent: ClaimCompanyQualifying4Screen },
  { path: ROUTE_PATH.CLAIM_COMPANY_WELCOME, exact: true, RenderComponent: ClaimCompanyWelcomeScreen },
  { path: ROUTE_PATH.ONBOARDING_VIDEO, exact: true, RenderComponent: OnboardingVideoScreen },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_INVOICE, RenderComponent: ControlInvoice },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_AUTOMATION_RULES, RenderComponent: EditAutomationRuleSidebarWithProvider },
  {
    path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_PURCHASE_ACCOUNT_RULE_SIDEBAR,
    RenderComponent: EditSupplierAutomationRuleOnPurchaseAccountSidebarWithProvider,
  },
  {
    path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_TAGS_RULE_SIDEBAR,
    RenderComponent: EditSupplierAutomationRuleOnTagsSidebarWithProvider,
  },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_PAYMENT_METHOD_RULE_SIDEBAR, RenderComponent: EditSupplierSettingsPaymentMethodSidebarWithProvider },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_DUE_DATE_RULE_SIDEBAR, RenderComponent: EditSupplierSettingsDueDateRuleSidebarWithProvider },
  {
    path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_BUSINESS_APPROVAL_RULE_SIDEBAR,
    RenderComponent: EditSupplierAutomationRuleOnBusinessApprovalSidebarWithProvider,
  },
  {
    path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_EDIT_CATEGORY_RULE_ON_BUSINESS_APPROVAL_SIDEBAR,
    RenderComponent: EditCategoryAutomationRuleOnBusinessApprovalSidebarWithProvider,
  },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_INVOICE_SIMPLIFIED, exact: true, RenderComponent: ControlInvoice },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_INVOICE_SPLIT, RenderComponent: SplitInvoice },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_CREDIT_NOTE_DOCUMENT, RenderComponent: ControlCreditNoteDocument },
  { path: ROUTE_PATH.PURCHASE_DRAFT_CONTROL_PURCHASE_ORDER, RenderComponent: ControlPurchaseOrder },
  { path: ROUTE_PATH.SALES_DRAFT_CONTROL_RECEIVABLE_INVOICE, RenderComponent: ControlReceivableInvoice },
  { path: ROUTE_PATH.PURCHASE_DRAFT, exact: true, RenderComponent: PurchaseDraft },
  { path: ROUTE_PATH.SALES_DRAFT, exact: true, RenderComponent: SalesDraft },
  { path: ROUTE_PATH.PURCHASE_BILLS, RenderComponent: Payout },
  { path: ROUTE_PATH.PURCHASE_BILLS_PAY, RenderComponent: PayoutSidebarFormPayment, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_BILLS_PAY_USD, RenderComponent: PayoutSidebarUSDFormPayment, RenderComponentFallBack: ContentLayoutAside },
  {
    path: ROUTE_PATH.PURCHASE_BILLS_PAY_NOW_FINTECTURE,
    RenderComponent: PayoutSidebarPayWithFintecture,
    RenderComponentFallBack: ContentLayoutAside,
  },
  {
    path: ROUTE_PATH.PURCHASE_BILLS_PAY_NOW_FINTECTURE_SUPPLIER_ADDRESS,
    RenderComponent: PayoutSidebarSupplierAddress,
    RenderComponentFallBack: ContentLayoutAside,
  },
  { path: ROUTE_PATH.PURCHASE_BILLS_PAY_PARTIALLY, RenderComponent: PayoutSidebarPayPartially, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_BILLS_PAY_LATER, RenderComponent: PayoutSidebarPayLater, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_BILLS_PAY_EXTERNALLY, RenderComponent: PayoutSidebarPayExternally, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_BILLS_HOLD_PAYMENT, RenderComponent: PayoutSidebarHoldPayment, RenderComponentFallBack: ContentLayoutAside },
  {
    path: ROUTE_PATH.PURCHASE_BILLS_REQUEST_BUSINESS_APPROVAL,
    RenderComponent: PayoutSidebarRequestBusinessApproval,
    RenderComponentFallBack: ContentLayoutAside,
  },
  { path: ROUTE_PATH.PURCHASE_BILLS_FILTERS, RenderComponent: PayoutSidebarFilters, RenderComponentFallBack: ContentLayoutAside, exact: true },
  { path: ROUTE_PATH.PURCHASE_BILLS_DETAIL, RenderComponent: PayoutSidebarInvoiceDetail, RenderComponentFallBack: ContentLayoutAside, exact: true },
  { path: ROUTE_PATH.PURCHASE_BILLS_DETAIL_TEST_INVOICE, RenderComponent: PayoutSidebarInvoiceDetail, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_BILLS_IBAN_TEST_INVOICE, RenderComponent: PayoutSidebarTestInvoiceIban, RenderComponentFallBack: ContentLayoutAside },
  {
    path: ROUTE_PATH.PURCHASE_BILLS_UPDATE_INVOICE,
    RenderComponent: PayoutSidebarUpdateControlledInvoice,
    RenderComponentFallBack: ContentLayoutAside,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK_MANDATE,
    RenderComponent: Mandate,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK_MANDATE_CODE,
    RenderComponent: MandateCodeScreen,
  },
  { path: ROUTE_PATH.PURCHASE_CREDIT_NOTES, RenderComponent: CreditNotes },
  { path: ROUTE_PATH.PURCHASE_CREDIT_NOTES_DETAILS, RenderComponent: CreditNotesDetails, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_REQUESTS, RenderComponent: UnvalidatedPayments },
  { path: ROUTE_PATH.PURCHASE_REQUESTS_DETAIL, RenderComponent: UnvalidatedPaymentsSidebarDetail, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_REQUESTS_HOLD, RenderComponent: UnvalidatedPaymentsSidebarHold, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_ORDERS, RenderComponent: PurchaseOrders },
  { path: ROUTE_PATH.PURCHASE_ORDER_DETAIL, RenderComponent: PurchaseOrderDetail, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.PURCHASE_ORDER_EDIT, RenderComponent: EditPurchaseOrder },
  { path: ROUTE_PATH.PURCHASE_ORDER_PAY, RenderComponent: PayoutSidebarPayDepositFromPurchaseOrder, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.NETWORK_SUPPLIER, RenderComponent: NetworkSupplier },
  { path: ROUTE_PATH.NETWORK_SUPPLIER_ADD_SUPPLIER, RenderComponent: NetworkAddSupplier },
  { path: ROUTE_PATH.NETWORK_CUSTOMER_ADD_CUSTOMER, RenderComponent: NetworkAddCustomer },
  { path: ROUTE_PATH.NETWORK_CUSTOMER, RenderComponent: NetworkCustomer },
  { path: ROUTE_PATH.SUPPLIER, RenderComponent: Supplier },
  { path: ROUTE_PATH.SUPPLIER, RenderComponent: SupplierActivitySideBar, RenderComponentFallBack: ContentLayoutAside, exact: true },
  { path: ROUTE_PATH.SUPPLIER_EDIT_IBAN, RenderComponent: PartnerSidebarEditIban, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.SUPPLIER_ADD_SIREN, RenderComponent: PartnerSidebarUpdateSiren, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.SUPPLIER_EDIT_SIREN, RenderComponent: PartnerSidebarUpdateSiren, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.SUPPLIER_EDIT_ADDRESS, RenderComponent: PartnerSidebarEditAddress, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.SUPPLIER_EDIT_CONTACT, RenderComponent: PartnerSidebarEditContact, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.SUPPLIER_ADD_COMPANY_NUMBER, RenderComponent: SupplierSidebarUpdateCompanyNumber, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.CUSTOMER, RenderComponent: Customer },
  { path: ROUTE_PATH.CUSTOMER_EDIT_CONTACT, RenderComponent: CustomerSidebarEditContact, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.QUICKBOOKS_LOGOUT, RenderComponent: QuickBooksLogout },
  { path: ROUTE_PATH.QUICKBOOKS_OPTIONS, RenderComponent: QuickBooksOptions },
  {
    path: ROUTE_PATH.SWITCH_ORGANISATION,
    RenderComponent: SwitchOrganisationScreen,
  },
  {
    path: ROUTE_PATH.INVOICES_EXPORT,
    RenderComponent: InvoiceExports,
  },
  {
    path: ROUTE_PATH.BALANCE,
    RenderComponent: Balance,
  },
  { path: ROUTE_PATH.PROFILE_INFORMATIONS, RenderComponent: ProfileEdit },
  { path: ROUTE_PATH.COMPANY_SETTINGS, RenderComponent: CompanySettings },
  {
    path: ROUTE_PATH.COMPANY_INFORMATIONS_BUSINESS_APPROVAL,
    exact: true,
    RenderComponent: BusinessApproval,
  },
  {
    path: ROUTE_PATH.COMPANY_INFORMATIONS_ACTION,
    exact: true,
    RenderComponent: Infos,
  },
  {
    path: ROUTE_PATH.COMPANY_INFORMATIONS_EDIT_CONTACT,
    exact: true,
    RenderComponent: InfosSideBarEditContact,
  },
  {
    path: ROUTE_PATH.COMPANY_INFORMATIONS_EDIT_ADDRESS,
    exact: true,
    RenderComponent: InfosSideBarEditAddress,
  },
  {
    path: ROUTE_PATH.COMPANY_ACCOUNTING_OPTIONS,
    RenderComponent: AccountingOptions,
  },
  {
    path: ROUTE_PATH.COMPANY_INTEGRATIONS,
    RenderComponent: PartnersIntegration,
  },
  {
    path: ROUTE_PATH.PREPREND_ORGANISATION_ID_AND_REDIRECT,
    RenderComponent: PrependOrganisationAndRedirect,
    exact: true,
  },
  {
    path: ROUTE_PATH.COMPANY_ACCOUNTING_LIST,
    RenderComponent: AccountingExportList,
    exact: true,
  },
  {
    path: ROUTE_PATH.COMPANY_ACCOUNTING,
    RenderComponent: AccountingExport,
    exact: true,
  },
  {
    path: ROUTE_PATH.COMPANY_ACCOUNTING_SETTINGS,
    RenderComponent: AccountingExportSettings,
    exact: true,
  },
  {
    path: ROUTE_PATH.COMPANY_QUICKBOOKS,
    RenderComponent: Quickbooks,
  },
  {
    path: ROUTE_PATH.COMPANY_TEAM,
    RenderComponent: Team,
  },
  {
    path: ROUTE_PATH.COMPANY_TEAM_INVITE,
    RenderComponent: TeamInviteSidebar,
  },
  {
    path: ROUTE_PATH.COMPANY_PRICING,
    RenderComponent: Pricing,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK,
    RenderComponent: Bank,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK_DETAIL,
    RenderComponent: BankDetail,
    RenderComponentFallBack: ContentLayoutAside,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK_ADD,
    RenderComponent: BankAdd,
    RenderComponentFallBack: ContentLayoutAside,
  },
  {
    path: ROUTE_PATH.COMPANY_BANK_PAYMENT,
    RenderComponent: BankPayment,
    RenderComponentFallBack: ContentLayoutAside,
  },
  {
    path: ROUTE_PATH.ONBOARDING,
    RenderComponent: Onboarding,
  },
  {
    path: ROUTE_PATH.GET_STARTED,
    RenderComponent: GetStarted,
    exact: true,
  },
  { path: ROUTE_PATH.COMPANY_BANK_SYNC, RenderComponent: BankSync },
  { path: ROUTE_PATH.COMPANY_BANK_TRANSACTION_DETAIL, RenderComponent: BankSidebarTransactionDetail, RenderComponentFallBack: ContentLayoutAside },
  { path: ROUTE_PATH.ANALYTICS_TREASURY, RenderComponent: AnalyticsTreasuryScreen },
  { path: ROUTE_PATH.ANALYTICS_INVOICE_TAGS, RenderComponent: AnalyticsInvoiceTagsScreen },
  { path: ROUTE_PATH.ANALYTICS_PAYMENT_SCHEDULE, RenderComponent: AnalyticsPaymentScheduleScreen },
  { path: ROUTE_PATH.ANALYTICS_AMOUNT_PER_ACCOUNTING_ACCOUNT, RenderComponent: AnalyticsAmountPerAccountingAccountScreen },
  { path: ROUTE_PATH.SALES, exact: true, RenderComponent: SalesScreen },
  { path: ROUTE_PATH.RECEIVABLES, RenderComponent: ReceivablesScreen },
  { path: ROUTE_PATH.RECEIVABLES_DETAIL, RenderComponent: ReceivableDetailSideBar, RenderComponentFallBack: ContentLayoutAside, exact: true },
  { path: ROUTE_PATH.SPONSORSHIP_INVITE, RenderComponent: SponsorshipInviteScreen },
  { path: ROUTE_PATH.SPONSORSHIP_INVITE, RenderComponent: SponsorshipInviteSidebar },
  { path: ROUTE_PATH.NETWORK_IBAN, RenderComponent: NetworkIban },
  {
    path: ROUTE_PATH.NETWORK_IBAN_SCREEN_UNVALIDATED_IBAN_DETAIL,
    RenderComponent: UnvalidatedIbanDetail,
    RenderComponentFallBack: ContentLayoutAside,
  },
  { path: ROUTE_PATH.AUTOMATIONS, RenderComponent: AutomationsScreen },
  { path: ROUTE_PATH.ADD_NEW_AUTOMATION_RULE_SIDEBAR, RenderComponent: AddNewAutomationRuleSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_AUTOMATION_RULE_SIDEBAR, RenderComponent: EditAutomationRuleSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_DUE_DATE_RULE_SIDEBAR, RenderComponent: EditSupplierSettingsDueDateRuleSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_PURCHASE_ACCOUNT_RULE_SIDEBAR, RenderComponent: EditSupplierAutomationRuleOnPurchaseAccountSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_TAGS_RULE_SIDEBAR, RenderComponent: EditSupplierAutomationRuleOnTagsSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_PAYMENT_METHOD_RULE_SIDEBAR, RenderComponent: EditSupplierSettingsPaymentMethodSidebarWithProvider },
  { path: ROUTE_PATH.EDIT_BUSINESS_APPROVAL_RULE_SIDEBAR, RenderComponent: EditSupplierAutomationRuleOnBusinessApprovalSidebarWithProvider },
  {
    path: ROUTE_PATH.EDIT_CATEGORY_RULE_ON_BUSINESS_APPROVAL_SIDEBAR,
    RenderComponent: EditCategoryAutomationRuleOnBusinessApprovalSidebarWithProvider,
  },
  { path: ROUTE_PATH.ORGANISATION_PROMOTIONAL_CODE, RenderComponent: OrganisationPromotionalCodeScreen },
];

export const kycRoutes: RenderRouteProps[] = [
  {
    path: ROUTE_PATH.KYC_STEP_VIEW,
    RenderComponent: Kyc,
  },
];

export const emptyRoute: RenderRouteProps = { RenderComponent: NotFound, path: "*" };
