import * as Tabs from "@radix-ui/react-tabs";
import * as React from "react";
import { Grid } from "../Layout";

interface TabsListProps extends Tabs.TabsListOwnProps {
  children: React.ReactNode;
}

export function TabsList(props: TabsListProps): React.ReactElement {
  return <Tabs.List as={Grid} autoFlow="column" columnGap={3} height="26px" borderBottomWidth={1} {...props} />;
}
