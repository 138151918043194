import * as React from "react";
import { Input } from "./Input";
import { useCode } from "./hooks/useCode.hook";
import { CodeProps } from "./types";

const InputCodeStyle = {
  marginRight: 10,
  width: 55,
};

export const InputCode: React.FunctionComponent<CodeProps> = ({ defaultValue, ...props }) => {
  const defaultValues = defaultValue?.split("");

  const { onChange, setInputRef1, setInputRef2, setInputRef3, setInputRef4, setInputRef5, setInputRef6 } = useCode(props, defaultValue);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        <Input
          id="input-code-1"
          key="input-code-1"
          {...props}
          defaultValue={defaultValues?.[0]}
          ref={setInputRef1}
          onChangeStringValue={onChange(1)}
          style={InputCodeStyle}
        />
        <Input
          id="input-code-2"
          key="input-code-2"
          {...props}
          defaultValue={defaultValues?.[1]}
          ref={setInputRef2}
          onChangeStringValue={onChange(2)}
          style={InputCodeStyle}
        />
        <Input
          id="input-code-3"
          key="input-code-3"
          {...props}
          defaultValue={defaultValues?.[2]}
          ref={setInputRef3}
          onChangeStringValue={onChange(3)}
          style={InputCodeStyle}
        />
        <Input
          id="input-code-4"
          key="input-code-4"
          {...props}
          defaultValue={defaultValues?.[3]}
          ref={setInputRef4}
          onChangeStringValue={onChange(4)}
          style={InputCodeStyle}
        />
        <Input
          id="input-code-5"
          key="input-code-5"
          {...props}
          defaultValue={defaultValues?.[4]}
          ref={setInputRef5}
          onChangeStringValue={onChange(5)}
          style={InputCodeStyle}
        />
        <Input
          id="input-code-6"
          key="input-code-6"
          {...props}
          defaultValue={defaultValues?.[5]}
          maxLength={1}
          ref={setInputRef6}
          onChangeStringValue={onChange(6)}
          style={{
            width: 55,
          }}
        />
      </div>
    </div>
  );
};
