import * as React from "react";
import { Checkbox } from "../Form/Checkbox";
import { spacing } from "../theme/spacing";
import { WHITE } from "../theme/ui-constants";
import { TableHeadText } from "./TableHeadText";
import { TableTh } from "./TableTh";
import { TableTr } from "./TableTr";
import { TableData } from "./types";
import { TableHeadProps, useTableHead } from "./useTableHead";

function renderRowSelectionHead(
  defaultCheckboxChecked: boolean,
  isSticky: boolean,
  topSticky: string | number | undefined,
  rowSelection: boolean,
  onBeforeCheckAllRow: (checked: boolean) => void,
): React.ReactNode {
  return rowSelection ? (
    <TableTh
      key="th-checkbox"
      styles={{
        width: "40px",
        position: isSticky ? "sticky" : "relative",
        top: isSticky ? topSticky : "0px",
        zIndex: 1,
        background: WHITE,
        paddingLeft: spacing(2),
        paddingRight: spacing(1),
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "auto",
          }}
        >
          <Checkbox
            key="table-head-checkbox-all"
            defaultChecked={defaultCheckboxChecked}
            style={{ marginBottom: 0 }}
            onChangeBooleanValue={onBeforeCheckAllRow}
          />
        </div>
      </div>
    </TableTh>
  ) : null;
}

export const TableHead = <T extends TableData>(props: TableHeadProps<T>): JSX.Element => {
  const { columns, topSticky, rowSelection, defaultCheckboxChecked, isSticky, onBeforeCheckAllRow, rowHeaderHeight } = useTableHead(props);

  return (
    <TableTr
      rowHeight={rowHeaderHeight}
      styles={{
        transform: "none",
        boxShadow: "none",
        cursor: "inherit",
      }}
    >
      {renderRowSelectionHead(defaultCheckboxChecked, isSticky, topSticky, Boolean(rowSelection), onBeforeCheckAllRow)}
      {columns?.map(
        (column: any, columnIndex: any): JSX.Element => {
          const isSortable = column.title && props.onChangeOrderBy;
          const isFirst = columnIndex === 0 && !rowSelection;
          const isLast = columnIndex === columns.length - 1;

          function handleClickActionTableTh(): void {
            if (isSortable && props.onChangeOrderBy) {
              props.onChangeOrderBy(column?.fieldNameOrder);
            }
          }

          return (
            <TableTh
              key={`th-${columnIndex}`}
              styles={{
                width: column.width,
                textAlign: column.align,
                position: isSticky ? "sticky" : "relative",
                top: isSticky ? topSticky : "0px",
                zIndex: 1,
                background: WHITE,
                paddingLeft: isFirst ? spacing(2) : spacing(1),
                paddingRight: isLast ? spacing(2) : spacing(1),
                cursor: isSortable ? "pointer" : "initial",
              }}
              onClick={handleClickActionTableTh}
            >
              {isSortable && props?.renderTitleThHead
                ? props.renderTitleThHead({
                    title: column.title as string,
                    fieldNameOrder: column.fieldNameOrder,
                    titleAlign: column.titleAlign ?? column.align,
                  })
                : column.title && <TableHeadText titleAlign={column.titleAlign ?? column.align}>{column.title}</TableHeadText>}
            </TableTh>
          );
        },
      )}
    </TableTr>
  );
};
