// Colors
export const QUICKBOOKS_GRAY_04 = "#BABEC5";
export const QUICKBOOKS_GRAY_03 = "#8D9096";
export const QUICKBOOKS_DK_GRAY = "#393A3D";
export const QUICKBOOKS_GREEN = "#2CA01C";
export const QUICKBOOKS_GREEN_01 = "#108000";
export const QUICKBOOKS_WHITE = "#FFFFFF";
export const QUICKBOOKS_WHITE_HOVER = "#F4F4F4";

export const QUICKBOOKS_LANDING_BACK = "#F4F4F4";
export const QUICKBOOKS_LANDING_BACK_INNER = "#FCFCFF";

// Fonts
export const QUICKBOOKS_FONT = "Avenir";
export const QUICKBOOKS_FONT_WEIGHT_BOLD = 800;
export const QUICKBOOKS_FONT_WEIGHT_PLUS = 500;
export const QUICKBOOKS_FONT_SIZE_H1 = "24px";
export const QUICKBOOKS_FONT_SIZE_H2 = "20px";
export const QUICKBOOKS_FONT_SIZE_LARGE = "16px";
export const QUICKBOOKS_FONT_SIZE_REGULAR = "14px";
export const QUICKBOOKS_FONT_SIZE_SMALL = "12px";
