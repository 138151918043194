import moment, { parseZone, utc } from "moment";
import { DateWithoutTime } from "../date-without-time/date-without-time";

export const FRENCH_DATE_FORMAT = "DD/MM/YYYY";
export const FRENCH_DATE_MID_LONG_FORMAT = "DD MMMM";
export const FRENCH_DATE_LONG_FORMAT = "DD MMMM YYYY";
export const FRENCH_DATE_LONG_FORMAT_AT_MIDNIGHT = "YYYY-MM-DD 00:00:00";
export type DateInFrenchformat = string;
export type DateWithTimeAtMidnight = string;

export const dateWithoutTimeToFrenchFormat = (dateWithoutTime: DateWithoutTime): DateInFrenchformat => {
  return parseZone(dateWithoutTime).format(FRENCH_DATE_FORMAT);
};

export const dateToFrenchFormat = (date: Date): DateInFrenchformat => {
  return parseZone(date).format(FRENCH_DATE_FORMAT);
};

export const dateWithoutTimeToMidLongFrenchFormat = (dateWithoutTime: DateWithoutTime): DateInFrenchformat => {
  return parseZone(dateWithoutTime).format(FRENCH_DATE_MID_LONG_FORMAT);
};

export const dateToMidLongFrenchFormat = (date: Date): DateInFrenchformat => {
  return parseZone(date).format(FRENCH_DATE_MID_LONG_FORMAT);
};

export const dateWithoutTimeToLongFrenchFormat = (dateWithoutTime: DateWithoutTime): DateInFrenchformat => {
  return parseZone(dateWithoutTime).format(FRENCH_DATE_LONG_FORMAT);
};

export const dateToLongFrenchFormat = (date: Date): DateInFrenchformat => {
  return parseZone(date).format(FRENCH_DATE_LONG_FORMAT);
};

export const dateWithoutTimeToDateAtMidnight = (date: DateWithoutTime): DateWithTimeAtMidnight => {
  return utc(date).format(FRENCH_DATE_LONG_FORMAT_AT_MIDNIGHT);
};

export const timestampToDateToFrenchFormat = (timestamp: Date | number): string => {
  return moment(timestamp as number).format(FRENCH_DATE_FORMAT);
};
