import { useState, useEffect, RefObject } from "react";

export const useDetectOverflow = (ref: RefObject<HTMLElement>): { isRefXOverflowing: boolean; isRefYOverflowing: boolean } => {
  const [isRefXOverflowing, setRefXOverflowing] = useState(false);
  const [isRefYOverflowing, setRefYOverflowing] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const isXOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
    const isYOverflowing = ref.current.scrollHeight > ref.current.clientHeight;

    if (isRefXOverflowing !== isXOverflowing) {
      setRefXOverflowing(isXOverflowing);
    }

    if (isRefYOverflowing !== isYOverflowing) {
      setRefYOverflowing(isYOverflowing);
    }
  }, [ref, isRefXOverflowing, isRefYOverflowing]);

  return {
    isRefXOverflowing,
    isRefYOverflowing,
  };
};
