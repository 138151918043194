import { MouseEvent, useState } from "react";
import { useHover } from "./useHover.hook";

export const useMouseMove = <T extends HTMLElement>(): {
  isHovered: boolean;
  point: {
    x: number;
    y: number;
  };
  onMouseOver: () => void;
  onMouseOut: () => void;
  onMouseMove: (event: MouseEvent<T, globalThis.MouseEvent>) => void;
} => {
  const { isHovered, onMouseOver, onMouseOut } = useHover();
  const [point, setPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const onMouseMove = (event: MouseEvent<T>): void => {
    setPoint({
      x: parseInt(`${event.clientX}`, 10),
      y: parseInt(`${event.clientY}`, 10),
    });
  };

  return {
    isHovered,
    point,
    onMouseOver,
    onMouseOut,
    onMouseMove,
  };
};
