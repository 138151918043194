import * as React from "react";
import ReactDatePicker from "react-datepicker";
import "./datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { useCustomHeader } from "./Date/useCustomHeader";
import { DatePickerIcon } from "./Icon/icons";
import { FormItemWrapper } from "./Wrapper";
import { useDatePicker } from "./hooks/useDatePicker.hook";
import { useInput } from "./hooks/useInput.hook";
import { inputStyle } from "./styles";
import { DatepickerProps, InputProps } from "./types";

export const DatePicker = React.forwardRef<HTMLInputElement, DatepickerProps>(function DatePickerWithForwardRef(props, ref) {
  const { required, id, label, isDisabled: disabled, message, minDate, description, style, ...rest } = props;
  const { color, error, inputFocus } = useInput((props as unknown) as InputProps);
  const { dateValue, defaultValueString, onChangeDateFromDatePicker, onChangeDateString, calendarRef, onClickCalendarIcon } = useDatePicker(props);
  const customHeader = useCustomHeader();

  function forwardInputRef(instance: HTMLInputElement | null) {
    if (typeof ref === "function" && instance) {
      ref(instance);
    }
  }

  return (
    <FormItemWrapper
      style={style}
      color={color}
      label={label}
      description={description}
      required={required}
      inputFocus={inputFocus}
      inputHasValue={Boolean(dateValue)}
      onClickSuffix={onClickCalendarIcon}
      suffix={<DatePickerIcon />}
      message={message}
      disabled={disabled}
    >
      <div className="date-picker">
        <ReactDatePicker
          id={id}
          ref={calendarRef}
          minDate={minDate}
          locale="fr"
          autoComplete="off"
          disabled={disabled}
          renderCustomHeader={customHeader}
          dateFormat="dd/MM/yyyy"
          onChange={onChangeDateFromDatePicker}
          openToDate={dateValue}
          selected={dateValue}
          portalId="date-picker"
          popperModifiers={{ flip: { enabled: false } }}
          customInputRef="customInputRef"
          customInput={
            <input
              ref={forwardInputRef}
              disabled={disabled}
              data-error={error}
              defaultValue={defaultValueString}
              style={inputStyle(true, Boolean(disabled), Boolean(label))}
              onChange={onChangeDateString}
            />
          }
          {...rest}
        />
      </div>
    </FormItemWrapper>
  );
});
