import { CheckedState } from "@radix-ui/react-checkbox";
import * as React from "react";
import { useOffsetTop } from "../../hooks/useOffsetTop.hook";
import { Checkbox } from "../Checkbox";
import { Box } from "../Layout";
import { TableCellHeader } from "./TableCellHeader";
import { TableRow } from "./TableRow";
import { TableItem, TableProps, TableSortDirection } from "./types";

const getNewSortDirection = (isCurrentSortedColumn: boolean, sortDirection?: TableSortDirection): TableSortDirection => {
  const defaultSortDirection = "ASC";

  if (!isCurrentSortedColumn) {
    return defaultSortDirection;
  }

  if (sortDirection === "ASC") {
    return "DESC";
  }

  return "ASC";
};

type CheckboxCellProps<T extends TableItem> = Pick<TableProps<T>, "data" | "onUpdateRowSelection" | "selectedRowKeys">;

const CheckboxCell = <T extends TableItem>(props: CheckboxCellProps<T>): React.ReactElement => {
  const { data, onUpdateRowSelection, selectedRowKeys = [] } = props;
  const selectableData = data.map((row) => row.id).filter((id): id is string => typeof id === "string");
  // const selectableData = selectableRowWithId.map((row) => row.id);
  const isCheckboxChecked = selectedRowKeys.length > 0 && selectedRowKeys.length === selectableData.length;

  const toggleCheckbox = (isSelected: CheckedState): void => {
    if (onUpdateRowSelection) {
      if (isSelected) {
        onUpdateRowSelection(selectableData);
      } else {
        onUpdateRowSelection([]);
      }
    }
  };

  return (
    <TableCellHeader width="40px" isFirstColumn={true} isLastColumn={false}>
      <Checkbox checked={isCheckboxChecked} onChange={toggleCheckbox} />
    </TableCellHeader>
  );
};

type TableHeaderProps<TableItem extends object> = Pick<
  TableProps<TableItem>,
  | "columns"
  | "data"
  | "isInline"
  | "isSticky"
  | "onSortChange"
  | "sortFieldName"
  | "sortDirection"
  | "hasRowSelection"
  | "onUpdateRowSelection"
  | "selectedRowKeys"
>;

export const TableHeader = <TableItem extends object>(props: TableHeaderProps<TableItem>): React.ReactElement => {
  const { columns, data, isInline, isSticky, sortFieldName, sortDirection, hasRowSelection, onUpdateRowSelection, selectedRowKeys = [] } = props;

  const { tableRef, offsetTop } = useOffsetTop<HTMLTableSectionElement>();

  return (
    <Box
      as="thead"
      display="table-header-group"
      borderBottomWidth={1}
      position={isSticky ? "sticky" : undefined}
      top={offsetTop}
      backgroundColor="white"
      zIndex={2}
      ref={tableRef}
    >
      <TableRow height={isInline ? 32 : 48}>
        {hasRowSelection && <CheckboxCell data={data} onUpdateRowSelection={onUpdateRowSelection} selectedRowKeys={selectedRowKeys} />}

        {columns.map((column, columnIndex) => {
          const isSortable = column.isSortable && !!column.title && !!props.onSortChange;

          const isCurrentSortedColumn = sortFieldName === column.fieldName;

          const handleClickActionTableTh = (): void => {
            const newSortDirection = getNewSortDirection(isCurrentSortedColumn, sortDirection);

            if (isSortable && props.onSortChange) {
              props.onSortChange(column?.fieldName, newSortDirection);
            }
          };

          return (
            <TableCellHeader
              key={column.fieldName}
              onClick={handleClickActionTableTh}
              {...column}
              isSortable={isSortable}
              sortDirection={isCurrentSortedColumn ? sortDirection : undefined}
              isFirstColumn={!hasRowSelection && columnIndex === 0}
              isLastColumn={columnIndex === columns.length - 1}
            >
              {column.title}
            </TableCellHeader>
          );
        })}
      </TableRow>
    </Box>
  );
};
